export const addressConstant = {
    GET_ADDRESSLIST_SUCCESS: 'GET_ADDRESSLIST_SUCCESS',
    GET_ADDRESSLIST_FAILED: 'GET_ADDRESSLIST_FAILED',
    GET_ADDRESSLIST_PENDING: 'GET_ADDRESSLIST_PENDING',
    GET_LOAD_ADDRESSLIST_SUCCESS: 'GET_LOAD_ADDRESSLIST_SUCCESS',
    GET_LOAD_ADDRESSLIST_FAILED: 'GET_LOAD_ADDRESSLIST_FAILED',
    GET_LOAD_ADDRESSLIST_PENDING: 'GET_LOAD_ADDRESSLIST_PENDING',
    GET_PHONECODE_LIST_PENDING: 'GET_PHONECODE_LIST_PENDING',
    GET_PHONECODE_LIST_SUCCESS: 'GET_PHONECODE_LIST_SUCCESS',
    GET_PHONECODE_LIST_FAILED: 'GET_PHONECODE_LIST_FAILED',
}
import { barcodeService } from "../../services/barcode.service";
import { barcodeConstant } from "../constants/barcode.constant";

export const getBarCodeData = (data) => {
    return () => {
        return barcodeService.getBarCodeService(data);
    };
};

const getBarcodeColumnsPending = () => ({
    type: barcodeConstant.GET_BARCODE_COLUMNS_PENDING,
});
const getBarcodeColumnsFailed = () => ({
    type: barcodeConstant.GET_BARCODE_COLUMNS_FAILED,
});
const getBarcodeColumnsSuccess = (response) => ({
    type: barcodeConstant.GET_BARCODE_COLUMNS_SUCCESS,
    payload: response,
});

export const fetchBarcodeColumns = () => {
    return (dispatch) => {
        dispatch(getBarcodeColumnsPending());
        barcodeService.getBarCodeColumns()
            .then((result) => {
                dispatch(getBarcodeColumnsSuccess(result.data.EntityList));
            })
            .catch((error) => {
                dispatch(getBarcodeColumnsFailed());
            });
    };
};

export const checkIfDuplicateName = (integrationName) => {
    return () => {
        return barcodeService.checkIntegrationName(integrationName);
    }
}

export const barcodeUploadIntegration = (data) => {
    return () => {
        return barcodeService.barcodeUploadIntegrationService(data);
    }
}

export const addUpdateBarcodeIntegration = (data) => {
    return () => {
        return barcodeService.addUpdateBarcodeIntegrationService(data);
    }
}

const getAllBarcodeIntegrationPending = () => ({
    type: barcodeConstant.GET_INTEGRATION_LIST_PENDING,
});
const getAllBarcodeIntegrationFailed = () => ({
    type: barcodeConstant.GET_BARCODE_COLUMNS_FAILED,
});
const getAllBarcodeIntegrationSuccess = (response) => ({
    type: barcodeConstant.GET_INTEGRATION_LIST_SUCCESS,
    payload: response,
});

export const getAllBarcodeIntegration = (payload) => {
    return (dispatch) => {
        dispatch(getAllBarcodeIntegrationPending());
        barcodeService.getAllBarcodeIntegrationService(payload)
            .then((result) => {
                dispatch(getAllBarcodeIntegrationSuccess(result.data.Entity));
            })
            .catch((error) => {
                dispatch(getAllBarcodeIntegrationFailed());
            });
    };
};

export const deleteIntegration = (integrationId) => {
    return () => {
        return barcodeService.deleteIntegrationService(integrationId);
    }
}

export const getIntegrationById = (integrationId) => {
    return () => {
        return barcodeService.getIntegrationByIdService(integrationId);
    }
}

export const getColumnsByFileId = (id) => {
    return () => {
        return barcodeService.getColumnsByFileIdService(id);
    }
}

export const getAllCSVActiveIntegration = (id) => {
    return () => {
        return barcodeService.getAllCSVActiveIntegrationService(id);
    }
}

export const fileUploadCSVIntegration = (data) => {
    return () => {
        return barcodeService.fileUploadCsvService(data);
    }
}

const getAllBarcodeColumnsPending = () => ({
    type: barcodeConstant.GET_COLUMN_LISTING_PENDING,
});
const getAllBarcodeColumnsFailed = () => ({
    type: barcodeConstant.GET_COLUMN_LISTING_FAILED,
});
const getAllBarcodeColumnsSuccess = (response) => ({
    type: barcodeConstant.GET_COLUMN_LISTING_SUCCESS,
    payload: response,
});

export const getAllBarcodeColumnsList = (payload) => {
    return (dispatch) => {
        dispatch(getAllBarcodeColumnsPending());
        barcodeService.getBarCodeListingService(payload)
            .then((result) => {
                dispatch(getAllBarcodeColumnsSuccess(result.data.Entity));
            })
            .catch((error) => {
                dispatch(getAllBarcodeColumnsFailed());
            });
    };
}

const getBarcodeSizePending = () => ({
    type: barcodeConstant.GET_BARCODE_SIZE_PENDING,
});
const getBarcodeSizeFailed = () => ({
    type: barcodeConstant.GET_BARCODE_SIZE_FAILED,
});
const getBarcodeSizeSuccess = (response) => ({
    type: barcodeConstant.GET_BARCODE_SIZE_SUCCESS,
    payload: response,
});

export const getBarcodeSize = (data) => {
    return (dispatch) => {
        dispatch(getBarcodeSizePending());
        barcodeService
            .getBarcodeSizeService(data)
            .then((result) => {
                dispatch(getBarcodeSizeSuccess(result.data.Entity));
            })
            .catch((error) => {
                dispatch(getBarcodeSizeFailed());
            });
    };
};


const getAllBarCodeTypePending = () => ({
    type: barcodeConstant.GET_ALL_BARCODE_TYPE_PENDING,
});
const getAllBarCodeTypeFailed = () => ({
    type: barcodeConstant.GET_ALL_BARCODE_TYPE_FAILED,
});
const getAllBarCodeTypeSuccess = (response) => ({
    type: barcodeConstant.GET_ALL_BARCODE_TYPE_SUCCESS,
    payload: response,
});

export const getAllBarCodeType = () => {
    return (dispatch) => {
        dispatch(getAllBarCodeTypePending());
        barcodeService
            .getAllBarCodeTypeService()
            .then((result) => {
                dispatch(getAllBarCodeTypeSuccess(result.data.EntityList));
            })
            .catch((error) => {
                dispatch(getAllBarCodeTypeFailed());
            });
    };
};

export const updateBarcodePrintingSize = (data) => {
    return () => {
        return barcodeService.updateBarcodePrintingSizeService(data);
    };
};

export const getActivePrintListAction = () => {
    return new Promise((res, rej) => {
        return res(barcodeService.getAllPrintersService());
    });
};

export const handleAutoPrintAction = (data) => {
    return new Promise((res, rej) => {
        return res(barcodeService.autoPrintService(data));
    });
};

export const CreateAdhocBarCode = (data) => {
    return () => {
        return barcodeService.createAdhocBarCodeService(data);
    };
};

const getBarcodeListPending = () => ({
    type: barcodeConstant.BARCODE_LISTING_PENDING,
});
const getBarcodeListFailed = () => ({
    type: barcodeConstant.BARCODE_LISTING_FAILED,
});
const getBarcodeListSuccess = (response) => ({
    type: barcodeConstant.BARCODE_LISTING_SUCCESS,
    payload: response,
});

export const getBarcodeList = (data) => {
    return (dispatch) => {
        dispatch(getBarcodeListPending());
        barcodeService.getBarCodeUploadedListingService(data)
            .then((result) => {
                dispatch(getBarcodeListSuccess(result.data.Entity));
            })
            .catch(() => {
                dispatch(getBarcodeListFailed());
            });
    };
};

export const getTemplateByBarCodeIntegration = (data) => {
    return () => {
        return barcodeService.getTemplateByBarCodeIntegrationService(data);
    };
};

export const barCodeUploadBackDetails = (data) => ({
    type: barcodeConstant.BARCODE_BACKDETAILS,
    payload: data,
});

export const barCodeMappingBackDetails = (data) => ({
    type: barcodeConstant.BARCODE_MAPPING_BACKDETAILS,
    payload: data,
});

export const barCodeLisitingBackDetails = (data) => ({
    type: barcodeConstant.BARCODE_LISTING_BACKDETAILS,
    payload: data,
});

export const updateBarCodes = (data) => {
    return () => {
        return barcodeService.updateBarCodesService(data);
    };
};
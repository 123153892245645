import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { filterMarketPlace, getTrackingDetails } from '../../store/actions/marketplace.action';
import LoaderSpinner from '../common/Loader';
import IntegrationList from './IntergrationList';
import MarketplaceFilter from './MarketplaceFilter';
import BatchPagination from '../batch/BatchPagination';
import { toast } from 'react-toastify';
import { featureSubmodulePermission, filterPayload, numOfPage, permssionCreate, settings, SUBF_MARKETPLACEINTEGRATION } from "../../constant";
import _ from 'lodash';
import { checkPermission } from '../../config/utils';

const MarketplaceIntegration = (props) => {
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const listData = useSelector((state) => state.marketplace.listData);
    const { listDataStatus, listingBackDetails } = useSelector((state) => state.marketplace);
    const userDetails = useSelector((state) => state.auth.user);
    const employeePermissions = useSelector((state) => state?.sidebar?.employeePermissions);
    const [totalPages, setTotalPages] = useState(1);
    const [defaultFilterData, setDefaultFilterData] = useState({});

    useEffect(() => {
        if (!_.isEmpty(listingBackDetails)) {
            setDefaultFilterData({ ...listingBackDetails });
        } else {
            setDefaultFilterData(JSON.parse(JSON.stringify(filterPayload)));
        }
    }, []);

    useEffect(() => {
        if (listDataStatus !== 'SUCCESS') {
            setLoader(true);
        } else {
            setLoader(false);
        }
    }, [listDataStatus]);

    const handleReset = () => {
        let data = JSON.parse(JSON.stringify(filterPayload));
        setDefaultFilterData(data);
        data.pageSize = defaultFilterData.pageSize.toString();
        setTimeout(() => {
            fetchHistory();
        }, 10);
    }

    useEffect(() => {
        if (
            listData &&
            listData.EntityList.length
        ) {
            setTotalPages(
                Math.ceil(listData.TotalRecords / defaultFilterData.pageSize)
            );
        } else {
            setTotalPages(0);
        }
    }, [listData]);

    useEffect(() => {
        if (!_.isEmpty(defaultFilterData)) {
            fetchHistory();
            saveHistory();
        }
    }, [defaultFilterData.pageIndex, defaultFilterData.pageSize]);

    const saveHistory = (filterObj = {}) => {
        let data = { ...defaultFilterData };
        Object.keys(filterObj).forEach((item) => {
            if (item !== "sortKey" || item !== "sortOrder" || item !== "pageSize" || item !== "pageNumber") {
                data[item] = filterObj[item];
            }
        });
        setDefaultFilterData(data);
    };

    const fetchHistory = () => {
        dispatch(filterMarketPlace(defaultFilterData));
    };

    const handleSortingColumn = (event, sortColumn) => {
        let data = { ...defaultFilterData };
        setLoader(true);
        let newSortOrder = defaultFilterData.sortOrder === "asc" ? "desc" : "asc";
        setDefaultFilterData({
            ...defaultFilterData,
            sortColumn: sortColumn,
            sortOrder: newSortOrder,
        });
        data.sortKey = sortColumn;
        data.sortOrder = newSortOrder;
        dispatch(filterMarketPlace(data));
    };

    const handleSync = async () => {
        let data = {
            tracking_details: [
                {
                    action_type: "sync"
                }
            ]
        }
        setLoader(true);
        let resData = await getTrackingDetails(data);
        if (resData && resData.data.Status === 200) {
            toast.success('Orders synced successfully');
            setLoader(false);
        } else {
            setLoader(false);
        }
    }

    return (
        <main>
            <div className="main__container">
                <div className="main__tile_heading cus-btn-include-sec">Marketplace Integration
                    <div className="top_right_button_container">
                        <div className="top_right_button_container">
                            {checkPermission(settings, SUBF_MARKETPLACEINTEGRATION, permssionCreate, featureSubmodulePermission, props.employeePermissions) && (
                                <button
                                    className="btn cus-seconday-bg-btn top-btn"
                                    onClick={() => {
                                        navigate("/market-place");
                                    }}
                                    disabled={loader}
                                >
                                    Add Marketplace Integration
                                </button>
                            )}
                            <button
                                className="btn cus-seconday-bg-btn top-btn syncBtn"
                                onClick={handleSync}
                                disabled={loader}
                            >
                                Sync
                            </button>
                            <button
                                className="btn cus-seconday-bg-btn showHideFilter top-btn"
                                onClick={() => setDefaultFilterData({ ...defaultFilterData, showFilter: !defaultFilterData.showFilter })}
                                disabled={loader}
                            >
                                <i className="fa fa-filter" aria-hidden="true"></i> Filter
                            </button>
                            <button
                                className="btn cus-primary-transparent-btn"
                                onClick={() => navigate('/setting')}
                            >Back</button>
                        </div>
                    </div>
                </div>
                {loader && <LoaderSpinner />}

                <MarketplaceFilter
                    showFilter={defaultFilterData.showFilter}
                    saveHistory={saveHistory}
                    userDetails={userDetails}
                    defaultFilterData={defaultFilterData}
                    setDefaultFilterData={setDefaultFilterData}
                    handleReset={handleReset}
                    setCurrentPage={(value) => setDefaultFilterData({ ...defaultFilterData, currentPage: value })}
                    filterPayload={JSON.parse(JSON.stringify(filterPayload))}
                    setLoader={setLoader}
                />
                <div className="main_content cus-page-content-sec">
                    <div className="main_content_table_history">
                        <div className='tableFixHead incomingTbl'>
                            <IntegrationList
                                sortingColumn={defaultFilterData.sortColumn}
                                handleSortingColumn={handleSortingColumn}
                                sortOrder={defaultFilterData.sortOrder}
                                listData={listData.EntityList || []}
                                loader={loader}
                                navigate={navigate}
                                defaultFilterData={defaultFilterData}
                                dispatch={dispatch}
                                employeePermissions={employeePermissions}
                            />
                        </div>
                    </div>
                </div>
                {listData?.EntityList?.length > 0 && (
                    <BatchPagination
                        gridData={listData.EntityList}
                        numberOfPage={defaultFilterData.pageSize}
                        numOfPage={numOfPage}
                        loader={loader}
                        currentPage={Number(defaultFilterData.pageIndex)}
                        totalPages={totalPages || 0}
                        totalRecords={listData.TotalRecords}
                        setCurrentPage={(value) => setDefaultFilterData({ ...defaultFilterData, pageIndex: value })}
                        setNumberOfPage={(value) => setDefaultFilterData({ ...defaultFilterData, pageSize: value })}
                    />
                )}
            </div>
        </main>
    );
}

export default MarketplaceIntegration;
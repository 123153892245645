import React, { useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { v4 as uuidv4 } from 'uuid';

const AsyncTypeAheadComponent = (props) => {
    const { isLoading, options, handleSearch, defaultInputValue, handleSelect, key, isDisabled } = props;
    const [keyVal, setKeyVal] = useState(uuidv4());

    const filterBy = () => true;

    return (
        <AsyncTypeahead
            filterBy={filterBy}
            id={key}
            key={keyVal}
            isLoading={isLoading}
            labelKey="productSku"
            minLength={3}
            onSearch={handleSearch}
            onBlur={(e) => {
                setKeyVal(uuidv4());
            }}
            options={options}
            className="asyncListing"
            highlightOnlyResult={false}
            placeholder=""
            defaultInputValue={defaultInputValue}
            renderMenuItemChildren={(option) => (
                <div id={key} onClick={() => handleSelect(option)}>{option.productSku}</div>
            )}
            disabled={isDisabled}
        />
    );
}

export default AsyncTypeAheadComponent;
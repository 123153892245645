import { InventoryConstant } from "../constants/inventory.constant";

const initialState = {
    consignmentOutboundData: {},
    consignmentAllOutboundStatus:[],
    consignmentOutboundStatus: [],
    outboundHistory: [],
    outboundHistoryStatus: '',
};
export default function InventoryOutboundReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case InventoryConstant.INVENTORY_OUTBOUND_LISTING_PENDING:
            return {
              ...state,
              consignmentOutboundData: {},
              consignmentOutboundStatus: "pending",
            };
        case InventoryConstant.INVENTORY_OUTBOUND_LISTING_SUCCESS:
            return {
              ...state,
              consignmentOutboundData: payload,
              consignmentOutboundStatus: "success",
            };
        case InventoryConstant.INVENTORY_OUTBOUND_LISTING_FAILED:
            return {
              ...state,
              consignmentOutboundData : {},
              consignmentOutboundStatus: "failed",
            };
        case InventoryConstant.GET_ALL_CONSIGNMENT_OUTBOUND_STATUS_SUCCESS:
            return {
            ...state,
            consignmentAllOutboundStatus: payload,
            };
        case InventoryConstant.INVENTORY_OUTBOUND_HISTORY_PENDING:
            return {
                ...state,
                outboundHistory: [],
                outboundHistoryStatus: 'PENDING'
            };
        case InventoryConstant.INVENTORY_OUTBOUND_HISTORY_SUCCESS:
            return {
                ...state,
                outboundHistory: payload,
                outboundHistoryStatus: 'SUCCESS'
            };
        case InventoryConstant.INVENTORY_OUTBOUND_HISTORY_FAILED:
            return {
                ...state,
                outboundHistory: [],
                outboundHistoryStatus: 'FAILED'
            };
        default:
            return state;
    }
}
import React from 'react';
import pic10 from "../../../assets/minus.png";
import { validDecimalNum } from '../../../config/utils';
const Parcels = (props) => {
    const { order, setOrder, createdBy, selectedPackage } = props;
    const handleParcelChange = (e, name, index) => {
        const { value } = e.target;
        let copyOrder = { ...order };
        if (!isNaN(value) && validDecimalNum.test(value)) {
            copyOrder.parcels[index][name] = Number(value);
            setOrder(copyOrder);
        }
    };
    const removeParcel = (index) => {
        let data = { ...order };
        data.parcels.splice(index, 1);
        setOrder(data);
    };
    const addParcel = () => {
        let data = { ...order };
        let parcelObj = {
            createdBy: createdBy?.Value?.employeeId,
            modifiedBy: createdBy?.Value?.employeeId,
            sellerId: createdBy?.Value?.sellerId,
            companyId: createdBy?.Value?.companyId,
            isActive: true,
            ounces: null,
            length: selectedPackage?.shouldAutofill ? selectedPackage.length : null,
            width: selectedPackage?.shouldAutofill ? selectedPackage.width : null,
            height: selectedPackage?.shouldAutofill ? selectedPackage.height : null,
            id: 0,
            shipmentID: 0,
            box: 0,
            weight: null,
            sku: "string",
            createdDate: new Date().toISOString(),
            modifiedDate: new Date().toISOString(),
            cnName: "string",
            enName: "string",
            hsCode: "string",
            num: 0,
            material: "string",
            use: "string",
            cWeight: 0,
            battery: "string",
            price: 0,
        };
        data.parcels.push(parcelObj);
        setOrder(data);
    };

    return (
        <div className='col-12 packageDetailsBox'>
            <div className='packageDetails'>
                {order.parcels && order.parcels.length > 0 && order.parcels.map((item, index) => (
                    <div key={item?.id}>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='row '>
                                    <div className='col-12'>Dimensions</div>
                                    <div className='col-12 custom-dim-boxes'>
                                        <div className='row'>
                                            <div className='col-3'>
                                                <input
                                                    onChange={(e) =>
                                                        handleParcelChange(e, "length", index)
                                                    }
                                                    type='number'
                                                    placeholder='L'
                                                    value={item.length}
                                                    className='serviceIDSelect form-control'
                                                    maxLength={10}
                                                    min={0}
                                                    disabled={!order.serviceID || !order.packageId || selectedPackage?.shouldAutofill}
                                                />
                                            </div>
                                            <div className='col-3'>
                                                <input
                                                    onChange={(e) =>
                                                        handleParcelChange(e, "width", index)
                                                    }
                                                    min={0}
                                                    type='number'
                                                    placeholder='W'
                                                    value={item.width}
                                                    className='form-control widthInput'
                                                    maxLength={10}
                                                    disabled={!order.serviceID || !order.packageId || selectedPackage?.shouldAutofill}
                                                />
                                            </div>
                                            <div className='custom-cm-box col-3'>
                                                <div>
                                                    <input
                                                        onChange={(e) =>
                                                            handleParcelChange(e, "height", index)
                                                        }
                                                        min={0}
                                                        type='number'
                                                        placeholder='H'
                                                        className='form-control heightInput'
                                                        value={item.height}
                                                        maxLength={10}
                                                        disabled={!order.serviceID || !order.packageId || selectedPackage?.shouldAutofill}
                                                    />
                                                </div>
                                            </div>
                                                <div className='col-3 pt-3'>{order.dimensionUnit === 'INCH' ? 'in' : 'cm'}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='custom-inline-row'>
                            <div className='col-6'>
                                <div className='row'>
                                    <div className='col-8 custom-align-left'>Weight</div>
                                    <div className='col-8 custom-showInflex'>
                                        <input
                                            onChange={(e) =>
                                                handleParcelChange(e, "weight", index)
                                            }
                                            placeholder=''
                                            value={item.weight}
                                            maxLength={10}
                                            type='number'
                                            className='form-control weightField'
                                            disabled={!order.serviceID || !order.packageId}
                                            min={0}
                                        />
                                        {order.dimensionUnit === 'INCH' ? 'LB' : 'KG'}</div>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className='row'>
                                    <div className='col-8 custom-align-left'>Ounces</div>
                                    <div className='col-8 custom-showInflex'>
                                        <input
                                            onChange={(e) =>
                                                handleParcelChange(e, "ounces", index)
                                            }
                                            placeholder='H'
                                            value={item.ounces}
                                            maxLength={10}
                                            type='number'
                                            min={0}
                                            className='form-control'
                                            disabled={!order.serviceID || !order.packageId}
                                        />{order.dimensionUnit === 'INCH' ? 'OZ' : 'GM'}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-4'>
                        <div className='row'>
                            <div className='col-12'>Insurance</div>
                            <div className='col-12'><input value={''} /> $</div>
                        </div>
                    </div> */}
                        <div className='col-12 custom-add-btn'>
                            {index + 1 === order.parcels.length &&
                                order.parcels.length <= 4 && (
                                    <button className="booking btn btn-outline-primary" onClick={addParcel}>
                                        <i className="fa fa-plus" aria-hidden="true"></i>Add New
                                    </button>
                                )}
                            {index > 0 && (
                                <button
                                    className="addplusicon custom-minus-btn1"
                                    id="removeParcelBtn"
                                    onClick={(e) => removeParcel(index)}
                                >
                                    <img
                                        alt=""
                                        src={pic10}
                                        style={{ height: "17px", width: "17px" }}
                                    />
                                </button>
                            )}
                            {/* <button className='addRow' onClick={addNewRow}>Add row</button> */}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
export default Parcels;
import { createPackageService, getAllCarrierListService, getDailyProductCountService, getFilterPackages, getPackageDetailsService, getPackageTypeListService, getPackageYearsService, getProductConditionListService, getProductProcessTypeListService, getProductStatusListService, getProductWarehouseListService, getProductYearsService, getSourceListService, getStatusListService, searchTrackingNumberService, updateImageForPackageService, updatePackageService, createProductService, getProductDetailsService, updateProductService, getFilterProducts, updateMediaForProductService } from "../../services/incoming.service";
import { getDailyPackageCountService } from "../../services/incoming.service";
import { incomingConstant } from "../constants/incoming";

const getYearsPending = () => ({
    type: incomingConstant.GET_YEAR_LIST_PENDING,
});
const getproductYearsPending = () => ({
    type: incomingConstant.GET_PRODUCT_YEAR_LIST_PENDING,
});
const getYearsFailed = () => ({
    type: incomingConstant.GET_YEAR_LIST_FAILED,
});
const getproductYearsFailed = () => ({
    type: incomingConstant.GET_PRODUCT_YEAR_LIST_FAILED,
});
const getYearsSuccess = (response) => ({
    type: incomingConstant.GET_YEAR_LIST_SUCCESS,
    payload: response,
});
const getproductYearsSuccess = (response) => ({
    type: incomingConstant.GET_PRODUCT_YEAR_LIST_SUCCESS,
    payload: response,
});
export const getPackageYearsList = (companySellerId, isSuperAdmin) => {
    return (dispatch) => {
        dispatch(getYearsPending());
        getPackageYearsService(companySellerId, isSuperAdmin).then((result) => {
            dispatch(getYearsSuccess(result?.data?.EntityList));
        }).catch((error) => {
            dispatch(getYearsFailed());
        })
    };
};
export const getProductYearsList = (companySellerId, employeeId) => {
    return (dispatch) => {
        dispatch(getproductYearsPending());
        getProductYearsService(companySellerId, employeeId).then((result) => {
            dispatch(getproductYearsSuccess(result?.data?.EntityList));
        }).catch((error) => {
            dispatch(getproductYearsFailed());
        })
    };
};
const getDailyPackageCountPending = () => ({
    type: incomingConstant.GET_DAILY_PACKAGE_COUNT_PENDING,
});
const getDailyProductCountPending = () => ({
    type: incomingConstant.GET_DAILY_PRODUCT_COUNT_PENDING,
});
const getDailyPackageCountFailed = () => ({
    type: incomingConstant.GET_DAILY_PACKAGE_COUNT_FAILED,
});
const getDailyProductCountFailed = () => ({
    type: incomingConstant.GET_DAILY_PRODUCT_COUNT_FAILED,
});
const getDailyPackageCountSuccess = (response) => ({
    type: incomingConstant.GET_DAILY_PACKAGE_COUNT_SUCCESS,
    payload: response,
});
const getDailyProductCountSuccess = (response) => ({
    type: incomingConstant.GET_DAILY_PRODUCT_COUNT_SUCCESS,
    payload: response,
});
export const getDailyPackageCountList = (year) => {
    return (dispatch) => {
        dispatch(getDailyPackageCountPending());
        getDailyPackageCountService(year).then((result) => {
            dispatch(getDailyPackageCountSuccess(result?.data?.EntityList));
        }).catch((error) => {
            dispatch(getDailyPackageCountFailed());
        })
    };
};
export const getDailyProductCountList = (year, companySellerId, employeeId) => {
    return (dispatch) => {
        dispatch(getDailyProductCountPending());
        getDailyProductCountService(year, companySellerId, employeeId).then((result) => {
            dispatch(getDailyProductCountSuccess(result?.data?.EntityList));
        }).catch((error) => {
            dispatch(getDailyProductCountFailed());
        })
    };
};
const getFilterPackagesPending = () => ({
    type: incomingConstant.GET_FILTER_DAILY_PACKAGE_COUNT_PENDING,
});
const getFilterProductsPending = () => ({
    type: incomingConstant.GET_FILTER_DAILY_PRODUCT_COUNT_PENDING,
});
const getFilterPackagesFailed = () => ({
    type: incomingConstant.GET_FILTER_DAILY_PACKAGE_COUNT_FAILED,
});
const getFilterProductsFailed = () => ({
    type: incomingConstant.GET_FILTER_DAILY_PRODUCT_COUNT_FAILED,
});
const getFilterPackagesSuccess = (response) => ({
    type: incomingConstant.GET_FILTER_DAILY_PACKAGE_COUNT_SUCCESS,
    payload: response,
});
const getFilterProductsSuccess = (response) => ({
    type: incomingConstant.GET_FILTER_DAILY_PRODUCT_COUNT_SUCCESS,
    payload: response,
});
export const getFilterPackagesList = (data) => {
    return (dispatch) => {
        dispatch(getFilterPackagesPending());
        getFilterPackages(data).then((result) => {
            dispatch(getFilterPackagesSuccess(result?.data));
        }).catch((error) => {
            dispatch(getFilterPackagesFailed());
        })
    };
};
export const getFilterProductsList = (data) => {
    return (dispatch) => {
        dispatch(getFilterProductsPending());
        getFilterProducts(data).then((result) => {
            dispatch(getFilterProductsSuccess(result?.data));
        }).catch((error) => {
            dispatch(getFilterProductsFailed());
        })
    };
};


const getSourceListListSuccess = (response) => ({
    type: incomingConstant.GET_SOURCELIST_SUCCESS,
    payload: response,
});
export const getSourceListList = () => {
    return (dispatch) => {
        getSourceListService().then((result) => {
            dispatch(getSourceListListSuccess(result?.data?.EntityList));
        }).catch((error) => {
            dispatch(getSourceListListSuccess([]));
        })
    };
};
const getPackagetypeListSuccess = (response) => ({
    type: incomingConstant.GET_PACKAGE_TYPE_LIST_SUCCESS,
    payload: response,
});
export const getPackagetypeList = () => {
    return (dispatch) => {
        getPackageTypeListService().then((result) => {
            dispatch(getPackagetypeListSuccess(result?.data?.EntityList));
        }).catch((error) => {
            dispatch(getPackagetypeListSuccess([]));
        })
    };
};
/** get PackageDetailByID */
const getPackageDetailsStatusPending = () => ({
    type: incomingConstant.GET_PACKAGE_DETAILS_PENDING,
});
const getProductDetailsStatusPending = () => ({
    type: incomingConstant.GET_PRODUCT_DETAILS_PENDING,
});

const getPackageDetailsStatusFailed = () => ({
    type: incomingConstant.GET_PACKAGE_DETAILS_FAILED,
});
const getProductDetailsStatusFailed = () => ({
    type: incomingConstant.GET_PRODUCT_DETAILS_FAILED,
});
const getPackageDetailsStatusSuccess = (response) => ({
    type: incomingConstant.GET_PACKAGE_DETAILS_SUCCESS,
    payload: response,
});
const getProductDetailsStatusSuccess = (response) => ({
    type: incomingConstant.GET_PRODUCT_DETAILS_SUCCESS,
    payload: response,
});
export const getPackageDetails = (packageId) => {
    return (dispatch) => {
        dispatch(getPackageDetailsStatusPending());
        getPackageDetailsService(packageId).then((result) => {
            dispatch(getPackageDetailsStatusSuccess(result?.data?.Entity));
        }).catch((error) => {
            dispatch(getPackageDetailsStatusFailed());
        })
    };
};
export const getProductDetails = (productId) => {

    return (dispatch) => {
        dispatch(getProductDetailsStatusPending());
        getProductDetailsService(productId).then((result) => {
            dispatch(getProductDetailsStatusSuccess(result?.data?.Entity));
        }).catch((error) => {
            dispatch(getProductDetailsStatusFailed());
        })
    };
};

export const createpackage = (data) => {
    return () => {
        return createPackageService(data);
    };
};
export const createproduct = (data) => {
    return new Promise((res, rej) => {
        return res(createProductService(data));
    });
};
export const updatepackage = (data) => {
    return () => {
        return updatePackageService(data);
    };
};
export const updateproduct = (data) => {
    return new Promise((resolve, reject) => {
        return resolve(updateProductService(data));
    });
};
export const updateImageForPackage = (data) => {
    return () => {
        return updateImageForPackageService(data);
    };
};
export const updateMediaForProduct = (data) => {
    return new Promise((resolve, reject) => {
        return resolve(updateMediaForProductService(data));
    });
};

/** Search Tracking number */

export const getSearchTrackingData = (data) => {
    return new Promise((resolve, reject) => {
        return resolve(searchTrackingNumberService(data));
    });
};

/** Carrier List */

const getAllCarrierListServicePending = () => ({
    type: incomingConstant.GET_ALL_CARRIER_LIST_PENDING,
});
const getAllCarrierListServiceFailed = () => ({
    type: incomingConstant.GET_ALL_CARRIER_LIST_FAILED,
});
const getAllCarrierListServiceSuccess = (response) => ({
    type: incomingConstant.GET_ALL_CARRIER_LIST_SUCCESS,
    payload: response,
});
export const getAllCarrierList = (packageId) => {
    return (dispatch) => {
        dispatch(getAllCarrierListServicePending());
        getAllCarrierListService(packageId).then((result) => {
            dispatch(getAllCarrierListServiceSuccess(result?.data?.EntityList));
        }).catch((error) => {
            dispatch(getAllCarrierListServiceFailed());
        })
    };
};

const getAllStatusListServiceSuccess = (response) => ({
    type: incomingConstant.GET_ALL_STATUS_LIST_SUCCESS,
    payload: response,
});
const getProductStatusListServiceSuccess = (response) => ({
    type: incomingConstant.GET_PRODUCT_STATUS_LIST_SUCCESS,
    payload: response,
});
const getProductConditionListServiceSuccess = (response) => ({
    type: incomingConstant.GET_PRODUCT_CONDITION_LIST_SUCCESS,
    payload: response,
});
const getProductWarehouseListServiceSuccess = (response) => ({
    type: incomingConstant.GET_PRODUCT_WAREHOUSE_LIST_SUCCESS,
    payload: response,
});
const getProductProcessTypeServiceSuccess = (response) => ({
    type: incomingConstant.GET_PRODUCT_PROCESS_TYPE_LIST_SUCCESS,
    payload: response,
});


export const getStatusList = () => {
    return (dispatch) => {
        getStatusListService().then((result) => {
            dispatch(getAllStatusListServiceSuccess(result?.data?.EntityList));
        }).catch((error) => {
            console.log(error);
        })
    };
};
export const getProductStatusList = () => {
    return (dispatch) => {
        getProductStatusListService().then((result) => {
            dispatch(getProductStatusListServiceSuccess(result?.data?.EntityList));
        }).catch((error) => {
            console.log(error);
        })
    };
};
export const getProductConditionList = () => {
    return (dispatch) => {
        getProductConditionListService().then((result) => {
            dispatch(getProductConditionListServiceSuccess(result?.data?.EntityList));
        }).catch((error) => {
            console.log(error);
        })
    };
};
export const getProductWarehouseList = () => {
    return (dispatch) => {
        getProductWarehouseListService().then((result) => {
            dispatch(getProductWarehouseListServiceSuccess(result?.data?.EntityList));
        }).catch((error) => {
            console.log(error);
        })
    };
};
export const getProductProcessTypeList = () => {
    return (dispatch) => {
        getProductProcessTypeListService().then((result) => {
            dispatch(getProductProcessTypeServiceSuccess(result?.data?.EntityList));
        }).catch((error) => {
            console.log(error);
        })
    };
};

export const setIncomingSelectedYear = (year) => {
    return (dispatch) => {
        dispatch({
            type: incomingConstant.INCOMING_SELECTED_YEAR,
            payload: year,
        })
    };
};

export const setProductSelectedYear = (year) => {
    return (dispatch) => {
        dispatch({
            type: incomingConstant.PRODUCT_SELECTED_YEAR,
            payload: year,
        })
    };
};

export const productBackDetails = (data) => ({
    type: incomingConstant.PRODUCT_BACK_DETAILS,
    payload: data,
});

export const packageBackDetails = (data) => ({
    type: incomingConstant.PACKAGE_BACK_DETAILS,
    payload: data,
});

export const packagesYearBackDetails = (data) => ({
    type: incomingConstant.PACKAGE_YEAR_BACK_DETAILS,
    payload: data,
});
export const productYearBackDetails = (data) => ({
    type: incomingConstant.PRODUCT_YEAR_BACK_DETAILS,
    payload: data,
});
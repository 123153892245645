import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./rateCardModal.css";
import { useState } from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import {
  saveIndividualRate
} from "../../../store/actions/batch.action";
import { toast } from "react-toastify";

const BaseZoneRateEditModal = (props) => {
  const { selectedRateRecord, getBaseZoneRate } = props;
  const [selectedRate, setSelectedRate] = useState({});
  const [errors, setErrors] = useState({});
  useEffect(() => {
    setSelectedRate(selectedRateRecord);
  }, [selectedRateRecord]);

  const handleFormChange = (e, name) => {
    const { value } = e.target;
    setSelectedRate({
      ...selectedRate,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const validateForm = () => {
    let isValid = true;
    let formErrors = {};
    if (isNaN(selectedRate.Rate) || selectedRate.Rate === "") {
      formErrors.Rate = "Enter valid rate";
      isValid = false;
    }
    setErrors(formErrors);
    return isValid;
  };

  const handleSaveRate = async () => {
    if (validateForm()) {
      let data = { ...selectedRate };
      delete data.rates;
      delete data.weight;
      let form_data = new FormData();
      for (let key in data) {
        form_data.append(key, data[key]);
      }
      let rateSaveRes = await saveIndividualRate(data);
      if (
        rateSaveRes &&
        rateSaveRes.data.Entity.isValid &&
        rateSaveRes.data.Status === 200
      ) {
        toast.success(rateSaveRes.data.Entity.message);
        getBaseZoneRate();
        props.onHide();
      } else {
        toast.error(rateSaveRes?.data?.Entity?.message);
      }
    }
  };

  return (
    <Modal {...props} size="lg" centered>
      <div className="modal_container_rate_card">
        <div className="modal_header_container">
          <span className="modal_header">Edit Rate</span>
        </div>
        <div className="row">
          <div className="col-8">
            <div className="row modal_input">
              <div className="col-4 formLabel">
                Rate <span className="mandatory_input">*</span>
              </div>
              <div className="col-8">
                <input
                  onChange={(e) => handleFormChange(e, "Rate")}
                  value={selectedRate.Rate}
                  className="form-control"
                  type="text"
                />
                <p className="error">{errors.Rate}</p>
              </div>
            </div>
          </div>
        </div>
        <Row className="modaL_footer">
          <Col xs={8}></Col>
          <div style={{ textAlign: "right" }}>
            <Button className="modal_btn cancel_btn" onClick={props.onHide}>
              Cancel
            </Button>
            <Button className="modal_btn save_btn" onClick={handleSaveRate}>
              Save
            </Button>
          </div>
        </Row>
      </div>
    </Modal>
  );
};

export default BaseZoneRateEditModal;

import React from 'react';
import Modal from "react-bootstrap/Modal";

const ViewImage = (props) => {
    const { selectedRecord, setShowHideImageModal, showHideImageModal } = props;
    return (
        <Modal
            {...props}
            size="lg"
            centered
            show={showHideImageModal}
            onHide={() => setShowHideImageModal(!showHideImageModal)}
        >
            <div className="top_modal_header_container uploadNew-modal">
                <span className="">
                    {props.title ? props.title : 'Package Image'}
                </span>
                <div className="modalDeleteWrapper">
                    <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => setShowHideImageModal(!showHideImageModal)}
                    ></button>
                </div>
            </div>
            <div className="modal_container_rate_card">
                <div className='viewImageWrapper'>
                    <img src={selectedRecord.imageUrl} alt={selectedRecord.imageUrl} />
                </div>
            </div>
        </Modal>
    );
}

export default ViewImage
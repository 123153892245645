import {
  CREATE_SELLER_SUCCESS,
  GET_SELLER_SUCCESS,
  GET_SELLER_FAIL,
  DELETE_SELLER_SUCCESS,
} from "../types";
const initialState = {
  SellerList: [],
  loading: true,
  error: {}
};
export default function (state = initialState, action = '') {
  const { type, payload } = action;
  switch (type) {
    case GET_SELLER_SUCCESS:
      return {
        ...state,
        SellerList: payload.seller,
        isLoading: false
      };
    case GET_SELLER_FAIL:
      return {
        ...state
      };
    case CREATE_SELLER_SUCCESS:
      return {
        ...state,
        SellerList: [...state.SellerList, payload.seller]
      };
    case DELETE_SELLER_SUCCESS:
      return {
        ...state,
        SellerList: [
          ...state.SellerList.filter(seller => seller != payload.seller)
        ]
      }
    default:
      return state;
  }
}


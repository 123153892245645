import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import { InputGroup } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Button from "bootstrap-4-react/lib/components/Button";
import BatchPagination from "../batch/BatchPagination";
import LoaderSpinner from "../common/Loader";
import { filterMarketPlace } from '../../store/actions/marketplace.action';
import { filterPayload, numOfPage } from "../../constant";

export function MarketPlaceHistory() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [totalPages, setTotalPages] = useState(1);
  const [filterError, setFilterError] = useState('');
  const { listDataStatus } = useSelector((state) => state.marketplace);
  const listData = useSelector((state) => state.marketplace.listData.EntityList);
  const totalListData = useSelector((state) => state.marketplace.listData.TotalRecords);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPage, setNumberOfPage] = useState(30);
  const location = useLocation();
  const data = location?.state?.rowData;
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortingColumn, setSortingColumn] = useState("");
  const [loader, setLoader] = useState([]);
  let payloadData = JSON.parse(JSON.stringify(filterPayload));

  const [formData, setFormData] = useState({
    ...payloadData,
    action_type: 'history',
    CompanySellerId: data?.company_seller_id,
    integration_id: data?.integration_id,
  });

  useEffect(() => {
    if (listDataStatus !== 'SUCCESS') {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [listDataStatus]);

  useEffect(() => {
    formData.pageNumber = currentPage.toString();
    formData.pageSize = numberOfPage.toString();
    dispatch(filterMarketPlace(formData));
  }, [numberOfPage, currentPage, location?.state?.dummyState, data?.integration_id]);

  /* istanbul ignore next */
  const handleDateChange = (item, name) => {
    if (item) {
      let dateSelected = moment(item).utc(true).format('YYYY-MM-DD');
      setFormData({ ...formData, [name]: dateSelected });
    } else {
      setFormData({ ...formData, [name]: null });
    }
    setFilterError('');
  };

  useEffect(() => {
    if (
      listData &&
      listData.length
    ) {
      setTotalPages(
        Math.ceil(totalListData / numberOfPage)
      );
    } else {
      setTotalPages(0);
    }
  }, [listData]);


  const handleApply = (type) => {
    if (type === "reset") {
      setCurrentPage(1);
      setNumberOfPage(30)
      setFilterError('');
      formData.date_min = '';
      formData.date_max = '';
      formData.pageSize = numberOfPage.toString();
      dispatch(filterMarketPlace(formData));
      setLoader(false);
    } else {
      if (moment(formData.date_max).isBefore(moment(formData.date_min))) {
        setFilterError('Select correct to/from date');
      } else {
        dispatch(filterMarketPlace(formData));
      }
    }
  };

  const handleSortingColumn = (event, sortColumn) => {
    event.preventDefault();
    let newSortOrder = "desc";
    setSortingColumn(sortColumn);
    if (sortColumn === sortingColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
      newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    } else {
      setSortOrder("desc");
    }
    formData.sortKey = sortColumn;
    formData.sortOrder = newSortOrder;
    dispatch(filterMarketPlace(formData));
  };

  const renderColumnHeader = (colName, type) => {
    return (
      <div
        className={(sortingColumn === type) ? "active" : "sortColumn"}
        onClick={(e) => handleSortingColumn(e, type)}
      >
        {colName}
        <i
          className={(sortingColumn === type && sortOrder === "asc") ? "fa fa-arrow-up" : "fa fa-arrow-down"}
          aria-hidden="true"
        ></i>
      </div>
    )
  }


  return (
    <main>
      <div className="main__container">
        <div className="main__tile_heading cus-btn-include-sec">
          MarketPlace History
          <div className="top_right_button_container">
            <Button
              className="cus-seconday-bg-btn top-btn"
              onClick={() => handleApply()}
            >
              Apply
            </Button>
            <Button
              className="resetBtn cus-seconday-bg-btn top-btn"
              onClick={() => handleApply("reset")}
            >
              Reset <i className="fa fa-refresh" aria-hidden="true"></i>
            </Button>
            <Button
              className="cus-primary-transparent-btn"
              onClick={() => {
                navigate("/marketplace-integration");
              }}
            >
              Back
            </Button>
          </div>
        </div>

        <div className="mb-3 zIndex2 fontFix">
          <Row>
            <div className="col-lg-4 col-sm-12">
              <InputGroup className="mb-2">
                <Row className="fieldAndLabel">
                  <div className="col-lg-2">
                    From:
                  </div>
                  <div className="col-lg-9">
                    <DatePicker
                      className="date-picker"
                      showIcon
                      dateFormat="MM-dd-yyyy"
                      autoComplete='one-time-code'
                      selected={formData.date_min ? new Date(formData.date_min) : null}
                      name={'date_min'}
                      onChange={(selectedDate) =>
                        handleDateChange(selectedDate, "date_min")
                      }
                      adjustDateOnChange
                    />
                  </div>
                  <Col><span className="error">{filterError}</span></Col>
                </Row>
              </InputGroup>
            </div>
            <div className="col-lg-4 col-sm-12">
              <InputGroup className="mb-2">
                <Row className="fieldAndLabel">
                  <div className="col-lg-2">
                    <div className="input-label-Created-date">To:</div>
                  </div>
                  <div className="col-lg-9">
                    <DatePicker
                      className="date-picker"
                      showIcon
                      dateFormat="MM-dd-yyyy"
                      autoComplete='one-time-code'
                      selected={formData.date_max ? new Date(formData.date_max) : null}
                      name={'date_max'}
                      onChange={(dateTo) => handleDateChange(dateTo, "date_max")}
                    />
                  </div>
                </Row>
              </InputGroup>
            </div>
          </Row>
        </div>
        {loader && <LoaderSpinner />}

        <div className="main_content cus-page-content-sec zIndex1">
          <div className="main_content_table_history">
            <div className="tableFixHead incomingTbl">
              <Table className={`table table-height cus-left-right-col-layout table ${loader ? "disableTble" : ""
                }`}>
                <thead border="Green">
                  <tr className="trow table-rw-batch">
                    <th>#</th>
                    <th>Integration Name</th>
                    <th>Marketplace Name</th>
                    <th>
                      {renderColumnHeader('Number of Orders', 'order_quantity')}
                    </th>
                    <th className="trows-s">
                      {renderColumnHeader('Date', 'date')}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {listData && listData?.map((item, i) => (
                    <tr key={item?.integration_id}>
                      <td>{i + 1}</td>
                      <td>{item?.integration_name}</td>
                      <td>{item?.channel_name}</td>
                      <td>{item?.order_quantity}</td>
                      <td>
                        {moment(item?.order_received_date).format(
                          "MM-DD-YYYY | h:mm:ss A"
                        )}
                      </td>
                    </tr>
                  ))}
                  {!loader && !listData.length &&
                    <tr>
                      <td colSpan={8} align="center">No Record Found...</td>
                    </tr>
                  }

                </tbody>
              </Table>
            </div>
          </div>
        </div>

        <BatchPagination
          gridData={listData}
          totalVisualRecords={listData.length}
          totalRecords={totalListData}
          numberOfPage={numberOfPage}
          numOfPage={numOfPage}
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
          setNumberOfPage={setNumberOfPage}
        />
      </div>
    </main>
  );
}
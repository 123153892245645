import {
  SET_MESSAGE,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_FAIL,
  GET_EMPLOYEE_BY_ID_SUCCESS,
  GET_EMPLOYEE_BY_ID_FAIL,
  DELETE_EMPLOYEE_SUCCESS,
  DELETE_EMPLOYEE_FAIL,
  CREATE_EMPLOYEE_WAREHOUSE_MAPPING_SUCCESS,
  CREATE_EMPLOYEE_WAREHOUSE_MAPPING_FAIL,
  BACKDETAILS,
  PRODUCT_BACKDETAILS,
  INBOUND_BACKDETAILS,
  MARGIN_CONFIG_BACKDETAILS
} from "../types";

import EmpServices from "../../services/employee.service";

export const getEmployee = () => (dispatch) => {
  return EmpServices.getEmployee().then(
    (data) => {
      dispatch({
        type: GET_EMPLOYEE_SUCCESS,
        payload: { Employee: data.data },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: GET_EMPLOYEE_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const getEmployeeById = (Id) => (dispatch) => {
  return EmpServices.getemployeebyId(Id)?.then(
    (data) => {
      dispatch({
        type: GET_EMPLOYEE_BY_ID_SUCCESS,
        payload: { Employee: data.data },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: GET_EMPLOYEE_BY_ID_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const deleteEmployeeById = (Employee) => (dispatch) => {
  return EmpServices.deleteemployeebyId(Employee.Id).then(
    (data) => {
      dispatch({
        type: DELETE_EMPLOYEE_SUCCESS,
        payload: { Employee: Employee },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: DELETE_EMPLOYEE_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const addemployeewarehouse = (EmployeeId, WarehouseId) => (dispatch) => {
  return EmpServices.addemployeewarehouse(EmployeeId, WarehouseId).then(
    (data) => {
      dispatch({
        type: CREATE_EMPLOYEE_WAREHOUSE_MAPPING_SUCCESS,
        payload: { Employee: data.data },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: CREATE_EMPLOYEE_WAREHOUSE_MAPPING_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const outboundBackDetails = (data) => ({
  type: BACKDETAILS,
  payload: data,
});

export const InventoryProductBackDetails = (data) => ({
  type: PRODUCT_BACKDETAILS,
  payload: data,
});

export const inboundBackDetails = (data) => ({
  type: INBOUND_BACKDETAILS,
  payload: data,
});

export const marginConfigBackDetails = (data) => ({
  type: MARGIN_CONFIG_BACKDETAILS,
  payload: data,
});
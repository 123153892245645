
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const GET_COMPANY_SUCCESS_BEFORE = "GET_COMPANY_SUCCESS_BEFORE";
export const GET_COMPANY_FAIL_BEFORE = "GET_COMPANY_FAIL_BEFORE";
export const UPDATE_USER = "UPDATE_USER";
export const GET_WALLET_FUND = "GET_WALLET_FUND";
export const GET_ALL_SELLERS = "GET_ALL_SELLERS";

//company
export const GET_SELLER_ADRESS_DETAIL = "GET_SELLER_ADRESS_DETAIL"
export const GET_ADRESS_COMPANY_DETAIL = "GET_ADRESS_COMPANY_DETAIL"
export const GET_MULTIPLE_SELLERS = "GET_MULTIPLE_SELLERS"
export const GET_MULTIPLE_SELLERS_STATUS = "GET_MULTIPLE_SELLERS_STATUS"
export const GET_COMPANY_SUCCESS = "GET_COMPANY_SUCCESS";
export const GET_COMPANY_FAIL = "GET_COMPANY_FAIL";
export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS";
export const CREATE_COMPANY_FAIL = "CREATE_COMPANY_FAIL";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAIL = "UPDATE_COMPANY_FAIL";
export const DELETE_COMPANY_SUCCESS = "DELETE_COMPANY_SUCCESS";
export const DELETE_COMPANY_FAIL = "DELETE_COMPANY_FAIL";
export const GET_COMPANY_BY_ID_SUCCESS = "GET_COMPANY_BY_ID_SUCCESS";
export const GET_COMPANY_BY_ID_FAIL = "GET_COMPANY_BY_ID_FAIL";
export const GET_FILTER_LIST = "GET_FILTER_LIST";
export const GET_SELLER_FILTER_LIST = "GET_SELLER_FILTER_LIST";
export const GET_ALL_SELLER_FILTER_LIST = "GET_ALL_SELLER_FILTER_LIST";

//warehouse
export const GET_WAREHOUSE_SUCCESS = "GET_WAREHOUSE_SUCCESS";
export const GET_WAREHOUSE_FAIL = "GET_WAREHOUSE_FAIL";
export const CREATE_WAREHOUSE_SUCCESS = "CREATE_WAREHOUSE_SUCCESS";
export const CREATE_WAREHOUSE_FAIL = "CREATE_WAREHOUSE_FAIL";
export const UPDATE_WAREHOUSE_SUCCESS = "UPDATE_WAREHOUSE_SUCCESS";
export const UPDATE_WAREHOUSE_FAIL = "UPDATE_WAREHOUSE_FAIL";
export const DELETE_WAREHOUSE_SUCCESS = "DELETE_WAREHOUSE_SUCCESS";
export const DELETE_WAREHOUSE_FAIL = "DELETE_WAREHOUSE_FAIL";
export const GET_WAREHOUSE_BY_ID_SUCCESS = "GET_WAREHOUSE_BY_ID_SUCCESS";
export const GET_WAREHOUSE_BY_ID_FAIL = "GET_WAREHOUSE_BY_ID_FAIL";
//EMPLOYEE
export const GET_EMPLOYEE_SUCCESS = "GET_EMPLOYEE_SUCCESS";
export const GET_ALL_COMPANY = "GET_ALL_COMPANY";
export const GET_ALL_COMPANY_STATUS = "GET_ALL_COMPANY_STATUS";
export const GET_EMPLOYEE_FAIL = "GET_EMPLOYEE_FAIL";
export const CREATE_EMPLOYEE_SUCCESS = "CREATE_EMPLOYEE_SUCCESS";
export const CREATE_EMPLOYEE_FAIL = "CREATE_EMPLOYEE_FAIL";
export const UPDATE_EMPLOYEE_SUCCESS = "UPDATE_EMPLOYEE_SUCCESS";
export const UPDATE_EMPLOYEE_FAIL = "UPDATE_EMPLOYEE_FAIL";
export const DELETE_EMPLOYEE_SUCCESS = "DELETE_EMPLOYEE_SUCCESS";
export const DELETE_EMPLOYEE_FAIL = "DELETE_EMPLOYEE_FAIL";
export const GET_EMPLOYEE_BY_ID_SUCCESS = "GET_EMPLOYEE_BY_ID_SUCCESS";
export const GET_EMPLOYEE_BY_ID_FAIL = "GET_EMPLOYEE_BY_ID_FAIL";
export const CREATE_EMPLOYEE_WAREHOUSE_MAPPING_SUCCESS = "CREATE_EMPLOYEE_WAREHOUSE_MAPPING_SUCCESS";
export const CREATE_EMPLOYEE_WAREHOUSE_MAPPING_FAIL = "CREATE_EMPLOYEE_WAREHOUSE_MAPPING_FAIL";
export const COMPANY_SAVE_PAGINATION_DETAILS = "COMPANY_SAVE_PAGINATION_DETAILS";
export const BASE_RATE_CARD_SAVE_PAGINATION_DETAILS = "BASE_RATE_CARD_SAVE_PAGINATION_DETAILS";
export const SELLER_RATE_CARD_SAVE_PAGINATION_DETAILS = "SELLER_RATE_CARD_SAVE_PAGINATION_DETAILS";
//SELLER
export const GET_SELLER_SUCCESS = "GET_SELLER_SUCCESS";
export const GET_SELLER_FAIL = "GET_SELLER_FAIL";
export const CREATE_SELLER_SUCCESS = "CREATE_SELLER_SUCCESS";
export const CREATE_SELLER_FAIL = "CREATE_SELLER_FAIL";
export const UPDATE_SELLER_SUCCESS = "UPDATE_SELLER_SUCCESS";
export const UPDATE_SELLER_FAIL = "UPDATE_SELLER_FAIL";
export const DELETE_SELLER_SUCCESS = "DELETE_SELLER_SUCCESS";
export const DELETE_SELLER_FAIL = "DELETE_SELLER_FAIL";
export const GET_SELLER_BY_ID_SUCCESS = "GET_SELLER_BY_ID_SUCCESS";
export const GET_SELLER_BY_ID_FAIL = "GET_SELLER_BY_ID_FAIL";
export const CREATE_SELLER_WAREHOUSE_MAPPING_SUCCESS = "CREATE_SELLER_WAREHOUSE_MAPPING_SUCCESS";
export const CREATE_SELLER_WAREHOUSE_MAPPING_FAIL = "CREATE_SELLER_WAREHOUSE_MAPPING_FAIL";
export const SELLER_SAVE_PAGINATION_DETAILS = 'SELLER_SAVE_PAGINATION_DETAILS';
//VARAIBLE
export const GET_VARAIBLE_SUCCESS = "GET_VARAIBLE_SUCCESS";
export const GET_VARAIBLE_FAIL = "GET_VARAIBLE_FAIL";
export const CREATE_VARAIBLE_SUCCESS = "CREATE_VARAIBLE_SUCCESS";
export const CREATE_VARAIBLE_FAIL = "CREATE_VARAIBLE_FAIL";
export const UPDATE_VARAIBLE_SUCCESS = "UPDATE_VARAIBLE_SUCCESS";
export const UPDATE_VARAIBLE_FAIL = "UPDATE_VARAIBLE_FAIL";
export const DELETE_VARAIBLE_SUCCESS = "DELETE_VARAIBLE_SUCCESS";
export const DELETE_VARAIBLE_FAIL = "DELETE_VARAIBLE_FAIL";
export const GET_VARAIBLE_BY_ID_SUCCESS = "GET_VARAIBLE_BY_ID_SUCCESS";
export const GET_VARAIBLE_BY_ID_FAIL = "GET_VARAIBLE_BY_ID_FAIL";
export const GET_SINGLE_FUNDS_DATA = "GET_SINGLE_FUNDS_DATA";
//USERS
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_USERS_PENDING = "GET_ALL_USERS_PENDING";
export const SAVE_PAGINATION_DETAILS = "SAVE_PAGINATION_DETAILS";
export const GET_USERS_COMPANY_SELLER_SUCCESS = "GET_USERS_COMPANY_SELLER_SUCCESS";
export const GET_USERS_COMPANY_SELLER_FAILED = "GET_USERS_COMPANY_SELLER_FAILED";
export const SHOW_LOADER = "SHOW_LOADER";
export const USER_ROLE_LIST_SUCCESS = "USER_ROLE_LIST_SUCCESS";
export const USER_ROLE_LIST_FAILED = "USER_ROLE_LIST_FAILED";
export const FETCH_ALL_USER_PERMISSIONS = "FETCH_ALL_USER_PERMISSIONS";

//RATETYPE
export const GET_RATETYPE_SUCCESS = "GET_RATETYPE_SUCCESS";
export const GET_RATETYPE_FAIL = "GET_RATETYPE_FAIL";
export const CREATE_RATETYPE_SUCCESS = "CREATE_RATETYPE_SUCCESS";
export const CREATE_RATETYPE_FAIL = "CREATE_RATETYPE_FAIL";
export const UPDATE_RATETYPE_SUCCESS = "UPDATE_RATETYPE_SUCCESS";
export const UPDATE_RATETYPE_FAIL = "UPDATE_RATETYPE_FAIL";
export const DELETE_RATETYPE_SUCCESS = "DELETE_RATETYPE_SUCCESS";
export const DELETE_RATETYPE_FAIL = "DELETE_RATETYPE_FAIL";
export const GET_RATETYPE_BY_ID_SUCCESS = "GET_RATETYPE_BY_ID_SUCCESS";
export const GET_RATETYPE_BY_ID_FAIL = "GET_RATETYPE_BY_ID_FAIL";
//RATETYPECATEGORY
export const GET_RATETYPECATEGORY_SUCCESS = "GET_RATETYPECATEGORY_SUCCESS";
export const GET_RATETYPECATEGORY_FAIL = "GET_RATETYPECATEGORY_FAIL";
export const CREATE_RATETYPECATEGORY_SUCCESS = "CREATE_RATETYPECATEGORY_SUCCESS";
export const CREATE_RATETYPECATEGORY_FAIL = "CREATE_RATETYPECATEGORY_FAIL";
export const UPDATE_RATETYPECATEGORY_SUCCESS = "UPDATE_RATETYPECATEGORY_SUCCESS";
export const UPDATE_RATETYPECATEGORY_FAIL = "UPDATE_RATETYPECATEGORY_FAIL";
export const DELETE_RATETYPECATEGORY_SUCCESS = "DELETE_RATETYPECATEGORY_SUCCESS";
export const DELETE_RATETYPECATEGORY_FAIL = "DELETE_RATETYPECATEGORY_FAIL";
export const GET_RATETYPECATEGORY_BY_ID_SUCCESS = "GET_RATETYPECATEGORY_BY_ID_SUCCESS";
export const GET_RATETYPECATEGORY_BY_ID_FAIL = "GET_RATETYPECATEGORY_BY_ID_FAIL";
export const GET_RATETYPECATEGORY_BY_RATETYPEID_SUCCESS = "GET_RATETYPECATEGORY_BY_RATETYPEID_SUCCESS";
export const GET_RATETYPECATEGORY_BY_RATETYPEID_FAIL = "GET_RATETYPECATEGORY_BY_RATETYPEID_FAIL";
//Store
export const GET_WALLET_BALANCE = "GET_WALLET_BALANCE";
export const GET_WALLET_FUND_DETAILS = 'GET_WALLET_FUND_DETAILS';
export const GET_STORE_SUCCESS = "GET_STORE_SUCCESS";
export const GET_STORE_FAIL = "GET_STORE_FAIL";
export const CREATE_STORE_SUCCESS = "CREATE_STORE_SUCCESS";
export const CREATE_STORE_FAIL = "CREATE_STORE_FAIL";
export const UPDATE_STORE_SUCCESS = "UPDATE_STORE_SUCCESS";
export const UPDATE_STORE_FAIL = "UPDATE_STORE_FAIL";
export const DELETE_STORE_SUCCESS = "DELETE_STORE_SUCCESS";
export const DELETE_STORE_FAIL = "DELETE_STORE_FAIL";
export const GET_STORE_BY_ID_SUCCESS = "GET_STORE_BY_ID_SUCCESS";
export const GET_STORE_BY_ID_FAIL = "GET_STORE_BY_ID_FAIL";
//orders
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_FAIL = "GET_ORDERS_FAIL";
//RateCard
export const GET_SELLER_RATECARDS = 'GET_SELLER_RATECARDS'
export const GET_BASE_RATECARDS = 'GET_BASE_RATECARDS'
export const GET_BASE_RATECARDS_ZONE_DATA = 'GET_BASE_RATECARDS_ZONE_DATA'
export const GET_ACTIVE_SELLERS = 'GET_ACTIVE_SELLERS'
export const GET_ACTIVE_SELLERS_FAILED = 'GET_ACTIVE_SELLERS_FAILED'
export const EXISTING_RATE_CARDS = 'EXISTING_RATE_CARDS'
export const EXISTING_RATE_CARDS_FAILED = 'EXISTING_RATE_CARDS_FAILED'
export const GET_ALL_FUNDS = 'GET_ALL_FUNDS'
export const GET_ALL_FUNDS_STATUS_PENDING = 'GET_ALL_FUNDS_STATUS_PENDING'
export const GET_ALL_FUNDS_STATUS_SUCCESS = 'GET_ALL_FUNDS_STATUS_SUCCESS'
export const GET_SINGLE_FUNDS = 'GET_SINGLE_FUNDS'
export const GET_SINGLE_FUND_DETAILS = 'GET_SINGLE_FUND_DETAILS'
export const GET_SELLER_DETAILS = 'GET_SELLER_DETAILS'
export const GET_ACTIVE_COMPANY = 'GET_ACTIVE_COMPANY'
export const GET_SELLERS = 'GET_SELLERS'
export const GET_SELLER_RATECARDS_ZONE_DATA = 'GET_SELLER_RATECARDS_ZONE_DATA'
export const EMPTY_TRANSACTION_HISTORY = 'EMPTY_TRANSACTION_HISTORY'
export const FUND_MANAGEMENT_BACK_DETAILS = 'FUND_MANAGEMENT_BACK_DETAILS'


//Config
export const CONFIGURATION_SUCCESS = "CONFIGURATION_SUCCESS";

//backbtn
export const BACKDETAILS = "BACKDETAILS";
export const PRODUCT_BACKDETAILS = "PRODUCT_BACKDETAILS";
export const INBOUND_BACKDETAILS = "INBOUND_BACKDETAILS";
export const MARGIN_CONFIG_BACKDETAILS = "MARGIN_CONFIG_BACKDETAILS";
import axios from "axios";
import { apiUrl } from '../constant'
import authHeaderWithcontent from "./auth-header-content";

const getCsvData = (data) => {
	return axios.get(`${apiUrl}/api/GetCsvActiveIntegration/${data}`);
};
const getCsvAllIntegration = (data) => {
	return axios.get(`${apiUrl}/api/GetCsvAllIntegration/${data}`);
};
const UpdateSkipDuplicateAddressCheckFlag = (event, autGenerateId) => {
	return axios.post(`${apiUrl}/api/UpdateSkipDuplicateAddressCheckFlag?flagStatus=${event?.target?.checked}&Id=${autGenerateId}`, { headers: authHeaderWithcontent() });
};
const fileUpload = (formdata) => {
	return axios.post(`${apiUrl}/api/FileUpload`, formdata, { headers: authHeaderWithcontent() });
};
const getIntegrationTemplateUrlService = (integrationOptions) => {
	return axios.get(`${apiUrl}/api/integrationtemplate?${integrationOptions}`);
};
const getCsvColumnListService = () => {
	return axios.get(`${apiUrl}/CsvColumns`);
};
export const csvListService = {
	getCsvData,
	getCsvAllIntegration,
	UpdateSkipDuplicateAddressCheckFlag,
	fileUpload,
	getIntegrationTemplateUrlService,
	getCsvColumnListService,
}

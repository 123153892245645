import { batchConstant } from "../constants/batch.constant";
const initialState = {
    historyRecordStatus: '',
    historyRecords: [],
    csvUrl: ''
};
export default function (state = initialState, action = '') {
    const { type, payload } = action;
    switch (type) {
        case batchConstant.GET_HISTORY_STATUS_PENDING:
            return {
                ...state,
                historyRecords: [],
                historyRecordStatus: 'pending'
            };
        case batchConstant.GET_HISTORY_STATUS_SUCCESS:
            return {
                ...state,
                historyRecords: payload,
                historyRecordStatus: 'success'
            };
        case batchConstant.GET_HISTORY_STATUS_FAILED:
            return {
                ...state,
                historyRecords: [],
                historyRecordStatus: 'failed'
            };
        case batchConstant.GET_CSV_STATUS_SUCCESS:
            return {
                ...state,
                csvUrl: payload,
            };
        default:
            return state;
    }
}

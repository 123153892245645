import React from "react";
import { Modal } from "react-bootstrap";
import ImageSlider from "../../incoming/ImageSlider";

const InLargeImageSlider = (props) => {
    const {
        showModal,
        view,
        handleMarkPrimary,
        handleSliderDeleteImage,
        isUserAdmin,
        images,
        popupTitle,
        setShowLargeSliderModal
    } = props;

    const handleCancel = () => {
        setShowLargeSliderModal(false);
    }

    return (
        <Modal
            {...props}
            size="xl"
            className="topUpModal"
            show={showModal}
            onHide={handleCancel}
        >
            <div className="top_modal_header_container">
                <span className="">{popupTitle}</span>
                <div className="modalDeleteWrapper">
                    <button
                        type="button"
                        onClick={() => setShowLargeSliderModal(false)}
                        className="btn-close"
                        aria-label="Close"
                    ></button>
                </div>
            </div>
            <div className="modal_container_rate_card inlargeImageSlider">
                <ImageSlider
                    {...props}
                    isView={view}
                    handleMarkPrimary={handleMarkPrimary}
                    handleDeleteImage={handleSliderDeleteImage}
                    images={images}
                    isUserAdmin={isUserAdmin}
                    hideMaximize={true}
                />
            </div>
        </Modal>
    );
}

export default InLargeImageSlider;
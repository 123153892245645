import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Form from 'react-bootstrap/Form';
import { useDispatch, useSelector } from 'react-redux';
import { generateTokenByCompanySeller, getUserCompanySeller, saveUser, showFullLoader } from '../../store/actions/auth';
import { getLocalStorageItem, setLocalStorageItem } from '../../services/StorageService';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <button
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
        className='btn btn-outline-primary d-flex'
    >
        <span className='pr-2 fontFix cssItem'>{children}</span>
        <i class="fa fa-caret-down" aria-hidden="true"></i>
    </button>
));

const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
        const [value, setValue] = useState('');

        return (
            <div
                ref={ref}
                style={style}
                className={className}
                aria-labelledby={labeledBy}
            >
                <Form.Control
                    autoFocus
                    className="mb-2 searchText"
                    placeholder="Search for Company/Seller..."
                    onChange={(e) => setValue(e.target.value)}
                    value={value}
                />

                <ul className="mt-3">
                    {React.Children.toArray(children).filter(
                        (child) =>
                            !value || child.props.children.toLowerCase().startsWith(value.toLowerCase()),
                    )}
                </ul>
            </div>
        );
    },
);

const SellerDropdown = (props) => {
    let [list, setList] = useState([]);
    const dispatch = useDispatch();
    let defaultCompanySeller = JSON.parse(getLocalStorageItem('currentCompany'));
    const { companyList } = useSelector((state) => state?.auth);
    const { Value } = useSelector((state) => state?.auth.user);
    const [defaultSeller, setDefaultSeller] = useState({});

    useEffect(() => {
        dispatch(getUserCompanySeller());
    }, []);

    useEffect(() => {
        if (companyList.length) {
            let findDefault = companyList.filter(item => item.isDefault)[0];
            setList([...companyList]);
            if (defaultCompanySeller) {
                setDefaultSeller(defaultCompanySeller);
            } else {
                setDefaultSeller(findDefault);
            }
        }
    }, [companyList])

    const handleSellerSelect = (item) => {
        let removedSelected = list.map(listItem => ({ ...listItem, isSelected: listItem.companySellerId === item.companySellerId ? true : false }));
        setDefaultSeller(item);
        setList(removedSelected);
        dispatch(showFullLoader(true));
        handleSwitchCompany(item);
    }

    const handleSwitchCompany = async (data) => {
        let payload = {
            ...data,
            companyId: data.companyId,
            sellerId: data.sellerId,
            emailId: Value.emailId,
        }
        let tokenRes = await Promise.resolve(dispatch(generateTokenByCompanySeller(payload)));
        if (tokenRes.data) {
            setLocalStorageItem('userDetails', JSON.stringify(tokenRes.data));
            setLocalStorageItem('currentCompany', JSON.stringify(data));
            dispatch(showFullLoader(false));
            dispatch(saveUser(tokenRes.data));
            props.getFunds();
            window.location.reload();
        }
    }

    return (
        <>
            {companyList.length > 1 && (
                <Dropdown className='mr-4 mobileMarginRight-4'>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
                        {defaultSeller.companySellerName}
                    </Dropdown.Toggle>

                    <Dropdown.Menu as={CustomMenu} align={'end'} className='sellerMenu'>
                        {companyList.map((item) => (
                            <Dropdown.Item key={item.companySellerId} className={`fontFix dropItem ${defaultSeller.companySellerId === item.companySellerId && 'viewItem'}`} onClick={() => handleSellerSelect(item)} eventKey="1" active={defaultSeller.companySellerId === item.companySellerId}>{item.companySellerName}</Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            )}
        </>
    );
}

export default SellerDropdown;
import { InventoryConstant } from "../constants/inventory.constant";

const initialState = {
    inventoryProductList: [],
    inventoryProductListStatus: '',
    productDetails: {},
    consignmentStatus: '',
    consignmentData: {},
    activeIntegrations: [],
    activeIntegrationsStatus: '',
    consignmentTypes: [],
    consignmentAllStatus: [],
    warehouseList: [],
    productHistory: [],
    productHistoryStatus: '',
    inboundHistory: [],
    inboundHistoryStatus: '',
    consignmentElementStatus: [],
    marketPlacesList: [],
    warehouseListBySellerId: [],
};
export default function InventoryReducer(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case InventoryConstant.INVENTORY_PRODUCT_LISTING_PENDING:
            return {
                ...state,
                inventoryProductList: [],
                inventoryProductListStatus: 'pending'
            };
        case InventoryConstant.INVENTORY_PRODUCT_LISTING_SUCCESS:
            return {
                ...state,
                inventoryProductList: payload,
                inventoryProductListStatus: 'success'
            };
        case InventoryConstant.INVENTORY_PRODUCT_LISTING_FAILED:
            return {
                ...state,
                inventoryProductList: [],
                inventoryProductListStatus: 'failed'
            };
        case InventoryConstant.GET_BY_PRODUCT_ID_PENDING:
            return {
                ...state,
                productDetails: {},
                productDetailsStatus: 'pending'
            };
        case InventoryConstant.GET_BY_PRODUCT_ID_SUCCESS:
            return {
                ...state,
                productDetails: payload,
                productDetailsStatus: 'success'
            };
        case InventoryConstant.GET_BY_PRODUCT_ID_FAILED:
            return {
                ...state,
                productDetails: {},
                productDetailsStatus: 'failed'
            };
        case InventoryConstant.GET_CONSIGNMENT_PENDING:
            return {
                ...state,
                consignmentData: {},
                consignmentStatus: 'pending'
            };
        case InventoryConstant.GET_CONSIGNMENT_SUCCESS:
            return {
                ...state,
                consignmentData: payload,
                consignmentStatus: 'success'
            };
        case InventoryConstant.GET_CONSIGNMENT_FAILED:
            return {
                ...state,
                consignmentData: {},
                consignmentStatus: 'failed'
            };
        case InventoryConstant.GET_ACTIVEINTEGRATION_PENDING:
            return {
                ...state,
                activeIntegrations: [],
                activeIntegrationsStatus: "pending",
            };
        case InventoryConstant.GET_ACTIVEINTEGRATION_SUCCESS:
            return {
                ...state,
                activeIntegrations: payload,
                activeIntegrationsStatus: "success",
            };
        case InventoryConstant.GET_ACTIVEINTEGRATION_FAILED:
            return {
                ...state,
                activeIntegrations: [],
                activeIntegrationsStatus: "failed",
            }
        case InventoryConstant.GET_ALL_CONSIGNMENT_TYPE_SUCCESS:
            return {
                ...state,
                consignmentTypes: payload
            };
        case InventoryConstant.GET_ALL_CONSIGNMENT_STATUS_SUCCESS:
            return {
                ...state,
                consignmentAllStatus: payload
            };
        case InventoryConstant.GET_ALL_WAREHOUSE_LIST_SUCCESS:
            return {
                ...state,
                warehouseList: payload
            };
        case InventoryConstant.EMPTYPRODUCT_DETAILS:
            return {
                ...state,
                productDetails: {},
                productDetailsStatus: ''
            };
        case InventoryConstant.INVENTORY_PRODUCT_HISTORY_PENDING:
            return {
                ...state,
                productHistory: [],
                productHistoryStatus: 'PENDING'
            };
        case InventoryConstant.INVENTORY_PRODUCT_HISTORY_SUCCESS:
            return {
                ...state,
                productHistory: payload,
                productHistoryStatus: 'SUCCESS'
            };
        case InventoryConstant.INVENTORY_PRODUCT_HISTORY_FAILED:
            return {
                ...state,
                productHistory: [],
                productHistoryStatus: 'FAILED'
            };
        case InventoryConstant.INVENTORY_INBOUND_HISTORY_PENDING:
            return {
                ...state,
                inboundHistory: [],
                inboundHistoryStatus: 'PENDING'
            };
        case InventoryConstant.INVENTORY_INBOUND_HISTORY_SUCCESS:
            return {
                ...state,
                inboundHistory: payload,
                inboundHistoryStatus: 'SUCCESS'
            };
        case InventoryConstant.INVENTORY_INBOUND_HISTORY_FAILED:
            return {
                ...state,
                inboundHistory: [],
                inboundHistoryStatus: 'FAILED'
            };
        case InventoryConstant.INVENTORY_PRODUCT_INVENTORY_PENDING:
            return {
                ...state,
                inventoryHistory: [],
                inventoryHistoryStatus: "PENDING",
            };
        case InventoryConstant.INVENTORY_PRODUCT_INVENTORY_SUCCESS:
            return {
                ...state,
                inventoryHistory: payload,
                inventoryHistoryStatus: "SUCCESS",
            };
        case InventoryConstant.INVENTORY_PRODUCT_INVENTORY_FAILED:
            return {
                ...state,
                inventoryHistory: [],
                inventoryHistoryStatus: "FAILED",
            };
        case InventoryConstant.GET_ALL_CONSIGNMENT_STATUS_LIST_SUCCESS:
            return {
                ...state,
                consignmentElementStatus: payload
            };
        case InventoryConstant.FETCH_ALL_MARKETPLACES:
            return {
                ...state,
                marketPlacesList: payload
            };
        case InventoryConstant.GET_WAREHOUSE_BY_SELLER_ID_SUCCESS:
            return {
                ...state,
                warehouseListBySellerId: payload
            };
        default:
            return state;
    }
}
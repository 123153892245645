import React, { Component } from 'react'
import './Breadcrumbs.css';

class Company extends React.Component {
    render() {
        return (
            <div className="breadcrumbsRoot">
                <nav className="breadcrumbsRoot_body1 breadcrumbsRoot_secondaryColor" aria-label="breadcrumb">
                    <ol className="breadcrumbsRoot_ol">
                        <li className="MuiBreadcrumbs-li"><a className="jss251" href="/"><svg className="MuiSvgIcon-root jss252" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M12 3L2 12h3v8h6v-6h2v6h6v-8h3L12 3zm5 15h-2v-6H9v6H7v-7.81l5-4.5 5 4.5V18z"></path><path d="M7 10.19V18h2v-6h6v6h2v-7.81l-5-4.5z" opacity=".3"></path></svg></a></li> <li aria-hidden="true"
                            className="MuiBreadcrumbs-separator">
                            <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path></svg>
                        </li>
                        <li className="MuiBreadcrumbs-li">
                            <span className="MuiTypography-root jss251 breadcrumbsRoot_body1">
                                {this.props.name}</span>
                        </li>
                    </ol>
                </nav>
            </div>
        )
    }
}

export default Company;
import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { InputGroup } from "react-bootstrap";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Button } from "bootstrap-4-react/lib/components";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import noImage from "../../assets/No-Image-Placeholder.png";
import { useEffect } from "react";
import {
  createpackage,
  getAllCarrierList,
  getPackageDetails,
  getPackagetypeList,
  getSourceListList,
  getStatusList,
  updateImageForPackage,
  updatepackage,
} from "../../store/actions/incoming";
import moment from "moment";
import LoaderSpinner from "../common/Loader";
import { allowedExtensions, featureSubmodulePermission, packages, permssionCreate, returnsManagement, trackingNum } from "../../constant";
import ImageSlider from "./ImageSlider";
import _ from "lodash";
import InLargeImageSlider from "../modal/InlargeImageSlider";
import { checkPermission } from "../../config/utils";
/* istanbul ignore next */
const EditPackages = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { view = null, date = null } = location?.state || {};

  const [error, setErrors] = useState({});
  const [loader, setLoader] = useState(false);
  const [isFormEdited, setIsFormEdited] = useState(false);
  const [image, setImage] = useState([]);
  const [formDataCopy, setFormDataCopy] = useState([]);
  const [showLargeSliderModal, setShowLargeSliderModal] = useState(false);

  const UserValue = useSelector((state) => state.auth.user);
  const {
    packageDetailsById,
    packageDetailStatus,
    PackageTypeList,
    sourcesList,
    allCarriersList,
    statusList,
  } = useSelector((state) => state.IncomingReducer);

  const [formData, setFormData] = useState({
    internalId: "",
    externalId: "",
    trackingNumber: "",
    packageType: "",
    source: "",
    receivedDate: new Date(),
    carrierId: "",
    statusId: 1,
  });

  const dispatch = useDispatch();
  let { id } = useParams();
  let isCreateForm = parseInt(id) === 0;

  useEffect(() => {
    if (parseInt(id) !== 0) {
      dispatch(getPackageDetails(id));
    }
  }, [id]);

  useEffect(() => {
    if (!sourcesList?.length) {
      dispatch(getSourceListList());
      dispatch(getPackagetypeList());
    }
    if (!allCarriersList.length) {
      dispatch(getAllCarrierList());
    }

    if (!statusList.length) {
      dispatch(getStatusList());
    }
  }, []);

  useEffect(() => {
    if (parseInt(id) !== 0) {
      setFormDataCopy(JSON.parse(JSON.stringify(packageDetailsById)));
      setFormData(packageDetailsById);
    } else {
      setFormData({
        ...formData,
        companySellerId: UserValue?.Value?.companySellerId,
      });
    }
  }, [packageDetailsById]);

  useEffect(() => {
    if (packageDetailStatus === "pending") {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [packageDetailStatus]);
  const validateForm = () => {
    let isValid = true;
    let errorData = {};
    if (UserValue?.Value?.roleName === "Seller") {
      if (!formData?.notes) {
        isValid = false;
        errorData.notes = "Notes is required";
      }
      if (!formData.carrierId) {
        isValid = false;
        errorData.carrierId = "This is required";
      }
      setErrors(errorData);
      return isValid;
    } else {
      if (!formData?.packageType) {
        isValid = false;
        errorData.packageType = "packageType is required";
      }
      if (!formData?.source) {
        isValid = false;
        errorData.source = "source is required";
      }
      if (!formData.carrierId) {
        isValid = false;
        errorData.carrierId = "This is required";
      }
      setErrors(errorData);
      return isValid;
    }
  }
  const validateCreateForm = () => {
    let isValid = true;
    let errorData = {};
    if (!formData?.packageTypeId) {
      isValid = false;
      errorData.packageTypeId = "This is required";
    }
    if (!formData?.sourceId) {
      isValid = false;
      errorData.sourceId = "This is required";
    }
    if (!image.length) {
      isValid = false;
      errorData.image = "This is required";
    }
    if (!formData.carrierId) {
      isValid = false;
      errorData.carrierId = "This is required";
    }
    setErrors(errorData);
    return isValid;
  };

  const handleFormChange = (e) => {
    const { value, name } = e.target;
    if (name === "trackingNumber") {
      if (value) {
        if (!trackingNum.test(value)) {
          return false;
        }
      }
    }
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...error,
      [name]: "",
    });
    setIsFormEdited(true);
  }
  const handleSliderDeleteImage = (e, item) => {
    let formDataObj = JSON.parse(JSON.stringify(formData));
    let updateImage = formDataObj.boxImages.filter((box) => box.id !== item.id);
    formDataObj.boxImages = updateImage;

    let cloneFormData = JSON.parse(JSON.stringify(formDataCopy));
    let updateImageToCopyObj = formDataCopy.boxImages.map((box) => {
      return {
        ...box,
        isDeleted: box.id === item.id || box.isDeleted ? true : false,
        isPrimary: box.id === item.id && false,
      };
    });
    cloneFormData.boxImages = updateImageToCopyObj;
    setFormDataCopy(cloneFormData);

    setIsFormEdited(true);
    setFormData(formDataObj);
  };
  const handleMarkPrimary = (e, item) => {
    let formDataCopyObj = JSON.parse(JSON.stringify(formData));
    let updateImage = formDataCopyObj.boxImages.map((box) => {
      return {
        ...box,
        isPrimary: box.id === item.id ? true : false,
      };
    });
    formDataCopyObj.boxImages = updateImage;
    setIsFormEdited(true);
    setFormData(formDataCopyObj);
  }
  const handleCreatePackage = async () => {
    let data = { ...formData };
    data.externalId = null;
    data.createdBy = UserValue?.Value?.employeeId;
    setLoader(true);
    let response = await Promise.resolve(dispatch(createpackage(data)));
    if (response && response?.data?.Status === 200) {
      let uploadImgData = new FormData();
      uploadImgData.append("InternalId", response?.data?.Entity);

      for (let i = 0; i < image.length; i++) {
        uploadImgData.append("BoxImages", image[i]);
      }
      let uploadRes = await Promise.resolve(dispatch(updateImageForPackage(uploadImgData)));
      if (uploadRes && uploadRes?.data?.Status === 200) {
        navigate(
          `/incoming-packages/fd-${new Date(
            formData.receivedDate
          ).toISOString()}-3pl/${response?.data?.Entity}`,
          { state: { view: true, date: location?.state?.date } }
        );
        setIsFormEdited(false);
        toast.success("Changes Saved Successfully");
        setImage("");
      } else {
        toast.error(uploadRes?.data?.Message?.AppStatusDescription);
      }
      setLoader(false);
    } else {
      toast.error(response?.data?.Message?.AppStatusDescription);
      setLoader(false);
    }
  }

  const handleSave = async (e) => {
    e.preventDefault();
    if (isCreateForm) {

      if (validateCreateForm()) {
        handleCreatePackage();
      }
    } else {

      if (validateForm()) {
        handleUpdatePackage();
      }
    }
  }
  const handleUpdatePackage = async () => {
    setLoader(true);
    let updateRes = JSON.parse(JSON.stringify(formData));
    let mergeImage = [...formData.boxImages, ...formDataCopy.boxImages];

    updateRes.companySellerId = UserValue?.Value?.companySellerId;
    updateRes.boxImages = _.uniqBy(mergeImage, "id");
    updateRes.modifiedBy = UserValue?.Value?.employeeId;
    let response = await Promise.resolve(dispatch(updatepackage(updateRes)));
    if (response && response?.data?.Status === 200) {
      if (image.length) {
        let uploadImgData = new FormData();
        uploadImgData.append("InternalId", formData.internalId);
        for (let i = 0; i < image.length; i++) {
          uploadImgData.append("BoxImages", image[i]);
        }
        let uploadPackageImageRes = await Promise.resolve(dispatch(
          updateImageForPackage(uploadImgData)
        ));
        if (
          uploadPackageImageRes &&
          uploadPackageImageRes?.data?.Status === 200
        ) {
          navigate(`/incoming-packages/fd-${date}-3pl/${id}`, {
            state: { view: true, date: location?.state?.date },
          });
          toast.success("Changes Saved Successfully");
          setIsFormEdited(false);
          dispatch(getPackageDetails(id));
          setImage("");
          setLoader(false);
        }
      } else {
        navigate(`/incoming-packages/fd-${date}-3pl/${id}`, {
          state: { view: true, date: location?.state?.date },
        });
        toast.success("Changes Saved Successfully");
        setIsFormEdited(false);
        dispatch(getPackageDetails(id));
        setLoader(false);
      }
    }
  }

  const handleEdit = () => {
    navigate(`/incoming-packages/fd-${date}-3pl/${id}`, {
      state: { view: false, date: location?.state?.date },
    });
  };
  const handleBack = () => {
    if (isCreateForm) {
      navigate(`/incoming-packages`);
    } else {
      navigate(`/incoming-packages/fd-${date}-3pl`, {
        state: { date: location?.state?.date },
      });
    }
  };
  const handleImageUpload = (e) => {
    let files = [...e.target.files];
    try {
      if (files.length > 0 && files.length <= 5) {
        let filterFiles = files.filter((file, index) => {
          const extension = file?.name?.split(".").slice(-1).pop();
          const fileSize = Math.round(file.size / 1024);
          if (
            !allowedExtensions.includes(
              extension ? extension.toLowerCase() : ""
            )
          ) {
            files.splice(index, 1);
            return false;
          } else if (fileSize > 1024) {
            files.splice(index, 1);
            e.target.value = "";
            return false;
          }
          return file;
        });
        setErrors({ ...error, image: "" });
        setImage(filterFiles);
        setIsFormEdited(true);
        if (e.target.files.length !== files.length) {
          toast.info("Each picture size should be less than 1MB", 1000);
          e.target.value = "";
        }
      } else if (files.length > 5) {
        toast.info("5 images can be upload at one time", 1000);
        e.target.value = "";
      } else {
        e.target.value = "";
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleDeleteImage = (index) => {
    let images = [...image];
    images.splice(index, 1);
    setImage(images);
  };

  const handleDateChange = (receivedDate) => {
    setFormData({
      ...formData,
      receivedDate: moment(receivedDate).format("MM-DD-YYYY"),
    });
    setIsFormEdited(true);
  };
  const renderTitle = () => {
    let title;
    if (parseInt(id) === 0) {
      title = "Create Package";
    } else if (!view) {
      title = "Edit Package Details";
    } else {
      title = "Package Details";
    }
    return title
  }

  return (
    <main>
      <div className="main__container">
        <div className="main__tile_heading cus-btn-include-sec">
          {renderTitle()}
          <div></div>
          <div className="top_right_button_container">
            {checkPermission(returnsManagement, packages, permssionCreate, featureSubmodulePermission, props.employeePermissions) && (
              <Button
                disabled={!isFormEdited}
                className="cus-seconday-bg-btn top-btn"
                id="savesr"
                onClick={handleSave}
              >
                Save
              </Button>
            )}

            {!isCreateForm && UserValue?.Value?.roleName !== "Seller" && (
              <Button
                disabled={!view}
                className="cus-seconday-bg-btn top-btn"
                id="hde"
                onClick={handleEdit}
              >
                Edit
              </Button>
            )}
            <Button
              className="cus-primary-transparent-btn top-btn"
              id="hdb"
              onClick={() => handleBack()}
            >
              Back
            </Button>
          </div>
        </div>
        {loader && <LoaderSpinner />}
        <div className="main_content cus-page-content-sec">
          <div className="top_right_button_container cus-content-only-sec">
            <div className="incoming_wrapper pt-3">
              <div className="row">
                <div className={parseInt(id) === 0 ? "col-12" : "col-lg-9 col-sm-12"}>
                  <div className="blank fontFix">
                    <Row className="mb-2 p-2 rowBottomSpace">
                      <Col xs={12} lg={3}>
                        <InputGroup>
                          <div className="input-label-Created-date">
                            Tracking Number
                          </div>
                          <div className="input-box">
                            <input
                              disabled={view}
                              onChange={handleFormChange}
                              value={formData?.trackingNumber}
                              name={"trackingNumber"}
                              className="form-control"
                              maxLength={20}
                            />
                          </div>
                          {error.trackingNumber && (
                            <label className="requiredthis">
                              *This is required
                            </label>
                          )}
                        </InputGroup>
                      </Col>
                      <Col xs={12} lg={3}>
                        <InputGroup>
                          <div className="input-label-Created-date ">
                            Package Type{" "}
                            <span className="require-field">*</span>
                          </div>
                          <div>
                            <select
                              disabled={view}
                              onChange={handleFormChange}
                              value={formData?.packageTypeId}
                              name={"packageTypeId"}
                              className="form-control noMarginBtm"
                            >
                              {isCreateForm && (
                                <option selected value>
                                  Select
                                </option>
                              )}
                              {PackageTypeList?.length &&
                                PackageTypeList.map((item, index) => (
                                  <option key={item.id} value={item.id}>
                                    {item.packageType}
                                  </option>
                                ))}
                            </select>
                          </div>
                          {error.packageTypeId && (
                            <label className="requiredthis">
                              *This is required
                            </label>
                          )}
                        </InputGroup>
                      </Col>
                      <Col xs={12} lg={3}>
                        <InputGroup>
                          <div className="input-label-Created-date ">
                            Source <span className="require-field">*</span>
                          </div>
                          <div className="input-box">
                            <select
                              disabled={view}
                              onChange={handleFormChange}
                              value={formData?.sourceId}
                              name={"sourceId"}
                              className="form-control noMarginBtm"
                            >
                              {isCreateForm && (
                                <option selected value>
                                  Select
                                </option>
                              )}
                              {sourcesList?.length &&
                                sourcesList.map((item, index) => (
                                  <option value={item.id} key={item.id}>
                                    {item.source}
                                  </option>
                                ))}
                            </select>
                          </div>
                          {error.sourceId && (
                            <label className="requiredthis">
                              *This is required
                            </label>
                          )}
                        </InputGroup>
                      </Col>
                      <Col xs={12} lg={3}>
                        <InputGroup>
                          <div className="input-label-Created-date ">
                            Received Date{" "}
                            <span className="require-field">*</span>
                          </div>
                          <div>
                            {formData?.receivedDate && (
                              <DatePicker
                                disabled={view}
                                className="form-control receivedDate"
                                dateFormat="MM-dd-yyyy"
                                onChange={(receivedDate) =>
                                  handleDateChange(receivedDate)
                                }
                                showIcon
                                selected={new Date(formData?.receivedDate) || null}
                                name={"receivedDate"}
                              />
                            )}
                          </div>
                          {error.receivedDate && (
                            <label className="requiredthis">
                              *This is required
                            </label>
                          )}
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row className="mb-2 p-2 rowBottomSpace">
                      <Col xs={12} lg={3}>
                        <div className="input-label-Created-date ">
                          Carrier <span className="require-field">*</span>
                        </div>
                        <div>
                          <select
                            disabled={view}
                            onChange={handleFormChange}
                            value={formData?.carrierId}
                            name={"carrierId"}
                            className="form-control noMarginBtm"
                          >
                            {isCreateForm && (
                              <option selected value="0">
                                Select
                              </option>
                            )}
                            {allCarriersList?.length &&
                              allCarriersList.map((item) => (
                                <option value={item.id} key={item.id}>
                                  {item.carrierName}
                                </option>
                              ))}
                          </select>
                          {error.carrierId && (
                            <label className="requiredthis">
                              *This is required
                            </label>
                          )}
                        </div>
                      </Col>
                      <Col xs={12} lg={3}>
                        <div className="input-label-Created-date ">
                          Status
                        </div>
                        <div>
                          <select
                            disabled={
                              isCreateForm ||
                              view ||
                              !(
                                UserValue?.Value?.isSuperAdmin ||
                                UserValue?.Value?.isAdmin
                              )
                            }
                            onChange={handleFormChange}
                            value={formData?.statusId}
                            name={"statusId"}
                            className="form-control noMarginBtm"
                          >
                            {isCreateForm && (
                              <option selected value>
                                Select
                              </option>
                            )}
                            {statusList?.length &&
                              statusList.map((item) => (
                                <option value={item.id} key={item.id}>
                                  {item.status}
                                </option>
                              ))}
                          </select>
                        </div>
                      </Col>

                      <Col xs={12} lg={3}>
                        <InputGroup>
                          <div className="input-label-Created-date ">
                            Image{" "}
                            {isCreateForm ? (
                              <span className="require-field">*</span>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div>
                            <div className="fileUpload">
                              <button
                                disabled={view}
                                type="button"
                                className="btn cus-seconday-bg-btn"
                              >
                                Upload image
                              </button>
                              <input
                                className="hiddenFileField"
                                onChange={handleImageUpload}
                                disabled={view}
                                type="file"
                                id="customFile"
                                multiple
                              />
                            </div>
                            {error.image && (
                              <label className="requiredthis">
                                *This is required
                              </label>
                            )}
                            <div>
                              {image.length > 0 &&
                                image.map((item, index) => (
                                  <div
                                    className="selectedFile"
                                    key={item?.id}
                                  >
                                    <div className="fileName">{item.name}</div>
                                    <button
                                      onClick={() => handleDeleteImage(index)}
                                    >
                                      <i
                                        className="fa fa-times"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                ))}
                            </div>
                          </div>
                        </InputGroup>
                      </Col>

                    </Row>
                    <Row className="mb-2 p-2 rowBottomSpace">
                      <Col xs={12}>
                        <Form.Group>
                          <div>
                            VC Seller Comment (if any)
                          </div>
                          <textarea
                            disabled={view}
                            type={"text"}
                            name={"vcSellerComment"}
                            value={formData?.vcSellerComment}
                            className="form-control incomingTextarea"
                            onChange={handleFormChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className="cus-mar-bottom20 p-2">
                      <Col xs={12}>
                        <Form.Group>
                          <div className="">Notes</div>
                          <textarea
                            disabled={view}
                            type={"text"}
                            name="notes"
                            value={formData?.notes}
                            className="form-control incomingTextarea"
                            onChange={handleFormChange}
                          />
                          {error.notes && (
                            <label className="requiredthis">
                              *This is required
                            </label>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                </div>
                {parseInt(id) !== 0 && (
                  <div className="col-3">
                    {formData?.boxImages?.length ? (
                      <ImageSlider
                        isView={view}
                        handleMarkPrimary={handleMarkPrimary}
                        images={formData.boxImages}
                        handleDeleteImage={handleSliderDeleteImage}
                        isUserAdmin={UserValue?.Value?.isSuperAdmin}
                        setShowLargeSliderModal={setShowLargeSliderModal}
                        showLargeSliderModal={showLargeSliderModal}
                      />
                    ) : (
                      <img src={noImage} alt="" />
                    )}
                  </div>
                )}
              </div>
            </div>
            <InLargeImageSlider
              popupTitle={'Package'}
              view={view}
              handleMarkPrimary={handleMarkPrimary}
              images={formData.boxImages}
              handleSliderDeleteImage={handleSliderDeleteImage}
              isUserAdmin={UserValue?.Value?.isSuperAdmin}
              showModal={showLargeSliderModal}
              setShowLargeSliderModal={setShowLargeSliderModal}
            />
          </div>
        </div>
      </div>
    </main>
  );
};

export default EditPackages;

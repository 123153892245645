import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./batch.css";
import Table from "react-bootstrap/Table";
import UpdateAddressWidget from "./AddressWidget";
import UpdateOrders from "./UpdateOrder";
import BatchGroup from "../common/BatchGroup";
import CreateBatchDialog from "../common/CreateBatchDialog";
import { Tooltip as WmsTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import {
  createPackingSlip,
  getAllBatches,
  getBatchPendingOrders,
} from "../../store/actions/batch.action";
import FilterBatch from "./FilterBatch";
import { filterActive, findIsHostShipAi } from "../../config/utils";
import BatchPagination from "./BatchPagination";
import LoaderSpinner from "../common/Loader";
import { v4 as uuidv4 } from 'uuid';
import { MenuList } from "../common/MenuList";
import { Dropdown } from "react-bootstrap";
import { toast } from "react-toastify";
import { UploadDialog } from "../mapping/csvListUploadForm";
import { Button } from "react-bootstrap";

export function BatchShipping() {
  const [gridData, setGridData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [thresholdValidation, setthresholdValidation] = useState([]);
  const [thresholdvalue, setthresholdvalue] = useState();
  const [availableAmount, setavailableAmount] = useState();
  const [Rate, setRate] = useState("0.00");
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const numOfPage = [30, 50, 100, 200, 500, 1000, 2000];
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPage, setNumberOfPage] = useState(30);
  const [minWeight, setMinWeight] = useState(0);
  const [maxWeight, setMaxWeight] = useState(0);
  const [marketplaceIntegrationId, setMarketplaceIntegrationId] = useState('');
  const [parcelUnit, setParcelUnit] = useState("");
  const [filterApplied, setFilterApplied] = useState(false);
  const [loader, setLoader] = useState(false);
  const [showCreateBatchDialog, setShowCreateBatchDialog] = useState(false);
  const userDetails = useSelector((state) => state.auth.user);
  const [selectedBatch, setSelectedBatch] = useState([]);
  const dispatch = useDispatch();
  const [csvModalShow, setCsvModalShow] = useState(false);

  const fundDetailsvalue = useSelector(
    (state) => state?.FundManagementReducer?.getWalletAmount
  );

  useEffect(() => {
    if (userDetails) {
      getAllRecords();
    }
    setSelectedData([]);
  }, [currentPage, userDetails, selectedBatch, numberOfPage, filterApplied]);

  useEffect(() => {
    setthresholdvalue(fundDetailsvalue?.threshold);
    setavailableAmount(fundDetailsvalue?.balanceAmount);
  }, [fundDetailsvalue?.threshold, fundDetailsvalue?.availableAmount]);

  useEffect(() => {
    if (userDetails) {
      fetchAllBatches();
    }
  }, [userDetails]);

  const handleValidations = () => {
    let threshholdErrors = [];
    if (availableAmount <= thresholdvalue) {
      let obj = {
        errorName:
          "You have reached your threshold limit. Please recharge your wallet to enjoy the continued service.",
      };
      threshholdErrors.push(obj);
    }

    if (
      availableAmount > thresholdvalue &&
      availableAmount - Rate < thresholdvalue
    ) {
      let obj = {
        errorName:
          "Your wallet balance will drop below the threshold limit. The wallet balance is not enough to proceed with this transaction. Please recharge your wallet to enjoy the continued service.",
      };
      threshholdErrors.push(obj);
    }

    setthresholdValidation(threshholdErrors);
  };

  useEffect(() => {
    handleValidations();
  }, [thresholdvalue, availableAmount, Rate]);

  const fetchAllBatches = () => {
    let data = {
      createdBy: userDetails?.Value?.employeeId,
      sellerId: userDetails?.Value?.sellerId,
      companyId: userDetails?.Value?.companyId,
      isActive: true,
      orderBatchName: "",
      companySellerId: userDetails?.Value?.companySellerId,
      role: 0,
      modifiedBy: 0,
      pageIndex: 1,
      pageSize: 100,
    };
    dispatch(getAllBatches(data));
  };

  const getAllRecords = async (callback, page = false) => {
    let data = {
      pageIndex: page || currentPage,
      pageSize: numberOfPage,
      createdBy: userDetails?.Value?.employeeId,
      sellerId: userDetails?.Value?.sellerId,
      companyId: userDetails?.Value?.companyId,
      companySellerId: userDetails?.Value?.companySellerId,
      orderBatchId: selectedBatch.length ? selectedBatch[0].id : 0,
      orderStatus: 1,
      orderIds: "",
      isActive: true,
      minWeight: minWeight,
      maxWeight: maxWeight,
      dimension: parcelUnit,
      marketplaceIntegrationId: marketplaceIntegrationId,
    };
    setLoader(true);
    let batchListResp = await Promise.resolve(dispatch(getBatchPendingOrders(data)));

    if (batchListResp.data && batchListResp.data.Status === 200) {
      setLoader(false);
      const resp = [...batchListResp.data.Entity.listBatchPendingOrdersEntity];
      setSelectedData(filterActive(selectedData, resp));
      setTotalPages(
        Math.ceil(batchListResp.data.Entity.TotalRecords / numberOfPage)
      );
      setGridData(resp);
      setTotalRecords(batchListResp.data.Entity.TotalRecords);
    }
    if (callback && typeof callback === "function") {
      callback();
    }
  };

  const handleRecordCheck = (e, obj, index) => {
    const { checked } = e.target;
    let copyGridData = [...gridData];
    if (obj === "all") {
      let allchecked = copyGridData.map((item) => {
        return {
          ...item,
          isChecked: checked,
        };
      });
      setGridData(allchecked);
      if (checked) {
        setSelectedData(allchecked);
      } else {
        setSelectedData([]);
      }
    } else {
      copyGridData[index].isChecked = checked;
      setGridData(copyGridData);
      let findChecked = gridData.filter((item) => item.isChecked);
      setSelectedData(findChecked);
    }
  };

  const renderStatus = (i) => {
    return (
      <td className={`batch-td-data ${i.isQueued ? "enableTble" : ""
        }`}>
        {i.isErrorStatus && (
          <>
            <span
              className="d-inline-block ml-3"
              id={i.orderId}
            >
              <i
                className="fa fa-exclamation-triangle error-icon"
                aria-hidden="true"
              ></i>
            </span>
            <WmsTooltip
              anchorId={i.orderId}
              place="bottom"
              data-tooltip-html={true}
              className="tooltipWidth"
            >
              <ul className="errorList">
                {i.errorMessage
                  .split(",")
                  .map((item, indexError) => (
                    <li
                      key={item}
                      className="alignLeft"
                    >
                      {item || "-"}
                    </li>
                  ))}
              </ul>
            </WmsTooltip>
          </>
        )}
        {!i.isErrorStatus && (
          <>
            <i
              className="fa fa-check success-icon"
              aria-hidden="true"
            ></i>
            {!isNaN(i.rate) && <span>$ {i.rate}</span>}
          </>
        )}
      </td>
    )
  }

  const renderParcels = (i) => {
    return (
      <td className="batch-td-data" key={i.id}>
        {i.parcel.length > 0 && (
          <React.Fragment key={i.id}>
            {i.parcel.map((item, parcelIndex) => (
              <span key={uuidv4()}>
                {Number(item.weight) > 0 ||
                  Number(item.ounces) > 0 ||
                  Number(item.length) > 0 ||
                  Number(item.width) > 0 ||
                  Number(item.height) > 0 ? (
                  <div>
                    {item.weight || 0}{" "}
                    {i.dimensionUnit === "INCH" ? "lb" : "kg"}
                    , {item.ounces || 0}{" "}
                    {i.dimensionUnit === "INCH" ? "oz" : "g"}
                    <p>
                      {item.length || 0}x{item.width || 0}
                      x{item.height || 0}
                      {i.dimensionUnit === "INCH" ? "in" : "cm"}
                    </p>
                  </div>
                ) : (
                  "----"
                )}
              </span>
            ))}
          </React.Fragment>
        )}

        {i.parcel.length === 0 && (
          "----"
        )}
      </td>
    )
  }

  const handlePackageSlip = async (e, item) => {
    e.preventDefault();
    let response = await Promise.resolve(dispatch(createPackingSlip(item.orderId)));
    if (response.data.Status === 200 && response.data.Entity) {
      window.open(response.data.Entity, "PRINT", "height=400,width=600");
      toast.success('Packing slip generated successfully')
    } else {
      toast.error(response.data.Message.AppStatusDescription);
    }
  }

  const refreshBatchCall = () => {
    getAllRecords(null, 1);
  }

  return (
    <main>
      <div className={"main__container"}>
        <div className="main__tile_heading cus-btn-include-sec">
          Batch
          <div className="top_right_button_container">
            <Button
              onClick={() => setCsvModalShow(true)}
              variant="outline-primary"
              className="cus-seconday-bg-btn mr-2"
            >
              Upload CSV
            </Button>

            <FilterBatch
              filterApplied={filterApplied}
              setFilterApplied={setFilterApplied}
              getAllRecords={getAllRecords}
              setMinWeight={setMinWeight}
              setMaxWeight={setMaxWeight}
              setParcelUnit={setParcelUnit}
              minWeight={minWeight}
              maxWeight={maxWeight}
              parcelUnit={parcelUnit}
              loader={loader}
              userDetails={userDetails}
              dispatch={dispatch}
              marketplaceIntegrationId={marketplaceIntegrationId}
              setMarketplaceIntegrationId={setMarketplaceIntegrationId}
            />
          </div>
        </div>
        <div className="container-fluid">
          <div className="row bacthWrapper">
            <div className={selectedData.length > 0 ? 'col-lg-9 col-sm-12 mobMb-10' : 'col-12'}>
              <div className="topSectionSticky">
                <BatchGroup
                  setShowCreateBatchDialog={setShowCreateBatchDialog}
                  selectedData={selectedData}
                  gridData={gridData}
                  setSelectedBatch={setSelectedBatch}
                  setSelectedData={setSelectedData}
                  getAllRecords={getAllRecords}
                  setRate={setRate}
                  thresholdValidation={thresholdValidation}
                  createdBy={userDetails}
                  fetchAllBatches={fetchAllBatches}
                  setCsvModalShow={setCsvModalShow}
                />
              </div>
              {loader ? (
                <div className="full-screen-spinner-overlay">
                  <LoaderSpinner />
                </div>
              ) : (
                ""
              )}

              <div className="tableFixHead">
                <Table
                  className={`table table-height cus-batch-table ${loader ? "disableTble" : ""
                    }`}
                >
                  <thead className="tbl-btch">
                    <tr className="table-rw-batch">
                      <th className="tbl-btch-hd" width="5%">
                        <button
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <input
                            className="allCheck"
                            disabled={!gridData.length}
                            type="checkbox"
                            checked={
                              gridData.filter((item) => item.isChecked)
                                .length === gridData.length
                            }
                            onChange={(e) => handleRecordCheck(e, "all")}
                          />
                        </button>
                      </th>
                      <th className="tbl-btch-hd">
                        <div>Order Date</div>
                        <div>Integration</div>
                        <div>Items</div>
                      </th>
                      <th className="tbl-btch-hd">Receiver</th>
                      <th className="tbl-btch-hd">Order Number</th>
                      <th className="tbl-btch-hd">Quote Booked</th>
                      <th className="tbl-btch-hd">
                        Service
                        <div>Package Type</div>
                      </th>
                      <th className="tbl-btch-hd">
                        Weight <div>Dims</div>
                      </th>
                      <th className="tbl-btch-hd">Signature</th>
                      {!findIsHostShipAi() && (
                        <th className="tbl-btch-hd">Action</th>
                      )}
                    </tr>
                  </thead>
                  <tbody className="tbl-bt-body">
                    {gridData.length > 0 &&
                      gridData.map((i, index) => (
                        <tr
                          className={`btch-rw ${i.isChecked && "selected"} ${i.isQueued ? "disableTble" : ""
                            }`}
                          key={i?.orderId}
                        >
                          <td className="blank" align="center">
                            <input
                              className="checkRecordRow"
                              type="checkbox"
                              checked={Boolean(i.isChecked)}
                              disabled={i.isQueued}
                              onChange={(e) => handleRecordCheck(e, i, index)}
                            />
                          </td>
                          <td className="batch-td-data">
                            {i.orderDate}
                            <p>{i.integrationName}.csv</p>
                            <p>{i.debugTrackingId}</p>
                          </td>
                          <td className="batch-td-data">
                            {i.receiverName}
                            <p>
                              {i.receiverAddress}
                              <label
                                className={
                                  i.AddressValidationStatus
                                    ? "greenColor"
                                    : "redColor"
                                }
                              >
                                {i.AddressValidationStatus
                                  ? "Verified Address"
                                  : "Invalid Address"}
                              </label>
                            </p>
                          </td>
                          <td className="batch-td-data">{i.orderNumber}</td>
                          {renderStatus(i)}
                          <td className="batch-td-data">
                            {i.serviceName || i.packageName ? (
                              <>
                                {i.serviceName}
                                <div>{i.packageName}</div>
                              </>
                            ) : (
                              "----"
                            )}
                          </td>
                          {renderParcels(i)}
                          <td className="batch-td-data">
                            {i?.signatureName || "-"}
                          </td>
                          {!findIsHostShipAi() && (
                            <td className="batch-td-data">
                              <MenuList
                                children={
                                  <Dropdown.Item
                                    className="printLabelBtn"
                                    onClick={(e) => handlePackageSlip(e, i)}
                                    disabled={!i.orderId}
                                  >
                                    Packing Slips
                                  </Dropdown.Item>
                                }
                              />
                            </td>
                          )}
                        </tr>
                      ))}
                    {gridData.length === 0 && (
                      <tr>
                        <td colSpan={8} align="center">
                          {!loader && "No Record found"}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>

            </div>
            {selectedData.length > 0 && (
              <div
                className={`col-lg-3 col-sm-12 rightWidgetArea rightSideBar ${loader ? "disableTble" : ""
                  }`}
              >
                {thresholdValidation.length > 0 && (
                  <div className="validationBox ">
                    <ul>
                      {thresholdValidation.length > 0 &&
                        thresholdValidation.map((error, index) => (
                          <li className="thresholderrors" key={error?.id}>
                            {error.errorName}
                          </li>
                        ))}
                    </ul>
                  </div>
                )}
                {selectedData.length === 1 && (
                  <UpdateAddressWidget
                    getAllRecords={getAllRecords}
                    selectedData={selectedData}
                    gridData={gridData}
                    createdBy={userDetails}
                    setSelectedData={setSelectedData}
                    fetchAllBatches={fetchAllBatches}
                  />
                )}
                {selectedData.length > 1 && (
                  <UpdateOrders
                    getAllRecords={getAllRecords}
                    createdBy={userDetails}
                    selectedData={selectedData}
                    setSelectedData={setSelectedData}
                    gridData={gridData}
                    fetchAllBatches={fetchAllBatches}
                  />
                )}
                {selectedData.length === 0 && (
                  <div className="noOrderSelected">
                    <h2>Selected Orders</h2>
                    <p className="cus-no-record">No orders selected</p>
                  </div>
                )}
              </div>
            )}
            <div className="col-12">
              <BatchPagination
                gridData={gridData}
                totalRecords={totalRecords}
                numberOfPage={numberOfPage}
                numOfPage={numOfPage}
                loader={loader}
                currentPage={currentPage}
                totalPages={totalPages}
                setCurrentPage={setCurrentPage}
                setNumberOfPage={setNumberOfPage}
              />
            </div>
          </div>
        </div>
      </div>
      {showCreateBatchDialog && (
        <CreateBatchDialog
          setShowCreateBatchDialog={setShowCreateBatchDialog}
          modalShow={showCreateBatchDialog}
          modalClass={"modalDeleteWrapper"}
          createdBy={userDetails}
          selectedData={selectedData}
          getAllRecords={getAllRecords}
          fetchAllBatches={fetchAllBatches}
        />
      )}
      {csvModalShow && (
        <UploadDialog
          onHide={() => setCsvModalShow(false)}
          show={csvModalShow}
          type={"Batch"}
          parentCallRefresh={refreshBatchCall}
        />
      )}
    </main>
  );
}

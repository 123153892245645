import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAddressFromZipCode, getAllPackagesByServiceId, getAllServices, getStates } from '../../store/actions';
import _ from 'lodash';
import Input from "react-phone-number-input/input";
import { toast } from 'react-toastify';
import { allowedPDFExtension } from '../../constant';
import Trash from "../../assets/trashRed.svg";
import { getTrackingNumberFromPdfAction } from '../../store/actions/inventory.action';

const ReceiverAddress = (props) => {
    const {
        formData,
        handleFormValue,
        isEditable,
        handleBlurInput,
        error,
        setFormData,
        setUploadedLabel,
        uploadedLabel,
        setLoader,
        setErrors,
        outboundId
    } = props;
    const dispatch = useDispatch();
    const [servicePackages, setServicePackages] = useState([]);
    const services = useSelector((state) => state.shipment?.services);
    const USSates = useSelector((state) => state.shipment?.states);
    const countries = useSelector((state) => state.shipment?.countries);
    const packages = useSelector((state) => state.shipment?.packages);

    useEffect(() => {
        if (!USSates?.length) {
            dispatch(getStates(false, false));
        }
    }, []);

    useEffect(() => {
        fetchTrackingNumber();
    }, [uploadedLabel]);

    const fetchTrackingNumber = async () => {
        if (uploadedLabel) {
            let formDataLabel = new FormData();
            formDataLabel.append("label", uploadedLabel);
            let responseLabel = await Promise.resolve(dispatch(getTrackingNumberFromPdfAction(formDataLabel)));
            if (responseLabel.data.Status === 200) {
                setFormData({ ...formData, trackingNumber: responseLabel.data.Entity.replaceAll(/\s/g, '') })
            } else {
                setFormData({ ...formData, trackingNumber: '' })
                toast.error(responseLabel.data.Message.AppStatusDescription);
            }
        }
    }

    useEffect(() => {
        if (formData.serviceTypeId) {
            selectServicePackage(formData.serviceTypeId);
        }
    }, [formData.serviceTypeId]);

    const handleLabelCheck = (e) => {
        let { value } = e.target;
        let formObj = _.cloneDeep(formData);
        formObj.isGenerateLabel = Boolean(Number(value));
        setFormData(formObj);
    }

    const handleLabelUpload = (event) => {
        let file = [...event.target.files][0];
        const extension = file.name.split(".").slice(-1).pop();
        const fileSize = Math.round(file.size / 1024);
        if (!allowedPDFExtension.includes(extension ? extension.toLowerCase() : "")) {
            toast.error("Unsupported File format", 1000);
            return false;
        }
        if (fileSize > 1024) {
            toast.info("Each file size should be less than 1MB", 1000);
            return false;
        }
        setUploadedLabel(file);
        setErrors({ ...error, uploadedLabel: '' })
    }

    useEffect(() => {
        if (formData.country_Id) {
            if (Number(formData.country_Id) !== 218) {
                dispatch(getAllServices(true, false));
            } else {
                if ((formData.state === "AA" || formData.state === "AE" || formData.state === "AP")) {
                    dispatch(getAllServices(false, true));
                } else {
                    dispatch(getAllServices(false, false));
                }
                dispatch(getStates(218));
            }
        }
    }, [formData.country_Id, formData.state]);

    useEffect(() => {
        if (services?.length) {
            let getCarrier = services.filter((item) => item.carrierCompanyId === Number(formData.carrierCompanyId));
            setServicePackages(getCarrier[0]);
        }
    }, [formData.carrierCompanyId, services]);

    const selectServicePackage = (value) => {
        dispatch(getAllPackagesByServiceId(value));
    }

    const fetchAddress = async (event) => {
        let { value } = event.target;
        setLoader(true);
        let responseAddress = await Promise.resolve(dispatch(fetchAddressFromZipCode(value)));
        if (responseAddress && responseAddress.data && responseAddress.data.Status === 200) {
            setLoader(false);
            let address = responseAddress?.data?.Entity;
            if (address && address?.Country) {
                setFormData({
                    ...formData,
                    city: address.City,
                    state: address.Country === "United States"
                        ? USSates.filter((state) => state.StateName === address?.State)[0]
                            .StateCode
                        : address?.State,
                    zipCode: value.trim(),
                    country_Id: countries.filter((country) => country.name === address?.Country)[0].id || formData.country_Id,
                });
            }

        }
    }

    const onTrashClick = (e) => {
        e.preventDefault();
        setUploadedLabel('');
    }

    return (
        <>
            <div className="row mb-3 dimesntionsBlock">
                <div className="col-12 p-0 mb-4"><b className='subHeadingInner'>Receivers Address</b></div>
                <div className='col-12'>
                    <div className="row">
                        <div className='col-lg-2 col-md-6 col-sm-12 mb-3'>
                            <label className="input-label-txt">Country <span className="require-field">*</span></label>
                            <select
                                className="custom-drp-down form-control"
                                value={formData.country_Id}
                                name={'country_Id'}
                                onChange={handleFormValue}
                                disabled={!isEditable}
                            >
                                {countries?.map((country) => {
                                    return (
                                        <option
                                            value={country.id}
                                            key={country.id}
                                        >
                                            {country.name}
                                        </option>
                                    );
                                })}
                            </select>
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 mb-3'>
                            <label className="input-label-txt">Name <span className="require-field">*</span></label>
                            <input
                                className={`form-control numInput`}
                                type={"text"}
                                name="receiverName"
                                value={formData.receiverName}
                                onBlur={handleBlurInput}
                                onChange={handleFormValue}
                                disabled={!isEditable}
                            />
                            <div className="error">{error.receiverName}</div>
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 mb-3'>
                            <label className="input-label-txt">Company</label>
                            <input
                                className={`form-control numInput`}
                                type={"text"}
                                name="company"
                                value={formData.company}
                                onChange={handleFormValue}
                                onBlur={handleBlurInput}
                                disabled={!isEditable}
                            />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 mb-3'>
                            <label className="input-label-txt">Email</label>
                            <input
                                className={`form-control numInput`}
                                type={"text"}
                                name="email"
                                value={formData.email}
                                onBlur={handleBlurInput}
                                onChange={handleFormValue}
                                disabled={!isEditable}
                            />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 mb-3'>
                            <label className="input-label-txt">Phone <span className="require-field">*</span></label>
                            <Input
                                value={formData.phoneNumber}
                                name="phoneNumber"
                                onChange={(e) => handleFormValue({ target: { value: e, name: 'phoneNumber' } })}
                                className={`form-control`}
                                disabled={!isEditable}
                            />
                            <div className="error">{error.phoneNumber}</div>
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 mb-3'>
                            <label className="input-label-txt">Zip <span className="require-field">*</span></label>
                            <input
                                className={`form-control numInput`}
                                type={"text"}
                                value={formData.zipCode}
                                name="zipCode"
                                onChange={handleFormValue}
                                placeholder='XXXXX or XXXXX-XXXX'
                                onBlur={fetchAddress}
                                disabled={!isEditable}
                            />
                            <div className="error">{error.zipCode}</div>
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 mb-3'>
                            <label className="input-label-txt">State <span className="require-field">*</span></label>
                            {Number(formData.country_Id) === 218 ? (
                                <select
                                    className='custom-drp-down form-control'
                                    value={formData.state}
                                    name='state'
                                    onChange={handleFormValue}
                                    disabled={!isEditable}
                                >
                                    <option value="">Select</option>
                                    {USSates &&
                                        USSates.length > 0 &&
                                        USSates.map((state, stateIndex) => (
                                            <option
                                                value={state.StateCode}
                                                key={state.StateCode}
                                            >
                                                {state.StateCode} {state.StateName}
                                            </option>
                                        ))}
                                </select>
                            ) : (
                                <input
                                    className='form-control'
                                    type='text'
                                    name='state'
                                    onChange={handleFormValue}
                                    value={formData.state}
                                    disabled={!isEditable}
                                />
                            )}
                            <div className="error">{error.state}</div>
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 mb-3'>
                            <label className="input-label-txt">City <span className="require-field">*</span></label>
                            <input
                                className={`form-control numInput`}
                                type={"text"}
                                name='city'
                                value={formData.city}
                                onBlur={handleBlurInput}
                                onChange={handleFormValue}
                                disabled={!isEditable}
                            />
                            <div className="error">{error.city}</div>
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 mb-3'>
                            <label className="input-label-txt">Address 1 <span className="require-field">*</span></label>
                            <input
                                className={`form-control numInput`}
                                type={"text"}
                                name="addressFirst"
                                value={formData.addressFirst}
                                onBlur={handleBlurInput}
                                disabled={!isEditable}
                                onChange={handleFormValue}
                            />
                            <div className="error">{error.addressFirst}</div>
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 mb-3'>
                            <label className="input-label-txt">Address 2</label>
                            <input
                                className={`form-control numInput`}
                                type={"text"}
                                name="addressSecond"
                                value={formData.addressSecond}
                                onBlur={handleBlurInput}
                                onChange={handleFormValue}
                                disabled={!isEditable}
                            />
                        </div>
                        <div className='col-lg-2 col-md-6 col-sm-12 mb-3'>
                            <label className="input-label-txt">Address 3</label>
                            <input
                                className={`form-control numInput`}
                                type={"text"}
                                name="addressThird"
                                value={formData.addressThird}
                                onBlur={handleBlurInput}
                                onChange={handleFormValue}
                                disabled={!isEditable}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="row mb-3 dimesntionsBlock">
                <div className="col-12 p-0 mb-4"><b className='subHeadingInner'>Shipping Label Details</b></div>
                <div className='col-12'>
                    <div className="row">
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <label className="input-label-txt">Carrier</label>
                            <select
                                className={`form-control carrierSelect`}
                                onChange={handleFormValue}
                                value={formData.carrierCompanyId}
                                disabled={!isEditable}
                                name={"carrierCompanyId"}
                            >
                                <option value={"0"}>Select Carrier</option>
                                {services?.map((item) => (
                                    <option value={item.carrierCompanyId} key={item.id}>
                                        {item.carrierCompanyName}
                                    </option>
                                ))}
                            </select>
                            <div className="error">{error.carrierTypeId}</div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <label className="input-label-txt">Service</label>
                            <select
                                className='form-control'
                                disabled={!isEditable}
                                onChange={handleFormValue}
                                name={"serviceTypeId"}
                                value={formData.serviceTypeId}
                            >
                                <option>Select service</option>
                                {servicePackages?.services?.map((item) => (
                                    <option value={item.id} key={item.serviceName}>
                                        {item.serviceName}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <label className="input-label-txt">Package</label>
                            <select
                                className='form-control'
                                disabled={!isEditable}
                                onChange={handleFormValue}
                                name={"packageTypeId"}
                                value={formData.packageTypeId}
                            >
                                <option value={0}>Select package Type...</option>
                                {packages && packages.map((item, index) => (
                                    <option key={item?.id} value={item.id}>{item.packageName}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className='col-12'>
                    <div className="row">
                        <div className='col-lg-8 col-md-6 col-sm-12'>
                            <fieldset
                                id="isGenerateLabel"
                                className="codeOptions"
                                disabled={!isEditable}
                                onChange={(e) => handleLabelCheck(e)}
                            >
                                <input
                                    type="radio"
                                    checked={!formData.isGenerateLabel}
                                    name="isGenerateLabel"
                                    id="savePdf"
                                    value={0}
                                    className='mr-1'
                                />
                                <label for="savePdf" className="input-label-txt">Upload Label</label>

                                <input
                                    type="radio"
                                    checked={formData.isGenerateLabel}
                                    name="isGenerateLabel"
                                    id="print"
                                    value={1}
                                    className='mr-1'
                                />
                                <label for="print" className="input-label-txt">Generate Label</label>
                            </fieldset>
                        </div>
                    </div>
                    {!formData.isGenerateLabel && (
                        <div className="row mb-3">
                            <div className='col-lg-4 col-md-6 col-sm-12 mobMb-10'>
                                <label>Label</label>
                                <div className="uploadContainer">
                                    <div className="inlineBlock fileUpload inventory">
                                        <button
                                            disabled={!isEditable}
                                            type="button"
                                            className="btn cus-seconday-bg-btn imgUploadbtn"
                                        >
                                            Upload Label
                                        </button>
                                        <input
                                            className="hiddenFileField"
                                            disabled={!isEditable}
                                            type="file"
                                            accept=".pdf"
                                            id="customFile"
                                            onChange={handleLabelUpload}
                                        />
                                    </div>
                                </div>
                                {uploadedLabel?.name && (
                                    <div>
                                        <div className='wordWrap'>{uploadedLabel?.name}</div>
                                        <span className="trash-icon ml-1">
                                            <img
                                                className="trash-Img"
                                                onClick={onTrashClick}
                                                src={Trash}
                                                alt=""
                                            />
                                        </span>
                                    </div>
                                )}
                                {formData.shippingLabel && (
                                    <div className="selectedFile">
                                        <div className="fileName">
                                            {formData.shippingLabel ? (
                                                <a
                                                    href={formData.shippingLabel}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    className='wordWrap'
                                                >
                                                    {formData.trackingNumber + "_" + formData.outboundNumber}
                                                </a>
                                            ) : (
                                                <>{formData.shippingLabel}</>
                                            )}
                                        </div>
                                    </div>
                                )}

                                <div className="error">{error.uploadedLabel}</div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12 mobMb-10'>
                                <label>Tracking Number <span className="require-field">*</span></label>
                                <input
                                    className={`form-control`}
                                    type={"text"}
                                    name="trackingNumber"
                                    value={formData.trackingNumber}
                                    onChange={handleFormValue}
                                    disabled={!isEditable}
                                    onBlur={handleBlurInput}
                                />
                                <div className="error">{error.trackingNumber}</div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <label>Sub Tracking Number</label>
                                <input
                                    className={`form-control`}
                                    type={"text"}
                                    name="subTrackingNumber"
                                    value={formData.subTrackingNumber}
                                    onChange={handleFormValue}
                                    disabled={!isEditable}
                                    onBlur={handleBlurInput}
                                    required={formData.outboundStatusId === 2}
                                />
                            </div>

                        </div>
                    )}
                    <div className="row mb-3">

                        {Number(outboundId) > 0 && formData.outboundStatusId >= 2 && (
                            <>
                                <div className='col-lg-4 col-md-6 col-sm-12 mobMb-10'>
                                    <label>Tracking Number</label>
                                    <input
                                        className={`form-control`}
                                        type={"text"}
                                        name="trackingNumber"
                                        value={formData.trackingNumber}
                                        onChange={handleFormValue}
                                        disabled={!isEditable}
                                        onBlur={handleBlurInput}
                                    />
                                </div>
                                <div className='col-lg-4 col-md-6 col-sm-12 mobMb-10'>
                                    <label>Label</label>
                                    <div className={`form-control`}>
                                        {formData.shippingLabel && (
                                            <a
                                                href={formData.shippingLabel}
                                                target="_blank"
                                                rel="noreferrer"
                                                className='wordWrap'
                                            >
                                                {formData.trackingNumber + "_" + formData.outboundNumber}
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}


export default ReceiverAddress;
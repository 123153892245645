import axios from "axios";
import { apiUrl } from '../constant'

const getIntegrationforcolumn = () => {
  return axios.get(`${apiUrl}/api/GetCsvIntegrationColumns`);
};

const checkDuplicateIntegration = (integrationName) => {
  return axios.post(`${apiUrl}/CheckDuplicateIntegration?name=${integrationName}`);
};

export const createintegrationService = {
  getIntegrationforcolumn,
  checkDuplicateIntegration
}
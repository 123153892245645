import React, { useState } from 'react';
import _ from 'lodash';

const SubmitRequest = () => {
    const [query, setQuery] = useState('');

    const handleSubmitRequest = async (e) => {
        e.preventDefault();
        let data = query.split(',').filter(trck => trck);
        let filterUniques = _.uniq(data)
        setQuery(filterUniques.join(','));
    }

    return (
        <div className='queryBox'>
            <h2>Generate refundable transactions</h2>
            <p>Please enter tracking numbers, comma separated.</p>
            <textarea value={query} type='text' className='form-control requestArea' rows={5} onChange={(e) => setQuery(e.target.value)} />
            <div className='actions mt-3 alignRight p-0'>
                <button
                    disabled={!query}
                    className="btn cus-seconday-bg-btn"
                    onClick={handleSubmitRequest}
                >Submit</button>
            </div>
        </div>
    );
}

export default SubmitRequest;
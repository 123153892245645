import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "../fundManagement/fundmanagement.css";
import { useDispatch, useSelector } from "react-redux";
import { checkPermission, handleInputValueTypes, validateEmail, validatePhoneNumber, validateZipCode, validateZipCodeLength } from "../../config/utils";
import {
  getAllCompanyDetails,
} from "../../store/actions/rateCard";
import { Button } from "bootstrap-4-react/lib/components";
import {
  GetCompanyAndAddressDetailForSeller,
  addNewSeller,
  deleteSellerAddress,
  fetchAddressFromZipCode,
  getAllCountries,
  getSellersforMultipleCompany,
  getStates,
  makeDefaultAddressForSeller,
} from "../../store/actions";
import AddAddressModal from "../modal/AddAddressModal";
import _ from "lodash";
import AlertDialogModal from "../common/AlertDialog";
import { UserManagement } from "../UserManagement/UserTable";
import BasicDetails from "./SellerDetails";
import AddressModalComponent from "./AddressModal";
import { accountsManagement, featureSubmodulePermission, permissionAssociatedUser, permissionAssociatedWarehouse, SUBF_SELLER } from "../../constant";
import { getWarehouseListing } from "../../store/actions/inventory.action";

export function EditSeller(props) {
  const navigate = useNavigate();
  const { rowId } = useParams();
  const sellerIdValue = parseInt(rowId, 10);
  const [activeTab, setActiveTab] = React.useState(1);
  const location = useLocation();

  const dispatch = useDispatch();
  const [isDefault, setisDefault] = useState(false);
  const [countryList, setcountryList] = useState();

  const [addressModalShow, setaddressModalShow] = useState(false);
  const { getAllCompany } = useSelector((state) => state.RateCardReducer);
  const { warehouseList } = useSelector((state) => state.InventoryManagement);

  const [errors, setErrors] = useState({});
  const [companyList, setCompanyList] = useState([]);
  const [addressDataValue, setaddressDataValue] = useState([]);
  const associatedUserTab = location?.state?.associatedUser;
  const [stateList, setstateList] = useState();
  const [statusModalShow, setStatusModalShow] = useState(false);
  const [addressId, setaddressId] = useState();
  const [setIsUserDetailOpened] = useState(false);
  const USSates = useSelector((state) => state.shipment.states);
  const countries = useSelector((state) => state.shipment.countries);
  const rowData = location?.state?.data;
  const editValue = location?.state?.editable;
  const getSellerAddressValue = useSelector(
    (state) => state.company?.getSellerAddressData
  );

  const [formData, setFormData] = useState({});
  const [associatedUser, setAssociatedUser] = useState(false);

  useEffect(() => {
    if (getSellerAddressValue?.Entity?.AddressModels) {
      setaddressDataValue(getSellerAddressValue?.Entity?.AddressModels);
    }
  }, [getSellerAddressValue?.Entity?.AddressModels, getSellerAddressValue]);

  useEffect(() => {
    if (
      getAllCompany?.Entity?.listCompanyMasterOrdersEntity &&
      getAllCompany?.Entity?.listCompanyMasterOrdersEntity?.length
    ) {
      let data = getAllCompany?.Entity?.listCompanyMasterOrdersEntity?.map(
        (item) => {
          return {
            ...item,
            value: item.Id,
            label: item.name,
          };
        }
      );
      setCompanyList(data);
    }
  }, [getAllCompany]);

  useEffect(() => {
    if (associatedUserTab) {
      setActiveTab(2);
    }
    if (!warehouseList?.length) {
      fetchWareHouses();
    }
  }, []);

  const fetchWareHouses = () => {
    let data = { "sortOrder": "ASCENDING" }
    dispatch(getWarehouseListing(data));
  }

  //for Multiple company
  useEffect(() => {
    setFormData({
      notes: getSellerAddressValue?.Entity.notes,
      secondaryEmailAddress: getSellerAddressValue?.Entity.secondaryEmailAddress,
      newSellerName:
        rowData?.newSellerName || getSellerAddressValue?.Entity?.newSellerName,
      steCode: rowData?.steCode || getSellerAddressValue?.Entity?.steCode,
      sellerCode:
        rowData?.sellerCode || getSellerAddressValue?.Entity?.sellerCode,
      email: rowData?.email || getSellerAddressValue?.Entity?.email,
      id: rowData?.id || getSellerAddressValue?.Entity?.id,
      createdDate: new Date(),
      modifiedDate: new Date(),
      addressModels: [],
      isActive: true,
      companyIds: companyList?.filter((option) =>
        _.map(
          getSellerAddressValue?.Entity?.selectedCompanyIds,
          "CompanyIds"
        ).includes(option.value)
      ),
      warehouseIds: warehouseList?.filter((option) =>
        _.map(
          getSellerAddressValue?.Entity?.selectedWarehouseIds,
          "WarehouseIds"
        ).includes(option.value)
      ),
    });
  }, [companyList, getSellerAddressValue, rowData]);

  const handleChangeAddress = (index, field, value) => {
    const newErrors = { ...errors };
    if (newErrors[`addressModels_${index}`]) {
      delete newErrors[`addressModels_${index}`][field];
    }

    if (field === 'country_Id') {
      let allCountries = [...countries];
      let countryIndex = allCountries.findIndex(
        (item) => item.id.toString() == value
      );
      const updatedAddressValue = { ...addressDataValue[index], [field]: value, country: countries[countryIndex].name, phoneNumber: `+(${countries[countryIndex].phoneCountryCode}) ` };
      const updatedFormData = [...addressDataValue];
      updatedFormData[index] = updatedAddressValue;
      setaddressDataValue(updatedFormData);
    } else {

      const updatedAddressValue = { ...addressDataValue[index], [field]: value };
      const updatedFormData = [...addressDataValue];
      updatedFormData[index] = updatedAddressValue;
      setaddressDataValue(updatedFormData);
    }
    setErrors(newErrors);
  };

  const handleSelectChange = (value, field) => {
    TopUpForm(value, field);
  };

  const TopUpForm = (item, name, sellerdetail) => {
    setFormData({ ...formData, [name]: item });
    setErrors({
      ...errors,
      [name]: "",
    });
  };
  const handleCancelAlert = () => {
    setStatusModalShow(false);
    setTimeout(() => {
      setisDefault(false);
    }, 500);
  };

  const handleYesDeleteAlert = () => {
    dispatch(deleteSellerAddress(addressId, formData?.id));
    setStatusModalShow(false);
    setisDefault(false);
  };
  const handleDeleteAddress = (d) => {
    if (d?.isDefault == 1) {
      setisDefault(true);
      setStatusModalShow(true);
      setaddressId(d?.id);
    } else {
      setStatusModalShow(true);
      setaddressId(d?.id);
    }
  };

  useEffect(() => {
    dispatch(
      GetCompanyAndAddressDetailForSeller(rowData?.id || props?.sellerId || sellerIdValue)
    );
    dispatch(getAllCompanyDetails(null, 500, 1));
    dispatch(getAllCountries());
    dispatch(getStates(218));
  }, []);

  useEffect(() => {
    if (countries && countries.length) {
      let data = countries.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });
      setcountryList(data);
    } else {
      setcountryList([]);
    }
  }, [countries]);

  const handleEditable = (e) => {
    navigate(`/editSeller/sell-${rowData?.id}`, {
      state: {
        data: rowData,
        editable: false,
      },
    });
  };

  const handleEditFromCompany = (e) => {
    return props?.seteditMode(false);
  };

  useEffect(() => {
    if (USSates && USSates.length) {
      let data = USSates.map((item) => {
        return {
          label: item.StateName,
          value: item.StateCode,
        };
      });
      setstateList(data);
    } else {
      setstateList([]);
    }
  }, [USSates]);

  /* istanbul ignore next */
  const saveData = (i) => {
    const requiredFields = ["email", "sellerCode", "companyIds", "newSellerName"];
    let isValid = true;
    let newErrors = {};

    requiredFields.forEach((field) => {
      if (!formData[field]) {
        isValid = false;
        newErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)
          } is required`;
      }
    });

    if (checkPermission(accountsManagement, SUBF_SELLER, permissionAssociatedWarehouse, featureSubmodulePermission, props.employeePermissions) && !formData.warehouseIds.length) {
      isValid = false;
      newErrors.warehouseIds = "Warehouse is required";
    }
    if (formData.companyIds === "") {
      isValid = false;
      newErrors.companyIds = "company is required";
    }
    if (formData.email && !validateEmail(formData.email)) {
      isValid = false;
      newErrors.email = "Invalid email address";
    }
    if (formData.secondaryEmailAddress && !validateEmail(formData.secondaryEmailAddress)) {
      isValid = false;
      newErrors.secondaryEmailAddress = "Invalid email address";
    }
    let data = handleAddressValidation(isValid, newErrors);
    newErrors = { ...newErrors, ...data.newErrors }
    isValid = data.isValid;
    handleValid(isValid, newErrors)
  };

  const validateZipCodeForCountry218 = (address, addressErrors) => {
    if (!address.zipCode) {
      addressErrors.zipCode = "Zip code is required";
    } else if (!validateZipCode(address.zipCode)) {
      addressErrors.zipCode = "Zip code should be in the format xxxxx or xxxxx-xxxx";
    }
  };

  const validateZipCodeForOtherCountries = (address, addressErrors) => {
    if (validateZipCodeLength(address.zipCode)) {
      addressErrors.zipCode = "Zip code should have a minimum length of 4";
    }
  };

  const handleAddressValidation = (isValid, newErrors) => {
    let res = addressDataValue?.forEach((address, index) => {
      let addressErrors = {};
      if (!address.state) {
        isValid = false;
        addressErrors.state = "State is required";
      }

      if (address.country_Id === 218) {
        validateZipCodeForCountry218(address, addressErrors);
      } else {
        validateZipCodeForOtherCountries(address, addressErrors)
      }

      if (!address.city) {
        isValid = false;
        addressErrors.city = "City is required";
      }
      if (!address.phoneNumber) {
        isValid = false;
        addressErrors.phoneNumber = "phoneNumber is required";
      }
      if (validatePhoneNumber(address.phoneNumber)) {
        isValid = false;
        addressErrors.phoneNumber = "Phone number should have a minimum of 10 digits";
      }

      if (!address.addressFirst) {
        isValid = false;
        addressErrors.addressFirst = "Address 1 is required";
      }
      if (!address.addressSecond) {
        isValid = false;
        addressErrors.addressSecond = "Address 2 is required";
      }
      newErrors[`addressModels_${index}`] = addressErrors;
    });

    return { res, newErrors, isValid };
  }
  const handleOnSuccess = () => {
    props?.setSellerState(false);
    dispatch(getSellersforMultipleCompany([props?.companyId]));
  };
  /* istanbul ignore next */
  const handleValid = (isValid, newErrors) => {
    if (isValid) {
      const changedAddressesValue = addressDataValue.filter(
        (address, addressIndex) => {
          const originalAddress =
            getSellerAddressValue?.Entity?.AddressModels[addressIndex];
          return JSON.stringify(address) !== JSON.stringify(originalAddress);
        }
      );

      if (changedAddressesValue?.length) {
        if (props?.sellerId) {
          dispatch(
            addNewSeller(
              {
                ...formData,
                companyIds: formData?.companyIds?.map((d) => d.Id).join(","),
                warehouseIds: formData?.warehouseIds?.map((d) => d.id).join(","),
                addressModels: changedAddressesValue,
              },
              null,
              true,
              formData?.id,
              handleOnSuccess
            )
          );
        } else {
          dispatch(
            addNewSeller(
              {
                ...formData,
                companyIds: formData?.companyIds?.map((d) => d.Id).join(","),
                warehouseIds: formData?.warehouseIds?.map((d) => d.id).join(","),
                addressModels: changedAddressesValue,
              },
              navigate,
              true,
              formData?.id
            )
          );
        }
      } else {
        if (props?.sellerId) {
          dispatch(
            addNewSeller(
              {
                ...formData,
                companyIds: formData?.companyIds?.map((d) => d.Id).join(","),
                warehouseIds: formData?.warehouseIds?.map((d) => d.id).join(","),
              },
              null,
              true,
              formData?.id,
              handleOnSuccess
            )
          );
        } else {
          dispatch(
            addNewSeller(
              {
                ...formData,
                companyIds: formData?.companyIds?.map((d) => d.Id).join(","),
                warehouseIds: formData?.warehouseIds?.map((d) => d.id).join(","),
              },
              navigate,
              true,
              formData?.id
            )
          );
        }
      }
    } else {
      setErrors(newErrors);
    }
  }

  const handleDefaultAddress = (Id) => {
    dispatch(makeDefaultAddressForSeller(Id, formData?.id));
  };

  const handleBack = () => {
    if (!associatedUser) {
      navigate("/SellerManagement");
    } else {
      setAssociatedUser((associatedUser) =>
        associatedUser ? false : associatedUser
      );
    }
  }

  /* istanbul ignore next */
  const fetchAddress = async (zipCode, index) => {
    if (zipCode) {
      let responseAddress = await Promise.resolve(dispatch(fetchAddressFromZipCode(zipCode)));
      if (responseAddress?.data?.Status === 200) {
        let address = responseAddress?.data?.Entity;
        if (address && address?.Country) {
          const updatedFormData = [...addressDataValue];
          let selectedCountry = countryList?.filter((country) => country.label === address?.Country)[0];
          updatedFormData[index].country = selectedCountry.label;
          updatedFormData[index].country_Id = selectedCountry.value;
          updatedFormData[index].state = address.Country === 'United States' ? USSates.filter((state) => state.StateName === address?.State)[0].StateName : address?.State;
          updatedFormData[index].city = address.City || updatedFormData[index].city;
          setaddressDataValue(updatedFormData);
        }
      }
    }
  }

  return (
    <>
      {props?.type ? (
        <AddressModalComponent
          {...props}
          addressModalShow={addressModalShow}
          formData={formData}
          setaddressModalShow={setaddressModalShow}
          navigate={navigate}
          handleEditFromCompany={handleEditFromCompany}
          saveData={saveData}
          TopUpForm={TopUpForm}
          errors={errors}
          warehouseList={warehouseList}
          companyList={companyList}
          handleSelectChange={handleSelectChange}
          addressDataValue={addressDataValue}
          handleDefaultAddress={handleDefaultAddress}
          handleDeleteAddress={handleDeleteAddress}
          countryList={countryList}
          handleChangeAddress={handleChangeAddress}
          handleInputValueTypes={handleInputValueTypes}
          stateList={stateList}
          statusModalShow={statusModalShow}
          handleCancelAlert={handleCancelAlert}
          handleYesDeleteAlert={handleYesDeleteAlert}
          isDefault={isDefault}
          setaddressDataValue={setaddressDataValue}
        />
      ) : (
        <main>
          {addressModalShow && (
            <AddAddressModal
              show={addressModalShow}
              SellerData={{
                newSellerName: formData?.newSellerName,
                companyIds: formData?.companyIds,
                email: formData?.email,
                steCode: formData?.steCode,
                sellerCode: formData?.sellerCode,
                sellerId: formData?.id,
              }}
              type="seller"
              onHide={() => setaddressModalShow(false)}
            />
          )}
          <div className="main__container">
            <div className="main__tile_heading cus-btn-include-sec">
              <div className="Back-btn">
                <Button
                  className="cus-primary-transparent-btn "
                  onClick={() => {
                    handleBack();
                  }}
                >
                  Back
                </Button>
              </div>
            </div>
            <div className="container-fluid">
              <div className="cus-page-content-sec">
                <div className="cus-tab-heading-sec">
                  <div className="tabs">
                    <button
                      className={`${activeTab === 1 ? "cus-tab-btn active" : "cus-tab-btn"
                        }`}
                      onClick={() => setActiveTab(1)}
                    >
                      Seller Details
                    </button>
                    {checkPermission(accountsManagement, SUBF_SELLER, permissionAssociatedUser, featureSubmodulePermission, props.employeePermissions) && (
                      <button
                        className={`${activeTab === 2 ? "cus-tab-btn active" : "cus-tab-btn"
                          }`}
                        onClick={() => { setActiveTab(2); setAssociatedUser(false); }}
                      >
                        Associated Users
                      </button>
                    )}
                    {activeTab === 1 && (
                      <BasicDetails
                        formData={formData}
                        TopUpForm={TopUpForm}
                        editValue={editValue}
                        errors={errors}
                        companyList={companyList}
                        warehouseList={warehouseList}
                        handleSelectChange={handleSelectChange}
                        handleEditable={handleEditable}
                        setaddressModalShow={setaddressModalShow}
                        saveData={saveData}
                        addressDataValue={addressDataValue}
                        handleDefaultAddress={handleDefaultAddress}
                        handleDeleteAddress={handleDeleteAddress}
                        countryList={countryList}
                        handleChangeAddress={handleChangeAddress}
                        handleInputValueTypes={handleInputValueTypes}
                        stateList={stateList}
                        editMode={props.editMode}
                        handleEditFromCompany={handleEditFromCompany}
                        fetchAddress={fetchAddress}
                      />
                    )}
                    {activeTab === 2 && (
                      <div className="main_content_table">
                        <UserManagement
                          {...props}
                          type={"associatedUser"}
                          sellerId={sellerIdValue}
                          setIsUserDetailOpened={setIsUserDetailOpened}
                          associatedUser={associatedUser}
                          setAssociatedUser={setAssociatedUser}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AlertDialogModal
            modalClass={"modalDeleteWrapper"}
            modalShow={statusModalShow}
            handleCancelDeleteAlert={handleCancelAlert}
            handleYesDeleteAlert={handleYesDeleteAlert}
            default={isDefault}
            messageText={
              isDefault
                ? `You cannot Delete the default Address of Seller .`
                : `Are you sure you want to 
         delete this address?`
            }
            headingText={"Delete Address"}
          />
        </main>
      )}
    </>
  );
}

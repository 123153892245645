import React, { useEffect, useState } from 'react';
import Table from "react-bootstrap/Table";
import { useSelector } from 'react-redux';
import BatchPagination from '../batch/BatchPagination';
import { numOfPage } from '../../constant';

const RequestHistory = () => {
    const getUserData = useSelector((state) => state?.company?.getAllUsersData);

    const [data, setData] = useState({
        sortColumn: '',
        sortOrder: 'DESCENDING'
    });

    useEffect(() => {
        setData({});
    }, [])

    const handleSortingClass = (columnName, isArrow = false) => {
        if (isArrow) {
            return data.sortColumn === columnName && data.sortOrder === "ASCENDING"
                ? "fa fa-arrow-up"
                : "fa fa-arrow-down";
        }
        return data.sortColumn === columnName ? "active" : "sortColumn";
    };

    return (
        <div className='queryBox'>
            <div className="main_content_table refundTable company-table incomingTbl">
                <Table className="table  table-height cus-left-right-col-layout">
                    <thead border="Green">
                        <tr>
                            <th className="head-company" width={'30%'}>
                                <div
                                    className={handleSortingClass("UserName")}
                                >
                                    Request Date
                                    <i
                                        className={handleSortingClass("UserName", true)}
                                        aria-hidden="true"
                                    ></i>
                                </div>
                            </th>
                            <th width={'30%'}>Tracking Number</th>
                            <th width={'20%'}>Value</th>
                            <th width={'20%'}>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getUserData?.Entity?.listUserOrdersEntity?.map((d) => (
                            <tr key={d?.id}>
                                <td>{d?.newUserName}</td>
                                <td>{d?.userEmailId}</td>
                                <td>{d?.phoneNumber}</td>
                                <td>{d?.Status}</td>
                            </tr>
                        ))}
                        {!getUserData?.Entity?.listUserOrdersEntity.length && (
                            <tr>
                                <td colSpan={"4"} align="center">
                                    No Record Found
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>

            <BatchPagination
                gridData={getUserData?.Entity?.listUserOrdersEntity}
                totalVisualRecords={
                    getUserData?.Entity?.listUserOrdersEntity?.length
                }
                numberOfPage={1}
                numOfPage={numOfPage}
                currentPage={1}
                totalPages={1}
                totalRecords={getUserData?.Entity?.TotalRecords}
            />
        </div>
    );
}

export default RequestHistory;
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrdersCsv } from '../../store/actions/history';
import _ from "lodash";

const ExportOrderCsv = (props) => {
    const { gridData, csvData, idList } = props;
    const [btnClicked, setBtnClicked] = useState(false);

    const { csvUrl } = useSelector((state) => state.HistoryReducer);
    const dispatch = useDispatch();

    useEffect(() => {
        csvUrl && btnClicked && window.open(csvUrl);
    }, [csvUrl, btnClicked]);

    const handleOpenCsv = async () => {
        setBtnClicked(true);
        let dataServiceIdList = _.map(csvData.serviceIdList, "id");
        let dataCarrierIdList = _.map(csvData.carrierIdList, "id");
        let data = { ...csvData, shipmentIdList: idList, serviceIdList: dataServiceIdList, carrierIdList: dataCarrierIdList };
        dispatch(getOrdersCsv(data));
    }

    return (
        <button
            className="btn cus-seconday-bg-btn top-btn"
            disabled={!gridData.length}
            onClick={handleOpenCsv}
        >
            Export to CSV
        </button>
    );
}

export default ExportOrderCsv;
import React, { useEffect, useState } from 'react';
import Select from "react-select";
import { getSelectList } from '../../../config/utils';
import { useSelector } from 'react-redux';
import { fetchCompanyFilters, getFilterSellers } from '../../../store/actions/rateCard';
import {
  getAllSellersList
} from "../../../store/actions";

const BarcodeListingFilter = (props) => {
  const {
    handleReset,
    fetchBarcodeDataList,
    dispatch,
    filterData,
    setfilterData,
  } = props;
  const [companyList, setCompanyList] = useState([]);
  const [sellerList, setSellerList] = useState([]);
  const [allSellers, setAllSellers] = useState([]);
  const { filterCompanies, filterAllSellers } = useSelector((state) => state.RateCardReducer);
  const UserValue = useSelector((state) => state.auth.user);

  useEffect(() => {
    let companyList =
      filterCompanies?.Entity?.listCompanyMasterOrdersEntity || [];
    let sellerList = filterAllSellers?.EntityList || [];
    if (companyList.length) {
      if (UserValue?.Value?.isSuperAdmin === true) {
        setCompanyList(getSelectList(filterCompanies?.Entity?.listCompanyMasterOrdersEntity, "Id", "name"));
      } else {
        let filterCompany = companyList.filter(
          (company) =>
            company.name.toLowerCase() ===
            UserValue?.Value?.companyName.toLowerCase()
        );
        setCompanyList(getSelectList(filterCompany, "Id", "name"));
      }
    }
    if (sellerList.length) {
      setAllSellers(getSelectList(sellerList, 'id', 'sellerName'));
      setSellerList(getSelectList(sellerList, 'id', 'sellerName'));
    }
  }, [filterCompanies, filterAllSellers]);

  const fetchSellers = async (companyList) => {
    if (companyList.length === 0) {
      setSellerList(allSellers);
      return;
    }
    let data = {
      companyIds: companyList,
    };
    let res = await getAllSellersList(data);
    if (res?.data?.Status === 200) {
      let dataList = res?.data?.Entity?.listSellerMasterOrdersEntity.map(
        (item) => {
          return {
            ...item,
            label: item.sellerAndCompany,
            value: item.id,
          };
        }
      );
      setSellerList(dataList);
    }
  };

  useEffect(() => {
    let data = {
      pageIndex: 1,
      pageSize: 10,
      sortColumn: "name",
      sortOrder: "ascending",
    };
    let sellerData = {
      "pageIndex": 1,
      "pageSize": 10,
      "sortColumn": "name",
      "sortOrder": "ASCENDING",
      "companyIdList": [0],
      screenType: 2,
    };
    dispatch(fetchCompanyFilters(data));
    dispatch(getFilterSellers(sellerData));
  }, []);

  const handleMultiSelect = (value, fieldName) => {
    setfilterData({
      ...filterData,
      [fieldName]: value
    });
    if (fieldName === "companyIdList") {
      let companyData = _.map(value, "Id");
      fetchSellers(companyData);
      if (filterData?.sellerIdList?.length) {
        const filteredSellers = filterData.sellerIdList.filter(seller =>
          companyData.includes(parseInt(seller.companyIds))
        );
        setfilterData({ ...filterData, sellerIdList: filteredSellers, companyIdList: value })
      }
    }
  }

  return (
    <div className="filterContainer show historyFilter barcode history-margin">
      <div className="row mb10">
        <div className="col-lg-4 col-sm-12 col-md-12 mobMbTop-10">
          <div className="row">
            <div className="col-12 mobMbTop-10">
              <label className="labelHead">Company</label>
            </div>
            <div className="col-12 fontFix">
              <Select
                isMulti
                closeMenuOnSelect={false}
                options={companyList}
                classNamePrefix="list"
                id="companyIdList"
                name="companyIdList"
                value={filterData?.companyIdList}
                onChange={(value) => handleMultiSelect(value, "companyIdList")}
              />
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-12 col-md-12 mobMbTop-10">
          <div className="row">
            <div className="col-12 mobMbTop-10">
              <label className="labelHead">Seller</label>
            </div>
            <div className="col-12 fontFix">
              <Select
                isMulti
                closeMenuOnSelect={false}
                options={sellerList}
                classNamePrefix="listSeller"
                id="sellerIdList"
                name="sellerIdList"
                value={filterData?.sellerIdList}
                onChange={(value) => handleMultiSelect(value, "sellerIdList")}
              />
            </div>
          </div>
        </div>

        <div className="col-4">
          <div className="row">
            <div className="col-12">
              <label className="labelHead hidLbl">-</label>
            </div>
            <div className="col-12 flexEnd">
              <button
                className="btn cus-primary-transparent-btn top-btn clearBtn"
                onClick={(e) => handleReset(e, "clear")}
              >
                Clear Filter
              </button>
              <button
                className="cus-seconday-bg-btn btn filterBtn"
                onClick={() => fetchBarcodeDataList()}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BarcodeListingFilter;
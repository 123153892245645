import { marginService } from "../../services/margin.service";
import { MarginConstant, SellerCarrierConstant } from "../constants/Margin.constant";

const getMarginPending = () => ({
    type: MarginConstant.GET_ALL_MARGIN_LIST_PENDING,
});
const getMarginFailed = () => ({
    type: MarginConstant.GET_ALL_MARGIN_LIST_FAILURE,
});
const getMarginSuccess = (response) => ({
    type: MarginConstant.GET_ALL_MARGIN_LIST_SUCCESS,
    payload: response,
});

export const fetchAllMargin = (data) => {
    return (dispatch) => {
        dispatch(getMarginPending());
        marginService.getAllMarginList(data).then((result) => {
            dispatch(getMarginSuccess(result.data));
        }).catch(() => {
            dispatch(getMarginFailed());
        });
    };
};

const getMarginByIdPending = () => ({
    type: MarginConstant.GET_MARGIN_BY_ID_PENDING,
});
const getMarginByIdFailed = () => ({
    type: MarginConstant.GET_MARGIN_BY_ID_FAILURE,
});
const getMarginByIdSuccess = (response) => ({
    type: MarginConstant.GET_MARGIN_BY_ID_SUCCESS,
    payload: response,
});

export const fetchMarginById = (marginId) => {
    return (dispatch) => {
        dispatch(getMarginByIdPending());
        marginService.getMarginByIdService(marginId).then((result) => {
            dispatch(getMarginByIdSuccess(result.data));
        }).catch(() => {
            dispatch(getMarginByIdFailed());
        });
    };
};

export const updateMargin = (data) => {
    return () => {
        return marginService.updateMarginService(data);
    };
};

const getCarrierServicesPending = () => ({
    type: SellerCarrierConstant.CARRIER_SERVICES_PENDING,
});
const getCarrierServicesFailed = () => ({
    type: SellerCarrierConstant.CARRIER_SERVICES_FAILURE,
});
const getCarrierServicesSuccess = (response) => ({
    type: SellerCarrierConstant.CARRIER_SERVICES_SUCCESS,
    payload: response,
});

export const fetchAllCarrierServicesList = (data) => {
    return (dispatch) => {
        dispatch(getCarrierServicesPending());
        marginService.getAllCarrierServicesService(data).then((result) => {
            dispatch(getCarrierServicesSuccess(result.data));
        }).catch(() => {
            dispatch(getCarrierServicesFailed());
        });
    };
};


const getCarrierServiceByCompanySellerIdPending = () => ({
    type: SellerCarrierConstant.SERVICES_BY_COMPANY_SELLER_ID_PENDING,
});
const getCarrierServiceByCompanySellerIdFailed = () => ({
    type: SellerCarrierConstant.SERVICES_BY_COMPANY_SELLER_ID_FAILED,
});
const getCarrierServiceByCompanySellerIdSuccess = (response) => ({
    type: SellerCarrierConstant.SERVICES_BY_COMPANY_SELLER_ID_SUCCESS,
    payload: response,
});

export const fetchServicesByCompanySellerId = (data) => {
    return (dispatch) => {
        dispatch(getCarrierServiceByCompanySellerIdPending());
        marginService.getAllServicesByCompanySellerIdService(data).then((result) => {
            dispatch(getCarrierServiceByCompanySellerIdSuccess(result.data));
        }).catch(() => {
            dispatch(getCarrierServiceByCompanySellerIdFailed());
        });
    };
};

export const updateServiceStatus = (data) => {
    return () => {
        return marginService.updateServiceStatusService(data);
    };
};
export const sellerCarrierServiceBackDetails = (data) => ({
    type: SellerCarrierConstant.SELLER_CARRIER_SERVICE_SERVICE_CONFIG_BACK_DETAILS,
    payload: data,
  });

export const sellerCarrierBackDetails = (data) => ({
    type: SellerCarrierConstant.SELLER_CARRIER_SERVICE_CONFIG_BACK_DETAILS,
    payload: data,
});
import React, { useEffect, useState } from 'react';
import { Table } from 'bootstrap-4-react/lib/components';
import LoaderSpinner from '../common/Loader';
import { deleteAddress, getAllAddressesList } from '../../store/actions/address.action';
import { useDispatch, useSelector } from 'react-redux';
import { MenuList } from '../common/MenuList';
import { Dropdown } from 'react-bootstrap';
import BatchPagination from '../batch/BatchPagination';
import { addressBook, addressModule, featureSubmodulePermission, numOfPage, permssionCreate, permssionDelete, permssionEdit } from '../../constant';
import { useNavigate } from 'react-router';
import AlertDialogModal from '../common/AlertDialog';
import _ from 'lodash';
import { toast } from "react-toastify";
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { checkPermission } from '../../config/utils';

const AddressBook = (props) => {
    const userDetails = useSelector((state) => state.auth.user);
    const { addressListStatus, addressListObject } = useSelector((state) => state.AddressReducer);
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [numberOfPage, setNumberOfPage] = useState(30);
    const [addressList, setAddressList] = useState([]);
    const [totalPages, setTotalPages] = useState(1);
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();
    const [alertStatusUpdate, setAlertStatusUpdate] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState({});
    const [checkAll, setCheckAll] = useState(false);

    useEffect(() => {
        if (addressListStatus === 'success' && addressListObject?.EntityList?.length) {
            setAddressList([...addressListObject.EntityList]);
            setTotalPages(
                Math.ceil(addressListObject.TotalRecords / numberOfPage)
            );
        }
        if (addressListStatus !== 'success') {
            setLoader(true);
        } else {
            setLoader(false);
        }
    }, [addressListStatus])

    useEffect(() => {
        fetchAllAddresses();
    }, [currentPage, numberOfPage]);

    const fetchAllAddresses = () => {
        let data = {
            "pageIndex": currentPage,
            "pageSize": numberOfPage,
            "sortColumn": "",
            "sortOrder": "ASCENDING",
            "companySellerId": userDetails?.Value?.companySellerId,
            "userId": userDetails?.Value?.employeeId,
        }
        dispatch(getAllAddressesList(data));
    }

    const handleAddAddress = () => {
        navigate("/address-book/0");
    };

    /**
     * @param {Object} item 
     */
    const handleDeleteRecord = (item) => {
        setSelectedRecord([item]);
        setAlertStatusUpdate(true);
    };

    const handleDeleteBtnClick = () => {
        let filterChecked = addressList.filter((item) => item.checked);
        setAlertStatusUpdate(true);
        setSelectedRecord(filterChecked);
    }
    /**
     * 
     * @param {htmlEvent} e 
     * @param {Number} index 
     * @param {String} isAll 
     */
    const handleRecordCheck = (e, index, isAll = '') => {
        const { checked } = e.target;
        let listData = [...addressList];
        if (isAll === 'all') {
            let updateList = listData.map((item) => {
                return {
                    ...item,
                    checked: checked
                }
            });
            setCheckAll(checked);
            setAddressList(updateList);
        } else {
            listData[index].checked = checked;
            setAddressList(listData)
        }
    }

    const handleDeleteConfirm = async () => {
        let getAddressIds = _.map(selectedRecord, 'id');
        let deleteRes = await deleteAddress(getAddressIds);
        if (deleteRes.data && deleteRes.data.Status === 200) {
            if (getAddressIds.length > 1) {
                toast.success("Addresses delete successfully");
            } else {
                toast.success("Address delete successfully");
            }
            fetchAllAddresses();
            setAlertStatusUpdate(false);
        }
    }

    return (
        <main>
            <div className="main__container">
                <div className="main__tile_heading cus-btn-include-sec">Address Book
                    <div className="top_right_button_container">
                        {checkPermission(addressBook, addressModule, permssionCreate, featureSubmodulePermission, props.employeePermissions) && (
                            <button className="btn cus-seconday-bg-btn top-btn" onClick={handleAddAddress}>
                                Add New Address
                            </button>
                        )}
                        {checkPermission(addressBook, addressModule, permssionDelete, featureSubmodulePermission, props.employeePermissions) && (
                            <button
                                className="btn cus-seconday-bg-btn top-btn"
                                disabled={!addressList.filter((item) => item.checked).length}
                                onClick={handleDeleteBtnClick}
                            >
                                Delete Selected
                            </button>
                        )}
                    </div>
                </div>
                {addressListStatus === 'pending' && <LoaderSpinner />}

                <div className="cus-page-content-sec">
                    <div className="top_right_button_container cus-content-only-sec">
                        <div className='incoming_wrapper main_content_table'>
                            <div className='tableFixHead'>
                                <Table className="table table-borderless keyGenerate">
                                    <thead>
                                        <tr className="trow">
                                            <th className="trows-s">
                                                <input className='checkInput' checked={checkAll} type={'checkbox'} onChange={(e) => handleRecordCheck(e, {}, 'all')} />
                                            </th>
                                            <th className="trows-s">Name</th>
                                            <th className="trows-s">Company</th>
                                            <th className="trows-s">Address 1</th>
                                            <th className="trows-s">City</th>
                                            <th className="trows-s">State</th>
                                            <th className="trows-s">Zip</th>
                                            <th className="trows-s">Country</th>
                                            <th className="trows-s">Phone</th>
                                            <th className="trows-s">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {addressList && addressList.length > 0 && addressList.map((item, index) => (
                                            <tr key={item.id}>
                                                <td>
                                                    <input
                                                        key={`address-${item.id}`}
                                                        className='checkInput'
                                                        type={'checkbox'}
                                                        checked={item.checked}
                                                        onChange={(e) => handleRecordCheck(e, index)}
                                                    />
                                                </td>
                                                <td>{item.name}</td>
                                                <td>{item.company}</td>
                                                <td>{item.addressLine1}</td>
                                                <td>{item.city}</td>
                                                <td>{item.state}</td>
                                                <td>{item.zip}</td>
                                                <td>{item.country}</td>
                                                <td>{formatPhoneNumberIntl(item.phone) || item.phone}</td>
                                                <td>
                                                    <MenuList
                                                        disabled={
                                                            !checkPermission(addressBook, permssionEdit, permssionDelete, featureSubmodulePermission, props.employeePermissions)
                                                            && !checkPermission(addressBook, addressModule, permssionDelete, featureSubmodulePermission, props.employeePermissions)
                                                        }
                                                        children={
                                                            <>
                                                                {checkPermission(addressBook, addressModule, permssionEdit, featureSubmodulePermission, props.employeePermissions) && (
                                                                    <Dropdown.Item
                                                                        className="printLabelBtn"
                                                                        onClick={() => navigate(`/address-book/${item.id}`)}
                                                                    >
                                                                        Edit Address
                                                                    </Dropdown.Item>
                                                                )}
                                                                {checkPermission(addressBook, addressModule, permssionDelete, featureSubmodulePermission, props.employeePermissions) && (
                                                                    <Dropdown.Item
                                                                        className="deleteLabelBtn"
                                                                        onClick={() => handleDeleteRecord(item)}
                                                                    >
                                                                        Delete Address
                                                                    </Dropdown.Item>
                                                                )}
                                                            </>
                                                        }
                                                    />
                                                </td>
                                            </tr>
                                        ))}

                                        {addressListStatus === 'success' && addressList.length === 0 && (
                                            <tr>
                                                <td align='center' colSpan={11}>No Address Found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
                {addressListObject.TotalRecords > 0 && (
                    <>
                        {addressListObject?.EntityList?.length && (
                            <BatchPagination
                                gridData={addressListObject.EntityList}
                                totalVisualRecords={addressListObject?.EntityList?.length}
                                numberOfPage={numberOfPage}
                                numOfPage={numOfPage}
                                loader={loader}
                                currentPage={currentPage}
                                totalPages={totalPages}
                                setCurrentPage={setCurrentPage}
                                totalRecords={addressListObject.TotalRecords}
                                setNumberOfPage={setNumberOfPage}
                            />
                        )}
                    </>
                )}
                {alertStatusUpdate &&
                    <AlertDialogModal
                        modalShow={alertStatusUpdate}
                        handleCancelDeleteAlert={() => setAlertStatusUpdate(false)}
                        modalClass={'modalDeleteWrapper'}
                        messageText={`Are you sure, you want to delete selected ${selectedRecord.length > 1 ? 'records?' : 'record?'}`}
                        handleYesDeleteAlert={handleDeleteConfirm}
                        headingText={'DELETE'}
                    />
                }
            </div>
        </main>
    );
}

export default AddressBook;
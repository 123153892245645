import React from "react";
import Modal from "react-bootstrap/Modal";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getLoadAddressesList } from "../../store/actions/address.action";
import LoaderSpinner from "../common/Loader";

const LoadSavedAddress = (props) => {
    const {
        showLoadAddressDialogObj,
        setShowLoadAddressDialogObj,
        showModal,
        dispatch,
        userDetails,
        setShipmentObject,
        shipmentObject,
        USSates,
        countries
    } = props;

    const { loadAddressList, loadAddressListStatus } = useSelector((state) => state.AddressReducer);

    useEffect(() => {
        if (showLoadAddressDialogObj.addressType) {
            loadAddresses()
        }
    }, [showLoadAddressDialogObj?.addressType]);

    const loadAddresses = () => {
        let data = `companySellerId=${userDetails.Value?.companySellerId}&userId=${userDetails.Value?.employeeId}&type=${showLoadAddressDialogObj.addressType}`
        dispatch(getLoadAddressesList(data));
    }

    const handleClose = () => {
        setShowLoadAddressDialogObj({
            showModal: false,
            addressType: ''
        });
    }

    const handleUseAddress = (item) => {
        if (showLoadAddressDialogObj.addressType === 'sender') {
            setShipmentObject({
                ...shipmentObject,
                fName: item.name,
                senderCountryID: item.countryId,
                fCompany: item.company,
                fTel: item.phone,
                fPostcode: item.zip,
                fState: item.state,
                fCity: item.city,
                fAdd1: item.addressLine1,
                fAdd2: item.addressLine2,
                fAdd3: item.addressLine3,
                fCountry: countries.filter((country) => country.id === item.countryId)[0]?.iSO
            });
        } else {
            setShipmentObject({
                ...shipmentObject,
                sName: item.name,
                receiverCountryID: item.countryId,
                sCompany: item.company,
                sTel: item.phone,
                sPostcode: item.zip,
                sState: item.state,
                sCity: item.city,
                sAdd1: item.addressLine1,
                sAdd2: item.addressLine2,
                sAdd3: item.addressLine3,
                sCountry: countries.filter((country) => country.id === item.countryId)[0]?.iSO
            });
        }
        handleClose();
    };

    return (
        <Modal
            {...props}
            size="lg"
            className="topUpModal"
            centered
            backdrop="true"
            show={showModal}
            onHide={handleClose}
        >
            <div className="top_modal_header_container">
                {loadAddressListStatus === 'pending' && <LoaderSpinner />}
                Select Address
                <div className="modalDeleteWrapper">
                    <button
                        type="button"
                        onClick={handleClose}
                        className="btn-close"
                        aria-label="Close"
                    ></button>
                </div>
            </div>
            <div className="modal_container_rate_card seller-Modal">
                {loadAddressList && loadAddressList.length > 0 && loadAddressList.map((item) => (
                    <div className="addressFields" key={item?.id}>
                        <div className="row">
                            <div className="col-3">Name</div>
                            <div className="col-9">{item.name}</div>
                        </div>
                        <div className="row">
                            <div className="col-3">Company</div>
                            <div className="col-9">{item.company}</div>
                        </div>
                        <div className="row">
                            <div className="col-3">Phone</div>
                            <div className="col-9">{item.phone}</div>
                        </div>
                        <div className="row">
                            <div className="col-3">Country</div>
                            <div className="col-9">{item.country}</div>
                        </div>
                        <div className="row">
                            <div className="col-3">State</div>
                            <div className="col-9">
                                {item.countryId === 218 ? (
                                    <>{USSates.map((state) => (
                                        <>
                                            {state.StateCode === item.state && state.StateName}
                                        </>
                                    ))}</>
                                ) : (
                                    <>
                                        {item.state}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">City</div>
                            <div className="col-9">{item.city}</div>
                        </div>
                        <div className="row">
                            <div className="col-3">Zip</div>
                            <div className="col-9">{item.zip}</div>
                        </div>
                        <div className="row">
                            <div className="col-3">AddressLine 1</div>
                            <div className="col-9">{item.addressLine1}</div>
                        </div>
                        <div className="row">
                            <div className="col-3">AddressLine 2</div>
                            <div className="col-9">{item.addressLine2}</div>
                        </div>
                        <div className="row">
                            <div className="col-3">AddressLine 3</div>
                            <div className="col-9">{item.addressLine3}</div>
                        </div>
                        <div className="row">
                            <div className="col-12 align-right">
                                <button className="btn cus-seconday-bg-btn mt-10" onClick={() => handleUseAddress(item)}>Use This Address</button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Modal >
    );
}

export default LoadSavedAddress;
import axios from "axios";
import {
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_FAIL,
  SET_MESSAGE,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAIL,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  GET_COMPANY_BY_ID_SUCCESS,
  GET_COMPANY_BY_ID_FAIL,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAIL,
  GET_ACTIVE_COMPANY,
  GET_ALL_COMPANY,
  GET_ALL_SELLERS,
  GET_ADRESS_COMPANY_DETAIL,
  GET_MULTIPLE_SELLERS,
  GET_MULTIPLE_SELLERS_STATUS,
  GET_SELLER_ADRESS_DETAIL,
  GET_ALL_USERS,
  GET_ALL_USERS_PENDING,
  SAVE_PAGINATION_DETAILS,
  SELLER_SAVE_PAGINATION_DETAILS,
} from "../types";
import { shipmentConstant } from "../constants";
import { apiUrl } from "../../constant";

import CompServices from "../../services/company.service";
import { logout } from "../../store/actions/auth";
import { toast } from "react-toastify";
import {
  createUserKeyService,
  getAllApiKeyService,
  getAllSellersService,
  getAllUserCompanies,
  setCompanyStatusService,
} from "../../services/incoming.service";
import companyServices from "../../services/company.service";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";

export const logOut = (dispatch) => {
  dispatch(logout());
};

export const getCompany = () => (dispatch) => {
  return CompServices.getCompany()?.then(
    (data) => {
      dispatch({
        type: GET_COMPANY_SUCCESS,
        payload: { company: data.data },
      });
      return Promise.resolve();
    },
    (error) => {
      if (error.request.status == 401) {
        logOut();
      }
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: GET_COMPANY_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const getCompanyById = (Id) => (dispatch) => {
  return CompServices.getcompanybyId(Id)?.then(
    (data) => {
      dispatch({
        type: GET_COMPANY_BY_ID_SUCCESS,
        payload: { company: data.data },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: GET_COMPANY_BY_ID_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const getAllSellers =
  (setTotalPages, numberOfPage, currentPage, filterData) =>
    async (dispatch) => {
      const config = {
        headers: { Authorization: `` },
      };
      dispatch({ type: GET_MULTIPLE_SELLERS_STATUS });
      try {
        if (typeof filterData?.state === "object") {
          const response = await axios.post(
            `${apiUrl}/api/GetAllSeller`,

            {
              ...filterData,
              pageIndex: currentPage,
              pageSize: numberOfPage,
              country: filterData?.country,
              state: filterData?.state.label,
              screenType: 1,
            },
            config
          );

          setTotalPages(
            Math.ceil(response?.data?.Entity?.TotalRecords / numberOfPage)
          );
          dispatch({
            type: GET_ALL_SELLERS,
            payload: response.data || [],
          });
        } else if (
          filterData?.country ||
          filterData?.state ||
          filterData?.sortColumn
        ) {
          const response = await axios.post(
            `${apiUrl}/api/GetAllSeller`,

            {
              ...filterData,
              pageIndex: currentPage,
              pageSize: numberOfPage,
              country: filterData?.country,
              state: filterData?.state,
              screenType: 1,
            },
            config
          );

          setTotalPages(
            Math.ceil(response?.data?.Entity?.TotalRecords / numberOfPage)
          );
          dispatch({
            type: GET_ALL_SELLERS,
            payload: response.data || [],
          });
        } else {
          const response = await axios.post(
            `${apiUrl}/api/GetAllSeller`,

            {
              ...filterData,
              pageIndex: currentPage,
              pageSize: numberOfPage,
              sortColumn: "",
              sortOrder: "DESCENDING",
              screenType: 1,
            },
            config
          );

          setTotalPages(
            Math.ceil(response?.data?.Entity?.TotalRecords / numberOfPage)
          );
          dispatch({
            type: GET_ALL_SELLERS,
            payload: response.data || [],
          });
        }
      } catch (e) {
        toast.error(e?.response?.data?.errorDescription);
      }
    };

export const getAllUsers =
  (setTotalPages, numberOfPage, currentPage, filterValue) =>
    async (dispatch) => {
      const config = {
        headers: { Authorization: `` },
      };
      dispatch({ type: GET_ALL_USERS_PENDING });
      try {
        const response = await axios.post(
          `${apiUrl}/GetAllUser`,

          {
            ...filterValue,
            pageIndex: currentPage,
            pageSize: numberOfPage,
            sortColumn: filterValue?.sortColumn,
            sortOrder: filterValue?.sortOrder,
          },
          config
        );
        setTotalPages(
          Math.ceil(response?.data?.Entity?.TotalRecords / numberOfPage)
        );
        dispatch({
          type: GET_ALL_USERS,
          payload: response.data || [],
        });
      } catch (e) {
        toast.error(e?.response?.data?.errorDescription);
      }
    };
export const getAllAssociatedUsers =
  (setTotalPages, numberOfPage, currentPage, filterValue, companyId, sellerId) =>
    async (dispatch) => {
      const config = {
        headers: { Authorization: `` },
      };
      if (companyId) {
        try {
          const response = await axios.post(
            `${apiUrl}/GetUsersByCompanyIds`,

            {
              pageIndex: currentPage,
              pageSize: numberOfPage,
              sortColumn: filterValue?.sortColumn,
              sortOrder: filterValue?.sortOrder,
              companyIds: [companyId],
              sellerIds: [0],
            },
            config
          );
          setTotalPages(
            Math.ceil(response?.data?.Entity?.TotalRecords / numberOfPage)
          );
          dispatch({
            type: GET_ALL_USERS,
            payload: response.data || [],
          });
        } catch (e) {
          toast.error(e?.response?.data?.errorDescription);
        }
      } else {
        try {
          const response = await axios.post(
            `${apiUrl}/GetUserBySellerIds`,
            {
              pageIndex: currentPage,
              pageSize: numberOfPage,
              sortColumn: filterValue?.sortColumn,
              sortOrder: filterValue?.sortOrder,
              sellerIds: [sellerId],
              companyIds: [0]
            },
            config
          );
          setTotalPages(
            Math.ceil(response?.data?.Entity?.TotalRecords / numberOfPage)
          );
          dispatch({
            type: GET_ALL_USERS,
            payload: response.data || [],
          });
        } catch (e) {
          toast.error(e?.response?.data?.errorDescription);
        }
      }
    };

export const addNewSeller =
  (data, navigate, editPage, sellerId, handleOnSuccess) =>
    async (dispatch) => {
      const config = {
        headers: { Authorization: `` },
      };

      try {
        const response = await axios.post(
          `${apiUrl}/api/InsertSellerDetails`,
          data,
          config
        );
        if (response?.data?.Status == 200) {
          toast.success(response?.data?.Message?.AppStatusDescription);
          navigate("/SellerManagement");
          handleOnSuccess();
        } else {
          toast.error(response?.data?.Message?.AppStatusDescription);
        }

        if (response?.data?.Status === 200 && !editPage) {
          const responseData = await axios.post(
            `${apiUrl}/api/GetAllSeller`,

            {
              pageIndex: 1,
              pageSize: 30,
              sortColumn: "id",
              sortOrder: "DESCENDING",
            },
            config
          );

          dispatch({
            type: GET_ALL_SELLERS,
            payload: responseData.data || [],
          });
        } else {
          toast.error(response?.data?.Entity?.message);
        }
        if (editPage) {
          const response = await axios.post(
            `${apiUrl}/api/GetSellerAndAddressBySellerId?Id=${sellerId}`
          );
          dispatch({
            type: GET_SELLER_ADRESS_DETAIL,
            payload: response.data || [],
          });

          navigate("/SellerManagement");
        }
        return response;
      } catch (e) {
        toast.error(e?.response?.data?.errorDescription);
      }
    };
export const addNewCompany = (data, navigate, editPage, companyId) => async (dispatch) => {
  const config = {
    headers: { Authorization: `` },
  };
  try {
    const response = await axios.post(`${apiUrl}/api/CreateNewCompany`, data);
    if (response?.data?.Status === 200) {
      toast.success(response?.data?.Message?.AppStatusDescription);
    } else {
      toast.error(response?.data?.Message?.AppStatusDescription);
    }

    if (response?.data?.Status === 200 && !editPage) {
      const responseData = await axios.post(
        `${apiUrl}/GetAllCompany`,
        {
          pageIndex: 1,
          pageSize: 30,
          sortColumn: "id",
          sortOrder: "DESCENDING",
        },
        config
      );

      dispatch({
        type: GET_ALL_COMPANY,
        payload: responseData.data || [],
      });
    } else {
      toast.error(response?.data?.Entity?.message);
    }
    if (editPage) {
      const response = await axios.post(
        `${apiUrl}/api/GetCompanyAndAddressByCompanyId?Id=${companyId}`
      );
      dispatch({
        type: GET_ADRESS_COMPANY_DETAIL,
        payload: response.data || [],
      });

      navigate("/companyManagement");
    }

    return new Promise((res, rej) => {
      return res(response);
    });
  } catch (e) {
    toast.error(e?.response?.data?.errorDescription);
    return new Promise((res, rej) => {
      return res(e);
    });
  }
};

export const updateCompanyDetails = (data, navigate) => async (dispatch) => {
  const config = {
    headers: { Authorization: `` },
  };

  try {
    const response = await axios.post(
      `${apiUrl}/api/CreateUpdateNewCompany`,
      data,
      config
    );
    toast.success("Company Details Updated Successfully");
    navigate("/companyManagement");
  } catch (e) {
    toast.error(e?.response?.data?.errorDescription);
  }
};

export const updateCurrentAddressDetails =
  (data, navigate) => async (dispatch) => {
    const config = {
      headers: { Authorization: `` },
    };

    try {
      const response = await axios.post(
        `${apiUrl}/api/UpdateCompanyAddress`,
        data,
        config
      );
      toast.success("Company Details Updated Successfully");
    } catch (e) {
      toast.error(e?.response?.data?.errorDescription);
    }
  };
export const GetCompanyAndAddressDetailForSeller =
  (sellerID) => async (dispatch) => {
    try {
      const response = await axios.post(
        `${apiUrl}/api/GetSellerAndAddressBySellerId?Id=${sellerID}`
      );
      dispatch({
        type: GET_SELLER_ADRESS_DETAIL,
        payload: response.data || [],
      });
    } catch (e) {
      toast.error(e?.response?.data?.errorDescription);
    }
  };

export const GetSellerSavedAddress =
  (sellerID) => async (dispatch) => {
    try {
      const response = await axios.post(
        `${apiUrl}/api/DetailsOfLoggedInUser?Id=${sellerID}`
      );
      return response;
    } catch (e) {
      toast.error(e?.response?.data?.errorDescription);
    }
  };

export const makeDefaultAddressForSeller =
  (addressId, sellerId) => async (dispatch) => {
    const config = {
      headers: { Authorization: `` },
    };

    try {
      const response = await axios.post(
        `${apiUrl}/api/SetSellerAddressAsDefault?Address_Id=${addressId}&Seller_Id=${sellerId}`,

        config
      );
      const responseData = await axios.post(
        `${apiUrl}/api/GetSellerAndAddressBySellerId?Id=${sellerId}`
      );
      dispatch({
        type: GET_SELLER_ADRESS_DETAIL,
        payload: responseData.data || [],
      });
      toast.success("Default Address Set Successfully");
      // toast.success("Address Deleted Successfully");
    } catch (e) {
      toast.error(e?.response?.data?.errorDescription);
    }
  };
export const makeDefaultAddress =
  (addressId, companyId) => async (dispatch) => {
    const config = {
      headers: { Authorization: `` },
    };

    try {
      const response = await axios.post(
        `${apiUrl}/api/SetAddressAsDefault?Address_Id=${addressId}&Company_Id=${companyId}`,

        config
      );
      const responseData = await axios.post(
        `${apiUrl}/api/GetCompanyAndAddressByCompanyId?Id=${companyId}`
      );
      dispatch({
        type: GET_ADRESS_COMPANY_DETAIL,
        payload: responseData.data || [],
      });
      toast.success("Default Address Set Successfully");
      // toast.success("Address Deleted Successfully");
    } catch (e) {
      toast.error(e?.response?.data?.errorDescription);
    }
  };
export const deleteCompanyAddress = (id, companyId) => async (dispatch) => {
  const config = {
    headers: { Authorization: `` },
  };

  try {
    const response = await axios.post(
      `${apiUrl}/api/DeleteAddress?Id=${id}`,

      config
    );
    toast.success("Address Deleted Successfully");
    const responseData = await axios.post(
      `${apiUrl}/api/GetCompanyAndAddressByCompanyId?Id=${companyId}`
    );
    dispatch({
      type: GET_ADRESS_COMPANY_DETAIL,
      payload: responseData.data || [],
    });
  } catch (e) {
    toast.error(e?.response?.data?.errorDescription);
  }
};

export const deleteSellerAddress = (id, sellerId) => async (dispatch) => {
  const config = {
    headers: { Authorization: `` },
  };

  try {
    const response = await axios.delete(
      `${apiUrl}/api/DeleteSellerAddress?Id=${id}`,

      config
    );
    toast.success("Address Deleted Successfully");
    const responseData = await axios.post(
      `${apiUrl}/api/GetSellerAndAddressBySellerId?Id=${sellerId}`
    );
    dispatch({
      type: GET_SELLER_ADRESS_DETAIL,
      payload: responseData.data || [],
    });
  } catch (e) {
    toast.error(e?.response?.data?.errorDescription);
  }
};
export const updateSellerDetails = (data, navigate) => async (dispatch) => {
  const config = {
    headers: { Authorization: `` },
  };

  try {
    const response = await axios.put(
      `${apiUrl}/api/UpdateSellerDetails `,
      data,
      config
    );
    toast.success("Seller Details Updated Successfully");
    navigate("/sellerManagement");
  } catch (e) {
    toast.error(e?.response?.data?.errorDescription);
  }
};
export const updateUserDetails =
  (
    data,
    navigate,
    handleOnSave,
    type,
    setTotalPages,
    numberOfPage,
    currentPage,
    filterData
  ) =>
    async (dispatch) => {
      const config = {
        headers: { Authorization: `` },
      };
      try {
        const response = await axios.put(
          `${apiUrl}/User `,
          {
            ...data,
            id: data?.id,
            name: data?.name,
            UserCompanyIdList: _.map(data?.UserCompanyIdList, "Id"),
            UserSellerIdList: _.map(data?.UserSellerIdList, "value"),
            newUserName: data?.newUserName,
            userEmailId: data?.userEmailId,
            phoneNumber: data?.phoneNumber,
            userRole: data?.userRole?.id,
            country_Id: data?.country_Id,
            defaultCompanyId: data?.defaultCompanyId?.Id,
            defaultSellerId: data?.defaultSellerId?.id,
            secondaryEmailAddress: data?.secondaryEmailAddress,
            notes: data?.notes,
            secondaryPhoneNumber: data?.secondaryPhoneNumber,
          },
          config
        );

        if (response?.data?.Status == 200) {
          toast.success("User Details Updated Successfully");
          if (type) {
            handleOnSave();
          } else {
            dispatch(
              getAllUsers(setTotalPages, numberOfPage, currentPage, filterData)
            );
            navigate("/userManagement");
          }
        } else {
          toast.error(response?.data?.Message?.AppStatusDescription);
        }
      } catch (e) {
        toast.error(e?.response?.data?.errorDescription);
      }
    };
export const createUser =
  (
    data,
    handleOnSaveAddressType,
    handleOnSaveAddress,
    type,
    setLoader
  ) =>
    async (dispatch) => {
      let payloadData = {
        ...data,
        userCompanyIdList: _.map(data?.userCompanyIdList, "Id"),
        userSellerIdList: _.map(data?.userSellerIdList, "value"),
        defaultCompanyId: data?.defaultCompanyId?.Id,
        defaultSellerId: data?.defaultSellerId?.id,
        userRole: data?.userRole?.id,
      }
      setLoader(true);
      const resp = await Promise.resolve(
        axios.post(
          `${apiUrl}/User`,
          payloadData,
          {
            headers: { Authorization: `` },
          }
        )
      );
      if (resp?.data?.Status === 200) {
        toast.success("User Added Successfully");
        setLoader(false);
        if (type) {
          handleOnSaveAddressType();
        } else {
          handleOnSaveAddress();
        }
      } else {
        toast.error(resp?.data?.Message?.AppStatusDescription);
        setLoader(false);
      }
    };

export const deleteCompanyById = (company) => (dispatch) => {
  return CompServices.deletecompanybyId(company.Id)?.then(
    (data) => {
      dispatch({
        type: DELETE_COMPANY_SUCCESS,
        payload: { company: company },
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      dispatch({
        type: DELETE_COMPANY_FAIL,
      });
      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });
      return Promise.reject();
    }
  );
};

export const createUserKeyAction = (data) => {
  return () => {
    return createUserKeyService(data);
  };
};

const getBatchHistoryPending = () => ({
  type: shipmentConstant.GET_GET_ALL_API_KEYS_PENDING,
});

const getBatchHistoryFailed = () => ({
  type: shipmentConstant.GET_GET_ALL_API_KEYS_FAILED,
});

const getBatchHistorySuccess = (response) => ({
  type: shipmentConstant.GET_GET_ALL_API_KEYS_SUCCESS,
  payload: response,
});

export const fetchAllApiKeyList = (filterValue) => {
  return (dispatch) => {
    dispatch(getBatchHistoryPending());
    getAllApiKeyService(filterValue)
      .then((result) => {
        dispatch(getBatchHistorySuccess(result.data));
      })
      .catch((error) => {
        dispatch(getBatchHistoryFailed());
      });
  };
};

export const getSellersforMultipleCompany = (companyIds) => async (dispatch) => {
  const config = {
    headers: { Authorization: `` },
  };

  try {
    const response = await axios.post(
      `${apiUrl}/api/GetSellersByCompanyIds`,
      {
        companyIds,
      },
      config
    );
    dispatch({
      type: GET_MULTIPLE_SELLERS,
      payload: response.data,
    });
  } catch (e) {
    toast.error(e?.response?.data?.errorDescription);
  }
};
export const getSellersDetailsOnEdit = (sellerId) => async (dispatch) => {
  const config = {
    headers: { Authorization: `` },
  };
  try {
    const response = await axios.get(
      `${apiUrl}/api/GetSellerRateCardDetailById?sellerRateCardId=${sellerId}`,

      config
    );

    return response;
  } catch (e) {
    toast.error(e?.response?.data?.errorDescription);
  }
};
export const getUserRole = () => {
  return new Promise((res, rej) => {
    return res(companyServices.getUserRoleService());
  });
};

export const setCompanyStatus = (data) => {
  return setCompanyStatusService(data);
};

export const getAllSellersList = (data) => {
  return axios.post(`${apiUrl}/api/GetSellersByCompanyIds`, data);
};

export const userListBackDetails = (data) => ({
  type: SAVE_PAGINATION_DETAILS,
  payload: data,
});

export const sellerListBackDetails = (data) => ({
  type: SELLER_SAVE_PAGINATION_DETAILS,
  payload: data,
});
import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Button, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { loadShipmentsBatch } from "../../store/actions";
import {
  bulkCreateShipment,
  getMergedPdf,
  updateOrderStatus,
} from "../../store/actions/batch.action";
import { getLoginUserDetailsonFund } from "../../store/actions/fundManagement";

const BatchBooking = (props) => {
  const { selectedData, createdBy } = props;
  const [isValidData, setIsValidData] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRate, setTotalRate] = useState("0.00");
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedData?.length) {
      let data = selectedData.filter((item) => item.isErrorStatus);
      if (data.length) {
        setIsValidData(false);
      } else {
        setIsValidData(true);
      }
      handleRate();
    } else {
      setTotalRate("0.00");
      props.setRate("0.00");
    }
  }, [selectedData]);

  const handleRate = () => {
    if (selectedData.length > 1) {
      let totalRateAmount = selectedData.reduce((a, b) => {
        if (b.rate && !isNaN(b.rate)) {
          return a + Number(b.rate);
        } else {
          return a;
        }
      }, 0);
      setTotalRate(Number(totalRateAmount).toFixed(2));
      props.setRate(Number(totalRateAmount).toFixed(2));
    } else {
      if (!isNaN(selectedData[0].rate)) {
        setTotalRate(selectedData[0].rate);
        props.setRate(selectedData[0].rate);
      } else {
        setTotalRate("0.00");
        props.setRate("0.00");
      }
    }
  };

  const handleLoadShipmentLabel = async (validData) => {
    let data = _.map(validData, "orderNumber");
    let mergedRes = await getMergedPdf(data);
    if (mergedRes && mergedRes.data && mergedRes.data.Status === 200) {
      window.open(mergedRes.data.Entity);
    }
  };

  const getBulkShipment = async () => {
    let orderIds = _.map(selectedData, "orderNumber");
    let addPrefix = orderIds.map((item) => item).toString();
    let labelData = {
      shipmentStatus: 0,
      employeeID: createdBy?.Value?.employeeId,
      commaSepratedOrderNumber: addPrefix,
    };
    let getShipmentsRes = await Promise.resolve(
      dispatch(loadShipmentsBatch(labelData))
    );
    /* istanbul ignore next */
    if (getShipmentsRes.data && getShipmentsRes.data.Status === 200) {
      if (getShipmentsRes.data.EntityList.length) {
        let filterValidRecord = getShipmentsRes.data.EntityList.filter(
          (item) => item.shipmentSubmitStatus === 1
        );
        if (orderIds.length === filterValidRecord.length) {
          toast("Request Successful", {
            time: 5000,
            className: "sucess-toast",
          });

          handleLoadShipmentLabel(filterValidRecord);
          handleUpdateOrderStatus(filterValidRecord);
        } else if (
          filterValidRecord.length > 0 &&
          orderIds.length !== filterValidRecord.length
        ) {
          toast(
            `Shipped Successfully, but some orders have errors. See errors in log file`,
            {
              time: 5000,
              className: "sucess-toast",
            }
          );
          handleLoadShipmentLabel(filterValidRecord);
          handleUpdateOrderStatus(filterValidRecord);
        } else if (filterValidRecord.length === 0) {
          toast("Error with order. See error in log file", {
            time: 5000,
            className: "sucess-toast",
          });
        }
        props.fetchAllBatches();
      }
      setIsLoading(false);
      props.getAllRecords();
      props.ref.clear();
    }
  };

  const handleUpdateOrderStatus = async (orders) => {
    /* istanbul ignore next */
    let orderData = _.map(orders, "orderID");
    let orderStatuObj = {
      id: orderData,
      companyId: createdBy?.Value?.companyId,
      sellerId: createdBy?.Value?.sellerId,
      createdBy: createdBy?.Value?.employeeId,
      modifiedBy: createdBy?.Value?.employeeId,
      isActive: true,
      companySellerId: createdBy?.Value?.companySellerId,
      role: createdBy?.Value?.role,
    };
    let updateOrderStatusRes = await Promise.resolve(
      dispatch(updateOrderStatus(orderStatuObj))
    );
    if (updateOrderStatusRes.data && updateOrderStatusRes.data.Status === 200) {
      /* istanbul ignore next */
      props.getAllRecords();
    }
  };

  const handleOrderBooking = async () => {
    if (!isLoading) {
      let mixRate = selectedData.map((item) => {
        return {
          ...item,
          orderDetail: `${item.orderId}$${item.rate}`,
        };
      });
      let orderIds = _.map(mixRate, "orderDetail").toString();
      let data = {
        commaSepratedOrderId: orderIds,
        createdBy: createdBy?.Value?.employeeId,
        sellerId: createdBy?.Value?.sellerId,
        companyId: createdBy?.Value?.companyId,
        totalAmount: totalRate,
      };
      setIsLoading(true);
      let bulkShipmentRes = await Promise.resolve(
        dispatch(bulkCreateShipment(data))
      );
      if (bulkShipmentRes.data && bulkShipmentRes.data.Status === 200) {
        getBulkShipment();
        await Promise.resolve(
          dispatch(
            getLoginUserDetailsonFund({
              creditCompanySellerId: createdBy?.Value?.companySellerId,
            })
          )
        );

        if (bulkShipmentRes.data.Entity) {
          window.open(bulkShipmentRes.data.Entity, "_blank");
        }
      } else {
        /* istanbul ignore next */
        toast(bulkShipmentRes.data?.Message?.AppStatusDescription, {
          time: 5000,
          className: "sucess-toast",
        });
        setIsLoading(false);
      }
    }
  };

  return (
    <Button
      variant="outline-primary"
      disabled={
        !isValidData ||
        selectedData?.length === 0 ||
        isLoading ||
        props.thresholdValidation?.length
      }
      className={`${selectedData?.length === 0 || !isValidData
        ? "batchBookingBtn booking disabled"
        : "batchBookingBtn booking"
        }`}
      onClick={handleOrderBooking}
    >
      Book {selectedData?.length} Selected (${totalRate})
      {isLoading && <Spinner animation="border" size="sm" />}
    </Button>
  );
};

export default BatchBooking;

import React from 'react';
import eye_icon from "../../assets/eye_icon.svg";
import edit_icon from "../../assets/edit_icon.svg";
import { Tooltip as WmsTooltip } from "react-tooltip";
import history from "../../assets/icons/timer1.svg";
import { deleteIcon } from '../../constant';

const ActionItems = (props) => {
    const { type, item, handleAction } = props;

    const showIcon = () => {
        let src;
        if (type === 'View') {
            src = eye_icon;
        } else if (type === 'Edit') {
            src = edit_icon;
        } else if (type === 'History') {
            src = history;
        } else if (type === 'Delete') {
            src = deleteIcon;
        } else {
            src = '';
        }
        return src
    }

    return (
        <>
            <img
                className="editIcon mr-15"
                style={{ cursor: "pointer" }}
                onClick={handleAction}
                src={showIcon()}
                alt=""
                id={`${item.id}+${type}`}
            />
            <WmsTooltip
                anchorId={`${item.id}+${type}`}
                content={type}
                place="right"
                className="tooltipWidth"
            />

        </>
    );
}

export default ActionItems;
import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { Row } from "react-bootstrap";
import { useNavigate } from "react-router";
import Button from "react-bootstrap/Button";
import { findConfiguration, formatNumberWithCommas } from "../../../config/utils";
import moment from "moment";
import { fetchProcessingFee } from "../../../store/actions/fundManagement";
import DatePicker from "react-datepicker";

const RechargeWallet = (props) => {
  const { showModal, userDetails, setShowRechargePopup, dispatch } = props;
  const [minAmountRecharge, setMinAmount] = useState(0);
  const modalRef = useRef();
  const fundTransactionData = useSelector(
    (state) => state?.FundManagementReducer?.getWalletAmount
  );
  const minAmountRechargeConfig = useSelector(
    (state) => state?.auth?.configurations
  );
  const navigate = useNavigate();
  const [validateAmount, setValidateAmount] = useState(false);

  useEffect(() => {
    setMinAmount(Number(findConfiguration('MRA', minAmountRechargeConfig)))
  }, [minAmountRechargeConfig]);

  const [formData, setFormData] = useState({
    amount: "",
    customerIdentifier: userDetails?.Value?.companySellerId,
    email: userDetails?.Value?.emailId,
    description: "",
    processingFees: "",
    totalAmount: "",
    shouldSendEmail: true
  });

  const handleRecharge = () => {
    const name = userDetails?.Value?.sellerName.split(/\s+/);
    const data = {
      ...formData,
      createdDate: moment(formData?.createdDate).format("MM-DD-YYYY HH:mm:ss"),
      customerIdentifier: formData?.customerIdentifier,
      amount: formData?.amount,
      commissionAmount: formData?.processingFees,
      description: formData.description,
      shouldSendEmail: formData.shouldSendEmail,
      email: formData.email,
      billTo: {
        firstName: name[0],
        lastName: name[1],
        company: "",
        address: "",
        city: "",
        state: "",
        zip: "",
        country: "",
      },
      customerEmail: {
        email: userDetails?.Value?.emailId,
      },
    };
    navigate(`/fundManagement/recharge`, {
      state: data,
    });
  };

  const handleCancel = () => {
    setShowRechargePopup(false);
  };

  const handleUserInput = (e) => {
    let { name, value } = e.target;
    if (name === 'amount') {
      setFormData({
        ...formData,
        [name]: value,
        totalAmount: ''
      });
      setValidateAmount(false);
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleDateChange = (e, name) => {
    setFormData({
      ...formData,
      [name]: moment(e).format("MM-DD-YYYY"),
    });
  };

  const getProcessingFee = async (e) => {
    const { value } = e.target;
    let formatNum = Number(value).toFixed(2);
    if (formatNum >= minAmountRecharge) {
      let data = `companySellerId=${userDetails?.Value?.companySellerId}&amount=${formData.amount}`;
      let response = await dispatch(fetchProcessingFee(data));
      if (response.data.Status === 200) {
        setFormData({
          ...formData,
          amount: formatNum,
          processingFees: response.data.Entity,
          totalAmount: formatNumberWithCommas(Number(formatNum) + Number(response.data.Entity)),
        });
      }
    } else {
      setValidateAmount(true);
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      className="topUpModal"
      centered
      backdrop="static"
      ref={modalRef}
      show={showModal}
    >
      <div className="top_modal_header_container">
        <span className="">Wallet Recharge</span>
        <div className="modalDeleteWrapper">
          <button
            type="button"
            onClick={() => handleCancel()}
            className="btn-close"
            aria-label="Close"
          ></button>
        </div>
      </div>
      <div className="modal_container_rate_card">
        <div className="row modal_input">
          <div className="col-lg-4 col-sm-12 formLabel ">
            <span className="input-label fnt-wgt-editmgmt">Company Name:</span>
          </div>
          <div className="col-lg-8 col-sm-12">
            <div className={"select_react"}>
              <input
                className="form-control"
                type={"text"}
                disabled
                value={userDetails?.Value?.companyName}
              />
            </div>
          </div>
        </div>
        <div className="row modal_input">
          <div className="col-lg-4 col-sm-12 formLabel ">
            <span className="input-label fnt-wgt-editmgmt">Seller Name:</span>
          </div>
          <div className="col-lg-8 col-sm-12">
            <div className={"select_react"}>
              <input
                className="form-control"
                type={"text"}
                disabled
                value={userDetails?.Value?.sellerName}
              />
            </div>
          </div>
        </div>
        <div className="row modal_input">
          <div className="col-lg-4 col-sm-12 formLabel">
            <span className="input-label fnt-wgt-editmgmt">Available Balance ($):</span>
          </div>
          <div className="col-lg-8 col-sm-12">
            <input
              className="form-control"
              type={"text"}
              disabled
              value={
                fundTransactionData
                  ? formatNumberWithCommas(fundTransactionData?.balanceAmount)
                  : 0
              }
            />
          </div>
        </div>
        <div className="row modal_input">
          <div className="col-lg-4 col-sm-12 formLabel">
            <span className="input-label fnt-wgt-editmgmt">Transaction Date:</span>
          </div>
          <div className="col-lg-8 col-sm-12">
            <DatePicker
              data-testid={'createdDate'}
              className="form-control createdDate"
              showIcon
              dateFormat="MM-dd-yyyy"
              name="createdDate"
              onChange={(e) => handleDateChange(e, "createdDate")}
              value={formData?.createdDate || moment(new Date()).format("MM-DD-YYYY")}
            />
          </div>
        </div>
        <div className="row modal_input fontFix">
          <div className="col-lg-4 col-sm-12 formLabel">
            <span className="input-label fnt-wgt-editmgmt">Enter The Amount ($):</span>
          </div>
          <div className="col-lg-8 col-sm-12">
            <input
              className="form-control enterAmount"
              type={"number"}
              maxLength={10}
              value={formData.amount}
              name="amount"
              onChange={handleUserInput}
              onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
              onBlur={getProcessingFee}
            />
            {validateAmount && <div className="error">Minimum recharge amount is ${minAmountRecharge}</div>}
          </div>
        </div>
        <div className="row modal_input">
          <div className="col-lg-4 col-sm-12 formLabel">
            <span className="input-label fnt-wgt-editmgmt">Processing Fees ($):</span>
          </div>
          <div className="col-lg-8 col-sm-12">
            <input
              type={"text"}
              className="form-control"
              disabled
              value={formData.processingFees}
            />
          </div>
        </div>
        <div className="row modal_input">
          <div className="col-lg-4 col-sm-12 formLabel">
            <span className="input-label fnt-wgt-editmgmt">Total Amount ($):</span>
          </div>
          <div className="col-lg-8 col-sm-12">
            <input
              type={"text"}
              className="form-control"
              disabled
              value={formData.totalAmount}
            />
          </div>
        </div>
        <div className="row modal_input">
          <div className="col-lg-4 col-sm-12 formLabel">
            <span className="input-label fnt-wgt-editmgmt">Description:</span>
          </div>
          <div className="col-lg-8 col-sm-12">
            <textarea
              maxLength={100}
              name="description"
              onChange={handleUserInput}
              className="form-control text-area"
              type="text"
              value={formData.description}
            />
          </div>
        </div>
        <div className="row modal_input fontFix">
          <div className="col-lg-4 col-sm-12 formLabel"></div>
          <div className="col-lg-8 col-sm-12">
            <input className="shouldSendEmail" type="checkbox" checked={formData.shouldSendEmail} onChange={(e) => setFormData({ ...formData, shouldSendEmail: e.target.checked })} /> Send Notification Email
          </div>
        </div>
        <Row className="modaL_footer">
          <div
            className="col-12 noPadding"
            style={{ textAlign: "right" }}
            xs={12}
          >
            <Button
              className="btn cus-seconday-bg-btn"
              onClick={() => handleCancel()}
            >
              Cancel
            </Button>
            <button
              id="saveTopUpBtn"
              disabled={!formData.totalAmount}
              className="btn cus-seconday-bg-btn marginLeft5"
              onClick={handleRecharge}
            >
              Proceed
            </button>
          </div>
        </Row>
      </div>
    </Modal>
  );
};

export default RechargeWallet;

import { walletConstant } from '../constants'
const initialState = {
    prePayStatus: '',
    prePayBalance: {}
};
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case walletConstant.GET_PREPAY_BALANCE_PENDING:
            return {
                ...state,
                prePayStatus: 'pending',
                prePayBalance: {}
            };
        case walletConstant.GET_PREPAY_BALANCE_SUCCESS:
            return {
                ...state,
                prePayBalance: payload,
                prePayStatus: 'success',
            };
        case walletConstant.GET_PREPAY_BALANCE_FAILED:
            return {
                ...state,
                prePayBalance: {},
                prePayStatus: 'failed',
            };
        default:
            return state;
    }
}


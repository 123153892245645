import {
    CREATE_RATETYPE_SUCCESS,
    CREATE_RATETYPE_FAIL,
    GET_RATETYPE_SUCCESS,
    GET_RATETYPE_FAIL,
    UPDATE_RATETYPE_SUCCESS,
    UPDATE_RATETYPE_FAIL,
    DELETE_RATETYPE_SUCCESS,
    DELETE_RATETYPE_FAIL,
    GET_RATETYPE_BY_ID_SUCCESS,
    GET_RATETYPE_BY_ID_FAIL
  } from "../types";
  const RateType = {};
  const initialState = {
    RateTypeList: [],
    loading: true,
    error: {}
  };
  export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case GET_RATETYPE_SUCCESS:
        return {
          ...state,
          RateTypeList: payload.ratetype,
          isLoading: false
        };    
      case GET_RATETYPE_FAIL:
        return {
          ...state
        }; 
      case CREATE_RATETYPE_SUCCESS:
        return {
          ...state,
          RateTypeList: [...state.RateTypeList, payload.ratetype]
        };
      case CREATE_RATETYPE_FAIL:
        return {
          ...state
        };
      case UPDATE_RATETYPE_SUCCESS:
        return {
          ...state
        };
      case UPDATE_RATETYPE_FAIL:
        return {
          ...state
        };
      case GET_RATETYPE_BY_ID_SUCCESS:
        return {
          ...state
        };
      case GET_RATETYPE_BY_ID_FAIL:
        return {
          ...state
        };    
      case DELETE_RATETYPE_SUCCESS:
        return{
          ...state,
          RateTypeList:[
            ...state.RateTypeList.filter(ratetype => ratetype != payload.ratetype)
          ]
        }
      case DELETE_RATETYPE_FAIL:
        return {
          ...state
        };
        
      default:
        return state;
    }  
  }

  
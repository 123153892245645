import axios from "axios";
import { apiUrl } from "../constant";

export const getAllAddressListService = (data) => {
    return axios.post(`${apiUrl}/api/AddressBook/GetAddressBook`, data);
};

export const addAddressService = (data) => {
    return axios.post(`${apiUrl}/api/AddressBook/CreateAddress`, data);
};

export const updateAddressService = (data) => {
    return axios.put(`${apiUrl}/api/AddressBook/UpdateAddress`, data);
};

export const getAddressByIdService = (addressId) => {
    return axios.get(`${apiUrl}/api/AddressBook/AddressBookDetails/${addressId}`);
};

export const getLoadAddressListService = (data) => {
    return axios.get(`${apiUrl}/api/AddressBook/GetAddressBookByType?${data}`);
};

export const deleteAddressesService = (data) => {
    return axios.delete(`${apiUrl}/api/AddressBook/DeleteAddressBook`, { data: data });
};

import React from 'react';
import DatePicker from "react-datepicker";

const DatePickerComponent = (props) => {
    const { dateField, handleDateChange, fieldName } = props;
    return (
        <DatePicker
            className="date-picker form-control"
            showIcon
            autoComplete='one-time-code'
            dateFormat="MM-dd-yyyy"
            selected={dateField ? new Date(dateField) : null}
            onChange={(date) => handleDateChange(date, fieldName)}
            name={'dateField'}
            adjustDateOnChange
        />
    );
}

export default DatePickerComponent;
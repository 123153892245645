import React from "react";
import { Modal } from "react-bootstrap";

const AlertDialogModal = (props) => {
  const {
    modalShow,
    modalClass,
    handleYesDeleteAlert,
    handleCancelDeleteAlert,
    messageText,
    headingText,
    loader
  } = props;
  return (
    <Modal
      {...props}
      show={modalShow || false}
      size="md"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={handleCancelDeleteAlert}
      className={modalClass || ""}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {headingText ? headingText : "DELETE"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="messageWrapper">
          {messageText ? (
            <>{messageText}</>
          ) : (
            <>Are you sure, you want to delete this record?</>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="modalFooter-1">
          {props?.default ? (
            <button
              className="btn cus-seconday-bg-btn btn btn-primary okBtn"
              onClick={handleCancelDeleteAlert}
            >
              Ok
            </button>
          ) : (
            <>
              <button
                className="btn cus-seconday-bg-btn btn btn-primary cancelBtn"
                onClick={handleCancelDeleteAlert}
                disabled={loader}
              >
                Cancel
              </button>
              <button
                className="btn cus-seconday-bg-btn btn btn-primary marginLeft5 yesBtn"
                onClick={handleYesDeleteAlert}
                disabled={loader}
              >
                Yes
              </button>
            </>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default AlertDialogModal;

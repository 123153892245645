import axios from "axios";
import { apiUrl } from "../constant";
import { toast } from "react-toastify";

const register = (username, email, password) => {
  return axios.post(apiUrl + "/api/login/authenticate", {
    username,
    email,
    password,
  });
};
const getCompany = () => {
  return axios.get(apiUrl + "/api/company/getcompany", {});
};
const login = (emailId, password) => {
  return axios
    .post(apiUrl + "/api/login/authenticate", {
      emailId,
      password,
    })
    .then((response) => {
      if (response?.data?.StatusCode == 401) {
        toast.error("Invalid Credentials");
      }
      if (response.data.Token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};
const logout = () => {
  localStorage.removeItem("user");
};

const refreshTokenService = (token) => {
  return axios.post(apiUrl + "/api/login/refreshtoken?token=" + token);
};

const configurationService = () => {
  return axios.get(apiUrl + "/api/Configuration");
};

const getCompanySellerService = () => {
  return axios.post(apiUrl + "/GetCompanySellerListByUserId");
};

export default {
  register,
  login,
  logout,
  getCompany,
  refreshTokenService,
  configurationService,
  getCompanySellerService
};

import React, { useEffect } from 'react';
import { Button } from "bootstrap-4-react/lib/components";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { fetchAddressFromZipCode, getAllCountries, getStates } from "../../store/actions";
import { alphabeticKeys, featureModule, inventoryManagement } from '../../constant';
import PhoneInput from 'react-phone-number-input';
import { checkPermission } from '../../config/utils';

const CompanyDetails = (props) => {
    const { editValue, handleEditable, setaddressModalShow, saveTopUpData,
        formData, TopUpForm, errors, addressData, handleDefaultAddress, handleDeleteAddress,
        countryList, handleChange, handleInputValueTypes, stateList, setstateList, setaddressData, userDetails } = props;

    const dispatch = useDispatch();
    const USSates = useSelector((state) => state.shipment.states);
    const employeePermissions = useSelector((state) => state?.sidebar?.employeePermissions);

    useEffect(() => {
        /* istanbul ignore next */
        if (userDetails?.Value?.emailId) {
            dispatch(getAllCountries());
            dispatch(getStates(218));
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }
    }, [userDetails]);

    useEffect(() => {
        if (USSates && USSates.length) {
            let data = USSates.map((item) => {
                return {
                    label: item.StateName,
                    value: item.StateCode,
                };
            });
            setstateList(data);
        } else {
            setstateList([]);
        }
    }, [USSates]);

    const fetchAddress = async (zipCode, index) => {
        if (zipCode) {
            let responseAddress = await Promise.resolve(dispatch(fetchAddressFromZipCode(zipCode)));
            /* istanbul ignore next */
            if (responseAddress?.data?.Status === 200) {
                let address = responseAddress?.data?.Entity;
                if (address && address?.Country) {
                    let formDataObj = [...addressData];
                    let selectedCountry = countryList.filter((country) => country.label === address?.Country)[0];
                    formDataObj[index].country = selectedCountry?.label;
                    formDataObj[index].country_Id = selectedCountry?.value;
                    formDataObj[index].state = selectedCountry?.value === 218 ? USSates.filter((state) => state.StateName === address?.State)[0].StateCode : address?.State;
                    formDataObj[index].city = address.City || formDataObj[index].city;
                    setaddressData(formDataObj);
                }
            }
        }
    }

    return (
        <>
            <div className="main__container main_content_table">
                <div className="main__tile_heading cus-btn-include-sec">
                    Company Details
                    <div className="top_right_button_container">
                        {editValue ? (
                            <>
                                <Button
                                    className="cus-seconday-bg-btn top-btn"
                                    disabled={editValue}
                                >
                                    Add Address
                                </Button>
                                <Button
                                    className="cus-seconday-bg-btn top-btn editButton"
                                    onClick={handleEditable}
                                >
                                    Edit
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button
                                    className="cus-seconday-bg-btn top-btn addAddressBtn"
                                    onClick={() => setaddressModalShow(true)}
                                >
                                    Add Address
                                </Button>
                                <Button
                                    className="cus-seconday-bg-btn top-btn saveBtn"
                                    onClick={saveTopUpData}
                                >
                                    Save
                                </Button>
                            </>
                        )}
                    </div>
                </div>
                <div className="col-lg-4 col-sm-12 formLabel Edit">
                    <span>Basic Details:</span>
                </div>
                <div className="edit-company-detail fontFix">
                    <div className="row modal_input">
                        <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                            <span className="input-label">Company Name:</span>
                        </div>
                        <div className="col-lg-8 col-sm-12">
                            <div className={"select_react"}>
                                <input
                                    value={formData?.name}
                                    onChange={(e) =>
                                        TopUpForm(e.target.value, "name")
                                    }
                                    disabled={editValue}
                                    className="form-control availableBalanceInput"
                                    type="text"
                                />
                                <p className="error dialogError">{errors?.name}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row modal_input">
                        <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                            <span className="input-label">Email Address:</span>
                        </div>
                        <div className="col-lg-8 col-sm-12">
                            <div className={"select_react"}>
                                <input
                                    value={formData?.email}
                                    onChange={(e) =>
                                        TopUpForm(e.target.value, "email")
                                    }
                                    className="form-control availableBalanceInput"
                                    type="text"
                                    disabled={editValue}
                                />
                                <p className="error dialogError">
                                    {errors?.email}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row modal_input">
                        <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                            <span className="input-label">Secondary Email Address:</span>
                        </div>
                        <div className="col-lg-8 col-sm-12">
                            <div className={"select_react"}>
                                <input
                                    value={formData?.secondaryEmailAddress || ''}
                                    onChange={(e) =>
                                        TopUpForm(e.target.value, "secondaryEmailAddress")
                                    }
                                    className="form-control availableBalanceInput"
                                    type="text"
                                    disabled={editValue}
                                />
                                <p className="error dialogError">
                                    {errors?.secondaryEmailAddress}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row modal_input">
                        <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                            <span className="input-label">Notes:</span>
                        </div>
                        <div className="col-lg-8 col-sm-12">
                            <div className={"select_react"}>
                                <textarea
                                    onChange={(e) => TopUpForm(e.target.value, "notes")}
                                    rows={2}
                                    name="notes"
                                    value={formData?.notes || ''}
                                    className="form-control availableBalanceInput notes"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row modal_input">
                        <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit"></div>
                        <div className="col-lg-8 col-sm-12">
                            {checkPermission(inventoryManagement, '', '', featureModule, employeePermissions) && (
                                <div className={"input-label"}>
                                    <input
                                        type="checkbox"
                                        checked={formData?.isInventoryManagementAccess}
                                        name="isInventoryManagementAccess"
                                        onChange={(e) => TopUpForm(e.target.checked, "isInventoryManagementAccess")}
                                        className="checkBoxField"
                                        disabled={editValue}
                                    />
                                    Inventory Management Access
                                </div>
                            )}
                        </div>
                    </div>

                </div>
                <div className="col-4 formLabel Edit">
                    <span>Addresses:</span>
                </div>
                {addressData?.length > 0 &&
                    addressData?.map((d, index) => (
                        <>
                            {d?.id !== 0 && (
                                <div className="edit-company-address fontFix">
                                    {editValue ? (
                                        <div className="row modal_input Edit-Form-company">
                                            <div className="col-4 formLabel Edit"></div>
                                            <div className="col-lg-8 col-sm-12 delete-company">
                                                <span className="input-label col-4">
                                                    <input
                                                        type="radio"
                                                        className="defaultCompany"
                                                        id="html"
                                                        checked={d?.isDefault}
                                                        onChange={() => handleDefaultAddress(d?.id)}
                                                        name="fav_language"
                                                        disabled={true}
                                                        value="HTML"
                                                    />
                                                    <label
                                                        className="default-add-label"
                                                        for="html"
                                                    >
                                                        Default Address
                                                    </label>
                                                </span>

                                                <span className="input-label col-4">
                                                    <i
                                                        className="fa fa-trash-o"
                                                        aria-hidden="true"
                                                    ></i>
                                                </span>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="row modal_input">
                                            <div className="col-4 formLabel Edit"></div>
                                            <div className="col-lg-8 col-sm-12 delete-company">
                                                <span className="input-label col-4">
                                                    <input
                                                        type="radio"
                                                        className={"defaultCompany"}
                                                        id="html"
                                                        checked={d?.isDefault}
                                                        onChange={() => handleDefaultAddress(d?.id)}
                                                        name="fav_language"
                                                        disabled={false}
                                                        value="HTML"
                                                    />
                                                    {" "}
                                                    <label
                                                        className="default-add-label"
                                                        for="html"
                                                    >
                                                        Default Address
                                                    </label>
                                                </span>

                                                <span
                                                    className="input-label col-4 delete-btn"
                                                    onClick={() => handleDeleteAddress(d)}
                                                >
                                                    <i
                                                        className="fa fa-trash-o"
                                                        aria-hidden="true"
                                                    ></i>
                                                </span>
                                            </div>
                                        </div>
                                    )}

                                    <div className="row modal_input">
                                        <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                                            <span className="input-label">
                                                Country:
                                            </span>
                                        </div>
                                        <div className="col-lg-8 col-sm-12 mobMb-10">
                                            <div className={"select_react"}>
                                                <Select
                                                    options={countryList}
                                                    onChange={(value) =>
                                                        handleChange(
                                                            index,
                                                            "country_Id",
                                                            value
                                                        )
                                                    }
                                                    key={d?.id}
                                                    className="activeSellerListInput"
                                                    isDisabled={editValue}
                                                    value={{
                                                        label: d?.country,
                                                        value: d?.country_Id,
                                                    }}
                                                    classNamePrefix="list"
                                                />
                                                {errors[`addressModels_${index}`] &&
                                                    /* istanbul ignore next */
                                                    errors[`addressModels_${index}`]
                                                        .country_Id && (
                                                        <p className="error dalogError">
                                                            {
                                                                errors[`addressModels_${index}`]
                                                                    .country_Id
                                                            }
                                                        </p>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row modal_input">
                                        <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                                            <span className="input-label">
                                                Zip Code:
                                            </span>
                                        </div>
                                        <div className="col-lg-8 col-sm-12 mobMb-10">
                                            <div className={"select_react"}>
                                                <input
                                                    value={d?.zipCode}
                                                    onChange={(e) =>
                                                        handleChange(
                                                            index,
                                                            "zipCode",
                                                            e.target.value
                                                        )
                                                    }
                                                    onKeyDown={(e) =>
                                                        handleInputValueTypes(e, alphabeticKeys)
                                                    }
                                                    className="form-control availableBalanceInput zipCodeInputField"
                                                    type="text"
                                                    disabled={editValue}
                                                    pattern="\d{5}(-\d{4})?"
                                                    title="Please enter a valid zip code format: xxxxx or xxxxx-xxxx"
                                                    onBlur={(e) => fetchAddress(e.target.value, index)}
                                                />
                                                {errors[`addressModels_${index}`] &&
                                                    /* istanbul ignore next */
                                                    errors[`addressModels_${index}`]
                                                        .zipCode && (
                                                        <p className="error dalogError">
                                                            {
                                                                errors[`addressModels_${index}`]
                                                                    .zipCode
                                                            }
                                                        </p>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                    {d?.country_Id === 218 ? (
                                        <div className="row modal_input">
                                            <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                                                <span className="input-label">
                                                    State:
                                                </span>
                                            </div>
                                            <div className="col-lg-8 col-sm-12 mobMb-10">
                                                <Select
                                                    //   value={formData?.state}
                                                    options={stateList}
                                                    onChange={(value) =>
                                                        handleChange(
                                                            index,
                                                            "state",
                                                            value.label
                                                        )
                                                    }
                                                    className="activeSellerListInput"
                                                    isDisabled={editValue}
                                                    defaultValue={{
                                                        label: d?.state,
                                                        value: "",
                                                    }}
                                                    classNamePrefix="list"
                                                />
                                                {errors[`addressModels_${index}`] &&
                                                    /* istanbul ignore next */
                                                    errors[`addressModels_${index}`]
                                                        .state && (
                                                        <p className="error dalogError">
                                                            {
                                                                errors[`addressModels_${index}`]
                                                                    .state
                                                            }
                                                        </p>
                                                    )}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="row modal_input">
                                            <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                                                <span className="input-label">
                                                    State:
                                                </span>
                                            </div>
                                            <div className="col-lg-8 col-sm-12 mobMb-10">
                                                <input
                                                    value={d?.state}
                                                    onChange={(e) =>
                                                        handleChange(
                                                            index,
                                                            "state",
                                                            e.target.value
                                                        )
                                                    }
                                                    onKeyDown={(e) =>
                                                        handleInputValueTypes(e, /^[A-Za-z]$/)
                                                    }
                                                    className="form-control availableBalanceInput stateInput"
                                                    disabled={editValue}
                                                    type="text"
                                                />
                                                {errors[`addressModels_${index}`] &&
                                                    /* istanbul ignore next */
                                                    errors[`addressModels_${index}`]
                                                        .state && (
                                                        <p className="error dalogError">
                                                            {
                                                                errors[`addressModels_${index}`]
                                                                    .state
                                                            }
                                                        </p>
                                                    )}
                                            </div>
                                        </div>
                                    )}

                                    <div className="row modal_input">
                                        <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                                            <span className="input-label">City:</span>
                                        </div>
                                        <div className="col-lg-8 col-sm-12 mobMb-10">
                                            <div className={"select_react"}>
                                                <input
                                                    value={d?.city}
                                                    onChange={(e) =>
                                                        handleChange(
                                                            index,
                                                            "city",
                                                            e.target.value
                                                        )
                                                    }
                                                    onKeyDown={(e) =>
                                                        handleInputValueTypes(e, /^[A-Za-z]$/)
                                                    }
                                                    className="form-control availableBalanceInput cityInput"
                                                    disabled={editValue}
                                                    type="text"
                                                />
                                                {errors[`addressModels_${index}`] &&
                                                    /* istanbul ignore next */
                                                    errors[`addressModels_${index}`]
                                                        .city && (
                                                        <p className="error dalogError">
                                                            {
                                                                errors[`addressModels_${index}`]
                                                                    .city
                                                            }
                                                        </p>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row modal_input">
                                        <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                                            <span className="input-label">
                                                Phone Number:
                                            </span>
                                        </div>
                                        <div className="col-lg-8 col-sm-12 mobMb-10">
                                            <div className={"select_react"}>
                                                <PhoneInput
                                                    value={d?.phoneNumber}
                                                    name="phoneNumber"
                                                    disabled={editValue}
                                                    onChange={(e) =>
                                                        handleChange(index, "phoneNumber", e)
                                                    }
                                                    className={`form-control availableBalanceInput phoneNumberInput`}
                                                />

                                                {errors[`addressModels_${index}`] &&
                                                    /* istanbul ignore next */
                                                    errors[`addressModels_${index}`]
                                                        .phoneNumber && (
                                                        <p className="error dalogError countrytype-error">
                                                            {
                                                                errors[`addressModels_${index}`]
                                                                    .phoneNumber
                                                            }
                                                        </p>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row modal_input">
                                        <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                                            <span className="input-label">
                                                Secondary Phone Number:
                                            </span>
                                        </div>
                                        <div className="col-lg-8 col-sm-12 mobMb-10">
                                            <div className={"select_react"}>
                                                <PhoneInput
                                                    value={d?.secondaryPhoneNumber}
                                                    name="secondaryPhoneNumber"
                                                    disabled={editValue}
                                                    onChange={(e) =>
                                                        handleChange(index, "secondaryPhoneNumber", e)
                                                    }
                                                    className={`form-control phoneNumberInput secondaryPhoneNumber`}
                                                />

                                                {errors[`addressModels_${index}`] &&
                                                    /* istanbul ignore next */
                                                    errors[`addressModels_${index}`]
                                                        .secondaryPhoneNumber && (
                                                        <p className="error dalogError countrytype-error">
                                                            {
                                                                errors[`addressModels_${index}`]
                                                                    .secondaryPhoneNumber
                                                            }
                                                        </p>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row modal_input">
                                        <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                                            <span className="input-label">
                                                Address 1:
                                            </span>
                                        </div>
                                        <div className="col-lg-8 col-sm-12 mobMb-10">
                                            <div className={"select_react"}>
                                                <input
                                                    value={d?.addressFirst}
                                                    onChange={(e) =>
                                                        handleChange(
                                                            index,
                                                            "addressFirst",
                                                            e.target.value
                                                        )
                                                    }
                                                    onKeyDown={(e) =>
                                                        handleInputValueTypes(
                                                            e,
                                                            /^[a-zA-Z0-9\s-]$/
                                                        )
                                                    }
                                                    className="form-control availableBalanceInput addressFirstInput"
                                                    disabled={editValue}
                                                    type="text"
                                                />
                                                {errors[`addressModels_${index}`] &&
                                                    /* istanbul ignore next */
                                                    errors[`addressModels_${index}`]
                                                        .addressFirst && (
                                                        <p className="error dalogError">
                                                            {
                                                                errors[`addressModels_${index}`]
                                                                    .addressFirst
                                                            }
                                                        </p>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row modal_input">
                                        <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                                            <span className="input-label">
                                                Address 2:
                                            </span>
                                        </div>
                                        <div className="col-lg-8 col-sm-12 mobMb-10">
                                            <div className={"select_react"}>
                                                <input
                                                    value={d?.addressSecond}
                                                    onChange={(e) =>
                                                        handleChange(
                                                            index,
                                                            "addressSecond",
                                                            e.target.value
                                                        )
                                                    }
                                                    onKeyDown={(e) =>
                                                        handleInputValueTypes(
                                                            e,
                                                            /^[a-zA-Z0-9\s-]$/
                                                        )
                                                    }
                                                    className="form-control availableBalanceInput addressSecondInput"
                                                    disabled={editValue}
                                                    type="text"
                                                />
                                                {errors[`addressModels_${index}`] &&
                                                    /* istanbul ignore next */
                                                    errors[`addressModels_${index}`]
                                                        .addressSecond && (
                                                        <p className="error dalogError">
                                                            {
                                                                errors[`addressModels_${index}`]
                                                                    .addressSecond
                                                            }
                                                        </p>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row modal_input">
                                        <div className="col-lg-4 col-sm-12 mobMb-10 formLabel Edit">
                                            <span className="input-label">
                                                Address 3:
                                            </span>
                                        </div>
                                        <div className="col-lg-8 col-sm-12 mobMb-10">
                                            <div className={"select_react"}>
                                                <input
                                                    value={d?.addressThird}
                                                    onChange={(e) =>
                                                        handleChange(
                                                            index,
                                                            "addressThird",
                                                            e.target.value
                                                        )
                                                    }
                                                    onKeyDown={(e) =>
                                                        handleInputValueTypes(e, /^[a-zA-Z0-9\s-]$/)
                                                    }
                                                    className="form-control availableBalanceInput addressThirdInput"
                                                    disabled={editValue}
                                                    type="text"
                                                />
                                                {errors[`addressModels_${index}`] &&
                                                    errors[`addressModels_${index}`]
                                                        .addressThird && (
                                                        <p className="error dalogError">
                                                            {
                                                                errors[`addressModels_${index}`]
                                                                    .addressThird
                                                            }
                                                        </p>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    ))}
            </div>
        </>
    );
}

export default CompanyDetails;
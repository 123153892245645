import Table from "react-bootstrap/Table";
import React from 'react';
import moment from "moment";
import history from "../../assets/icons/timer1.svg";
import { marketPlaceBackDetails } from "../../store/actions/marketplace.action";
import { featureSubmodulePermission, permssionView, settings, SUBF_MARKETPLACEINTEGRATION } from "../../constant";
import { checkPermission } from "../../config/utils";

const IntegrationList = (props) => {
    const {
        sortingColumn,
        handleSortingColumn,
        sortOrder,
        listData,
        navigate,
        loader,
        defaultFilterData,
        dispatch
    } = props;

    const renderColumnHeader = (colName, type) => {
        return (
            <div
                className={(sortingColumn === type) ? "active" : "sortColumn"}
                onClick={(e) => handleSortingColumn(e, type)}
            >
                {colName}
                <i
                    className={(sortingColumn === type && sortOrder === "asc") ? "fa fa-arrow-up" : "fa fa-arrow-down"}
                    aria-hidden="true"
                ></i>
            </div>
        )
    }


    const handleIntegrationRedirect = (integration) => {
        dispatch(marketPlaceBackDetails(defaultFilterData));
        navigate(`/market-place/${integration.integration_name}`, {
            state: {
                data: integration,
                isIntegration: true,
                backUrl: '/marketplace-integration',
            },
        });
    }

    const renderLoading = () => {
        return (
            <tr className="table-rw-batch">
                <td colSpan={7} align="center">Loading...</td>
            </tr>
        )
    }
    const renderNoRecord = () => {
        return (
            <tr className="table-rw-batch">
                <td colSpan={7} align="center">No Record Found</td>
            </tr>
        )
    }

    const handleHistoryPage = (rowData) => {
        dispatch(marketPlaceBackDetails(defaultFilterData));
        navigate(
            `/MarketplaceIntegration/history/fd-${rowData?.integration_id}-3pl`,
            {
                state: {
                    rowData,
                },
            }
        );
    };

    return (
        <Table className="table  table-height cus-left-right-col-layout">
            <thead border="Green">
                <tr>
                    <th className="marketplace-table">
                        {renderColumnHeader('Name', 'integration_name')}
                    </th>
                    <th className="marketplace-table">
                        {renderColumnHeader('Type', 'type')}
                    </th>
                    <th>
                        Status
                    </th>
                    <th className="marketplace-table">
                        {renderColumnHeader('Active', 'active')}
                    </th>
                    <th className="marketplace-table">
                        {renderColumnHeader('Total Records', 'total_orders')}
                    </th>
                    <th>
                        {renderColumnHeader('Date', 'date')}
                    </th>
                    <th>History</th>
                </tr>
            </thead>
            <tbody className="tbl-bt-body">
                {!loader && listData && listData.length > 0 && (
                    <>
                        {listData.map((item) => (
                            <tr className="table-rw-batch" key={item}>
                                <td>
                                    {item.active ? checkPermission(settings, SUBF_MARKETPLACEINTEGRATION, permssionView, featureSubmodulePermission, props.employeePermissions) && (
                                        <span className="linkIntegration" onClick={() => handleIntegrationRedirect(item)}>{item.integration_name}</span>
                                    ) : (
                                        <span>{item.integration_name}</span>
                                    )}
                                </td>
                                <td>{item.type}</td>
                                <td>{item.pending_shipment_statuses === 'Completed' ?
                                    <span className="shipped">OK</span>
                                    : <span className="error">{item.pending_shipment_statuses}</span>}</td>
                                <td>
                                    {item.active ? <i className="fa fa-check" aria-hidden="true"></i> : <i className="fa fa-times" aria-hidden="true"></i>}
                                </td>
                                <td>{item.total_orders}</td>
                                <td>{item.order_received_date ? moment(item.order_received_date).format("MM-DD-YYYY") : '-'}</td>
                                <td>
                                {checkPermission(settings, SUBF_MARKETPLACEINTEGRATION, permssionView, featureSubmodulePermission, props.employeePermissions) && (
                                    <img
                                        className="history-Icon"
                                        onClick={() => handleHistoryPage(item)}
                                        src={history}
                                        alt=""
                                    ></img>
                                )}
                                </td>
                            </tr>
                        ))}
                    </>
                )}
                {loader && (renderLoading())}
                {!loader && listData.length === 0 && renderNoRecord()}
            </tbody >
        </Table >

    );
}

export default IntegrationList;

import React from 'react';
import Button from 'react-bootstrap/Button';
import SubmitRequest from './RefundRequest';
import RequestHistory from './RequestHistory';

const Refunds = () => {
    const [activeTab, setActiveTab] = React.useState(1);

    return (
        <main>
            <div className="main__container">
                <div className="main__tile_heading cus-btn-include-sec">
                    <span>Refunds</span>
                </div>
                <div className="container-fluid">
                    <div className="cus-page-content-sec">
                        <div className="cus-tab-heading-sec">
                            <div className="tabs">
                                <button
                                    className={`${activeTab === 1 ? "cus-tab-btn active" : "cus-tab-btn"
                                        }`}
                                    onClick={() => setActiveTab(1)}
                                >
                                    Submit Request
                                </button>

                                <button
                                    className={`${activeTab === 2 ? "cus-tab-btn active" : "cus-tab-btn"
                                        }`}
                                    onClick={() => { setActiveTab(2); }}
                                >
                                    Request History
                                </button>

                            </div>
                        </div>
                    </div>
                    <div className="main_content_table">
                        <div className="">
                            <div className='refundWrapper'>
                                {activeTab === 1 && (
                                    <SubmitRequest />
                                )}
                                {activeTab === 2 && (
                                    <RequestHistory />
                                )}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </main>
    );
}

export default Refunds;
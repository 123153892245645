import { addAddressService, deleteAddressesService, getAddressByIdService, getAllAddressListService, getLoadAddressListService, updateAddressService } from "../../services/address.service";
import { addressConstant } from "../constants/address.constant";

const getAddressesListSuccess = (response) => ({
    type: addressConstant.GET_ADDRESSLIST_SUCCESS,
    payload: response,
});
const getAddressesListFailed = () => ({
    type: addressConstant.GET_ADDRESSLIST_FAILED,
});
const getAddressesListPending = () => ({
    type: addressConstant.GET_ADDRESSLIST_PENDING
});

export const getAllAddressesList = (data) => {
    return (dispatch) => {
        dispatch(getAddressesListPending());
        getAllAddressListService(data).then((result) => {
            dispatch(getAddressesListSuccess(result.data));
        }).catch((error) => {
            dispatch(getAddressesListFailed());
        })
    };
};

export const createAddress = (data) => {
    return new Promise((res, rej) => {
        return res(addAddressService(data));
    });
};

export const updateAddress = (data) => {
    return new Promise((res, rej) => {
        return res(updateAddressService(data));
    });
};

export const getAddressById = (addressId) => {
    return new Promise((res, rej) => {
        return res(getAddressByIdService(addressId));
    })
};

const getLoadAddressesListSuccess = (response) => ({
    type: addressConstant.GET_LOAD_ADDRESSLIST_SUCCESS,
    payload: response,
});
const getLoadAddressesListFailed = () => ({
    type: addressConstant.GET_LOAD_ADDRESSLIST_FAILED,
});
const getLoadAddressesListPending = () => ({
    type: addressConstant.GET_LOAD_ADDRESSLIST_PENDING
});

export const getLoadAddressesList = (data) => {
    return (dispatch) => {
        dispatch(getLoadAddressesListPending());
        getLoadAddressListService(data).then((result) => {
            dispatch(getLoadAddressesListSuccess(result.data.EntityList));
        }).catch((error) => {
            dispatch(getLoadAddressesListFailed());
        })
    };
};

export const deleteAddress = (data) => {
    return new Promise((res, rej) => {
        return res(deleteAddressesService(data));
    });
};

import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from 'react-redux';
import { getAllCarrierServices, getAllCarrierServicesList } from '../../store/actions';
import { shipmentStatusList, unitList } from '../../constant';
import { getOrderHistory } from '../../store/actions/history';
import Select from "react-select";
import moment from 'moment';
import _ from "lodash";
import { fetchCompanyFilters, getFilterSellers } from '../../store/actions/rateCard';
import {
    getAllSellersList
} from "../../store/actions";
import { getSelectList } from '../../config/utils';


const HistoryFilter = (props) => {
    const { showFilter, defaultFilterData, setDefaultFilterData, handleReset, historyFormData, sellerList, setSellerList, allSellers, setAllSellers, setIdList } = props;
    const dispatch = useDispatch();
    const carriers = useSelector((state) => state.shipment.carriers);
    const UserValue = useSelector((state) => state.auth.user);
    const carrierServices = useSelector((state) => state.shipment.carrierServices);
    const { historyRecordStatus } = useSelector((state) => state.HistoryReducer);
    const [allCarriers, setAllCarriers] = useState([]);
    const [errors, setErrors] = useState({});
    const [allCarrierServices, setAllCarrierServices] = useState([]);
    const [activeSellerList, setActiveSellerList] = useState([]);
    const { filterCompanies, filterAllSellers } = useSelector((state) => state.RateCardReducer);

    const fetchSellers = async (activeSellerList) => {
        if (activeSellerList.length === 0) {
            setSellerList(allSellers);
            return;
        }
        let data = {
            companyIds: activeSellerList,
        };
        let res = await getAllSellersList(data);
        if (res?.data?.Status === 200) {
            let dataList = res?.data?.Entity?.listSellerMasterOrdersEntity.map(
                (item) => {
                    return {
                        ...item,
                        label: item.sellerAndCompany,
                        value: item.id,
                    };
                }
            );
            setSellerList(dataList);
        }
    };

    useEffect(() => {
        let sellerList = filterAllSellers?.EntityList || [];
        let companyList = filterCompanies?.Entity?.listCompanyMasterOrdersEntity || [];
        if (companyList.length) {
            setActiveSellerList(getSelectList(filterCompanies?.Entity?.listCompanyMasterOrdersEntity, 'Id', 'name'));
        }
        if (sellerList.length) {
            setAllSellers(getSelectList(sellerList, 'id', 'sellerName'));
            setSellerList(getSelectList(sellerList, 'id', 'sellerName'));
        }
    }, [filterCompanies, filterAllSellers]);

    useEffect(() => {
        let sellerData = {
            "pageIndex": 1,
            "pageSize": 10,
            "sortColumn": "name",
            "sortOrder": "ASCENDING",
            "companyIdList": [0],
            screenType: 2
        };
        let data = {
            pageIndex: 1,
            pageSize: 10,
            sortColumn: "name",
            sortOrder: "ascending",
        };
        dispatch(fetchCompanyFilters(data));
        dispatch(getFilterSellers(sellerData));
    }, [])

    useEffect(() => {
        if (!carriers.length) {
            dispatch(getAllCarrierServices({}));
        }
    }, []);

    useEffect(() => {
        if (!carrierServices || !carrierServices.length) {
            dispatch(getAllCarrierServicesList());
        }
    }, [carrierServices]);

    useEffect(() => {
        if (carrierServices && carrierServices.length) {
            let dataCarrierServices = carrierServices?.map((item) => {
                return {
                    ...item,
                    value: item.id,
                    label: item.serviceName,
                };
            });
            setAllCarrierServices(dataCarrierServices);
        }
    }, [carrierServices]);

    const handleFilterValidation = () => {
        let isValidated = true;
        let allErrors = {};
        if (Number(defaultFilterData.weightMin) > Number(defaultFilterData.weightMax)) {
            isValidated = false;
            allErrors.weightMin = 'Min weight cannot be greater than max weight';
        }
        if (Number(defaultFilterData.quoteMin) > Number(defaultFilterData.quoteMax)) {
            isValidated = false;
            allErrors.quoteMin = 'Quote min value cannot be greater than Quote max value';
        }

        if (moment(defaultFilterData.labelCreationTo).isBefore(moment(defaultFilterData.labelCreationFrom))) {
            isValidated = false;
            allErrors.labelCreationTo = 'Label to date cannot be before label from date';
        }

        if (moment(defaultFilterData.shipTo).isBefore(moment(defaultFilterData.shipFrom))) {
            isValidated = false;
            allErrors.shipTo = 'Ship to date cannot be before ship from date';
        }
        setErrors(allErrors);
        return isValidated;
    };

    useEffect(() => {
        if (carriers.length) {
            let dataCarriers = carriers?.map((item) => {
                return {
                    ...item,
                    value: item.id,
                    label: item.carrierCompanyName,
                };
            });
            setAllCarriers(dataCarriers);
        }
    }, [carriers]);

    const updateUserInput = (e) => {
        const { value, name } = e.target;
        setDefaultFilterData({
            ...defaultFilterData,
            [name]: value
        });
        setErrors({});
    }

    const handleNumFieldBlur = (e) => {
        const { value, name } = e.target;
        if (value) {
            setDefaultFilterData({
                ...defaultFilterData,
                [name]: Number(value),
            });
        }
    }

    const updateUserInputNum = (e) => {
        const { value, name } = e.target;
        setDefaultFilterData({
            ...defaultFilterData,
            [name]: value,
        });
        setErrors({});
    }

    const handleDateChange = (date, name) => {
        if (date) {
            let dateSelected = moment(date).utc(true).format();
            setDefaultFilterData({
                ...defaultFilterData,
                [name]: dateSelected,
            });
        } else {
            setDefaultFilterData({
                ...defaultFilterData,
                [name]: null,
            });
        }
    }

    const handleFilter = (e) => {
        /*istanbul ignore next */
        if (handleFilterValidation()) {
            setDefaultFilterData({ ...historyFormData, currentPage: 1 });
            e.preventDefault();
            let historyObj = { ...defaultFilterData };
            historyObj.weightMin = Number(historyObj.weightMin);
            historyObj.weightMax = Number(historyObj.weightMax);
            historyObj.quoteMin = Number(historyObj.quoteMin);
            historyObj.quoteMax = Number(historyObj.quoteMax);
            historyObj.numPiece = Number(historyObj.numPiece);
            historyObj.numPiece = Number(historyObj.numPiece);
            if (historyObj.carrierIdList.length) {
                let dataCarrierIdList = _.map(historyObj.carrierIdList, "id");
                historyObj.carrierIdList = dataCarrierIdList;
            }

            if (historyObj.serviceIdList.length) {
                let dataServiceIdList = _.map(historyObj.serviceIdList, "id");
                historyObj.serviceIdList = dataServiceIdList;
            }
            if (historyObj.companyIdList.length) {
                let dataServiceIdList = _.map(historyObj.companyIdList, "Id");
                historyObj.companyIdList = dataServiceIdList;
            }
            if (historyObj.sellerIdList.length) {
                let dataServiceIdList = _.map(historyObj.sellerIdList, "id");
                historyObj.sellerIdList = dataServiceIdList;
            }

            dispatch(getOrderHistory(historyObj));
            setIdList([]);
        }
    }

    /* istanbul ignore next */
    const handleServiceIdList = (value, fieldName) => {
        setDefaultFilterData({
            ...defaultFilterData,
            [fieldName]: value
        });
        if (fieldName === "companyIdList") {
            fetchSellers(_.map(value, "Id"));
            if (defaultFilterData?.sellerIdList?.length) {
                const filteredSellers = defaultFilterData.sellerIdList.filter(seller =>
                    companyData.includes(parseInt(seller.companyIds))
                );
                setDefaultFilterData({ ...defaultFilterData, sellerIdList: filteredSellers, companyIdList: value })
            }
        }
    }

    useEffect(() => {
        setDefaultFilterData({
            ...defaultFilterData,
            sellerIdList: defaultFilterData?.sellerIdList?.filter((item) => {
                return defaultFilterData?.companyIdList?.some(
                    (s) =>
                        s?.Id === Number(item?.companyIds)
                );
            })
        });
    }, [defaultFilterData.companyIdList])

    return (
        <div className={showFilter ? 'filterContainer show historyFilter history-margin fontFix' : 'filterContainer historyFilter fontFix'}>
            <div className='row mb-3'>
                <div className='col-lg-4 col-sm-12 mobMb-10'>
                    <div className='row'>
                        <div className='col-12'>
                            <label className='labelHead'>Weight
                                {errors.weightMin &&
                                    <span className='error filterHisory'> {errors.weightMin}</span>
                                }
                            </label>
                        </div>
                        <div className='col-6 fieldAndLabel mb-2'>
                            <span>Min:</span>
                            <input
                                key={11222}
                                min={0}
                                onChange={updateUserInputNum}
                                name='weightMin'
                                value={defaultFilterData.weightMin}
                                className='form-control inputHistory minWeight'
                                type='number'
                                onBlur={handleNumFieldBlur}
                                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            />
                        </div>
                        <div className='col-6 fieldAndLabel mb-2'>
                            <span>Max:</span>
                            <input
                                min={0}
                                onChange={updateUserInputNum}
                                name='weightMax'
                                value={defaultFilterData.weightMax}
                                className='form-control inputHistory maxWeight'
                                type='number'
                                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            />
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-sm-12 mobMb-10'>
                    <div className='row'>
                        <div className='col-12'>
                            <label className='labelHead'>Ship Date
                                {errors.shipTo &&
                                    <span className='error filterHisory'> {errors.shipTo}</span>
                                }
                            </label>
                        </div>

                        <div className='col-6 fieldAndLabel mb-2'>
                            <span>From:</span>
                            <div className='inputHistory'>
                                <DatePicker
                                    className="date-picker form-control"
                                    showIcon
                                    autoComplete='one-time-code'
                                    dateFormat="MM-dd-yyyy"
                                    selected={defaultFilterData.shipFrom ? new Date(defaultFilterData.shipFrom) : null}
                                    onChange={(date) => handleDateChange(date, 'shipFrom')}
                                    name={'shipFrom'}
                                    adjustDateOnChange
                                />
                            </div>
                        </div>

                        <div className='col-6 fieldAndLabel mb-2'>
                            <span>To:</span>
                            <div className='inputHistory'>
                                <DatePicker
                                    className="date-picker form-control"
                                    showIcon
                                    autoComplete='one-time-code'
                                    dateFormat="MM-dd-yyyy"
                                    selected={defaultFilterData.shipTo ? new Date(defaultFilterData.shipTo) : null}
                                    onChange={(date) => handleDateChange(date, 'shipTo')}
                                    name={'shipTo'}
                                />
                            </div>
                        </div>

                    </div>
                </div>
                <div className='col-lg-4 col-sm-12 mobMb-10'>
                    <div className='row'>
                        <div className='col-12'>
                            <label className='labelHead'>Quote Range
                                {errors.quoteMin &&
                                    <span className='error filterHisory'> {errors.quoteMin}</span>
                                }
                            </label>
                        </div>
                        <div className='col-6 fieldAndLabel mb-2'>
                            <label>Min</label>
                            <input
                                min={0}
                                onChange={updateUserInputNum}
                                name={'quoteMin'}
                                value={defaultFilterData.quoteMin}
                                className='form-control inputHistory quoteMin'
                                type='number'
                                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            />
                        </div>
                        <div className='col-6 fieldAndLabel mb-2'>
                            <label>Max</label>
                            <input
                                min={0}
                                onChange={updateUserInputNum}
                                name={'quoteMax'}
                                value={defaultFilterData.quoteMax}
                                className='form-control inputHistory'
                                type='number'
                                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className='row mb-3'>
                <div className='col-lg-4 col-sm-12 mobMb-10'>
                    <div className='row'>
                        <div className='col-6'>
                            <label className='labelHead'>Weight's Unit</label>
                            <div className='row'>
                                <div className='col-12 fieldAndLabel mb-2'>
                                    <select
                                        className="servicesDropDown form-control"
                                        onChange={updateUserInput}
                                        value={defaultFilterData.weightUnit}
                                        name={'weightUnit'}
                                    >
                                        <option value="0" selected>Select Unit</option>
                                        {unitList.map((item) => (
                                            <option key={item.id} value={item.id}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='col-6'>
                            <label className='labelHead'>Number of pieces</label>
                            <div className='row'>
                                <div className='col-12'>
                                    <input
                                        min={0}
                                        onChange={updateUserInputNum}
                                        className='form-control numPiece'
                                        type='number'
                                        name={'numPiece'}
                                        value={defaultFilterData.numPiece}
                                        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-sm-12 mobMb-10'>
                    <div className='row'>
                        <div className='col-12'>
                            <label className='labelHead'>Label Creation
                                {errors.labelCreationTo &&
                                    <span className='error filterHisory'> {errors.labelCreationTo}</span>
                                }
                            </label>
                        </div>

                        <div className='col-6 fieldAndLabel mb-2'>
                            <label>From:</label>
                            <div className='inputHistory'>
                                <DatePicker
                                    className="date-picker form-control"
                                    showIcon
                                    dateFormat="MM-dd-yyyy"
                                    autoComplete='one-time-code'
                                    selected={defaultFilterData.labelCreationFrom ? new Date(defaultFilterData.labelCreationFrom) : null}
                                    name={'labelCreationFrom'}
                                    onChange={(date) => handleDateChange(date, 'labelCreationFrom')}
                                />
                            </div>
                        </div>

                        <div className='col-6 fieldAndLabel mb-2'>
                            <label>To:</label>
                            <div className='inputHistory'>
                                <DatePicker
                                    className="date-picker form-control"
                                    showIcon
                                    autoComplete='one-time-code'
                                    onChange={(date) => handleDateChange(date, 'labelCreationTo')}
                                    dateFormat="MM-dd-yyyy"
                                    selected={defaultFilterData.labelCreationTo ? new Date(defaultFilterData.labelCreationTo) : null}
                                    name={'labelCreationTo'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-sm-12 mobMb-10'>
                    <div className='row'>
                        <div className='col-12'>
                            <label className='labelHead'>Shipment Carrier</label>
                        </div>
                        <div className='col-12'>
                            <div>
                                <Select
                                    closeMenuOnSelect={false}
                                    isMulti
                                    options={allCarriers}
                                    classNamePrefix="list"
                                    name="carrierIdList"
                                    value={defaultFilterData.carrierIdList}
                                    onChange={(value) =>
                                        handleServiceIdList(value, "carrierIdList")
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row mb-3'>
                <div className='col-lg-4 col-sm-12 mobMb-10'>
                    <div className='row'>
                        <div className='col-12 mb-2'>
                            <div className='row'>
                                <div className='col-12'>
                                    <label className='labelHead'>Service Type</label>
                                </div>
                                <div className='col-12'>
                                    <Select
                                        isMulti
                                        closeMenuOnSelect={false}
                                        options={allCarrierServices}
                                        classNamePrefix="list"
                                        name="serviceIdList"
                                        value={defaultFilterData.serviceIdList}
                                        onChange={(value) =>
                                            handleServiceIdList(value, "serviceIdList")
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-lg-4 col-sm-12 mobMb-10'>
                    <div className='row'>
                        <div className='col-12'>
                            <label className='labelHead'>Search</label>
                        </div>
                        <div className='col-12 fieldAndLabel mb-2'>
                            <input
                                value={defaultFilterData.referenceSearchText}
                                type={'text'}
                                name={'referenceSearchText'}
                                onChange={updateUserInput}
                                className='form-control referenceSearchText'
                                placeholder='Search Tracking number, Order, Reference...'
                            />
                        </div>
                    </div>
                </div>

                <div className='col-lg-4 col-sm-12 mobMb-10'>
                    <div className='row'>
                        <div className='col-12'>
                            <label className='labelHead'>Search Receiver</label>
                        </div>
                        <div className='col-12 fieldAndLabel mb-2'>
                            <input
                                value={defaultFilterData.receiverSearchText}
                                type={'text'}
                                name={'receiverSearchText'}
                                onChange={updateUserInput}
                                className='form-control receiverSearchText'
                                placeholder='Search Receiver Details...'
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className='row mb-3'>
                {UserValue?.Value?.roleName !== 'Seller' && (
                    <>
                        <div className='col-lg-4 col-sm-12 mobMb-10 mb-2'>
                            <div className='row'>
                                <div className='col-12'>
                                    <label className='labelHead'>Company</label>
                                </div>
                                <div className='col-12'>
                                    <Select
                                        closeMenuOnSelect={false}
                                        maxMenuHeight={170}
                                        isMulti
                                        options={activeSellerList}
                                        classNamePrefix="list"
                                        name="carrierIdList"
                                        value={defaultFilterData.companyIdList}
                                        onChange={(value) =>
                                            handleServiceIdList(value, "companyIdList")
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-sm-12 mobMb-10 mb-2'>
                            <div className='row'>
                                <div className='col-12'>
                                    <label className='labelHead'>Seller</label>
                                </div>
                                <div className='col-12'>
                                    <Select
                                        closeMenuOnSelect={false}
                                        maxMenuHeight={170}
                                        isMulti
                                        options={sellerList}
                                        classNamePrefix="list"
                                        name="carrierIdList"
                                        value={defaultFilterData.sellerIdList}
                                        onChange={(value) =>
                                            handleServiceIdList(value, "sellerIdList")
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                )}

                <div className='col-lg-4 col-sm-12 mobMb-10'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <label className='labelHead'>Shipment Status</label>
                                        </div>
                                        <div className='col-12'>
                                            <select
                                                className='form-control servicesDropDown'
                                                value={defaultFilterData.shipmentStatus}
                                                name={'shipmentStatus'}
                                                onChange={updateUserInputNum}
                                            >
                                                <option selected value="0" className='optionService'>Select service</option>
                                                {shipmentStatusList && shipmentStatusList.length > 0 && shipmentStatusList.map((item, index) => (
                                                    <option className='optionService' id={item.id} key={item.id} value={item.id}>{item.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-sm-12 mobMb-10">
                    <div className='row'>
                        <div className='col-12'>
                            <label className='labelHead'></label>
                        </div>

                        <div className='col-12 d-flex'>
                            <button
                                className="btn cus-primary-transparent-btn top-btn"
                                onClick={() => handleReset()}
                            >
                                Clear Filter
                            </button>
                            <button
                                className="cus-seconday-bg-btn btn"
                                onClick={handleFilter}
                                disabled={historyRecordStatus === '' || historyRecordStatus === 'pending'}
                            >
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HistoryFilter;
import {
  CREATE_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_SUCCESS,
  GET_EMPLOYEE_FAIL,
  DELETE_EMPLOYEE_SUCCESS,
  BACKDETAILS,
  PRODUCT_BACKDETAILS,
  INBOUND_BACKDETAILS,
  MARGIN_CONFIG_BACKDETAILS,
} from "../types";

const initialState = {
  EmployeeList: [],
  loading: true,
  error: {},
  prevState: {},
  productPrevState: {},
  inboundPrevState: {},
  marginConfgPrevData: {}
};
export default function (state = initialState, action = '') {
  const { type, payload } = action;
  switch (type) {
    case GET_EMPLOYEE_SUCCESS:
      return {
        ...state,
        EmployeeList: payload?.Employee,
        isLoading: false
      };
    case GET_EMPLOYEE_FAIL:
      return {
        ...state
      };
    case CREATE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        EmployeeList: [...state.EmployeeList, payload.Employee]
      };
    case DELETE_EMPLOYEE_SUCCESS:
      return {
        ...state,
        EmployeeList: [
          ...state.EmployeeList.filter(emp => emp != payload.Employee)
        ]
      }
    case BACKDETAILS:
      return {
        ...state,
        prevState: payload
      }
    case PRODUCT_BACKDETAILS:
      return {
        ...state,
        productPrevState: payload
      }
    case INBOUND_BACKDETAILS:
      return {
        ...state,
        inboundPrevState: payload
      }
    case MARGIN_CONFIG_BACKDETAILS:
      return {
        ...state,
        marginConfgPrevData: payload
      }
    default:
      return state;
  }
}


import React, { useEffect, useState } from "react";
import Select from "react-select";
import { getSelectList } from "../../config/utils";
import {
  getAllConsignmentCarrierType,
  getAllConsignmentOutboundStatus,
  getAllConsignmentType,
} from "../../store/actions/inventory.action";
import { useSelector } from "react-redux";
import { fetchCompanyFilters, getFilterSellers } from "../../store/actions/rateCard";
import { fetchAllMarketPlaces } from "../../store/actions/marketplace.action";

const FilterOutbound = (props) => {
  const {
    showFilter,
    handleReset,
    handleFilter,
    dispatch,
    filterData,
    handleMultiSelect,
    sellerList,
    setSellerList,
    setAllSellers,
  } = props;
  const [allCarriers, setAllCarriers] = useState([]);
  const [consignmentList, setConsignmentList] = useState([]);
  const [consignmentStatusList, setConsignmentStatusList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const { consignmentTypes, marketPlacesList } = useSelector(
    (state) => state.InventoryManagement
  );
  const { consignmentAllOutboundStatus } = useSelector(
    (state) => state.InventoryOutboundManagement
  );
  const { filterCompanies, filterAllSellers } = useSelector((state) => state.RateCardReducer);
  const UserValue = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (!marketPlacesList.length) {
      dispatch(fetchAllMarketPlaces());
    }
  }, [marketPlacesList]);

  useEffect(() => {
    let companyList = filterCompanies?.Entity?.listCompanyMasterOrdersEntity || [];
    let sellerList = filterAllSellers?.EntityList || [];
    if (companyList.length) {
      setCompanyList(getSelectList(filterCompanies?.Entity?.listCompanyMasterOrdersEntity, 'Id', 'name'));
    }
    if (sellerList.length) {
      setAllSellers(getSelectList(sellerList, 'id', 'sellerName'));
      setSellerList(getSelectList(sellerList, 'id', 'sellerName'));
    }
  }, [filterCompanies, filterAllSellers]);

  const fetchCarriers = () => {
    getAllConsignmentCarrierType().then((res) => {
      if (res.data.Status === 200) {
        let carriersData = getSelectList(
          res.data.EntityList,
          "id",
          "carrierName"
        );
        setAllCarriers(carriersData);
      }
    });
  };

  useEffect(() => {
    if (!consignmentTypes.length) {
      dispatch(getAllConsignmentType());
    } else {
      let consignmentData = getSelectList(consignmentTypes, "id", "name");
      setConsignmentList(consignmentData);
    }
  }, [consignmentTypes]);

  useEffect(() => {
    if (!consignmentAllOutboundStatus?.length) {
      dispatch(getAllConsignmentOutboundStatus());
    } else {
      let selectConsignmentStatusData = getSelectList(
        consignmentAllOutboundStatus,
        "id",
        "name"
      );
      setConsignmentStatusList(selectConsignmentStatusData);
    }
  }, [consignmentAllOutboundStatus]);

  useEffect(() => {
    fetchCarriers();
    let data = {
      pageIndex: 1,
      pageSize: 10,
      sortColumn: 'name',
      sortOrder: 'ascending'
    };
    let sellerData = {
      "pageIndex": 1,
      "pageSize": 10,
      "sortColumn": "name",
      "sortOrder": "ASCENDING",
      "companyIdList": [0],
      screenType: 2
    };
    dispatch(fetchCompanyFilters(data));
    dispatch(getFilterSellers(sellerData));
  }, []);

  return (
    <div
      className={
        showFilter
          ? "filterContainer show historyFilter"
          : "filterContainer historyFilter"
      }
    >
      <div className="row mb10 mb-res">
        <div className='col-sm-12 col-lg-3 mobMb-10'>
          <div className='row'>
            <div className='col-12'>
              <label className='labelHead'>Outbound Id</label>
            </div>
            <div className='col-12'>
              <input
                type="text"
                className='form-control searchText outboundNumber'
                value={filterData.outboundNumber}
                onChange={(e) => handleMultiSelect(e.target.value, "outboundNumber")}
                placeholder="Outbound Id"
              />
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-3 mobMb-10">
          <div className="row">
            <div className="col-12">
              <label className="labelHead">SKU</label>
            </div>
            <div className="col-12">
              <input
                type="text"
                className="form-control searchText sku"
                value={filterData?.productSku}
                onChange={(e) =>
                  handleMultiSelect(e.target.value, "productSku")
                }
                placeholder="Enter Product SKU"
              />
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-3 mobMb-10">
          <div className="row">
            <div className="col-12">
              <label className="labelHead">Name</label>
            </div>
            <div className="col-12">
              <input
                type="text"
                className="form-control searchText name"
                value={filterData?.name}
                placeholder="Enter Name"
                onChange={(e) => handleMultiSelect(e.target.value, "name")}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-3 mobMb-10 fontFix">
          <div className="row">
            <div className="col-12">
              <label className="labelHead">Consignment Type</label>
            </div>
            <div className="col-12">
              <Select
                isMulti
                closeMenuOnSelect={false}
                options={consignmentList}
                classNamePrefix="listType"
                id="consignmentTypeIdList"
                name="consignmentTypeIdList"
                value={filterData?.consignmentTypeIdList}
                onChange={(value) =>
                  handleMultiSelect(value, "consignmentTypeIdList")
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row mb10 mb-res">
        <div className="col-sm-12 col-lg-3 mobMb-10">
          <div className="row">
            <div className="col-12">
              <label className="labelHead">Carrier</label>
            </div>
            <div className="col-12 fontFix">
              <Select
                closeMenuOnSelect={false}
                isMulti
                options={allCarriers}
                classNamePrefix="listCarrier"
                name="carrierIdList"
                id="carrierIdList"
                value={filterData.carrierIdList}
                onChange={(value) => handleMultiSelect(value, "carrierIdList")}
              />
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-3 mobMb-10">
          <div className="row">
            <div className="col-12">
              <label className="labelHead">Tracking Number</label>
            </div>
            <div className="col-12">
              <input
                type="text"
                className="form-control searchText trackingNumber"
                value={filterData.trackingNumber}
                placeholder="Enter Tracking Number"
                onChange={(e) =>
                  handleMultiSelect(e.target.value, "trackingNumber")
                }
              />
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-3 mobMb-10">
          <div className="row">
            <div className="col-12">
              <label className="labelHead">Status</label>
            </div>
            <div className="col-12 fontFix">
              <Select
                closeMenuOnSelect={false}
                isMulti
                options={consignmentStatusList}
                classNamePrefix="listStatus"
                id="consignmentStatusIdList"
                name="consignmentStatusIdList"
                value={filterData?.consignmentStatusIdList}
                onChange={(value) =>
                  handleMultiSelect(value, "consignmentStatusIdList")
                }
              />
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-lg-3 mobMb-10 mb10">
          <div className="row">
            <div className="col-12">
              <label className="labelHead">Marketplace</label>
            </div>
            <div className="col-12 fontFix">
              <Select
                closeMenuOnSelect={false}
                isMulti
                options={marketPlacesList}
                classNamePrefix="listStatus"
                id="marketPlace"
                name="marketPlace"
                value={filterData?.marketPlace}
                onChange={(value) =>
                  handleMultiSelect(value, "marketPlaceIdList")
                }
              />
            </div>
          </div>
        </div>
        {UserValue?.Value?.roleName !== 'Seller' && (
          <>
            <div className="col-sm-12 col-lg-3 mobMb-10">
              <div className="row">
                <div className="col-12">
                  <label className="labelHead">Company Name</label>
                </div>
                <div className="col-12 fontFix">
                  <Select
                    closeMenuOnSelect={false}
                    isMulti
                    options={companyList}
                    classNamePrefix="listStatus"
                    id="consignmentCompanyIdList"
                    name="consignmentComapanyIdList"
                    value={filterData?.consignmentCompanyIdList}
                    onChange={(value) =>
                      handleMultiSelect(value, "consignmentCompanyIdList")
                    }
                  />
                </div>
              </div>
            </div>

            <div className="col-sm-12 col-lg-3 mobMb-10">
              <div className="row">
                <div className="col-12">
                  <label className="labelHead">Seller Name</label>
                </div>
                <div className="col-12 fontFix">
                  <Select
                    closeMenuOnSelect={false}
                    isMulti
                    options={sellerList}
                    classNamePrefix="listStatus"
                    id="consignmentSellerIdList"
                    name="consignmentSellerIdList"
                    value={filterData?.consignmentSellerIdList}
                    onChange={(value) =>
                      handleMultiSelect(value, "consignmentSellerIdList")
                    }
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="row mb-res">
        <div className="col-sm-12 col-lg-6 flexOut-mob-res">
          <button
            className="btn cus-primary-transparent-btn top-btn"
            onClick={(e) => handleReset(e, "clear")}
          >
            Clear Filter
          </button>
          <button
            className="cus-seconday-bg-btn btn filterBtn"
            onClick={() => handleFilter(true)}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterOutbound;

import React, { useEffect, useState } from 'react';
import AccordionList from '../common/AccordionList';
import { Button } from "bootstrap-4-react/lib/components";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LoaderSpinner from "../common/Loader";
import { useLocation, useNavigate, useParams } from 'react-router';
import MarginCarrierFilter from '../MarginConfiguration/CarrierFilter';
import { useDispatch, useSelector } from 'react-redux';
import { fetchServicesByCompanySellerId, updateServiceStatus } from '../../store/actions';
import _ from 'lodash';
import { toast } from "react-toastify";

const SellerCarrierList = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [showFilter, setShowFilter] = useState(false);
    const dispatch = useDispatch();
    const location = useLocation();
    const companyDetails = location?.state?.data;
    const edit = location?.state?.editable;
    const [editable, setEditable] = React.useState(edit);
    const [carrierData, setCarrierData] = React.useState([]);
    const [isFormEdited, setIsFormEdited] = useState(false);
    const { carrierServicesStatus, carrierServices } = useSelector((state) => state.MarginReducer);
    const userDetails = useSelector((state) => state.auth.user);

    let payloadDataObj = {
        filterCarrierCompanyIds: [],
        filterCarrierServiceIds: [],
        filtercompanySellerId: Number(id)
    };
    const [payloadData, setPayloadData] = useState(payloadDataObj);

    useEffect(() => {
        if (carrierServices?.Entity) {
            setCarrierData(carrierServices.Entity);
        }
    }, [carrierServices]);

    useEffect(() => {
        fetchCarrierServices();
    }, []);

    const fetchCarrierServices = () => {
        dispatch(fetchServicesByCompanySellerId(payloadData))
    }

    const handleStatusChange = (status, index, serviceIndex) => {
        let data = [...carrierData];
        data[index].services[serviceIndex].isActive = status;
        data[index].services[serviceIndex].isModified = true;
        setIsFormEdited(true);
        setCarrierData(data);
    }

    const handleFilter = () => {
        let data = { ...payloadData };
        data.filterCarrierCompanyIds = _.map(data.filterCarrierCompanyIds, "id");
        data.filterCarrierServiceIds = _.map(data.filterCarrierServiceIds, "id");
        dispatch(fetchServicesByCompanySellerId(data));
    }

    const handleFilterValue = (value, type) => {
        setPayloadData({ ...payloadData, [type]: value });
    }

    const handleResetFilter = () => {
        let data = { ...payloadData };
        data.filterCarrierCompanyIds = [];
        data.filterCarrierServiceIds = [];
        setPayloadData(data);
        dispatch(fetchServicesByCompanySellerId(data));
    }

    const handleSave = async () => {
        const filteredServices = carrierData.flatMap(item =>
            item.services.filter(service => service.isModified === true)
        );
        const updatePayload = filteredServices.map(item => ({ ...item, companySellerId: Number(id) }));
        let response = await Promise.resolve(dispatch(updateServiceStatus(updatePayload)));
        if (response.data.Status === 200) {
            toast.success('Carrier Service Updated Successfully!');
            navigate("/manage-carriers");
        }
    }

    return (
        <main>
            <div className="main__container">
                <div className="main__tile_heading cus-btn-include-sec">
                    <div>Company and Seller details</div>
                    <div className="top_right_button_container">
                        <Button
                            className="cus-seconday-bg-btn top-btn"
                            onClick={() => setShowFilter(!showFilter)}
                        >
                            <i className="fa fa-filter" aria-hidden="true"></i> Filter
                        </Button>
                        <button
                            className="btn cus-seconday-bg-btn top-btn editBtn"
                            onClick={() => setEditable(true)}
                            disabled={editable}
                        >
                            Edit
                        </button>
                        <button
                            className="btn cus-seconday-bg-btn top-btn"
                            onClick={handleSave}
                            disabled={!isFormEdited}
                        >
                            Save
                        </button>
                        <Button
                            className="cus-primary-transparent-btn"
                            onClick={() => {
                                navigate("/manage-carriers");
                            }}
                        >
                            Back
                        </Button>

                    </div>
                </div>
                {carrierServicesStatus === 'pending' && <LoaderSpinner />}
                <div className="mb-3">
                    <Row>
                        <Col xs={3}>
                            <div className="input-label-Created-date">
                                Company Name : {companyDetails?.companyName}
                            </div>
                        </Col>

                        <Col xs={3}>
                            <div className="input-label-Created-date">
                                Seller Name : {companyDetails?.sellerName}
                            </div>
                        </Col>
                    </Row>
                </div>

                <MarginCarrierFilter
                    showFilter={showFilter}
                    userDetails={userDetails}
                    handleResetFilter={handleResetFilter}
                    handleFilter={handleFilter}
                    payloadData={payloadData}
                    setPayloadData={setPayloadData}
                    handleFilterValue={handleFilterValue}
                />

                <div className="cus-page-content-sec">
                    <div className="top_right_button_container cus-content-only-sec">
                        <div className="incoming_wrapper main_content_table incomingTbl">
                            <div className='pl-3 pr-3'>
                                <AccordionList
                                    userDetails={userDetails}
                                    handleStatusChange={handleStatusChange}
                                    data={carrierData}
                                    edit={editable}
                                    isSeller={true}
                                />
                                {carrierData.length === 0 && carrierServicesStatus === 'success' && (
                                    <div className='alignCenter'>No Record Found</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default SellerCarrierList;
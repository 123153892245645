import {
  CREATE_RATETYPECATEGORY_SUCCESS,
  CREATE_RATETYPECATEGORY_FAIL,
  GET_RATETYPECATEGORY_SUCCESS,
  DELETE_RATETYPECATEGORY_SUCCESS,
  GET_RATETYPECATEGORY_BY_RATETYPEID_SUCCESS
} from "../types";
const initialState = {
  ratetypecategoryList: [],
  loading: true,
  error: {}
};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_RATETYPECATEGORY_SUCCESS:
      return {
        ...state,
        ratetypecategoryList: payload.ratetypecategory,
        isLoading: false
      };
    case GET_RATETYPECATEGORY_BY_RATETYPEID_SUCCESS:
      return {
        ...state,
        ratetypecategoryList: payload.ratetypecategory,
        isLoading: false
      }
    case CREATE_RATETYPECATEGORY_SUCCESS:
      return {
        ...state,
        ratetypecategoryList: [...state.ratetypecategoryList, payload.ratetypecategory]
      };
    case CREATE_RATETYPECATEGORY_FAIL:
      return {
        ...state
      };
    case DELETE_RATETYPECATEGORY_SUCCESS:
      return {
        ...state,
        ratetypecategoryList: [
          ...state.ratetypecategoryList.filter(ratetypecategory => ratetypecategory != payload.ratetypecategory)
        ]
      }
    default:
      return state;
  }
}
import React from 'react';

const SearchProduct = (props) => {
    const { showSearch, searchTxt, setSearchTxt, handleReset, handleSearchFilter, placeHolderText } = props;

    const handleEnterClick = (e) => {
        if (e.key === "Enter") {
            handleSearchFilter();
        }
    };

    return (
        <div
            className={
                showSearch
                    ? "filterContainer show searchBox mb-3"
                    : "filterContainer searchBox"
            }
        >
            <div className="searchInputBox">
                <input
                    value={searchTxt}
                    onChange={(e) => setSearchTxt(e.target.value)}
                    className="form-control searchInput"
                    placeholder={placeHolderText}
                    onBlur={(e) => setSearchTxt(e.target.value.trim())}
                    onKeyDown={handleEnterClick}
                    autoFocus={true}
                />
            </div>
            <button disabled={!searchTxt} className="btn cus-primary-transparent-btn clrBtn" onClick={(e) => handleReset(e, 'clear')}>Reset</button>
            <button
                className="btn cus-seconday-bg-btn marginLeft5 searchBtn"
                onClick={handleSearchFilter}
            >
                <i className="fa fa-search" aria-hidden="true"></i> Search
            </button>
        </div>
    );
}

export default SearchProduct;
import axios from "axios";
import { apiUrl } from "../../constant";
import {
    ProductListFileUploadService,
    areaByWarehouseIdService,
    bayByWarehouseIdService,
    createInventoryProductService,
    getActiveIntegrationsService,
    getAllConsignmentStatusService,
    getAllConsignmentTypesService,
    getConsignmentListingService,
    getInventoryProductListingService,
    getProductByIdService,
    getWarehouseListingService,
    levelByWarehouseIdService,
    positionByWarehouseIdService,
    rowByWarehouseIdService,
    updateInventoryProductService,
    uploadProductMediaService,
    getProductListTemplateUrlService,
    getInboundByIdService,
    getProductHisotryService,
    getProductInventoryService,
    updateConsoleService,
    createConsoleService,
    updateConsignmentMediaService,
    getConsignmentElementByIdService,
    createConsignmentElementService,
    updateConsignmentElementService,
    deleteConsignmentService,
    getInboundHisotryService,
    fetchAllConsignmentElementStatusService,
    getConsignmentOutboundListingService,
    getAllConsignmentOutboundStatusService,
    createOutboundService,
    updateOutboundService,
    updateOutboundImageService,
    updateOutboundFilesService,
    getOutboundByIdService,
    getOutboundHisotryService,
    updateOutboundLabel,
    getTrackingNumberFromPdfService,
    updateOutboundStatusService,
    searchProductBySkuService,
    outboundBulkUploadService,
    outboundBulkUploadSampleTemplateService,
    getWarehouseBySellerIdService,
} from "../../services/inventory.service";
import { InventoryConstant } from "../constants/inventory.constant";
import { getSelectList } from "../../config/utils";

const getInventoryProductListPending = () => ({
    type: InventoryConstant.INVENTORY_PRODUCT_LISTING_PENDING,
});
const getInventoryProductListFailed = () => ({
    type: InventoryConstant.INVENTORY_PRODUCT_LISTING_FAILED,
});
const getInventoryProductListSuccess = (response) => ({
    type: InventoryConstant.INVENTORY_PRODUCT_LISTING_SUCCESS,
    payload: response,
});

export const getInventoryProductList = (data) => {
    return (dispatch) => {
        dispatch(getInventoryProductListPending());
        getInventoryProductListingService(data)
            .then((result) => {
                dispatch(getInventoryProductListSuccess(result.data.Entity));
            })
            .catch(() => {
                dispatch(getInventoryProductListFailed());
            });
    };
};

const getProductByIdPending = () => ({
    type: InventoryConstant.GET_BY_PRODUCT_ID_PENDING,
});
const getProductByIdFailed = () => ({
    type: InventoryConstant.GET_BY_PRODUCT_ID_FAILED,
});
const getProductByIdSuccess = (response) => ({
    type: InventoryConstant.GET_BY_PRODUCT_ID_SUCCESS,
    payload: response,
});

export const getProductById = (productId) => {
    return (dispatch) => {
        dispatch(getProductByIdPending());
        getProductByIdService(productId)
            .then((res) => {
                dispatch(getProductByIdSuccess(res.data.Entity));
            })
            .catch(() => {
                dispatch(getProductByIdFailed());
            });
    };
};

export const getProductByIdResponse = (productId) => {
    return () => {
        return getProductByIdService(productId);
    };
}

export const updateInventoryProduct = (data) => {
    return () => {
        return updateInventoryProductService(data);
    };
};

export const createInventoryProduct = (data) => {
    return () => {
        return createInventoryProductService(data);
    };
};

export const uploadProductMedia = (data) => {
    return () => {
        return uploadProductMediaService(data);
    };
};

// Get inbound listing

const getConsignmentPending = () => ({
    type: InventoryConstant.GET_CONSIGNMENT_PENDING,
});
const getConsignmentFailed = () => ({
    type: InventoryConstant.GET_CONSIGNMENT_FAILED,
});
const getConsignmentSuccess = (response) => ({
    type: InventoryConstant.GET_CONSIGNMENT_SUCCESS,
    payload: response,
});

export const getConsignmentListing = (data) => {
    return (dispatch) => {
        dispatch(getConsignmentPending());
        getConsignmentListingService(data)
            .then((res) => {
                dispatch(getConsignmentSuccess(res.data.Entity));
            })
            .catch(() => {
                dispatch(getConsignmentFailed());
            });
    };
};

const getConsignmentOutboundPending = () => ({
    type: InventoryConstant.INVENTORY_OUTBOUND_LISTING_PENDING,
});
const getConsignmentOutboundFailed = () => ({
    type: InventoryConstant.INVENTORY_OUTBOUND_LISTING_FAILED,
});
const getConsignmentOutboundSuccess = (response) => ({
    type: InventoryConstant.INVENTORY_OUTBOUND_LISTING_SUCCESS,
    payload: response,
});

export const getConsignmentOutboundListing = (data) => {
    return (dispatch) => {
        dispatch(getConsignmentOutboundPending());
        getConsignmentOutboundListingService(data)
            .then((res) => {
                dispatch(getConsignmentOutboundSuccess(res.data.Entity));
            })
            .catch(() => {
                dispatch(getConsignmentOutboundFailed());
            });
    };
};
const getActiveIntegrationsPending = () => ({
    type: InventoryConstant.GET_ACTIVEINTEGRATION_PENDING,
});
const getActiveIntegrationsFailed = () => ({
    type: InventoryConstant.GET_ACTIVEINTEGRATION_FAILED,
});
const getActiveIntegrationsSuccess = (response) => ({
    type: InventoryConstant.GET_ACTIVEINTEGRATION_SUCCESS,
    payload: response,
});

export const getActiveIntegrations = () => {
    return (dispatch) => {
        dispatch(getActiveIntegrationsPending());
        getActiveIntegrationsService()
            .then((result) => {
                dispatch(getActiveIntegrationsSuccess(result.data));
            })
            .catch((error) => {
                dispatch(getActiveIntegrationsFailed());
            });
    };
};


const getAllConsignmentTypeSuccess = (response) => ({
    type: InventoryConstant.GET_ALL_CONSIGNMENT_TYPE_SUCCESS,
    payload: response,
});

export const getAllConsignmentType = () => {
    return (dispatch) => {
        getAllConsignmentTypesService().then((res) => {
            dispatch(getAllConsignmentTypeSuccess(res.data.EntityList));
        }).catch((err) => {
            console.log(err)
        });
    };
};

const getAllConsignmentStatusSuccess = (response) => ({
    type: InventoryConstant.GET_ALL_CONSIGNMENT_STATUS_SUCCESS,
    payload: response,
});

export const getAllConsignmentStatus = () => {
    return (dispatch) => {
        getAllConsignmentStatusService().then((res) => {
            dispatch(getAllConsignmentStatusSuccess(res.data.EntityList));
        }).catch((err) => {
            console.log(err)
        });
    };
};

const getAllConsignmentOutboundStatusSuccess = (response) => ({
    type: InventoryConstant.GET_ALL_CONSIGNMENT_OUTBOUND_STATUS_SUCCESS,
    payload: response,
});

export const getAllConsignmentOutboundStatus = () => {
    return (dispatch) => {
        getAllConsignmentOutboundStatusService()
            .then((res) => {
                dispatch(getAllConsignmentOutboundStatusSuccess(res.data.EntityList));
            })
            .catch((err) => {
                console.log(err);
            });
    };
};
const getAllWarehouseListingSuccess = (response) => ({
    type: InventoryConstant.GET_ALL_WAREHOUSE_LIST_SUCCESS,
    payload: response,
});

export const getWarehouseListing = (data) => {
    return (dispatch) => {
        getWarehouseListingService(data).then((res) => {
            let createList = getSelectList(res.data.Entity.listWarehouse, 'id', 'name');
            dispatch(getAllWarehouseListingSuccess(createList));
        }).catch((err) => {
            console.log(err)
        });
    };
};


export const getAreaByWarehouseId = (data) => {
    return () => {
        return areaByWarehouseIdService(data);
    };
};

export const getRowByWarehouseId = (data) => {
    return () => {
        return rowByWarehouseIdService(data);
    };
};

export const getBayByWarehouseId = (data) => {
    return () => {
        return bayByWarehouseIdService(data);
    };
};

export const getLevelByWarehouseId = (data) => {
    return () => {
        return levelByWarehouseIdService(data);
    };
};

export const getPositionByWarehouseId = (data) => {
    return () => {
        return positionByWarehouseIdService(data);
    };
};

export const getInboundById = (inboundId) => {
    return () => {
        return getInboundByIdService(inboundId);
    };
};

export const getOutboundById = (outboundId) => {
    return () => {
        return getOutboundByIdService(outboundId);
    };
};

export const ProductListFileUpload = (formdata) => {
    return () => {
        return ProductListFileUploadService(formdata);
    };
};

export const getProductListTemplateUrl = (downloadParams) => {
    return () => {
        return getProductListTemplateUrlService(downloadParams);
    };
};


const emptyProductDetails = () => ({
    type: InventoryConstant.EMPTYPRODUCT_DETAILS
});

export const resetProductDetails = () => {
    return (dispatch) => {
        return dispatch(emptyProductDetails())
    };
};


const getProductHistoryByIdPending = () => ({
    type: InventoryConstant.INVENTORY_PRODUCT_HISTORY_PENDING,
});
const getProductHistoryByIdFailed = () => ({
    type: InventoryConstant.INVENTORY_PRODUCT_HISTORY_FAILED,
});

const getProductHistoryByIdSuccess = (response) => ({
    type: InventoryConstant.INVENTORY_PRODUCT_HISTORY_SUCCESS,
    payload: response,
});

export const getProductHistoryById = (product) => {
    return (dispatch) => {
        dispatch(getProductHistoryByIdPending());
        getProductHisotryService(product).then((res) => {
            dispatch(getProductHistoryByIdSuccess(res.data.Entity));
        }).catch((err) => {
            dispatch(getProductHistoryByIdFailed())
            console.log(err);
        });
    };
};

const getInboundHistoryByIdPending = () => ({
    type: InventoryConstant.INVENTORY_INBOUND_HISTORY_PENDING,
});
const getInboundHistoryByIdFailed = () => ({
    type: InventoryConstant.INVENTORY_INBOUND_HISTORY_FAILED,
});

const getInboundHistoryByIdSuccess = (response) => ({
    type: InventoryConstant.INVENTORY_INBOUND_HISTORY_SUCCESS,
    payload: response,
});

export const getInboundHistoryById = (product) => {
    return (dispatch) => {
        dispatch(getInboundHistoryByIdPending());
        getInboundHisotryService(product)
            .then((res) => {
                dispatch(getInboundHistoryByIdSuccess(res.data.Entity));
            })
            .catch((err) => {
                dispatch(getInboundHistoryByIdFailed());
                console.log(err);
            });
    };
};

const getProductInventoryByIdPending = () => ({
    type: InventoryConstant.INVENTORY_PRODUCT_INVENTORY_PENDING,
});

const getProductInventoryByIdFailed = () => ({
    type: InventoryConstant.INVENTORY_PRODUCT_INVENTORY_FAILED,
});

const getProductInventoryByIdSuccess = (response) => ({
    type: InventoryConstant.INVENTORY_PRODUCT_INVENTORY_SUCCESS,
    payload: response,
});

export const getProductInventoryById = (product) => {
    return (dispatch) => {
        dispatch(getProductInventoryByIdPending());
        getProductInventoryService(product)
            .then((res) => {
                dispatch(getProductInventoryByIdSuccess(res.data.Entity));
            })
            .catch((err) => {
                dispatch(getProductInventoryByIdFailed());
                console.log(err);
            });
    };
};

export const updateConsoleById = (data) => {
    return () => {
        return updateConsoleService(data);
    };
};

export const createConsole = (data) => {
    return () => {
        return createConsoleService(data);
    };
};

export const createOutbound = (data) => {
    return () => {
        return createOutboundService(data);
    };
};

export const updateOutbound = (data) => {
    return () => {
        return updateOutboundService(data);
    };
};

export const updateConsignmentMedia = (data) => {
    return () => {
        return updateConsignmentMediaService(data);
    };
};

export const updateOutboundImage = (data) => {
    return () => {
        return updateOutboundImageService(data);
    };
};

export const updateOutboundFiles = (data) => {
    return () => {
        return updateOutboundFilesService(data);
    };
};

export const getConsignmentElementById = (id) => {
    return () => {
        return getConsignmentElementByIdService(id);
    };
};

export const createConsignmentElement = (data) => {
    return () => {
        return createConsignmentElementService(data);
    };
};

export const updateConsignmentElementAction = (data) => {
    return () => {
        return updateConsignmentElementService(data);
    };
};

export const deleteConsignments = (data) => {
    return () => {
        return deleteConsignmentService(data);
    };
};

export const getInboundDefaultValues = () => {
    return axios.get(`${apiUrl}/api/InventoryManagement/DefaultValues`);
};

export const getAllConsignmentCarrierType = () => {
    return axios.get(`${apiUrl}/api/Consignment/GetAllConsignmentCarrierType`);
};

export const deleteWarehouseLocation = (data) => {
    return axios.post(`${apiUrl}/api/InventoryManagement/api/DeleteWarehouseLocation`, data);
};

export const getConsignmentStatusListById = (id) => {
    return axios.get(`${apiUrl}/api/Consignment/ConsignmentElementStatusListById?id=${id}`);
};

const getAllConsignmentElementStatusSuccess = (response) => ({
    type: InventoryConstant.GET_ALL_CONSIGNMENT_STATUS_LIST_SUCCESS,
    payload: response,
});

export const fetchAllConsignmentElementStatus = () => {
    return (dispatch) => {
        fetchAllConsignmentElementStatusService().then((res) => {
            dispatch(getAllConsignmentElementStatusSuccess(res.data.EntityList));
        }).catch((err) => {
            console.log(err)
        });
    };
};

export const updateAttachmentsByProductsId = (data) => {
    return axios.put(`${apiUrl}/api/InventoryManagement/update-attachments`, data);
};

export const updateOutboundProductImage = (data) => {
    return axios.put(`${apiUrl}/api/Consignment/update-outbound-product-image`, data);
};

export const updateOutboundProductDoc = (data) => {
    return axios.put(`${apiUrl}/api/Consignment/update-outbound-product-file`, data);
};

export const checkSkuExists = (data) => {
    return axios.post(`${apiUrl}/api/Consignment/SkuExists`, data);
};

export const getQuantityBySku = (data) => {
    return axios.post(`${apiUrl}/api/Consignment/GetQuantity`, data);
};

const getOutboundHistoryByIdPending = () => ({
    type: InventoryConstant.INVENTORY_OUTBOUND_HISTORY_PENDING,
});
const getOutboundHistoryByIdFailed = () => ({
    type: InventoryConstant.INVENTORY_OUTBOUND_HISTORY_FAILED,
});

const getOutboundHistoryByIdSuccess = (response) => ({
    type: InventoryConstant.INVENTORY_OUTBOUND_HISTORY_SUCCESS,
    payload: response,
});

export const getOutboundHistoryById = (product) => {
    return (dispatch) => {
        dispatch(getOutboundHistoryByIdPending());
        getOutboundHisotryService(product).then((res) => {
            dispatch(getOutboundHistoryByIdSuccess(res.data.Entity));
        }).catch((err) => {
            dispatch(getOutboundHistoryByIdFailed())
            console.log(err);
        });
    };
};

export const updateOutboundLabelAction = (data) => {
    return () => {
        return updateOutboundLabel(data);
    };
};

export const getTrackingNumberFromPdfAction = (data) => {
    return () => {
        return getTrackingNumberFromPdfService(data);
    };
};

export const updateOutboundStatus = (data) => {
    return () => {
        return updateOutboundStatusService(data);
    };
};

export const searchProductBySku = (data) => {
    return () => {
        return searchProductBySkuService(data);
    };
};

export const outboundBulkUpload = (data) => {
    return () => {
        return outboundBulkUploadService(data);
    };
};

export const outboundBulkUploadSampleTemplate = () => {
    return axios.get(`${apiUrl}/api/Consignment/GetBulkOutboundTemplate`);
};

const getWarehouseBySellerIdSuccess = (response) => ({
    type: InventoryConstant.GET_WAREHOUSE_BY_SELLER_ID_SUCCESS,
    payload: response,
});

export const getWarehouseBySellerId = (data) => {
    return (dispatch) => {
        getWarehouseBySellerIdService(data).then((res) => {
            let createList = getSelectList(res.data.Entity.listWarehouse, 'id', 'name');
            dispatch(getWarehouseBySellerIdSuccess(createList));
        }).catch((err) => {
            console.log(err)
        });
    };
};
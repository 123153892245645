import {
  GET_ALL_FUNDS,
  GET_SINGLE_FUNDS,
  GET_SINGLE_FUND_DETAILS,
  GET_WALLET_FUND_DETAILS,
  GET_SINGLE_FUNDS_DATA,
  GET_SELLERS,
  GET_WALLET_BALANCE,
  GET_WALLET_FUND,
  EMPTY_TRANSACTION_HISTORY,
  GET_ALL_FUNDS_STATUS_PENDING,
  GET_ALL_FUNDS_STATUS_SUCCESS,
  FUND_MANAGEMENT_BACK_DETAILS,
} from "../types";
const initialState = {
  activeSeller: [],
  activeSellerStatus: '',
  getAllFundDetails: {},
  getAllFundDetailStatus: '',
  fundManagementprevState: {},

};
const FundManagementReducer = (state = initialState, { type, payload } = []) => {
  switch (type) {
    case GET_ALL_FUNDS_STATUS_PENDING:
      return {
        ...state,
        getAllFundDetailStatus: 'PENDING',
      };
    case GET_ALL_FUNDS_STATUS_SUCCESS:
      return {
        ...state,
        getAllFundDetailStatus: 'SUCCESS',
      };
    case GET_ALL_FUNDS:
      return {
        ...state,
        getAllFundDetails: payload,
      };

    case GET_SINGLE_FUNDS:
      return {
        ...state,
        getsingleFundDetails: payload,

      };
    case GET_WALLET_FUND:
      return {
        ...state,
        getWalletFund: payload,

      };
    case GET_WALLET_BALANCE:
      return {
        ...state,
        getSellerWalletBalance: payload,

      };
    case GET_SELLERS:
      return {
        ...state,
        getSellersDetail: payload,
      };
    case GET_SINGLE_FUNDS_DATA:
      return {
        ...state,
        getsingleFundData: payload,
      };
    case GET_SINGLE_FUND_DETAILS:
      return {
        ...state,
        getTransactionDetails: payload,
      };
    case GET_WALLET_FUND_DETAILS:
      return {
        ...state,
        getWalletAmount: payload,
      };
    case EMPTY_TRANSACTION_HISTORY:
      return {
        ...state,
        getTransactionDetails: {},
      };
    case FUND_MANAGEMENT_BACK_DETAILS:
      return {
        ...state,
        fundManagementprevState: payload,
      };
    default:
      return state;
  }
}

export default FundManagementReducer;



import React from 'react';

const Accounts = () => {
    return (
        <main>
            <div className="main__container">
                <div className="main__tile_heading">Accounts</div>
                <div className="container-fluid">
                    <div className="cus-page-content-sec">
                        <div className="content-container">
                            <div className="cards setup-container">

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Accounts;
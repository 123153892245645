import axios from "axios";
import { apiUrl } from "../constant";
import authHeaderWithcontent from "./auth-header-content";

const getEmployeeAddressById = (id, addressType) => {
  return axios.get(
    `${apiUrl}/api/EmployeeAddress/GetEmployeeAddress/${id}/${addressType}`
  );
};
const getSellerAddressByIdService = (sellerId) => {
  return axios.get(`${apiUrl}/api/seller/GetSellerAddressBySellerId/${sellerId}`);
};
const getAllServices = (isInternational, isMilitary) => {
  return axios.get(
    `${apiUrl}/api/Carrier/GetAllCarriers?isInternational=${isInternational}&isMilitary=${isMilitary}`
  );
};
const fetchAllCarriers = (sortData) => {
  return axios.post(`${apiUrl}/api/Carrier/GetAllCarrierCompany`, sortData);
};
const getAllPackagesByServiceId = (serviceId) => {
  return axios.get(`${apiUrl}/api/Carrier/GetAllCarrierPackage/${serviceId}`);
};
const getAllCountries = () => {
  return axios.get(`${apiUrl}/api/Carrier/GetAllCountries`);
};
const createShipment = (shipment) => {
  return axios.post(`${apiUrl}/api/Shipment/CreateUpdateShipment`, shipment, {
    headers: authHeaderWithcontent(),
  });
};
const getAllUnitesStates = (countryId) => {
  return axios.get(
    `${apiUrl}/api/Master/GetStatesByCountry?countryId=${countryId}`
  );
};
const postValidateAddress = (address) => {
  return axios.post(`${apiUrl}/api/Shipment/AddressValidator`, address, {
    headers: authHeaderWithcontent(),
  });
};
const csvActiveIntegration = (data) => {
  return axios.get(`${apiUrl}/api/GetCsvActiveIntegration/${data}`);
};
const uploadCsvFile = (data) => {
  return axios.post(`${apiUrl}/api/FileUpload`, data, {
    headers: authHeaderWithcontent(),
  });
};
const updateSkipDuplicateService = (data) => {
  return axios.post(
    `${apiUrl}/api/UpdateSkipDuplicateAddressCheckFlag?${data}`
  );
};
const deleteOrderService = (data) => {
  return axios.post(`${apiUrl}/api/DeleteOrder`, data);
};
const deleteFileMasterService = (orderId, data) => {
  return axios.post(`${apiUrl}/api/DeleteFileMaster?Id=${orderId}`, data);
};
const getShipmentsService = (data) => {
  return axios.post(`${apiUrl}/api/Shipment/GetShipments`, data);
};
const saveShipmentsService = (data) => {
  return axios.post(`${apiUrl}/api/Shipment/SaveShipment`, data);
};
const deleteIntegrationService = (orderId, data) => {
  return axios.post(`${apiUrl}/api/DeleteIntegration?Id=${orderId}`, data);
};
const getShipmentByIdService = (data) => {
  return axios.get(`${apiUrl}/api/Shipment/LoadShipmentById?${data}`);
};
const updateAddressService = (data) => {
  return axios.post(`${apiUrl}/api/Update`, data);
};
const updateBulkOrderService = (data) => {
  return axios.post(`${apiUrl}/api/UpdateBulkOrder`, data);
};
const getZipZoneRateByServiceTypeService = (data) => {
  return axios.post(`${apiUrl}/api/Shipment/GetZipZoneRateByServiceType`, data);
};
const deleteShipmentService = (data) => {
  return axios.delete(`${apiUrl}/api/Shipment/DeleteShipment?${data}`);
};

const updateCarrierStatusService = (data) => {
  return axios.post(`${apiUrl}/api/Carrier/SetCarrierCompanyActive?${data}`);
};

const getSellerCarrierListService = (sortData) => {
  return axios.post(`${apiUrl}/api/Carrier/GetSellerCarrierDisplay`, sortData);
};

const getSellerCarriersForCompanyService = (data) => {
  return axios.get(`${apiUrl}/api/Carrier/GetSellerCarriersForCompany?${data}`);
};

const updateSellerCarriersService = (data) => {
  return axios.post(`${apiUrl}/api/Carrier/UpdateSellerCarriers`, data);
};

const fetchAllCarrierServices = () => {
  return axios.get(`${apiUrl}/api/Carrier/getallcarrierservices`);
};

const getOrderByIntegrationIdService = (url) => {
  return axios.get(`${apiUrl}/api/GetIntegrationOrderById?${url}`);
};

const getPendingOrdersService = (url) => {
  return axios.get(`${apiUrl}/api/pendingorders?${url}`);
};

const getSignatureListService = (serviceId) => {
  return axios.post(`${apiUrl}/Signature?ServiceId=${serviceId}`);
};

const getAddressFromZipCodeService = (zipCode) => {
  return axios.get(`${apiUrl}/GetZipCode?searchString=${zipCode}`);
};

const getContentTypeListService = (carriercompanyid) => {
  return axios.post(
    `${apiUrl}/api/Shipment/ContentType?carriercompanyid=${carriercompanyid}`
  );
};

const getDimensionUnitService = () => {
  return axios.get(`${apiUrl}/GetDimensionUnit`);
};

const getWeightUnitService = () => {
  return axios.get(`${apiUrl}/GetWeightUnit`);
};

export const shipmentService = {
  getEmployeeAddressById,
  getAllServices,
  getAllPackagesByServiceId,
  getAllCountries,
  createShipment,
  getAllUnitesStates,
  postValidateAddress,
  csvActiveIntegration,
  uploadCsvFile,
  updateSkipDuplicateService,
  deleteOrderService,
  getShipmentsService,
  saveShipmentsService,
  getShipmentByIdService,
  deleteFileMasterService,
  deleteIntegrationService,
  updateAddressService,
  updateBulkOrderService,
  deleteShipmentService,
  getSellerAddressByIdService,
  fetchAllCarriers,
  updateCarrierStatusService,
  getSellerCarrierListService,
  getSellerCarriersForCompanyService,
  updateSellerCarriersService,
  getZipZoneRateByServiceTypeService,
  fetchAllCarrierServices,
  getOrderByIntegrationIdService,
  getPendingOrdersService,
  getSignatureListService,
  getAddressFromZipCodeService,
  getContentTypeListService,
  getDimensionUnitService,
  getWeightUnitService,
};

import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { filterMarketPlace } from "../../store/actions/marketplace.action";


const MarketplaceFilter = (props) => {
    const { showFilter, defaultFilterData, setDefaultFilterData, handleReset, setCurrentPage, filterPayload, saveHistory } = props;
    const dispatch = useDispatch();
    const [integrationValue, setIntegrationValue] = React.useState("");
    const [channelName, setChannelName] = React.useState("");
    const [status, setStatus] = React.useState("");
    const [errors, setErrors] = useState({});
    const listData = useSelector((state) => state.marketplace.listData);


    useEffect(() => {
        saveHistory();
        setTimeout(() => {
        }, 5000);

    }, []);

    const handleFilter = () => {
        if (handleFilterValidation()) {
            setCurrentPage(1);
            let recordObj = { ...defaultFilterData };
            if (integrationValue.length && integrationValue !== '0') {
                recordObj.integration_name_filter = integrationValue;
            } else {
                recordObj.integration_name_filter = "";
            }
            if (channelName.length && channelName !== '0') {
                recordObj.type_filter = channelName;
            } else {
                recordObj.type_filter = "";
            }
            recordObj.active_filter = status;
            recordObj.min_order_quantity = recordObj.min_order_quantity.toString();
            saveHistory(recordObj);
            dispatch(filterMarketPlace(recordObj));
        }
    }

    const handleIntegrationChange = (event) => {
        const { value } = event.target;
        setIntegrationValue(value);
    };

    const handleMarketplaceTypeChange = (event) => {
        const { value } = event.target;
        setChannelName(value);
    };

    const handleStatusChange = (event) => {
        const { value } = event.target;
        setStatus(value);
    };

    const handleNumFieldBlur = (e) => {
        const { value, name } = e.target;
        if (value) {
            setDefaultFilterData({
                ...defaultFilterData,
                [name]: Number(value),
            });
        }
    }

    const updateUserInputNum = (e) => {
        const { value, name } = e.target;
        setDefaultFilterData({
            ...defaultFilterData,
            [name]: value,
        });
        setErrors({});
    }

    const handleDateChange = (date, name) => {
        if (date) {
            let dateSelected = moment(date).utc(true).format('YYYY-MM-DD');
            setDefaultFilterData({
                ...defaultFilterData,
                [name]: dateSelected,
            });
        } else {
            setDefaultFilterData({
                ...defaultFilterData,
                [name]: null,
            });
        }
    }

    const handleFilterValidation = () => {
        let isValidated = true;
        let allErrors = {};
        if (Number(defaultFilterData.min_order_quantity) > Number(defaultFilterData.max_order_quantity)) {
            isValidated = false;
            allErrors.min_order_quantity = 'Min record cannot be greater than max record';
        }

        if (moment(defaultFilterData.date_max).isBefore(moment(defaultFilterData.date_min))) {
            isValidated = false;
            allErrors.date_max = 'date cannot be before integration creation date';
        }
        setErrors(allErrors);
        return isValidated;
    };



    const handleClear = () => {
        setIntegrationValue(0);
        setChannelName(0);
        setStatus(0);
        handleReset(filterPayload);
    }

    return (
        <div className={showFilter ? 'filterContainer show historyFilter' : 'filterContainer historyFilter'}>
            <div className='row mb10'>
                <div className='col-lg-4 col-sm-12 mobMb-10'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='row'>
                                <div className='col-12'>
                                    <label className='labelHead'>Name of Integration</label>
                                </div>
                                <div className='col-12'>
                                    <select
                                        className="form-control"
                                        value={integrationValue}
                                        name="integrationValue"
                                        onChange={(e) => handleIntegrationChange(e)}
                                    >
                                        <option value='0' key='0'>Select Integration</option>
                                        {listData?.unique_integration_names?.map((item) => (
                                            <option
                                                value={item}
                                                key={item}
                                            >
                                                {item}
                                            </option>
                                        ))}
                                    </select>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-sm-12 mobMb-10'>
                    <div className='row'>
                        <div className='col-12'>
                            <label className='labelHead'>Type</label>
                        </div>
                        <div className='col-12'>
                            <select
                                className="form-control"
                                value={channelName}
                                name="channelName"
                                onChange={(e) => handleMarketplaceTypeChange(e)}
                            >
                                <option value='0' key='0'>Select MarketPlace</option>
                                {listData?.unique_types?.map((item) => (
                                    <option
                                        value={item}
                                        key={item}
                                    >
                                        {item}
                                    </option>
                                ))}
                            </select>

                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-sm-12 mobMb-10'>
                    <div className='row'>
                        <div className='col-12'>
                            <label className='labelHead'>Dates
                                {errors.date_max &&
                                    <span className='error filterHisory'> {errors.date_max}</span>
                                }
                            </label>
                        </div>

                        <div className='col-6 fieldAndLabel'>
                            <label>From</label>
                            <div className='inputHistory'>
                                <DatePicker
                                    className="date-picker form-control"
                                    showIcon
                                    dateFormat="MM-dd-yyyy"
                                    autoComplete='one-time-code'
                                    selected={defaultFilterData.date_min ? new Date(defaultFilterData.date_min) : null}
                                    name={'date_min'}
                                    onChange={(date) => handleDateChange(date, 'date_min')}
                                    adjustDateOnChange
                                />
                            </div>
                        </div>

                        <div className='col-6 fieldAndLabel'>
                            <label>To</label>
                            <div className='inputHistory'>
                                <DatePicker
                                    className="date-picker form-control"
                                    showIcon
                                    autoComplete='one-time-code'
                                    onChange={(date) => handleDateChange(date, 'date_max')}
                                    dateFormat="MM-dd-yyyy"
                                    selected={defaultFilterData.date_max ? new Date(defaultFilterData.date_max) : null}
                                    name={'date_max'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row mb10'>
                <div className='col-lg-4 col-sm-12 mobMb-10'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='row'>
                                <div className='col-12'>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <label className='labelHead'>Status</label>
                                        </div>
                                        <div className='col-12'>
                                            <select
                                                className="form-control selectStatus"
                                                value={status}
                                                name="status"
                                                onChange={(e) => handleStatusChange(e)}
                                            >
                                                <option selected key={0}>Select Status</option>
                                                <option value="true">Active</option>
                                                <option value="false">Inactive</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-lg-4 col-sm-12 mobMb-10'>
                    <div className='row'>
                        <div className='col-12'>
                            <label className='labelHead'>Number of Records
                                {errors.min_order_quantity &&
                                    <span className='error filterHisory'> {errors.min_order_quantity}</span>
                                }
                            </label>
                        </div>
                        <div className='col-6 fieldAndLabel'>
                            <span>Min:</span>
                            <input
                                key={11222}
                                min={0}
                                onChange={updateUserInputNum}
                                name='min_order_quantity'
                                value={defaultFilterData.min_order_quantity}
                                className='form-control inputHistory minOrder'
                                type='number'
                                onBlur={handleNumFieldBlur}
                                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            />
                        </div>
                        <div className='col-6 fieldAndLabel'>
                            <span>Max:</span>
                            <input
                                min={0}
                                onChange={updateUserInputNum}
                                name='max_order_quantity'
                                value={defaultFilterData.max_order_quantity}
                                className='form-control inputHistory maxOrder'
                                type='number'
                                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-12 mobMb-10">
                    <div className='row'>
                        <div className='col-12'>
                            <label className='labelHead'></label>
                        </div>

                        <div className='col-12 d-flex'>
                            <button
                                className="btn cus-primary-transparent-btn top-btn clearBtn"
                                onClick={handleClear}
                            >
                                Clear Filter
                            </button>
                            <button
                                className="cus-seconday-bg-btn btn applyFilter"
                                onClick={handleFilter}
                            >
                                Apply
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default MarketplaceFilter;

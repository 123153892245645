import React from 'react';
import { Spinner } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';

const BatchPagination = (props) => {
    const { gridData, totalRecords, numberOfPage, numOfPage, loader, currentPage, totalPages, setCurrentPage, setNumberOfPage } = props;

    const handlePaginationChange = (e, type) => {
        if (type === "") {
            const { value } = e.target;
            setCurrentPage(parseInt(value));
        } else if (type === "totalPage") {
            const { value } = e.target;
            setNumberOfPage(parseInt(value));
            setCurrentPage(1);
        } else if (type === "next") {
            setCurrentPage(currentPage + 1);
        } else if (type === "prev") {
            setCurrentPage(currentPage - 1);
        }
    };

    return (
        <>
            {gridData?.length > 0 && (
                <div className="pagination-wrapper fontFix">
                    <div className="numOfPage">
                        {!loader &&
                            <div className="label">{(numberOfPage * (currentPage - 1) + 1)} - {totalRecords > currentPage * numberOfPage ? currentPage * numberOfPage : totalRecords} of {totalRecords}</div>
                        }
                        <select
                            onChange={(e) => {
                                handlePaginationChange(e, "totalPage");
                                setNumberOfPage(e.target.value);
                            }}
                            value={numberOfPage}
                            disabled={!gridData.length || loader}
                            className="totalPageSelect"
                        >
                            {numOfPage.map((item, index) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="pagination batchPagination">
                        {loader && (
                            <div className="loaderWrapper_batch">
                                <Spinner animation="border" size="sm" />
                            </div>
                        )}
                        <button
                            disabled={currentPage === 1 || loader}
                            onClick={() => handlePaginationChange({}, "prev")}
                            className='prevPageBtn'
                        >
                            Previous Page
                        </button>
                        <select
                            onChange={(e) => handlePaginationChange(e, "")}
                            value={currentPage}
                            disabled={!gridData?.length || loader}
                            className="currentPageSelect"
                        >
                            {[...Array(totalPages)].map((item, index) => (
                                <option key={uuidv4()} value={index + 1}>{index + 1}</option>
                            ))}
                        </select>
                        <button
                            disabled={currentPage === totalPages || loader}
                            onClick={() => handlePaginationChange({}, "next")}
                            className='nextPageBtn'
                        >
                            Next Page
                        </button>
                    </div>
                </div>
            )}
        </>
    );
}

export default BatchPagination;
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { packagesYearBackDetails, productYearBackDetails  } from '../../store/actions/incoming';

const PackageCard = (props) => {
    const navigate = useNavigate();
    const { data, dispatch, index } = props;
    const handleNavigateDate = (date) => {
        if (props.type) {
            dispatch(productYearBackDetails(index));
            navigate(`/incoming-products/fd-${date}-3pl`, {
                state: {
                    date,
                },
            });
        } else {
            dispatch(packagesYearBackDetails(index));
            navigate(`/incoming-packages/fd-${date}-3pl`, {
                state: {
                    date,
                },
            });
        }

    };
    return (
        <div className='packageCard' onClick={() => handleNavigateDate(data.date)}>
            <div className='dateBox'>
                <div className='date'>{moment(data.date).format('MM-DD-YYYY')}</div>
                <div>Date</div>
            </div>
            <div className='packageBox'>
                <div className='totalPackages'>{data.count}</div>
                <div className='Packages'>{props.type ? "Products" : "Packages"}</div>
            </div>
        </div>
    );
}

export default PackageCard;
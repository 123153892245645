import { shipmentConstant } from "../constants";
import {
  CREATE_COMPANY_SUCCESS,
  GET_COMPANY_SUCCESS,
  GET_COMPANY_FAIL,
  DELETE_COMPANY_SUCCESS,
  GET_ALL_SELLERS,
  GET_ADRESS_COMPANY_DETAIL,
  GET_MULTIPLE_SELLERS,
  GET_SELLER_ADRESS_DETAIL,
  GET_ALL_USERS,
  GET_ALL_USERS_PENDING,
  GET_MULTIPLE_SELLERS_STATUS,
  SAVE_PAGINATION_DETAILS,
  SELLER_SAVE_PAGINATION_DETAILS,
} from "../types";

const initialState = {
  companyList: [],
  loading: true,
  error: {},
  companyApiKeysStatus: '',
  companyApiKeys: [],
  allSellerList: [],
  getAllUsersData: {},
  getAllUsersDataStatus: '',
  getMultipleCompanySellers: {},
  getMultipleCompanySellersStatus: '',
  userBackDetails: {},
  sellerBackDetails: {},
};

export default function (state = initialState, action = '') {
  const { type, payload } = action;
  switch (type) {
    case GET_COMPANY_SUCCESS:
      return {
        ...state,
        companyList: payload.company,
        isLoading: false
      };
    case GET_COMPANY_FAIL:
      return {
        ...state
      };
    case CREATE_COMPANY_SUCCESS:
      return {
        ...state,
        companyList: [...state.companyList, payload.company]
      };
    case GET_ALL_SELLERS:
      return {
        ...state,
        getAllSellersData: payload,
        getMultipleCompanySellersStatus: 'SUCCESS',
      };
    case GET_ALL_USERS_PENDING:
      return {
        ...state,
        getAllUsersData: {},
        getAllUsersDataStatus: 'PENDING'
      };
    case GET_ALL_USERS:
      return {
        ...state,
        getAllUsersData: payload,
        getAllUsersDataStatus: 'SUCCESS'
      };
    case GET_ADRESS_COMPANY_DETAIL:
      return {
        ...state,
        getAddressCompanyData: payload,

      };
    case GET_SELLER_ADRESS_DETAIL:
      return {
        ...state,
        getSellerAddressData: payload,

      };
    case GET_MULTIPLE_SELLERS:
      return {
        ...state,
        getMultipleCompanySellers: payload,
      };
    case GET_MULTIPLE_SELLERS_STATUS:
      return {
        ...state,
        getMultipleCompanySellersStatus: 'PENDING',
        getMultipleCompanySellers: {}
      };

    case DELETE_COMPANY_SUCCESS:
      return {
        ...state,
        companyList: [
          ...state.companyList.filter(comp => comp != payload.company)
        ]
      }
    case shipmentConstant.GET_GET_ALL_API_KEYS_PENDING:
      return {
        ...state,
        companyApiKeysStatus: 'pending',
        companyList: [
          ...state.companyList.filter(comp => comp != payload.company)
        ],
        companyApiKeys: []
      }
    case shipmentConstant.GET_GET_ALL_API_KEYS_SUCCESS:
      return {
        ...state,
        companyApiKeysStatus: 'success',
        companyApiKeys: payload
      }
    case shipmentConstant.GET_GET_ALL_API_KEYS_FAILED:
      return {
        ...state,
        companyApiKeysStatus: 'failed',
        companyApiKeys: [],
      }
    case shipmentConstant.GET_ALL_SELLER_LIST_PENDING:
      return {
        ...state,
        allSellerList: []
      }
    case shipmentConstant.GET_ALL_SELLER_LIST_SUCCESS:
      return {
        ...state,
        allSellerList: payload
      }
    case shipmentConstant.GET_ALL_SELLER_LIST_FAILED:
      return {
        ...state,
        allSellerList: []
      }
    case SAVE_PAGINATION_DETAILS:
      return {
        ...state,
        userBackDetails: payload
      }
    case SELLER_SAVE_PAGINATION_DETAILS:
      return {
        ...state,
        sellerBackDetails: payload
      }
    default:
      return state;
  }
}


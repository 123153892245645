import React from 'react';

const RenderVideos = (props) => {
    const { video, formData, setVideo, videoInputRef } = props;

    const handleDeleteVideo = (index) => {
        let videos = [...video];
        videos.splice(index, 1);
        setVideo(videos);
        if (videoInputRef.current) {
            videoInputRef.current.value = "";
        }
    };
    return (
        <div>
            {video &&
                !formData?.videoUrl &&
                video.map((item, index) => (
                    <div
                        className="selectedFile"
                        key={item?.id}
                    >
                        <div className="fileName">{item.name}</div>
                        <button
                            onClick={() => handleDeleteVideo(index)}
                            className='deleteBtn'
                        >
                            <i
                                className="fa fa-times"
                                aria-hidden="true"
                            ></i>
                        </button>
                    </div>
                ))}
        </div>
    );
}

export default RenderVideos;
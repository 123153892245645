import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getInboundHistoryById,
  getOutboundHistoryById,
  getProductHistoryById,
} from "../../../store/actions/inventory.action";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LoaderSpinner from "../../common/Loader";
import Table from "react-bootstrap/Table";
import BatchPagination from "../../batch/BatchPagination";
import { numOfPage } from "../../../constant";
import moment from "moment";
import { renderTableRow } from "../../../config/utils";

const ProductHistory = () => {
  const { id } = useParams();
  const location = useLocation();
  const historyType = location?.state?.type;
  const dispatch = useDispatch();

  const { productHistory, productHistoryStatus } = useSelector(
    (state) => state.InventoryManagement
  );
  const { inboundHistory, inboundHistoryStatus } = useSelector(
    (state) => state.InventoryManagement
  );
  const { outboundHistory, outboundHistoryStatus } = useSelector(
    (state) => state.InventoryOutboundManagement
  );
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numberOfPage, setNumberOfPage] = useState(30);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [sortingColumn, setSortingColumn] = useState("");
  const [sortOrder, setSortOrder] = useState("DESCENDING");
  const [filterData, setfilterData] = useState();
  const [listingData, setListingData] = useState([]);
  const [batchData, setBatchData] = useState([]);
  const navigate = useNavigate();

  const handleBack = () => {
    if (historyType === "product") {
      navigate("/inventory");
    } else if (historyType === "inbound") {
      navigate("/inbound");
    } else if (historyType === "outbound") {
      navigate("/outbound");
    }
  };

  useEffect(() => {
    let history = "";
    if (historyType === "product") {
      history = productHistory;
    } else if (historyType === "inbound") {
      history = inboundHistory;
    } else if (historyType === "outbound") {
      history = outboundHistory;
    }

    if (history?.TotalRecords > 0) {
      setTotalPages(Math.ceil(history?.TotalRecords / numberOfPage));
      setTotalRecords(history?.TotalRecords);
    }
  }, [productHistory, inboundHistory, outboundHistory]);

  useEffect(() => {
    if (
      productHistoryStatus === "PENDING" ||
      inboundHistoryStatus === "PENDING" ||
      outboundHistoryStatus === "PENDING"
    ) {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [productHistoryStatus, inboundHistoryStatus, outboundHistoryStatus]);

  const fetchInventoryHistory = () => {
    let data = {
      pageIndex: currentPage,
      pageSize: numberOfPage,
      sortColumn: sortingColumn,
      sortOrder: sortOrder,
      searchKeyword: "",
      id: Number(id),
    };
    if (historyType === "product") {
      dispatch(getProductHistoryById(data));
    } else if (historyType === "inbound") {
      dispatch(getInboundHistoryById(data));
    } else if (historyType === "outbound") {
      dispatch(getOutboundHistoryById(data));
    }
  };

  const handleSortingClass = (columnName, isArrow = false) => {
    if (isArrow) {
      return sortingColumn === columnName && sortOrder === "ASCENDING"
        ? "fa fa-arrow-up"
        : "fa fa-arrow-down";
    }
    return sortingColumn === columnName ? "active" : "sortColumn";
  };

  const handleSortingColumn = (event, sortColumn) => {
    event.preventDefault();
    let newSortOrder = "DESCENDING";
    setSortingColumn(sortColumn);
    if (sortColumn === sortingColumn) {
      setSortOrder(sortOrder === "ASCENDING" ? "DESCENDING" : "ASCENDING");
      newSortOrder = sortOrder === "ASCENDING" ? "DESCENDING" : "ASCENDING";
    } else {
      setSortOrder("DESCENDING");
    }
    setfilterData({
      ...filterData,
      sortColumn: sortColumn,
      sortOrder: newSortOrder,
    });
  };

  useEffect(() => {
    fetchInventoryHistory();
  }, [filterData, sortOrder, currentPage, numberOfPage]);

  const handleproductUpdate = (item) => {
    return (
      <>
        {item.warehouse ? (
          <>
            <b>{`${item.columnName}`}</b>{" "}
            {`${item.action} from ${item.oldValue} to ${item.newValue} for Warehouse ${item.warehouse} [${item.area} - ${item.row} - ${item.bay} - ${item.level} - ${item.position}]`} {linkInboudId(item)}
          </>
        ) : (
          <>
            <b>{item.columnName}</b>{" "}
            {`${item.action} from ${item.oldValue} to ${item.newValue}`} {linkInboudId(item)}
          </>
        )}
      </>
    );
  };

  const handleinboundUpdate = (item) => {
    return (
      <>
        {item.consignmentName ? (
          <>
            <b>{`${item.columnName}`}</b>{" "}
            {`${item.action} from ${item.oldValue} to ${item.newValue} for `} {linkInboudId(item)}
            <b>{`${item.consignmentType}`}</b>
            {` ${item.consignmentName}`} {linkInboudId(item)}
          </>
        ) : (
          <>
            <b>{`${item.columnName}`}</b>{" "}
            {`${item.action} from ${item.oldValue} to ${item.newValue}`} {linkInboudId(item)}
          </>
        )}
      </>
    );
  };

  useEffect(() => {
    if (historyType === "product") {
      setListingData(productHistory?.objProductHistoryEntity);
      setBatchData(productHistory?.objProductHistoryEntity);
    } else if (historyType === "inbound") {
      setListingData(inboundHistory?.Consignments);
      setBatchData(inboundHistory?.Consignments);
    } else if (historyType === "outbound") {
      setListingData(outboundHistory?.Outbounds);
      setBatchData(outboundHistory?.Outbounds);
    }
  }, [inboundHistory, productHistory, outboundHistory]);

  const handleHistoryView = (item) => {
    return (
      <>
        {item.action === "Created" && (
          <>
            {(historyType === "inbound" || historyType === "outbound") ? (
              <>
                <b>
                  {item.consignmentType} {item.columnName}
                </b>{" "}
                {item.newValue} {item.action} {linkInboudId(item)}
              </>
            ) : (
              <>
                <b>{item.columnName}</b> {item.newValue} {item.action} {linkInboudId(item)}
              </>
            )}
          </>
        )}
        {item.action === "Deleted" && (
          <>
            {(historyType === "inbound" || historyType === "outbound") ? (
              <>
                <b>
                  {item.consignmentType} {item.columnName}
                </b>{" "}
                {item.oldValue} {item.action}
              </>
            ) : (
              <>
                <b>{item.columnName}</b> {item.oldValue} {item.action}
              </>
            )}
          </>
        )}
        {item.action === "Added" && (
          <>
            {(historyType === "inbound" || historyType === "outbound") ? (
              <>
                <b>
                  {item.consignmentType} {item.columnName}
                </b>{" "}
                {item.newValue} {item.action}
              </>
            ) : (
              <>
                <b>{item.columnName}</b> {item.newValue} {item.action} {linkInboudId(item)}
              </>
            )}
          </>
        )}
        {item.action === "Updated" && (
          <>
            {(historyType === "inbound" || historyType === "outbound")
              ? handleinboundUpdate(item)
              : handleproductUpdate(item)}
          </>
        )}
        {item.action === "Primary" && (
          <>
            <b>{item.columnName} </b> {item.newValue} marked {item.action} {linkInboudId(item)}
          </>
        )}
      </>
    );
  };

  const navigateToInbound = (item) => {
    navigate(`/${item.outboundId ? 'outbound' : 'inbound'}/${item.outboundId || item.consignmentId}`, {
      state: {
        data: item,
        isEdit: false,
        backUrl: `/${historyType === 'product' ? 'inventory' : 'outbound'}/history-${Number(id)}`,
        id: id,
        historyType: historyType,
      }
    });
  }

  const linkInboudId = (item) => {
    if (item?.inventoryType) {
      return <span>{item.inventoryType.split('-')[0]} <span className='link v' onClick={() => navigateToInbound(item)}> {item.inventoryType.split('-')[1]}</span></span>;
    }
  }

  const handleHistoryTitle = (historyType) => {
    if (historyType === "product") {
      return "Product History"
    } else if (historyType === "inbound") {
      return "Inbound History"
    } else {
      return "Outbound History"
    }
  }

  return (
    <main>
      <div className="main__container">
        <div className="main__tile_heading cus-btn-include-sec">
          History
          <div className="top_right_button_container">
            <div className="top_right_button_container">
              <button
                className="btn cus-primary-transparent-btn"
                onClick={handleBack}
              >
                Back
              </button>
            </div>
          </div>
        </div>

        {loader && <LoaderSpinner />}
        <div className="container-fluid">
          <div className="cus-page-content-sec">
            <div className="cus-tab-heading-sec">
              <div className="tabs">
                <button className="cus-tab-btn active">
                  {handleHistoryTitle(historyType)}
                </button>
                <>
                  <div className="main_content_table_history">
                    <div className="tableFixHead incomingTbl">
                      <Table
                        className={`table table-height cus-left-right-col-layout table ${loader ? "disableTble" : ""
                          }`}
                      >
                        <thead className="tbl-btch">
                          <tr className="table-rw-batch">
                            <th width={"25%"}>
                              <div
                                className={handleSortingClass("date")}
                                onClick={(e) => handleSortingColumn(e, "date")}
                                id="date"
                              >
                                Date
                                <i
                                  className={handleSortingClass("date", true)}
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </th>
                            <th width={"50%"}>Description</th>
                            <th width={"25%"}>
                              <div
                                className={handleSortingClass("modifiedBy")}
                                onClick={(e) =>
                                  handleSortingColumn(e, "modifiedBy")
                                }
                                id="modifiedBy"
                              >
                                Modified By
                                <i
                                  className={handleSortingClass(
                                    "modifiedBy",
                                    true
                                  )}
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="tbl-bt-body">
                          {listingData?.length > 0 &&
                            listingData.map((item) => (
                              <tr key={item.id}>
                                <td>
                                  {moment(item.modifiedDate).format(
                                    "MM-DD-YYYY | h:mm:ss A"
                                  )}
                                </td>
                                <td>{handleHistoryView(item)}</td>
                                <td width={"25%"}>{item.userName}</td>
                              </tr>
                            ))}
                          {listingData && listingData.length === 0 && (
                            <>{renderTableRow(3, 'No Record Found')}</>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  {batchData && batchData?.length > 0 && (
                    <BatchPagination
                      gridData={batchData}
                      totalVisualRecords={batchData?.length}
                      numberOfPage={numberOfPage}
                      numOfPage={numOfPage}
                      currentPage={currentPage}
                      totalPages={totalPages}
                      totalRecords={totalRecords}
                      setCurrentPage={setCurrentPage}
                      setNumberOfPage={setNumberOfPage}
                    />
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ProductHistory;

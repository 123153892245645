import {
  CREATE_STORE_SUCCESS,
  GET_STORE_SUCCESS,
  UPDATE_STORE_SUCCESS,
} from "../types";
const Store = {};
const initialState = {
  StoreList: [],
  loading: true,
  error: {}
};
export default function (state = initialState, action = '') {
  const { type, payload } = action;
  switch (type) {
    case GET_STORE_SUCCESS:
      return {
        ...state,
        StoreList: payload.Store,
        isLoading: false
      };
    case CREATE_STORE_SUCCESS:
      return {
        ...state,
        StoreList: [...state.StoreList, payload.Store],
        error: {}
      };
    case UPDATE_STORE_SUCCESS:
      return {
        ...state,
        StoreList: [...state.StoreList, payload.Store]
      };
    default:
      return state;
  }
}


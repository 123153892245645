export const barcodeConstant = {
    GET_BARCODE_COLUMNS_PENDING: "GET_BARCODE_COLUMNS_PENDING",
    GET_BARCODE_COLUMNS_SUCCESS: "GET_BARCODE_COLUMNS_SUCCESS",
    GET_BARCODE_COLUMNS_FAILED: "GET_BARCODE_COLUMNS_FAILED",
    GET_INTEGRATION_LIST_PENDING: "GET_INTEGRATION_LIST_PENDING",
    GET_INTEGRATION_LIST_SUCCESS: "GET_INTEGRATION_LIST_SUCCESS",
    GET_INTEGRATION_LIST_FAILED: "GET_INTEGRATION_LIST_FAILED",
    GET_BARCODE_SIZE_SUCCESS: "GET_BARCODE_SIZE_SUCCESS",
    GET_BARCODE_SIZE_FAILED: "GET_BARCODE_SIZE_FAILED",
    GET_BARCODE_SIZE_PENDING: "GET_BARCODE_SIZE_PENDING",
    GET_COLUMN_LISTING_PENDING: 'GET_COLUMN_LISTING_PENDING',
    GET_COLUMN_LISTING_SUCCESS: 'GET_COLUMN_LISTING_SUCCESS',
    GET_COLUMN_LISTING_FAILED: 'GET_COLUMN_LISTING_FAILED',
    GET_ALL_BARCODE_TYPE_PENDING: 'GET_ALL_BARCODE_TYPE_PENDING',
    GET_ALL_BARCODE_TYPE_SUCCESS: 'GET_ALL_BARCODE_TYPE_SUCCESS',
    GET_ALL_BARCODE_TYPE_FAILED: 'GET_ALL_BARCODE_TYPE_FAILED',
    BARCODE_LISTING_SUCCESS: 'BARCODE_LISTING_SUCCESS',
    BARCODE_LISTING_FAILED: 'BARCODE_LISTING_FAILED',
    BARCODE_LISTING_PENDING: 'BARCODE_LISTING_PENDING',
    BARCODE_BACKDETAILS: 'BARCODE_BACKDETAILS',
    BARCODE_MAPPING_BACKDETAILS: 'BARCODE_MAPPING_BACKDETAILS',
    BARCODE_LISTING_BACKDETAILS: 'BARCODE_LISTING_BACKDETAILS',
};

import React from 'react';

const ViewParcel = (props) => {
    const { shipmentObject, validationsList } = props;
    return (
        <>
            {validationsList.length === 0 &&
                <div className='details btn-ship margin-tb'>
                    {shipmentObject?.parcels?.length} Parcel
                    {
                        shipmentObject.parcels &&
                        shipmentObject.parcels.length > 0 &&
                        shipmentObject.parcels.map((item, index) => (
                            <div className="row mr-tp units-details parcelRow" key={item?.id}>
                                <div className="col-4">
                                    <div className='labelName'>Length</div>
                                    <input type={'text'} disabled value={item.length} className="form-control parcel" />
                                    <span className="txt-label">
                                        {shipmentObject.dimensionUnit === "INCH"
                                            ? "in"
                                            : "cm"}
                                    </span>
                                </div>
                                <div className="col-4">
                                    <div className='labelName'>Width</div>
                                    {/* <span className='labelName'>{item.width}</span> */}
                                    <input type={'text'} disabled value={item.width} className="form-control parcel" />
                                    <span className="txt-label">
                                        {shipmentObject.dimensionUnit === "INCH"
                                            ? "in"
                                            : "cm"}
                                    </span>
                                </div>
                                <div className="col-4">
                                    <div className='labelName'>Height</div>
                                    <input type={'text'} disabled value={item.height} className="form-control parcel" />
                                    <span className="txt-label">
                                        {shipmentObject.dimensionUnit === "INCH"
                                            ? "in"
                                            : "cm"}
                                    </span>
                                </div>
                                <div className="col-12">
                                    <div className='row mr-tp'>
                                        <div className="col-6">
                                            <div className='labelName'>Weight</div>
                                            <input type={'text'} disabled value={item.weight} className="form-control parcel" />
                                            <span className="txt-label">
                                                {shipmentObject.dimensionUnit === "INCH"
                                                    ? "lb"
                                                    : "kg"}
                                            </span>
                                        </div>
                                        <div className="col-6">
                                            <div className='labelName'>
                                                {shipmentObject.dimensionUnit === "INCH"
                                                    ? "Ounces"
                                                    : "gm"}
                                            </div>
                                            <input type={'text'} disabled value={item.ounces} className="form-control parcel" />
                                            <span className="txt-label">
                                                {shipmentObject.dimensionUnit === "INCH" ? "oz" : "g"}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            }
        </>
    );
}

export default ViewParcel;
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "./setup.css";
import { useNavigate } from "react-router-dom";
import MarginLogo from "../../assets/icons/margin.svg";
import { csvUploadListBackDetails, mappingListBackDetails, sellerCarrierBackDetails, sellerCarrierServiceBackDetails } from "../../store/actions";
import { fundmanagementBackDetails } from "../../store/actions/fundManagement";
import { baseRatecardListBackDetails, sellerRatecardListBackDetails } from "../../store/actions/rateCard";
import { marketPlaceBackDetails } from "../../store/actions/marketplace.action";
import { marginConfigBackDetails } from "../../store/actions/employee";
import { checkPermission } from "../../config/utils";
import { featureSubmodule, settings } from "../../constant";

export function Setup(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { employeePermissions } = props;
  const backDetailsArray = [
    mappingListBackDetails,
    fundmanagementBackDetails,
    baseRatecardListBackDetails,
    sellerRatecardListBackDetails,
    csvUploadListBackDetails,
    sellerCarrierBackDetails,
    sellerCarrierServiceBackDetails,
    marketPlaceBackDetails,
    marginConfigBackDetails,
  ];

  const handleBackDetails = () => {
    backDetailsArray.forEach(action => {
      dispatch(action({}));
    });
  };

  const [cardList] = useState([
    {
      name: "CSV Mapping",
      icon: "fa fa-map-signs",
      color: "#0000ff69",
      path: "/csvList",
      subModule: 14
    },
    {
      name: "Fund Management",
      icon: "fa fa-usd",
      color: "#4BBBD3",
      path: "/fundManagement",
      subModule: 15
    },
    {
      name: "Rate Card",
      icon: "fa fa-money",
      color: "#D04545",
      path: "/rateCard",
      subModule: 16
    },
    {
      name: "CSV Upload",
      icon: "fa fa-file-excel-o",
      color: "#0000ff69",
      path: "/csvUpload",
      subModule: 17
    },
    {
      name: "API Key Generation",
      icon: "fa fa-key",
      color: "#0000ff69",
      path: "/generate-key",
      subModule: 18
    },
    {
      name: "Service Configuration",
      icon: "fa fa-ship",
      color: "#0000ff69",
      path: "/manage-carriers",
      subModule: 19
    },
    {
      name: "Marketplace Integration",
      icon: "fa fa-globe",
      color: "#0000ff69",
      path: "/marketplace-integration",
      subModule: 20
    },
    {
      name: "Margin Configuration",
      icon: "fa fa-globe",
      color: "#0000ff69",
      path: "/margin-configuration",
      subModule: 21
    },
    {
      name: "Configuration",
      icon: "fa fa-sliders",
      color: "#0000ff69",
      path: "/Configuration",
      subModule: 22
    },
    {
      name: "Role Permissions",
      icon: "fa fa-lock",
      color: "#0000ff69",
      path: "/permissions",
      subModule: 29,
    },
  ]);

  return (
    <main>
      <div className="main__container">
        <div className="main__tile_heading">Settings</div>
        <div className="container-fluid">
          <div className="cus-page-content-sec">
            <div className="content-container">
              <div className="cards setup-container">
                {cardList.map((card) => (
                  <React.Fragment key={card.subModule}>
                    {checkPermission(settings, card.subModule, '', featureSubmodule, employeePermissions) && (
                      <div
                        className="setup-card"
                        onClick={() => {
                          navigate(card.path);
                          handleBackDetails();
                        }}
                      >
                        <div className="icon">
                          {card.path === '/margin-configuration' ? (
                            <img alt="" className="cus-sm-icon-cls" src={MarginLogo} style={{ maxWidth: '30px' }} />
                          ) : (
                            <i className={card.icon} />
                          )}
                        </div>
                        <div className="txt-name">{card.name}</div>
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

import {
  CREATE_WAREHOUSE_SUCCESS,
  GET_WAREHOUSE_SUCCESS,
  GET_WAREHOUSE_FAIL,
  DELETE_WAREHOUSE_SUCCESS,
} from "../types";
const Warehouse = {};
const initialState = {
  WarehouseList: [],
  loading: true,
  error: {}
};
export default function (state = initialState, action = '') {
  const { type, payload } = action;
  switch (type) {
    case GET_WAREHOUSE_SUCCESS:
      return {
        ...state,
        WarehouseList: payload.Warehouse,
        isLoading: false
      };
    case GET_WAREHOUSE_FAIL:
      return {
        ...state
      };
    case CREATE_WAREHOUSE_SUCCESS:
      return {
        ...state,
        WarehouseList: [...state.WarehouseList, payload.Warehouse]
      };
    case DELETE_WAREHOUSE_SUCCESS:
      return {
        ...state,
        WarehouseList: [
          ...state.WarehouseList.filter(ware => ware != payload.Warehouse)
        ]
      }
    default:
      return state;
  }
}


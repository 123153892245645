import axios from "axios";
import {
  GET_ALL_FUNDS,
  GET_SINGLE_FUNDS,
  GET_SINGLE_FUND_DETAILS,
  GET_SINGLE_FUNDS_DATA,
  GET_SELLERS,
  GET_WALLET_BALANCE,
  GET_WALLET_FUND,
  GET_WALLET_FUND_DETAILS,
  EMPTY_TRANSACTION_HISTORY,
  GET_ALL_FUNDS_STATUS_PENDING,
  GET_ALL_FUNDS_STATUS_SUCCESS,
  FUND_MANAGEMENT_BACK_DETAILS,
} from "../types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiUrl } from "../../constant";

export const getSellers = (companyId) => async (dispatch) => {
  const config = {
    headers: { Authorization: `` },
  };
  try {
    const response = await axios.get(
      `${apiUrl}/api/GetSellersByCompanyId?companyId=${companyId}`,
      config
    );
    dispatch({
      type: GET_SELLERS,
      payload: response.data,
    });
  } catch (e) {
    toast.error(e?.response?.data?.errorDescription);
  }
};

export const getSellerWalletDetails = (data) => async (dispatch) => {
  const config = {
    headers: { Authorization: `` },
  };

  try {
    const response = await axios.post(
      `${apiUrl}/api/Fund/GetWalletDetails`,
      data,
      config
    );

    dispatch({
      type: GET_WALLET_BALANCE,
      payload: response.data,
    });
  } catch (e) {
    toast.error(e?.response?.data?.errorDescription);
  }
};
export const getLoginUserDetailsonFund = (data) => async (dispatch) => {
  const config = {
    headers: { Authorization: `` },
  };

  try {
    const response = await axios.post(
      `${apiUrl}/api/Fund/GetLoginUserFundDetails`,
      data,
      config
    )
    dispatch({
      type: GET_WALLET_FUND_DETAILS,
      payload: response?.data?.Entity?.listloginuserFundDetailEntitytwo[0],
    });
  } catch (e) {
    toast.error(e?.response?.data?.errorDescription);
  }
};

export const getFundTransactionDetails =
  (
    data,
    setTotalPages,
    numberOfPage,
    pageIndex,
    filterTransactionType,
    setShowLoader = {}
  ) =>
    async (dispatch) => {
      try {
        setShowLoader(true);
        if (pageIndex && numberOfPage) {
          const response = await axios.post(
            `${apiUrl}/api/Fund/GetFundDetails`,
            {
              ...data,
              pageIndex,
              pageSize: numberOfPage,
              transactionType: filterTransactionType,
            },
          );

          dispatch({
            type: GET_SINGLE_FUND_DETAILS,
            payload: response.data,
          });

          setTotalPages(
            Math.ceil(response?.data?.Entity?.TotalRecords / numberOfPage)
          );
          setShowLoader(false);
        } else {
          const response = await axios.post(
            `${apiUrl}/api/Fund/GetFundDetails`,
            {
              ...data,
              pageIndex: 1,
              pageSize: 30,
              transactionType: filterTransactionType,
            }
          );

          dispatch({
            type: GET_SINGLE_FUND_DETAILS,
            payload: response.data,
          });
          setTotalPages(Math.ceil(response?.data?.Entity?.TotalRecords / 30));
          setShowLoader(false);
        }
      } catch (e) {
        toast.error(e?.response?.data?.errorDescription);
      }
    };

const emptyFundtransaction = () => ({
  type: EMPTY_TRANSACTION_HISTORY
});

export const emptyFundTransactionList = () => {
  return (dispatch) => {
    dispatch(emptyFundtransaction())
  };
};

export const AdjustWalletDetails = (formData, singleCustomer, setAmount, isAdmin, onHide) =>
  async (dispatch) => {
    const config = {
      headers: { Authorization: `` },
    };
    try {
      if (singleCustomer?.length) {
        const response = await axios.post(
          `${apiUrl}/api/Fund/AdjustSellerWallet`,
          {
            ...formData,
            id: singleCustomer[0]?.id,
            SellerName: formData?.SellerName?.label,
            amount: formData?.amount,
          },
          config
        );
        if (response?.data?.Entity === "RC_IAF") {
          toast.error(
            "Insufficient funds! Please recharge the wallet to proceed."
          );
        } else if (formData?.transactionType == 5) {
          toast.success("Amount Debited Succesfully");
        } else {
          toast.success("Recharge Succesfully");
        }
      } else {
        const response = await axios.post(
          `${apiUrl}/api/Fund/AdjustSellerWallet`,
          {
            ...formData,
            id: 0,
            SellerName: formData?.SellerName?.label,
            amount: formData?.amount,
          },
          config
        );

        if (response?.data?.Entity === "RC_IAF") {
          toast.error(
            "Insufficient funds! Please recharge the wallet to proceed."
          );
        } else if (formData?.transactionType == 5) {
          toast.success("Amount Debited Succesfully");
        } else {
          toast.success("Recharge Succesfully");
        }
      }

      const responseWallet = await axios.post(
        `${apiUrl}/api/Fund/GetFunds`,
        {
          id: 0,
          topupCompanyId: formData?.companyId,
          topupSellerId: formData?.sellerId,
          role: formData?.role,
          isAdmin,
          pageIndex: 1,
          pageSize: 30,
          IsCurrentUser: true,
        },
        config
      );

      dispatch(
        getLoginUserDetailsonFund({
          creditCompanySellerId: 0,
        })
      )

      dispatch({
        type: GET_WALLET_FUND,
        payload: responseWallet?.data,
      });

      dispatch({
        type: GET_ALL_FUNDS,
        payload: responseWallet.data,
      });
      dispatch({
        type: GET_SINGLE_FUNDS,
        payload: responseWallet.data,
      });
      dispatch({
        type: GET_WALLET_BALANCE,
        payload: [],
      });
      dispatch({
        type: GET_SINGLE_FUNDS_DATA,
        payload: [],
      });
      onHide();
    } catch (e) {
      toast.error(e?.response?.data?.errorDescription);
    }
  };

export const UpdateTopUpFundDetails =
  (formData, singleCustomer, setAmount, isAdmin, onHide, filterData) =>
    async (dispatch) => {
      const config = {
        headers: { Authorization: `` },
      };
      try {
        if (singleCustomer?.length) {
          await axios.post(
            `${apiUrl}/api/Fund/CreateUpdateFund`,
            {
              ...formData,
              id: singleCustomer[0]?.id,
              SellerName: formData?.SellerName?.label,
              amount: formData?.amount,
            },
            config
          );
        } else {
          await axios.post(
            `${apiUrl}/api/Fund/CreateUpdateFund`,
            {
              ...formData,
              id: 0,
              SellerName: formData?.SellerName?.label,
              amount: formData?.amount,
            },
            config
          );
        }
        const responseWallet = await axios.post(
          `${apiUrl}/api/Fund/GetFunds`,
          filterData,
          config
        );

        dispatch({
          type: GET_WALLET_FUND,
          payload: responseWallet?.data,
        });

        dispatch({
          type: GET_ALL_FUNDS,
          payload: responseWallet.data,
        });
        dispatch({
          type: GET_SINGLE_FUNDS,
          payload: responseWallet.data,
        });
        dispatch({
          type: GET_WALLET_BALANCE,
          payload: [],
        });
        dispatch({
          type: GET_SINGLE_FUNDS_DATA,
          payload: [],
        });
        onHide();
      } catch (e) {
        toast.error(e?.response?.data?.errorDescription);
      }
    };

export const getAllFundDetails = (data, setTotalPages, filterData) => async (dispatch) => {
  const config = {
    headers: { Authorization: `` },
  };
  dispatch({ type: GET_ALL_FUNDS_STATUS_PENDING });
  try {
    if (data.topupCompanyId) {
      const response = await axios.post(
        `${apiUrl}/api/Fund/GetFunds`, data,
        config
      );
      if (
        !window.location.href.includes("fundManagement") ||
        data.IsCurrentUser
      ) {
        dispatch({
          type: GET_WALLET_FUND,
          payload: response?.data,
        });
      }


      dispatch({
        type: GET_ALL_FUNDS,
        payload: response.data,
      });
      setTotalPages(Math.ceil(response?.data?.Entity?.TotalRecords / filterData.pageSize))
    } else {
      const response = await axios.post(
        `${apiUrl}/api/Fund/GetFunds`, data,
        config
      );
      dispatch({
        type: GET_ALL_FUNDS,
        payload: response.data,
      });
      setTotalPages(Math.ceil(response?.data?.Entity?.TotalRecords / filterData.pageSize))
    }
    dispatch({ type: GET_ALL_FUNDS_STATUS_SUCCESS });
  } catch (e) {
    toast.error(e?.response?.data?.errorDescription);
  }
};

export const updateTreshold = (values) => {
  const config = {
    headers: { Authorization: `` },
  };
  return () => {
    return axios.post(`${apiUrl}/api/Fund/UpdateThreshold`, values, config);
  };
};
export const fetchProcessingFee = (url) => {
  return () => {
    return axios.get(`${apiUrl}/api/fund/processing-fee?${url}`);
  };
};

export const updateProcessingFee = (data) => {
  return () => {
    return axios.post(`${apiUrl}/api/Fund/processing-fee/update`, data);
  };
};
export const getPaymentToken = (data) => {
  return new Promise((res, rej) => {
    return res(axios.post(`${apiUrl}/api/PaymentToken`, data));
  })
};
export const fundmanagementBackDetails = (data) => ({
  type: FUND_MANAGEMENT_BACK_DETAILS,
  payload: data,
});


export const getSellersByCompanyId = (companyId) => {
  return axios.get(`${apiUrl}/api/GetSellersByCompanyId?companyId=${companyId}`);
};
import axios from "axios";
import { apiUrl } from "../constant";

export const getPackageYearsService = (companySellerId, isSuperAdmin) => {
    return axios.get(`${apiUrl}/api/IncomingPackage/PackageYearsList?companySellerId=${companySellerId}&isSuperAdmin=${isSuperAdmin}`);
};
export const getProductYearsService = (companySellerId, employeeId) => {
    return axios.get(`${apiUrl}/api/IncomingProduct/YearsList?companySellerId=${companySellerId}&userId=${employeeId}`);
};

export const getDailyPackageCountService = (year) => {
    return axios.get(`${apiUrl}/api/IncomingPackage/GetDailyPackageCount?year=${year}`);
};
export const getDailyProductCountService = (year, companySellerId, employeeId) => {
    return axios.get(`${apiUrl}/api/IncomingProduct/GetDailyCount?year=${year}&companySellerId=${companySellerId}&userId=${employeeId}`);
};

export const getFilterPackages = (data) => {
    return axios.post(`${apiUrl}/api/IncomingPackage/FilterPackages`, data);
};
export const getFilterProducts = (data) => {
    return axios.post(`${apiUrl}/api/IncomingProduct/FilterProducts`, data);
};

export const getSourceListService = () => {
    return axios.get(`${apiUrl}/api/IncomingPackage/SourceList`);
};

export const getPackageTypeListService = () => {
    return axios.get(`${apiUrl}/api/IncomingPackage/PackageTypeList`);
};

export const getPackageDetailsService = (packageId) => {
    return axios.get(`${apiUrl}/api/IncomingPackage/PackageDetails/${packageId}`);
};
export const getProductDetailsService = (productId) => {
    return axios.get(`${apiUrl}/api/IncomingProduct/${productId}`);
};

export const createPackageService = (data) => {
    return axios.post(`${apiUrl}/api/IncomingPackage/CreatePackage`, data);
};
export const createProductService = (data) => {
    return axios.post(`${apiUrl}/api/IncomingProduct/create`, data);
};

export const updatePackageService = (data) => {
    return axios.put(`${apiUrl}/api/IncomingPackage/UpdatePackage`, data);
};
export const updateProductService = (data) => {
    return axios.put(`${apiUrl}/api/IncomingProduct/update`, data);
};

export const updateImageForPackageService = (data) => {
    return axios.put(`${apiUrl}/api/IncomingPackage/UpdateImages`, data);
};
export const updateMediaForProductService = (data) => {
    return axios.put(`${apiUrl}/api/IncomingProduct/update-media`, data);
};

export const searchTrackingNumberService = (data) => {
    return axios.post(`${apiUrl}/api/IncomingPackage/SearchTrackingNo`, data);
};

export const createUserKeyService = (data) => {
    return axios.post(`${apiUrl}/api/Shipment/CreateUserKey`, data);
};

export const getAllApiKeyService = (filterValue) => {
    return axios.post(`${apiUrl}/api/Shipment/GetAllApiKey`, filterValue);
};

export const setCompanyStatusService = (data) => {
    return axios.put(`${apiUrl}/api/Shipment/UpdateAPIKeyStatus?${data}`);
};

export const getAllCarrierListService = () => {
    return axios.get(`${apiUrl}/api/IncomingPackage/CarrierList`);
};

export const getStatusListService = () => {
    return axios.get(`${apiUrl}/api/IncomingPackage/StatusList`);
};
export const getProductStatusListService = () => {
    return axios.get(`${apiUrl}/api/IncomingProduct/StatusList`);
};
export const getProductConditionListService = () => {
    return axios.get(`${apiUrl}/api/IncomingProduct/ConditionList`);
};
export const getProductWarehouseListService = () => {
    return axios.get(`${apiUrl}/api/IncomingProduct/WarehouseList`);
};
export const getProductProcessTypeListService = () => {
    return axios.get(`${apiUrl}/api/IncomingProduct/ProcessTypeList`);
};
export const getAllSellersService = (data) => {
    return axios.post(`${apiUrl}/api/GetAllSeller`, data);
};
export const getAllUserCompanies = () => {
    return axios.post(`${apiUrl}/GetCompanyListByUserId`);
};


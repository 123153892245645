import React, { useEffect, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { Col, Row } from "react-bootstrap";
import { fileUploadCSVIntegration, getAllCSVActiveIntegration, getTemplateByBarCodeIntegration } from '../../../store/actions/barcode.action';
import { useDispatch } from 'react-redux';
import { getSelectList } from '../../../config/utils';
import Trash from "../../../assets/trashRed.svg";
import { toast } from 'react-toastify';
import _ from 'lodash';
import SelectMenu from '../../common/SelectMenu';
import { allowedCSVExtension } from '../../../constant';
import LoaderSpinner from '../../common/Loader';

const UploadCsvBarcode = (props) => {
    const { handleCancel, resetListing } = props;
    let dispatch = useDispatch();
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({});
    const [template, setTemplate] = useState(null);
    const [sampleTemplateUrl, setSampleTemplateUrl] = useState('');
    const [showLoader, setShowLoader] = useState(false);

    const handleValidation = () => {
        let isFormValid = true;
        let error = {};
        if (!formData.file) {
            isFormValid = false;
            error.file = 'This is required';
        }

        if (_.isEmpty(template, true)) {
            isFormValid = false;
            error.template = 'This is required';
        }

        setErrors(error);
        return isFormValid;
    }

    const handleUploadCsv = async () => {
        if (handleValidation()) {
            const dataPayload = new FormData();
            dataPayload.append("FormFile", formData.file);
            dataPayload.append("IntegrationId", template.id);
            let res = await Promise.resolve(dispatch(fileUploadCSVIntegration(dataPayload)));
            if (res.data.Status === 200) {
                toast.success(res.data.Entity);
                resetListing();
                handleCancel();
            } else {
                toast.error(res.data.Entity);
            }
        }
    }

    const fetchAllIntegration = async () => {
        let params = {
            isActive: true,
            SortOrder: 'DESCENDING'
        }
        setShowLoader(true);
        let listRes = await Promise.resolve(dispatch(getAllCSVActiveIntegration(params)));
        if (listRes?.data?.Status === 200) {
            let selectDataList = getSelectList(listRes?.data?.EntityList, 'id', 'name');
            setFormData({ ...formData, integrationList: selectDataList });
            setShowLoader(false);
        }
        setShowLoader(false);
    }

    useEffect(() => {
        fetchAllIntegration();
    }, []);

    const handleChange = (event) => {
        const extension = event.target.files[0].name.split(".").slice(-1).pop();
        if (!allowedCSVExtension.includes(extension ? extension.toLowerCase() : "")) {
            toast.error('Please select CSV file only');
            return
        }
        setFormData({ ...formData, fileName: event.target.files[0].name, file: event.target.files[0] });
        setErrors({ errors, file: '' })
    }

    const handleDelete = () => {
        setFormData({ fileName: '', file: null });
    }

    const handleCancelModal = () => {
        resetListing();
        handleCancel();
    }

    const handleTemplateMenuChange = async (value, name) => {
        setTemplate(value);
        setErrors({ ...errors, template: '' });
        setShowLoader(true);
        let response = await Promise.resolve(dispatch(getTemplateByBarCodeIntegration(value)));
        if (response?.data?.Status === 200) {
            setSampleTemplateUrl(response?.data?.Entity);
        }
        setShowLoader(false);
    };

    return (
        <Modal {...props} className="modalDeleteWrapper" size="lg">
            <Modal.Header
                closeButton
                onHide={handleCancel}
            >
                <div className="modal-main-div"><text className="modal-m-text">Upload CSV </text>{showLoader && <LoaderSpinner />}</div>
            </Modal.Header>
            <Modal.Body className="selectSvcModal">
                <div className="row modal_input">
                    <div className="col-4 formLabel csv_label">Select Template</div>
                    <div className="col-8">
                        <div className={"select_react_Csv"}>
                            <SelectMenu
                                optionList={formData.integrationList}
                                handleChange={handleTemplateMenuChange}
                                value={template}
                                placeholderText={'Select template..'}
                                name='test'
                            />
                            <p className="error">{errors.template}</p>
                        </div>
                    </div>
                </div>

                <div className="row modal_input">
                    <div className="col-4 formLabel csv_label">Select File</div>
                    <div className="col-8">
                        <div className='select_react_Csv'>
                            <button className="btn-input-csv">
                                <label className="btn-chooseFile" htmlFor={`actual-btn-6`} style={{ width: '265px' }}>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-upload"
                                        viewBox="0 0 16 16"
                                        cursor="pointer"
                                    >
                                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                        <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                                    </svg> Choose File
                                </label>
                            </button>
                            <input
                                type="file"
                                name="file"
                                accept=".csv"
                                id={`actual-btn-6`}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                hidden
                            />
                            {formData.fileName && (
                                <div className='mt-3' style={{ wordBreak: 'break-all' }}>
                                    {formData.fileName}
                                    <span className="trash-icon ml-1">
                                        <img
                                            className="trash-Img"
                                            onClick={handleDelete}
                                            src={Trash}
                                            alt=""
                                        />
                                    </span>
                                </div>
                            )}
                            <p className='error'>{errors.file}</p>
                        </div>

                    </div>
                </div>
                {sampleTemplateUrl && !showLoader && (
                    <div className="downLoad">
                        <a rel="noreferrer" href={sampleTemplateUrl} target="_blank">Download Template</a>
                    </div>
                )}
                <Row className="modaL_footer">
                    <Col xs={6}></Col>
                    <Col className="csv_Buttons mt-5" xs={6}>
                        <button
                            className="btn cus-seconday-bg-btn uploadBtn"
                            onClick={handleUploadCsv}
                            disabled={showLoader}
                        >Upload</button>

                        <button
                            className="btn cus-seconday-bg-btn marginLeft5 cancelBtn"
                            onClick={handleCancelModal}
                        // disabled={showLoader}
                        >
                            Cancel
                        </button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}

export default UploadCsvBarcode;
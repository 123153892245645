import React, { useState, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import { shippingStatusListconn } from '../../../constant';
import { createConsignmentElement } from '../../../store/actions/inventory.action';
import { createElemPayload } from '../../../config/utils';
import { toast } from "react-toastify";
import { useDispatch } from 'react-redux';
import LoaderSpinner from '../../common/Loader';
import { v4 as uuidv4 } from 'uuid';

const ConsoleConnection = (props) => {
  const {
    showPaletteBoxObj,
    handleCancelPalette,
    elements,
    isEditable,
    type,
    inBoundObject,
    setElements
  } = props;

  const [checkAll, setCheckAll] = useState(false);
  const [selectedPallets, setSelectedPallets] = useState(new Set());
  const [selectedBoxes, setSelectedBoxes] = useState(new Set());
  const [selectedPalletsOpen, setSelectedPalletsOpen] = useState(new Set());
  const [selectedBoxesOpen, setSelectedBoxesOpen] = useState(new Set());
  const [selectedItemsOpen, setSelectedItemsOpen] = useState(new Set());
  const [formData, setFormData] = useState({});
  const [currentShippingStatus, setCurrentShippingStatus] = useState(0);
  const [loader, setLoader] = useState(false)

  const addUniqueIdentifiers = (elements) => {
    const updateElements = (elements) => {
      return elements.map(element => {
        if (element.id === 0) {
          element.uniqueId = uuidv4();
        } else {
          element.uniqueId = element.id;
        }

        if (element.consignmentElements && element.consignmentElements.length > 0) {
          element.consignmentElements = updateElements(element.consignmentElements);
        }

        return element;
      });
    };

    return updateElements(elements);
  };

  useEffect(() => {
    setFormData({
      ...inBoundObject,
      consignmentElements: addUniqueIdentifiers(elements)
    });
  }, [inBoundObject, elements]);

  const handleshippingStatusId = (item) => {
    if(item.shippingStatusId === 1){
        return "Stored at warehouse"
    } else{
        return item.shippingStatusId === 2 ? "Shipped Out" :""
    }
  }

  useEffect(() => {
    const allPallets = new Set();
    const allBoxes = new Set();
    const allItems = new Set();

    const addNestedElements = (elements) => {
      elements.forEach(element => {
        allPallets.add(element.uniqueId);
        if (element.consignmentElements) {
          element.consignmentElements.forEach(box => {
            allBoxes.add(box.uniqueId);
            if (box.consignmentElements) {
              box.consignmentElements.forEach(item => {
                allItems.add(item.uniqueId);
              });
            }
          });
        }
      });
    };

    addNestedElements(elements);
  
    let isAllSelected;

    switch (type) {
      case "Pallet":
        isAllSelected = selectedPalletsOpen.size === allPallets.size &&
                        selectedBoxesOpen.size === allBoxes.size &&
                        selectedItemsOpen.size === allItems.size;
        break;
      case "Box":
        isAllSelected = selectedBoxesOpen.size === allBoxes.size &&
                        selectedItemsOpen.size === allItems.size;
        break;
      default:
        isAllSelected = selectedItemsOpen.size === allItems.size;
        break;
    }
    setCheckAll(isAllSelected);
  }, [selectedPalletsOpen, selectedBoxesOpen, selectedItemsOpen, elements]);

  useEffect(() => {
    if((!selectedPalletsOpen.size || !selectedBoxesOpen.size || !selectedItemsOpen.size) && currentShippingStatus){
      setSelectedPalletsOpen(new Set());
      setSelectedBoxesOpen(new Set());
      setSelectedItemsOpen(new Set());
    }
  }, [currentShippingStatus])

  const handlePalletClick = (id) => {
    setSelectedPallets((prevSelectedPallets) => {
      const newSelectedPallets = new Set(prevSelectedPallets);
      if (newSelectedPallets.has(id)) {
        newSelectedPallets.delete(id);
      } else {
        newSelectedPallets.add(id);
      }
      return newSelectedPallets;
    });
  };
  const handleBoxClick = (id) => {
    setSelectedBoxes((prevSelectedBoxes) => {
      const newSelectedBoxes = new Set(prevSelectedBoxes);
      if (newSelectedBoxes.has(id)) {
        newSelectedBoxes.delete(id);
      } else {
        newSelectedBoxes.add(id);
      }
      return newSelectedBoxes;
    });
  };
  const handlePalletClickOpen = (id) => {
    setCurrentShippingStatus(0)
    setSelectedPalletsOpen((prevSelectedPallets) => {
      const newSelectedPalletsOpen = new Set(prevSelectedPallets);
      if (newSelectedPalletsOpen.has(id)) {
        newSelectedPalletsOpen.delete(id);
      } else {
        newSelectedPalletsOpen.add(id);
      }
      return newSelectedPalletsOpen;
    });
  };
  const handleBoxClickOpen = (id) => {
    setCurrentShippingStatus(0)
    setSelectedBoxesOpen((prevSelectedBoxes) => {
      const newSelectedBoxesOpen = new Set(prevSelectedBoxes);
      if (newSelectedBoxesOpen.has(id)) {
        newSelectedBoxesOpen.delete(id);
      } else {
        newSelectedBoxesOpen.add(id);
      }
      return newSelectedBoxesOpen;
    });
  };
  const handleItemClickOpen = (id) => {
    setCurrentShippingStatus(0)
    setSelectedItemsOpen((prevSelectedItems) => {
      const newSelectedItemsOpen = new Set(prevSelectedItems);
      if (newSelectedItemsOpen.has(id)) {
        newSelectedItemsOpen.delete(id);
      } else {
        newSelectedItemsOpen.add(id);
      }
      return newSelectedItemsOpen;
    });
  };

  const handleSelectAllClickPallete = (element, allBoxes, allItems) => {
    if (element.consignmentElements.length) {
      element.consignmentElements.forEach(box => {
        allBoxes.add(box.uniqueId);
        if (box.consignmentElements) {
          box.consignmentElements.forEach(item => {
            allItems.add(item.uniqueId);
          });
        }
      });
    }
  }
  const handleSelectAllClickBox = (element, allBoxes) => {
    if (element.consignmentElements.length) {
      element.consignmentElements.forEach(box => {
        allBoxes.add(box.uniqueId);
      });
    }
  }
  const handleSelectAllClickItem = (element, allItems) => {
    if (element.consignmentElements.length) {
      element.consignmentElements.forEach(box => {
        if (box.consignmentElements) {
          box.consignmentElements.forEach(item => {
            allItems.add(item.uniqueId);
          });
        }
      });
    }
  }

  const handleSelectAllClick = () => {
    const allPallets = new Set();
    const allBoxes = new Set();
    const allItems = new Set();

    const addNestedElements = (elements) => {
      elements.forEach(element => {
        if (type === 'Pallet') {
          allPallets.add(element.uniqueId);
        }
        if(type !== 'Item'){
          handleSelectAllClickPallete(element, allBoxes, allItems)
        }
        if(type === 'Box'){
          handleSelectAllClickBox(element, allBoxes)
        }
        if(type === 'Item'){
          handleSelectAllClickItem(element, allItems)
        }
      });
    };

    if (checkAll) {
      setSelectedPalletsOpen(new Set());
      setSelectedBoxesOpen(new Set());
      setSelectedItemsOpen(new Set());
      setSelectedPallets(new Set());
      setSelectedBoxes(new Set());
    } else {
      addNestedElements(elements);
      setSelectedPalletsOpen(allPallets);
      setSelectedBoxesOpen(allBoxes);
      setSelectedItemsOpen(allItems);
    }
    setCheckAll(!checkAll);
  };

  const renderItems = (items, margin) => {
    return items.map((item) => (
      <div key={item.uniqueId} style={{ marginLeft: margin }}>
        <div className='console_conn'>
          <div className='left_section'>
            <input className='input_conn' type='checkbox' disabled={!isEditable} checked={selectedItemsOpen.has(item.uniqueId)} onChange={() => handleItemClickOpen(item.uniqueId)} />
            <span>{item.name}</span>
          </div>
          <div className='right_section'>
            <span className={item.shippingStatusId === 1 ? 'stored_console_conn' : 'shipped_console_conn'}>{handleshippingStatusId(item)}</span>
          </div>
        </div>
      </div>
    ));
  };

  const renderBoxes = (boxes) => {
    return boxes.map((box) => (
      <div key={box.uniqueId} style={{ marginLeft: formData.consignmentTypeId === 1 ? '30px' : '0px' }}>
        <div className='console_conn'>
          <div className='left_section'>
            <i className={`fa ${selectedBoxes.has(box.uniqueId) ? 'fa-minus-square' : 'fa-plus-square'}`} onClick={() => handleBoxClick(box.uniqueId)}></i>
            <div className='boxMargin'>
              <input type='checkbox' checked={selectedBoxesOpen.has(box.uniqueId)} disabled={!isEditable} onChange={() => handleBoxClickOpen(box.uniqueId)} />
            </div>
            <div className='boxMargin'>
              <span>{box.name}</span>
            </div>
          </div>
          <div className='right_section'>
            <span className={box.shippingStatusId === 1 ? 'stored_console_conn' : 'shipped_console_conn'}>{handleshippingStatusId(box)}</span>
          </div>
        </div>
        {selectedBoxes.has(box.uniqueId) && box.consignmentElements && box.consignmentElements.length > 0 &&
          renderItems(box.consignmentElements, '40px')}
      </div>
    ));
  };

  const renderPallets = () => {
    return formData && formData.consignmentElements && formData?.consignmentElements.map((pallet) => (
      <div key={pallet.uniqueId}>
        <div className='console_conn'>
          <div className='left_section'>
            <i className={`fa ${selectedPallets.has(pallet.uniqueId) ? 'fa-minus-square' : 'fa-plus-square'}`} onClick={() => handlePalletClick(pallet.uniqueId)}></i>
            <div className='boxMargin'>
              <input className='input_conn' disabled={!isEditable} type='checkbox' checked={selectedPalletsOpen.has(pallet.uniqueId)} onChange={() => handlePalletClickOpen(pallet.uniqueId)} />
            </div>
            <span>{pallet.name}</span>
          </div>
          <div className='right_section'>
            <span className={pallet.shippingStatusId === 1 ? 'stored_console_conn' : 'shipped_console_conn'}>{handleshippingStatusId(pallet)}</span>
          </div>
        </div>
        {selectedPallets.has(pallet.uniqueId) && pallet.consignmentElements && pallet.consignmentElements.length > 0 &&
          renderBoxes(pallet.consignmentElements)}
      </div>
    ));
  };

  const renderContent = () => {
    if (type === 'Pallet') {
      return renderPallets();
    } else if (type === 'Box') {
      const allBoxes = getAllBoxes(formData);
      return renderBoxes(allBoxes);
    } else if (type === 'Item') {
      const allItems = getAllItems(formData);
      return renderItems(allItems, '0px');
    }
  };
  
  const getAllBoxes = (formData) => {
    const allBoxes = [];
    if (formData && formData.consignmentElements) {
      formData.consignmentElements.forEach(pallet => {
        if (pallet.consignmentElements) {
          pallet.consignmentElements.forEach(box => {
            allBoxes.push(box);
          });
        }
      });
    }
    return allBoxes;
  };
  
  const getAllItems = (formData) => {
    const allItems = [];
    if (formData && formData.consignmentElements) {
      formData.consignmentElements.forEach(pallet => {
        if (pallet.consignmentElements) {
          pallet.consignmentElements.forEach(box => {
            if (box.consignmentElements) {
              box.consignmentElements.forEach(item => {
                allItems.push(item);
              });
            }
          });
        }
      });
    }
    return allItems;
  };
  
  /* istanbul ignore next */
  const handleUpdateElement = async () => {
    setLoader(true);
    try {
      if(elements && elements.length){
        setLoader(true)
        handleCancelPalette();
        setElements(formData.consignmentElements)
        toast.success(`Consignment updated Successfully`);
        setLoader(false)
      }
    } finally {
      setLoader(false);
    }
  };

  const handleAddElement = async () => {
    if(currentShippingStatus === 0 && (!selectedBoxesOpen.size || !selectedPalletsOpen.size || !selectedItemsOpen.size)){
      toast.error("Please select Connection")
    } else{
    handleUpdateElement()
    }
  }

  const handleFormValue = (e) => {
    const { name, value } = e.target;
    const newValue = value ? Number(value) : 0;

    setCurrentShippingStatus(newValue)
    const updateShippingStatusId = (element) => {
      let Element = { ...element };
      if (selectedPalletsOpen.has(Element.uniqueId) || selectedBoxesOpen.has(Element.uniqueId) || selectedItemsOpen.has(Element.uniqueId)) {
        if (Element.hasOwnProperty(name)) {
          Element[name] = newValue;
        }
      }
      if (Element.consignmentElements && Element.consignmentElements.length > 0) {
        Element.consignmentElements = Element.consignmentElements.map(updateShippingStatusId);
      }
      return Element;
    };
    const updatedFormData = updateShippingStatusId({ ...formData });
    setFormData(updatedFormData);
  };

  return (
    <Modal
      {...props}
      size="xl"
      className="topUpModal"
      centered
      backdrop="static"
      show={showPaletteBoxObj?.show}
      onHide={handleCancelPalette}
    >
      <div className="top_modal_header_container con_mrg">
        <span>Console Connection</span>
        <div className="modalDeleteWrapper">
          <button
            type="button"
            onClick={() => handleCancelPalette()}
            className="btn-close"
            aria-label="Close"
          ></button>
        </div>
      </div>

      <div className='modal_container_rate_card seller-Modal'>
        {loader && <LoaderSpinner />}
        <div className="top_console_header_container">
          <div>

          </div>
          <div className='icon_title'>
            <select
              className={`form-control countrySelect`}
              name={'shippingStatusId'}
              onChange={handleFormValue}
              value={currentShippingStatus}
              disabled={currentShippingStatus === 0 && !isEditable}
            >
              {shippingStatusListconn.map((list) => (
                <option key={list.id} value={list.value}>{list.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div className='console_conn_select'>
          <div>
            <input className='input_conn' checked={checkAll} type='checkbox' onChange={handleSelectAllClick} disabled={!isEditable}/>
            <span className='selectAllTxt'>Select All</span>
          </div>
          <div>
            <i className="fa fa-info-circle"></i>
            <span className='strng-txt'>Click a Category to expand and Collapse</span>
          </div>
        </div>

        <div className='top_console_header_containerB cardBody'>
          {renderContent()}
        </div>
      </div>
      <div className="modal_container_rate_card seller-Modal">
        <div className='paletteActions mb-3'>
          <button
            className="btn cus-primary-transparent-btn top-btn"
            onClick={handleCancelPalette}
          >
            Cancel
          </button>
          <button
            className={`btn cus-seconday-bg-btn ${!isEditable ? 'disableTble' : ''}`}
            onClick={() => handleAddElement()}
          >
            Update
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default ConsoleConnection;
import React from 'react';

const RenderImages = (props) => {
    const { afterImage, setAfterImage } = props;
    const handleDeleteAfterImage = (index) => {
        let afterimages = [...afterImage];
        afterimages.splice(index, 1);
        setAfterImage(afterimages);
    };
    return (
        <div>
            {afterImage.length > 0 &&
                afterImage.map((item, index) => (
                    <div
                        className="selectedFile"
                        key={item?.id}
                    >
                        <div className="fileName">{item.name}</div>
                        <button
                            onClick={() =>
                                handleDeleteAfterImage(index)
                            }
                            className='deleteBtn'
                        >
                            <i
                                className="fa fa-times"
                                aria-hidden="true"
                            ></i>
                        </button>
                    </div>
                ))}
        </div>
    );
}

export default RenderImages;
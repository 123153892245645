import React, { useRef, useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useNavigate } from 'react-router';
import { getSearchTrackingData } from '../../store/actions/incoming';

const AsyncPackageSearch = (props) => {
    const navigate = useNavigate();
    const { placeHolder, userDetails } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const ref = useRef(null);
    /* istanbul ignore next */
    const handleSearch = async (query) => {
        setIsLoading(true);
        let data = {
            pageIndex: 1,
            pageSize: 20,
            sortColumn: "",
            sortOrder: "ASCENDING",
            companySellerId: userDetails.Value.companySellerId,
            isSuperAdmin: userDetails.Value.isSuperAdmin,
            searchKeywords: query,
        }

        let trackSearchList = await getSearchTrackingData(data);
        /* istanbul ignore next */
        if (trackSearchList && trackSearchList.data && trackSearchList.data.Status === 200) {
            setOptions(trackSearchList.data.EntityList);
            setIsLoading(false);
        }
    };

    // Bypass client-side filtering by returning `true`. Results are already
    // filtered by the search endpoint, so no need to do it again.
    /* istanbul ignore next */
    const filterBy = () => true;

    const handleTrackClick = (selectedPackage) => {
        /* istanbul ignore next */
        navigate(`/incoming-packages/fd-${selectedPackage.receivedDate}-3pl/${selectedPackage.internalId}`,
            { state: { view: true, isCreated: true, date: selectedPackage.receivedDate } });
    }

    return (
        <AsyncTypeahead
            filterBy={filterBy}
            id="async-example"
            isLoading={isLoading}
            labelKey="trackingNumber"
            minLength={3}
            onSearch={handleSearch}
            options={options}
            placeholder={placeHolder}
            ref={ref}
            onBlur={() => ref.current?.clear()}
            renderMenuItemChildren={(option) => (
                /* istanbul ignore next */
                <>
                    <div onClick={() => handleTrackClick(option)}>{option.trackingNumber}</div>
                </>
            )}
        />
    );
};

export default AsyncPackageSearch;
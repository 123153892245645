import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import store from './store/store'
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';

ReactDOM.render(
  <React.Fragment>
    <Provider store={store}>
      <App />
    </Provider>
  </React.Fragment>,
  document.getElementById('root')
);


if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/sw-test/sw.js', { scope: 'sw-test' }).then(function (registration) {
    // registration worked
    registration.unregister().then(function (boolean) {
      // if boolean = true, unregister is successful
    });
  }).catch(function (error) {
    // registration failed
  });
};


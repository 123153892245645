import React from 'react';
import Select from "react-select";

const SelectMenu = (props) => {
    const { optionList, handleChange, value, placeholderText, name, isMulti = false } = props;
    return (
        <Select
            {...props}
            options={optionList}
            onChange={(value) => handleChange(value, name)}
            value={value}
            placeholder={placeholderText}
            classNamePrefix={'list'}
            isMulti={isMulti}
        />
    );
}

export default SelectMenu;
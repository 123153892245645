import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router";
import { Table } from "bootstrap-4-react/lib/components";
import ActionItems from '../../common/showActions';
import { useDispatch, useSelector } from 'react-redux';
import { barCodeMappingBackDetails, deleteIntegration, getAllBarcodeIntegration } from '../../../store/actions/barcode.action';
import LoaderSpinner from '../../common/Loader';
import BatchPagination from '../../batch/BatchPagination';
import { barCode, featureSubmodulePermission, numOfPage, permssionCreate, permssionDelete, permssionEdit, permssionView, SUBF_BARCODEMAPPING } from '../../../constant';
import AlertDialogModal from '../../common/AlertDialog';
import { toast } from 'react-toastify';
import moment from 'moment';
import { checkPermission, renderTableRow } from '../../../config/utils';
import _ from 'lodash';

const BarCodeMappings = (props) => {
    const navigate = useNavigate();
    const { barCodeIntegrationListStatus, barCodeIntegrationList, mappingBackDetails } = useSelector((state) => state.barcodeReducer);
    const dispatch = useDispatch();
    const [showLoader, setShowLoader] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [listData, setListData] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [currentItem, setCurrentItem] = useState({});
    let dataObj = {
        sortColumn: '',
        sortOrder: 'DESCENDING',
        numberOfPage: 30,
        currentPage: 1
    }
    const [defaultFilterData, setDefaultFilterData] = useState({});

    useEffect(() => {
        if (!_.isEmpty(mappingBackDetails)) {
            setDefaultFilterData({ ...defaultFilterData, ...mappingBackDetails });
        } else {
            setDefaultFilterData({ ...dataObj });
        }
    }, []);

    useEffect(() => {
        if (!_.isEmpty(defaultFilterData)) {
            fetchAllIntegration();
        }
    }, [defaultFilterData.numberOfPage, defaultFilterData.currentPage, defaultFilterData.sortOrder]);

    const fetchAllIntegration = () => {
        let params = `isActive=${true}&PageIndex=${defaultFilterData.currentPage}&PageSize=${defaultFilterData.numberOfPage}&SortColumn=${defaultFilterData.sortColumn}&SortOrder=${defaultFilterData.sortOrder}`;
        dispatch(getAllBarcodeIntegration(params));
    }

    const saveCurrentPagination = (data) => {
        dispatch(barCodeMappingBackDetails(data));
    }

    useEffect(() => {
        if (barCodeIntegrationList?.TotalRecords) {
            setTotalPages(
                Math.ceil(barCodeIntegrationList?.TotalRecords / defaultFilterData.numberOfPage)
            );
        }
        if (barCodeIntegrationList?.listBarcodeCsvEntity?.length) {
            setListData(barCodeIntegrationList?.listBarcodeCsvEntity)
        }
    }, [barCodeIntegrationList])

    useEffect(() => {
        if (barCodeIntegrationListStatus === 'pending') {
            setShowLoader(true)
            return
        }
        setShowLoader(false)
    }, [barCodeIntegrationListStatus]);


    const handleSortingCoulmn = (event, sortColumn) => {
        event.preventDefault();
        let newSortOrder = defaultFilterData.sortOrder === "ASCENDING" ? "DESCENDING" : "ASCENDING";
        let data = { ...defaultFilterData, sortColumn: sortColumn, sortOrder: newSortOrder }
        setDefaultFilterData(data);
    };

    const handleNavigateToProduct = (id, type) => {
        saveCurrentPagination(defaultFilterData);
        if (type) {
            navigate(`/barcode/mappings/bar-${id}`, {
                state: {
                    data: id,
                    isEdit: type === 'edit' ? true : false
                }
            });
            return
        }
        navigate(`/barcode/mappings/bar-${0}`, {
            state: {
                data: 0,
                isEdit: true
            }
        });
    }

    const handleDelete = (event, item) => {
        event.preventDefault();
        setCurrentItem(item);
        setModalShow(true);
        saveCurrentPagination(defaultFilterData);
    }

    const handleYesDeleteAlert = async () => {
        let deleteIntegrationRes = await Promise.resolve(dispatch(deleteIntegration(currentItem.id)));
        if (deleteIntegrationRes.data.Status === 200) {
            toast.success('Integration Deleted Successfully');
            fetchAllIntegration();
            setModalShow(false);
        }
    }

    const handleTemplateNavigate = (item) => {
        saveCurrentPagination(defaultFilterData);
        navigate(`/barcode/mappings/bar-${item.id}`);
    }

    return (
        <main>
            <div className="main__container">
                <div className="main__tile_heading cus-btn-include-sec custitle">
                    <div>
                        Barcode Mapping
                    </div>

                    <div className="top_right_button_container">
                        {checkPermission(barCode, SUBF_BARCODEMAPPING, permssionCreate, featureSubmodulePermission, props.employeePermissions) && (
                            <button
                                className="cus-seconday-bg-btn btn top-btn"
                                id="savesr"
                                onClick={() => handleNavigateToProduct()}
                            >
                                Create Template
                            </button>
                        )}
                        <button
                            className="cus-primary-transparent-btn btn"
                            onClick={() => {
                                navigate("/barcode");
                                dispatch(barCodeMappingBackDetails({}));
                            }}
                        >
                            Back
                        </button>
                    </div>
                </div>
                {showLoader && <LoaderSpinner />}
                <div className="main_content">
                    <div className="main_content_table_history">
                        <div className="tableFixHead incomingTbl">
                            <Table className="table keyGenerate">
                                <thead>
                                    <tr className="trow">
                                        <th className="trows-s" width="15%">Type</th>
                                        <th className="trows-s" width="20%">
                                            <div
                                                className={
                                                    defaultFilterData.sortColumn === "templateName" ? "active" : "sortColumn"
                                                }
                                                onClick={(e) => handleSortingCoulmn(e, "templateName")}
                                            >
                                                Name
                                                <i
                                                    className={
                                                        defaultFilterData.sortColumn === "templateName" &&
                                                            defaultFilterData.sortOrder === "ASCENDING"
                                                            ? "fa fa-arrow-up"
                                                            : "fa fa-arrow-down"
                                                    }
                                                    aria-hidden="true"
                                                ></i>
                                            </div>
                                        </th>
                                        <th className="trows-s" width="20%">Created Date</th>
                                        <th className="trows-s" width="20%">Modified Date</th>
                                        <th className="trows-s" width="15%">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {listData?.length > 0 && barCodeIntegrationListStatus === 'success' && (
                                        <>
                                            {listData.map((item, index) => (
                                                <tr key={item.id}>
                                                    <td>{item.type}</td>
                                                    {checkPermission(barCode, SUBF_BARCODEMAPPING, permssionView, featureSubmodulePermission, props.employeePermissions) ? (
                                                        <td>
                                                            <span className='linkIntegration' onClick={() => handleTemplateNavigate(item)}>
                                                                {item.templateName}
                                                            </span>
                                                        </td>
                                                    ) : (
                                                        <td>
                                                            {item.templateName}
                                                        </td>
                                                    )}

                                                    <td>{moment(item.createdDate).format(
                                                        "MM-DD-YYYY | h:mm:ss A"
                                                    )}</td>
                                                    <td>{moment(item.modifiedDate).format(
                                                        "MM-DD-YYYY | h:mm:ss A"
                                                    )}</td>
                                                    <td>
                                                        <>
                                                            {checkPermission(barCode, SUBF_BARCODEMAPPING, permssionView, featureSubmodulePermission, props.employeePermissions) && (
                                                                <ActionItems type={'View'} item={item} handleAction={() => handleNavigateToProduct(item.id, 'view')} />
                                                            )}
                                                            {checkPermission(barCode, SUBF_BARCODEMAPPING, permssionEdit, featureSubmodulePermission, props.employeePermissions) && (
                                                                <ActionItems type={'Edit'} item={item} handleAction={() => handleNavigateToProduct(item.id, 'edit')} />
                                                            )}
                                                        </>
                                                        {checkPermission(barCode, SUBF_BARCODEMAPPING, permssionDelete, featureSubmodulePermission, props.employeePermissions) && (
                                                            <span onClick={(e) => handleDelete(e, item)}>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="24"
                                                                    height="24"
                                                                    fill="currentColor"
                                                                    className="bi bi-trash editIcon"
                                                                    viewBox="0 0 16 16"
                                                                    color="red"
                                                                    cursor="pointer"
                                                                >
                                                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                                                    <path
                                                                        fill-rule="evenodd"
                                                                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                                                    />
                                                                </svg>
                                                            </span>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    )}

                                    {listData?.length === 0 && barCodeIntegrationListStatus === 'success' && (
                                        <>{renderTableRow(5, 'No Record Found')}</>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                        <AlertDialogModal
                            modalClass={'modalDeleteWrapper'}
                            modalShow={modalShow}
                            handleCancelDeleteAlert={() => setModalShow(false)}
                            handleYesDeleteAlert={handleYesDeleteAlert}
                        />
                    </div>
                </div>
                {barCodeIntegrationList?.listBarcodeCsvEntity?.length > 0 && (
                    <BatchPagination
                        gridData={barCodeIntegrationList?.listBarcodeCsvEntity}
                        totalRecords={barCodeIntegrationList.TotalRecords}
                        numberOfPage={defaultFilterData.numberOfPage}
                        numOfPage={numOfPage}
                        loader={showLoader}
                        currentPage={defaultFilterData.currentPage}
                        totalPages={totalPages || 0}
                        setCurrentPage={(value) => setDefaultFilterData({ ...defaultFilterData, currentPage: value })}
                        setNumberOfPage={(value) => setDefaultFilterData({ ...defaultFilterData, numberOfPage: value })}
                    />
                )}
            </div>
        </main>
    );
}

export default BarCodeMappings;
import { csvIntegrationConstant } from '../constants';
import { batchConstant } from '../constants/batch.constant';
const initialState = {
    batchesList: [],
    batchStatus: '',
    carrierList: [],
    carrierListStatus: '',
    recentBatchHistoryStatus: '',
    recentBatchHistory: {},
    scanFormList: [],
    scanFormListStatus: '',
    csvColumnList: [],
    csvColumnListStatus: [],
    filterBackDetails: {},
    mappingListPrevState: {},
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action = '') {
    const { type, payload } = action;
    switch (type) {
        case batchConstant.GET_BATCHES_STATUS:
            return {
                ...state,
                batchStatus: payload,
            };
        case batchConstant.GET_BATCHES_LIST:
            return {
                ...state,
                batchesList: payload,
            };
        case batchConstant.GET_ALL_CARRIERS:
            return {
                ...state,
                carrierList: payload,
            };
        case batchConstant.GET_ALL_CARRIERS_STATUS:
            return {
                ...state,
                carrierListStatus: payload,
            };
        case batchConstant.GET_RECENT_HISTORY_BATCH_PENDING:
            return {
                ...state,
                recentBatchHistoryStatus: 'pending',
                recentBatchHistory: { list: [], totalRecords: 0 },
            };
        case batchConstant.GET_RECENT_HISTORY_BATCH_SUCCESS:
            return {
                ...state,
                recentBatchHistoryStatus: 'success',
                recentBatchHistory: { list: payload.EntityList, totalRecords: payload.TotalRecords },
            };
        case batchConstant.GET_RECENT_HISTORY_BATCH_FAILED:
            return {
                ...state,
                recentBatchHistoryStatus: 'failed',
                recentBatchHistory: { list: [], totalRecords: 0 },
            };
        case batchConstant.RESET_RECENT_HISTORY_BATCH_LIST:
            return {
                ...state,
                recentBatchHistoryStatus: '',
                recentBatchHistory: { list: [], totalRecords: 0 },
            };
        case batchConstant.GET_SCAN_FORM_LIST_PENDING:
            return {
                ...state,
                scanFormList: [],
                scanFormListStatus: 'pending'
            };
        case batchConstant.GET_SCAN_FORM_LIST_SUCCESS:
            return {
                ...state,
                scanFormList: payload,
                scanFormListStatus: 'success'
            };
        case batchConstant.GET_SCAN_FORM_LIST_FAILED:
            return {
                ...state,
                scanFormList: [],
                scanFormListStatus: 'failed'
            };
        case csvIntegrationConstant.GET_COLUMN_LIST_SUCCESS:
            return {
                ...state,
                csvColumnList: payload,
                csvColumnListStatus: 'success'
            };
        case csvIntegrationConstant.GET_COLUMN_LIST_PENDING:
            return {
                ...state,
                csvColumnList: [],
                csvColumnListStatus: 'pending'
            };
        case csvIntegrationConstant.GET_COLUMN_LIST_FAILURE:
            return {
                ...state,
                csvColumnList: [],
                csvColumnListStatus: 'failed'
            };
        case batchConstant.HISTORYTRACK_BACK_DETAILS:
            return {
                ...state,
                filterBackDetails: payload
            };
        case csvIntegrationConstant.MAPPING_LIST_BACK_DETAILS:
            return {
                ...state,
                mappingListPrevState: payload,
            };
        default:
            return state;
    }
}


import axios from "axios";
import { apiUrl } from "../constant";

const getBarCodeService = (data) => {
    return axios.post(`${apiUrl}/api/BarCode`, data);
};

const getBarCodeColumns = () => {
    return axios.get(`${apiUrl}/api/BarCode/BarcodeColumns`);
};

const checkIntegrationName = (name) => {
    return axios.post(`${apiUrl}/api/BarCode/CheckDuplicateBarcodeIntegration?name=${name}`);
};

const barcodeUploadIntegrationService = (data) => {
    return axios.post(`${apiUrl}/api/BarCode/api/BarcodeUploadIntegration`, data);
};

const addUpdateBarcodeIntegrationService = (data) => {
    return axios.post(`${apiUrl}/api/BarCode/api/AddUpdateBarcodeIntegration`, data);
};

const getBarcodeSizeService = (data) => {
    return axios.post(`${apiUrl}/api/BarCode/api/GetBarcodeSize`, data);
}

const updateBarcodePrintingSizeService = (data) => {
    return axios.post(`${apiUrl}/api/BarCode/api/AddUpdateBarcodePrintingsize`, data);
};

const getAllBarcodeIntegrationService = (payload) => {
    return axios.post(`${apiUrl}/api/BarCode/api/GetAllBarcodeIntegration?${payload}`);
};

const getAllCSVActiveIntegrationService = (payload) => {
    return axios.post(`${apiUrl}/api/BarCode/CsvActiveIntegration`, payload);
};

const deleteIntegrationService = (integrationId) => {
    return axios.post(`${apiUrl}/api/BarCode/api/DeleteBarcodeIntegration?id=${integrationId}`);
};

const getIntegrationByIdService = (integrationId) => {
    return axios.post(`${apiUrl}/api/BarCode/api/GetBarcodeIntegrationById?id=${integrationId}`);
};

const getColumnsByFileIdService = (integrationId) => {
    return axios.post(`${apiUrl}/api/BarCode/api/BarcodeUploadIntegrationColumns?id=${integrationId}`);
};

const fileUploadCsvService = (data) => {
    return axios.post(`${apiUrl}/api/BarCodeUpload/FileUpload`, data);
};

const getBarCodeListingService = (payload) => {
    return axios.post(`${apiUrl}/api/BarCode/Listing`, payload);
};

const getAllPrintersService = () => {
    return axios.get(`http://localhost:7000/printers/list`);
};

const autoPrintService = (data) => {
    return axios.post(`http://localhost:7000/print/printpaper`, data);
};

const getAllBarCodeTypeService = () => {
    return axios.get(`${apiUrl}/api/BarCode/GetAllBarCodeType`);
};

const createAdhocBarCodeService = (payload) => {
    return axios.post(`${apiUrl}/api/BarCode/CreateAdhocBarCode`, payload);
};

const getBarCodeUploadedListingService = (payload) => {
    return axios.post(`${apiUrl}/api/BarCode/BarCodeProductListing`, payload);
};

const getTemplateByBarCodeIntegrationService = (payload) => {
    return axios.get(`${apiUrl}/api/BarCode/BarcodeTemplate?integrationId=${payload.id}`, payload);
};

const updateBarCodesService = (payload) => {
    return axios.put(`${apiUrl}/api/BarCode/UpdateBarCodes`, payload);
};

export const barcodeService = {
    getBarCodeService,
    getBarCodeColumns,
    checkIntegrationName,
    barcodeUploadIntegrationService,
    addUpdateBarcodeIntegrationService,
    getAllBarcodeIntegrationService,
    deleteIntegrationService,
    getIntegrationByIdService,
    getColumnsByFileIdService,
    getAllCSVActiveIntegrationService,
    fileUploadCsvService,
    getBarCodeListingService,
    getBarcodeSizeService,
    updateBarcodePrintingSizeService,
    getAllPrintersService,
    autoPrintService,
    getAllBarCodeTypeService,
    createAdhocBarCodeService,
    getBarCodeUploadedListingService,
    getTemplateByBarCodeIntegrationService,
    updateBarCodesService
}
import axios from "axios";
import { apiUrl } from "../constant";

const getAllMarginList = (data) => {
    return axios.post(`${apiUrl}/api/AllMargin`, data);
};

const getMarginByIdService = (data) => {
    return axios.post(`${apiUrl}/api/MarginByCompanySellerId`, data);
};

const updateMarginService = (data) => {
    return axios.put(`${apiUrl}/api/Margin`, data);
};

const updateServiceStatusService = (data) => {
    return axios.put(`${apiUrl}/api/Carrier/CarrierService`, data);
};

const getAllCarrierServicesService = (data) => {
    return axios.post(`${apiUrl}/api/Carrier/CarrierServicesWithCompanySeller`, data);
};

const getAllServicesByCompanySellerIdService = (data) => {
    return axios.post(`${apiUrl}/api/Carrier/CarrierServicesByCompanySellerId`, data);
};

export const marginService = {
    getAllMarginList,
    getMarginByIdService,
    updateMarginService,
    getAllCarrierServicesService,
    getAllServicesByCompanySellerIdService,
    updateServiceStatusService,
}
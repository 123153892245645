export const batchConstant = {
    GET_BATCHES_LIST: 'GET_BATCHES_LIST',
    GET_BATCHES_STATUS: 'GET_BATCHES_STATUS',
    GET_ALL_CARRIERS: 'GET_ALL_CARRIERS',
    GET_ALL_CARRIERS_STATUS: 'GET_ALL_CARRIERS_STATUS',
    GET_HISTORY_STATUS_PENDING: 'GET_HISTORY_STATUS_PENDING',
    GET_HISTORY_STATUS_SUCCESS: 'GET_HISTORY_STATUS_SUCCESS',
    GET_HISTORY_STATUS_FAILED: 'GET_HISTORY_STATUS_FAILED',
    GET_CSV_STATUS_SUCCESS: 'GET_CSV_STATUS_SUCCESS',
    GET_RECENT_HISTORY_BATCH_PENDING: 'GET_RECENT_HISTORY_BATCH_PENDING',
    GET_RECENT_HISTORY_BATCH_SUCCESS: 'GET_RECENT_HISTORY_BATCH_SUCCESS',
    GET_RECENT_HISTORY_BATCH_FAILED: 'GET_RECENT_HISTORY_BATCH_FAILED',
    RESET_RECENT_HISTORY_BATCH_LIST: 'RESET_RECENT_HISTORY_BATCH_LIST',
    GET_SCAN_FORM_LIST_PENDING: 'GET_SCAN_FORM_LIST_PENDING',
    GET_SCAN_FORM_LIST_SUCCESS: 'GET_SCAN_FORM_LIST_SUCCESS',
    GET_SCAN_FORM_LIST_FAILED: 'GET_SCAN_FORM_LIST_FAILED',
    GET_FILTER_STATUS: 'GET_FILTER_STATUS',
    GET_INTEGRATION_LIST_PENDING: 'GET_INTEGRATION_LIST_PENDING',
    HISTORYTRACK_BACK_DETAILS: 'HISTORYTRACK_BACK_DETAILS',
    MARKET_PLACE_BACK_DETAILS: 'MARKET_PLACE_BACK_DETAILS',
}
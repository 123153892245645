import authService from "../services/auth.service";
import { clearLocalStorage, getLocalStorageItem, setLocalStorageItem } from "../services/StorageService";
import axios from 'axios';

export const logout = () => {
    clearLocalStorage();
    window.location.href = '/';
}

export const getUserToken = () => {
    let token = JSON.parse(getLocalStorageItem('userDetails') || '{}');
    if (token?.Value?.Token) {
        return token.Value.Token;
    }
}

const updateToken = (userToken) => {
    let getUserDetails = JSON.parse(getLocalStorageItem('userDetails'));
    getUserDetails.Value.Token = userToken;
    setLocalStorageItem('userDetails', JSON.stringify(getUserDetails));
}

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    })

    failedQueue = [];
}

axios.interceptors.response.use(function (response) {
    return response
}, function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
            return new Promise(function (resolve, reject) {
                failedQueue.push({ resolve, reject })
            }).then(token => {
                originalRequest.headers['Authorization'] = 'Bearer ' + getUserToken();
                return axios(originalRequest);
            }).catch(err => {
                return Promise.reject(err);
            })
        }

        originalRequest._retry = true;
        isRefreshing = true;

        return new Promise(function (resolve, reject) {
            authService.refreshTokenService(getUserToken()).then(({ data }) => {
                updateToken(data?.Value?.Token);
                originalRequest.headers['Authorization'] = 'Bearer ' + data?.Value?.Token;
                processQueue(null, data?.Value?.Token);
                resolve(axios(originalRequest));
            }).catch((err) => {
                processQueue(err, null);
                reject(err);
                logout();
            }).finally(() => { isRefreshing = false })
        })
    }

    return Promise.reject(error);
});

axios.interceptors.request.use(async (req) => {
    if ((!req.url.includes('login') && !req.url.includes('printers'))) {
        req.headers['Authorization'] = 'Bearer ' + getUserToken();
        req.headers['AccessTokenId'] = 'AccessTokenId';
    }
    return req
}, error => {
    return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        logout();
    }
    return error;
});


export const MarginConstant = {
    GET_ALL_MARGIN_LIST_PENDING: 'GET_ALL_MARGIN_LIST_PENDING',
    GET_ALL_MARGIN_LIST_SUCCESS: 'GET_ALL_MARGIN_LIST_SUCCESS',
    GET_ALL_MARGIN_LIST_FAILURE: 'GET_ALL_MARGIN_LIST_FAILURE',
    GET_MARGIN_BY_ID_PENDING: 'GET_MARGIN_BY_ID_PENDING',
    GET_MARGIN_BY_ID_SUCCESS: 'GET_MARGIN_BY_ID_SUCCESS',
    GET_MARGIN_BY_ID_FAILURE: 'GET_MARGIN_BY_ID_FAILURE',
}
export const SellerCarrierConstant = {
    CARRIER_SERVICES_PENDING: 'CARRIER_SERVICES_PENDING',
    CARRIER_SERVICES_SUCCESS: 'CARRIER_SERVICES_SUCCESS',
    CARRIER_SERVICES_FAILURE: 'CARRIER_SERVICES_FAILURE',
    SERVICES_BY_COMPANY_SELLER_ID_PENDING: 'SERVICES_BY_COMPANY_SELLER_ID_PENDING',
    SERVICES_BY_COMPANY_SELLER_ID_FAILED: 'SERVICES_BY_COMPANY_SELLER_ID_FAILED',
    SERVICES_BY_COMPANY_SELLER_ID_SUCCESS: 'SERVICES_BY_COMPANY_SELLER_ID_SUCCESS',
    SELLER_CARRIER_SERVICE_CONFIG_BACK_DETAILS: "SELLER_CARRIER_SERVICE_CONFIG_BACK_DETAILS",
    SELLER_CARRIER_SERVICE_SERVICE_CONFIG_BACK_DETAILS: "SELLER_CARRIER_SERVICE_SERVICE_CONFIG_BACK_DETAILS"
}


import "./slick.min.css";
import "./slick-theme.css";
import React from 'react';
import Slider from "react-slick";
import { Tooltip as WmsTooltip } from "react-tooltip";

const ImageSlider = (props) => {
    const { images, handleMarkPrimary, handleDeleteImage, isView, isUserAdmin, setShowLargeSliderModal, hideMaximize } = props;
    let settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
    };

    return (
        <div className="sliderSmall">
            {!hideMaximize &&
                <>
                    <button type="button" id="maximize" className="btn cus-seconday-bg-btn maximize" onClick={() => setShowLargeSliderModal(true)}>
                        <i className="fa fa-arrows" aria-hidden="true"></i>
                    </button>
                    <WmsTooltip
                        anchorId={'maximize'}
                        content="Maximize Slider"
                        place="top"
                        className="tooltipWidth"
                    />
                </>
            }
            <Slider {...settings}>
                {images && images.length > 0 && images.map((item, index) => (
                    <>
                        {!item.isDeleted && (
                            <div className="innerImgWrapper" key={item.id}>
                                <img src={item.imageURL || item.imageUrl} alt="" />
                                {!isView && (
                                    <div className="imageOption">
                                        {item.isPrimary ? (
                                            <></>
                                        ) : (
                                            <>
                                                <button type="button" id={`primary-${item.id}-${index}`} className="primary mkePrimaryBtn" onClick={(e) => handleMarkPrimary(e, item)}>
                                                    <i className="fa fa-thumb-tack" aria-hidden="true"></i>
                                                </button>
                                                {isUserAdmin && (
                                                    <button type="button" id={`delete-${item.id}-${index}`} className="primary delete" onClick={(e) => handleDeleteImage(e, item)}>
                                                        <i className="fa fa-trash" aria-hidden="true"></i>
                                                    </button>
                                                )}
                                            </>
                                        )}
                                        <WmsTooltip
                                            anchorId={`primary-${item.id}-${index}`}
                                            content="Mark as Primary"
                                            place="top"
                                            className="tooltipWidth"
                                        />
                                        <WmsTooltip
                                            anchorId={`delete-${item.id}-${index}`}
                                            content="Delete Image"
                                            place="top"
                                            className="tooltipWidth"
                                        ></WmsTooltip>
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                ))}
            </Slider>
        </div>
    );
}

export default ImageSlider;
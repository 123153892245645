import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./rateCardModal.css";
import { Col, Row } from "react-bootstrap";

const EffectiveSellerModal = (props) => {
  return (
    <Modal
      {...props}
      size="md"
      centered
    >
      <div className="modal_container_rate_card">
        <div className="modal_header_container">
          <span className="modal_header">Effective Seller / Company</span>
        </div>
        <Row className="modaL_footer">
          <Col xs={12}>
            <ul>
              {props?.sellerData?.map((item) => (
                <li key={item}>{item?.sellerAndCompany}</li>
              ))}
            </ul>
          </Col>
          <Col style={{ textAlign: "right" }} xs={12}>
            <Button className="cus-seconday-bg-btn" onClick={props.onHide}>
              Close
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};
export default EffectiveSellerModal;

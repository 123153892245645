import "./Main.css";
import BreadCrumb from "../breadcrumbs/Breadcrumbs";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
const Main = () => {
  const currentUser = useSelector((state) => state?.auth?.user);
  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  return (
    <main>
      <BreadCrumb name="Dashboard"></BreadCrumb>
      <div className="main__container">
        <div className="main__tile_heading">
          Dashboard
        </div>
        <div className="main_head">
          Welcome to Warehouse Management System
        </div>
      </div>
    </main>
  );
};

export default Main;

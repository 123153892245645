import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PermissionTable from './PermissionTable';
import { useNavigate } from 'react-router-dom';
import LoaderSpinner from '../common/Loader';

const Permissions = (props) => {
    const { employeePermissions } = props;
    const dispatch = useDispatch();
    const [userPermission, setUserPermission] = useState([]);
    const [showLoader, setShowLoader] = useState(false);
    const navigate = useNavigate();
    const { allEmployeePermission } = useSelector((state) => state?.auth);

    useEffect(() => {
        if (!allEmployeePermission.length) {
            setShowLoader(true);
        } else {
            setShowLoader(false);
        }
    }, [allEmployeePermission])

    useEffect(() => {
        if (employeePermissions.length) {
            setUserPermission(employeePermissions);
        }
    }, [employeePermissions]);

    const handleBack = () => {
        navigate('/setting');
    }

    return (
        <main>
            <div className="main__container">
                <div className="main__tile_heading cus-btn-include-sec">
                    <span>
                        Role Permissions
                        <div className='infoPermission'>Modify the permissions of modules and actions available to each role.</div>
                    </span>
                    <div className="top_right_button_container">
                        <div className="top_right_button_container">
                            <button
                                className="btn cus-primary-transparent-btn bckbtn"
                                onClick={handleBack}
                            >
                                Back
                            </button>
                        </div>
                    </div>
                </div>
                {showLoader && <LoaderSpinner />}
                <div className="cus-page-content-sec">
                    <div className='content-container'>
                        <PermissionTable userPermissions={userPermission} dispatch={dispatch} />
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Permissions;
import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import LoaderSpinner from '../common/Loader';
import DatePickerComponent from '../common/DatePickerComponent';
import moment from 'moment';
import { toast } from 'react-toastify';
import { getDetailedStatement } from '../../store/actions/history';

const DetailedReports = (props) => {
    const { dispatch } = props;
    const [showDialog, setShowDialog] = useState(false);
    const [showLoader, setShowLoader] = useState(false);

    const [formData, setFormData] = useState({
        shipFrom: moment(new Date()).subtract(1, 'w'),
        shipTo: new Date()
    });

    const handleDialog = () => {
        setShowDialog(!showDialog);
    }

    const handleDateChange = (date, fieldName) => {
        let dateSelected = moment(date).utc(true).format();
        setFormData({ ...formData, [fieldName]: dateSelected })
    }

    const handleGetStatement = async (event) => {
        event.preventDefault();
        if (moment(formData.shipFrom).isAfter(moment(formData.shipTo))) {
            toast.error('Enter correct dates');
            return false
        }
        setShowLoader(true);
        let detailedDataRes = await dispatch(getDetailedStatement(formData));
        if (detailedDataRes?.data?.Status === 200) {
            setShowLoader(false);
            window.open(detailedDataRes.data.Entity);
            toast.success('Request successful');
            handleDialog();
        } else {
            setShowLoader(false);
            toast.error(detailedDataRes?.data?.Message?.AppStatusDescription);
        }
    }

    return (
        <>
            <button
                className="btn cus-seconday-bg-btn top-btn"
                onClick={handleDialog}
            >
                Detailed Statement
            </button>

            <Modal
                size="md"
                show={showDialog}
            >
                <Modal.Header>
                    <div className="modal-main-div">
                        <span className="modal-m-text">Statement</span>{" "}
                        {showLoader && <LoaderSpinner />}
                    </div>
                    <div className="modalDeleteWrapper">
                        <button
                            id="closeBtn"
                            type="button"
                            className="btn-close"
                            aria-label="Close"
                            onClick={handleDialog}
                        ></button>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="filterBatch createBatchForm">
                        <div className="row mb-4">
                            <div className='col-12 mb-4 fw600'>Select the dates</div>
                            <div className="col-6">
                                <label>From:</label>
                                <DatePickerComponent
                                    dateField={formData?.shipFrom}
                                    fieldName={'shipFrom'}
                                    handleDateChange={handleDateChange}
                                />
                            </div>
                            <div className="col-6">
                                <label>To:</label>
                                <DatePickerComponent
                                    dateField={formData?.shipTo}
                                    fieldName={'shipTo'}
                                    handleDateChange={handleDateChange}
                                />
                            </div>
                        </div>

                        <div className="formActions">
                            <button
                                className="btn cus-seconday-bg-btn"
                                onClick={() => handleDialog()}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn cus-seconday-bg-btn marginLeft5"
                                onClick={handleGetStatement}
                            >
                                Get Statement
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default DetailedReports;
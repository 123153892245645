import React, { useState, useEffect } from "react";
import { Button } from "bootstrap-4-react/lib/components";
import { useLocation, useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import FilterIncoming from "./filterIncoming";
import eye_icon from "../../assets/eye_icon.svg";
import edit_icon from "../../assets/edit_icon.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getFilterPackagesList,
  getPackagetypeList,
  getSourceListList,
  packageBackDetails,
} from "../../store/actions/incoming";
import BatchPagination from "../batch/BatchPagination";
import { featureSubmodulePermission, numOfPage, packages, permssionView, permssionEdit, returnsManagement } from "../../constant";
import moment from "moment";
import LoaderSpinner from "../common/Loader";
import ViewImage from "../common/ViewImage";
import _ from "lodash";
import { checkPermission, renderTableRow } from "../../config/utils";
import { v4 as uuidv4 } from 'uuid';

const PackageList = (props) => {
  const navigate = useNavigate();
  const UserValue = useSelector((state) => state.auth.user);
  const packagesList = useSelector(
    (state) => state.IncomingReducer.packagesList
  );
  const packagesListPageInfo = useSelector(
    (state) => state.IncomingReducer.packagesListPageInfo
  );
  const packagesListStatus = useSelector(
    (state) => state.IncomingReducer.packagesListStatus
  );
  const { packageBackDetailsObj } = useSelector((state) => state.IncomingReducer);
  const dispatch = useDispatch();
  const location = useLocation();
  const [loader, setLoader] = useState("");
  const [totalPages, setTotalPages] = useState(1);
  const [selectedRecord, setSelectedRecord] = useState([]);
  const [showHideImageModal, setShowHideImageModal] = useState(false);
  const [sellerList, setSellerList] = useState([]);
  const [allSellers, setAllSellers] = useState([]);

  useEffect(() => {
    if (packagesList && packagesList.length) {
      setTotalPages(
        Math.ceil(packagesListPageInfo?.TotalRecords / filterValue.pageSize)
      );
    }
  }, [packagesList]);

  let dataLoad = {
    pageIndex: 1,
    pageSize: 30,
    sortColumn: "",
    date: location?.state?.date,
    searchKeywords: "",
    packageTypes: [],
    sources: [],
    sellerIdList: [],
    companyIdList: [],
    trackingNumber: location?.state?.trackingNumber,
    showFilter: false,
    searchTxt: '',
    sortOrder: 'DESCENDING',
    showSearch: false,
    companySellerId: 0,
    isSuperAdmin: UserValue?.Value?.isSuperAdmin
  }

  const [filterValue, setFilterValue] = useState({});

  useEffect(() => {
    if (!_.isEmpty(packageBackDetailsObj)) {
      setFilterValue({ ...filterValue, ...packageBackDetailsObj });
    } else {
      setFilterValue({ ...dataLoad });
    }
  }, []);

  useEffect(() => {
    if (location?.state?.date && moment(location?.state?.date).isValid()) {
      if (!_.isEmpty(filterValue)) {
        fetchFilterPackageList();
      }
    } else {
      navigate("/incoming-packages");
    }
  }, [filterValue.pageIndex, filterValue.pageSize, filterValue.sortColumn, filterValue.sortOrder]);

  useEffect(() => {
    if (packagesListStatus === "pending") {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [packagesListStatus]);

  const fetchFilterPackageList = () => {
    let data = {
      pageIndex: filterValue.pageIndex,
      pageSize: filterValue.pageSize,
      sortColumn: filterValue.sortColumn,
      sortOrder: filterValue.sortOrder,
      date: location?.state?.trackingNumber ? undefined : location?.state?.date,
      searchKeywords: filterValue.searchTxt,
      isSuperAdmin: UserValue?.Value?.isSuperAdmin,
      trackingNumber: location?.state?.trackingNumber || '',
      sources: _.map(filterValue.sources, "id"),
      packageTypes: _.map(filterValue.packageTypes, "id"),
      sellerIdList: _.map(filterValue.companySellerId, "id"),
      companyIdList: _.map(filterValue.companyIdList, "Id"),
    };
    dispatch(getFilterPackagesList(data));
  };

  useEffect(() => {
    window.scroll(0, 0);
    dispatch(getSourceListList());
    dispatch(getPackagetypeList());
  }, []);

  const handleEditRow = (item) => {
    dispatch(packageBackDetails(filterValue));
    navigate(
      `/incoming-packages/fd-${location?.state?.date}-3pl/${item.internalId}`,
      {
        state: {
          view: false,
          boxId: item.internalId,
          date: location?.state?.date,
        },
      }
    );
  };
  const handleproductPageNavigation = (item) => {
    navigate(`/incoming-products/fd-${location?.state?.date}-3pl`, {
      state: {
        trackingNumber: item.trackingNumber,
        backDate: location?.state?.date
      },
    });
  }
  const handleViewRow = (item) => {
    dispatch(packageBackDetails(filterValue));
    navigate(`/incoming-packages/fd-${location?.state?.date}-3pl/${item.internalId}`, {
      state: {
        view: true,
        boxId: item.internalId,
        date: location?.state?.date,
      },
    });
  };

  const handleSortingCoulmn = (event, sortColumn) => {
    event.preventDefault();
    let newSortOrder = filterValue.sortOrder === "ASCENDING" ? "DESCENDING" : "ASCENDING";
    setFilterValue({
      ...filterValue,
      sortColumn: sortColumn,
      sortOrder: newSortOrder,
    });
  };

  const handleSearchFilter = (e, type) => {
    if (type === "clear") {
      handleResetSearch();
      return;
    }
    if (filterValue.pageIndex !== 1) {
      setFilterValue({ ...filterValue, pageIndex: 1 });
    } else {
      fetchFilterPackageList();
    }
  };

  const handleResetSearch = () => {
    let data = {
      ...dataLoad,
      pageSize: filterValue.pageSize
    };
    setFilterValue({ ...data });
    dispatch(getFilterPackagesList(data));
  }

  const handleEnterClick = (e) => {
    if (e.key === "Enter") {
      handleSearchFilter(e, "enter");
    }
  };

  const handleBlur = (e) => {
    const { value } = e.target;
    setFilterValue({ ...filterValue, searchTxt: value.trim() });
  };

  const handleFilterToggle = (key, value) => {
    setFilterValue({ ...filterValue, [key]: value });
  }

  const handleReset = () => {
    setFilterValue({ ...dataLoad, showFilter: true, pageSize: filterValue.pageSize });
    let data = JSON.parse(JSON.stringify(dataLoad));
    data.showFilter = true;
    data.pageSize = filterValue.pageSize
    dispatch(getFilterPackagesList(data));
    setSellerList(allSellers)
  }

  const handleBackNavigation = () => {
    const date = location?.state?.date
    if (location?.state?.trackingNumber) {
      navigate(`/incoming-products/fd-${date}-3pl`, {
        state: {
          date,
        },
      })
    }
    else {
      navigate("/incoming-packages");
      dispatch(packageBackDetails({}));
    }
  }
  return (
    <main>
      <div className="main__container">
        <div className="main__tile_heading cus-btn-include-sec">
          <div>
            Packages
            <h5 className="mt-2">
              {moment(new Date(location?.state?.date)).format("MM-DD-YYYY")}
            </h5>
          </div>
          <div className="top_right_button_container">
            <button className="btn cus-seconday-bg-btn top-btn m2" onClick={() => handleFilterToggle('showFilter', !filterValue.showFilter)}>Filter</button>
            <Button
              className="cus-seconday-bg-btn top-btn m2"
              onClick={() => handleFilterToggle('showSearch', !filterValue.showSearch)}
            >
              <i className="fa fa-search" aria-hidden="true"></i> Search
            </Button>
            <Button
              className="cus-primary-transparent-btn top-btn"
              onClick={() => { handleBackNavigation() }}
            >
              Back
            </Button>
          </div>
        </div>
        {loader && <LoaderSpinner />}
        <div
          className={
            filterValue.showSearch
              ? "filterContainer show searchBox"
              : "filterContainer searchBox"
          }
        >
          <div className="searchInputBox">
            <input
              value={filterValue.searchTxt}
              onBlur={handleBlur}
              onKeyDown={handleEnterClick}
              onChange={(e) => setFilterValue({ ...filterValue, searchTxt: e.target.value })}
              className="form-control"
              placeholder="Search Tracking Number, Package Type, Carrier or Source"
            />
          </div>
          <button
            className="btn cus-seconday-bg-btn marginLeft5 searchBtn"
            onClick={(e) => handleSearchFilter(e, "search")}
          >
            <i className="fa fa-search" aria-hidden="true"></i> Search
          </button>
          <button
            disabled={!filterValue.searchTxt}
            className="btn cus-primary-transparent-btn clearbtn"
            onClick={(e) => handleSearchFilter(e, "clear")}
          >
            Clear Search
          </button>
        </div>
        {filterValue.showFilter && (
          <FilterIncoming
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            getFilterPackagesList={getFilterPackagesList}
            dataLoad={dataLoad}
            handleReset={handleReset}
            sellerList={sellerList}
            setSellerList={setSellerList}
            allSellers={allSellers}
            setAllSellers={setAllSellers}
          />
        )}

        <div className="main_content cus-page-content-sec">
          <div className="main_content_table_history incomingTbl">
            <div className="tableFixHead">
              <Table className="table table-height cus-left-right-col-layout">
                <thead>
                  <th>Thumbnail</th>
                  {UserValue?.Value?.roleName !== 'Seller' && (
                    <th>Company / Seller Name</th>
                  )}
                  <th>Products</th>
                  <th>
                    <div
                      className={
                        filterValue.sortColumn === "trackingNumber"
                          ? "active"
                          : "sortColumn"
                      }
                      onClick={(e) => handleSortingCoulmn(e, "trackingNumber")}
                    >
                      Tracking Number
                      <i
                        className={
                          filterValue.sortColumn === "trackingNumber" &&
                            filterValue.sortOrder === "ASCENDING"
                            ? "fa fa-arrow-up"
                            : "fa fa-arrow-down"
                        }
                        aria-hidden="true"
                      ></i>
                    </div>
                  </th>
                  <th>
                    <div
                      className={
                        filterValue.sortColumn === "carrierName"
                          ? "active carrierName"
                          : "sortColumn carrierName"
                      }
                      onClick={(e) => handleSortingCoulmn(e, "carrierName")}
                    >
                      Carrier
                      <i
                        className={
                          filterValue.sortColumn === "carrierName" &&
                            filterValue.sortOrder === "ASCENDING"
                            ? "fa fa-arrow-up"
                            : "fa fa-arrow-down"
                        }
                        aria-hidden="true"
                      ></i>
                    </div>
                  </th>

                  <th>
                    <div
                      className={
                        filterValue.sortColumn === "packageType"
                          ? "active"
                          : "sortColumn"
                      }
                      onClick={(e) => handleSortingCoulmn(e, "packageType")}
                    >
                      Package Type
                      <i
                        className={
                          filterValue.sortColumn === "packageType" &&
                            filterValue.sortOrder === "ASCENDING"
                            ? "fa fa-arrow-up"
                            : "fa fa-arrow-down"
                        }
                        aria-hidden="true"
                      ></i>
                    </div>
                  </th>
                  <th>
                    <div
                      className={
                        filterValue.sortColumn === "source" ? "active" : "sortColumn"
                      }
                      onClick={(e) => handleSortingCoulmn(e, "source")}
                    >
                      Source
                      <i
                        className={
                          filterValue.sortColumn === "source" &&
                            filterValue.sortOrder === "ASCENDING"
                            ? "fa fa-arrow-up"
                            : "fa fa-arrow-down"
                        }
                        aria-hidden="true"
                      ></i>
                    </div>
                  </th>
                  <th>Action</th>
                </thead>
                <tbody>
                  {packagesList && packagesList.length > 0 && (
                    <>
                      {packagesList.map((item, index) => (
                        <tr key={uuidv4()}>
                          <td>
                            {item.thumbnailUrl ? (
                              <div
                                className="thumbImg"
                                onClick={() => {
                                  setSelectedRecord(item);
                                  setShowHideImageModal(!showHideImageModal);
                                }}
                              >
                                <img src={item.thumbnailUrl} alt="" />
                              </div>
                            ) : (
                              <></>
                            )}
                          </td>
                          {UserValue?.Value?.roleName !== 'Seller' && (
                            <td>{item.companySellerName}</td>
                          )}
                          <td>
                            <div
                              className="seller-modal"
                              onClick={() =>
                                handleproductPageNavigation(item)
                              }
                            >
                              {item?.products}
                            </div>
                          </td>
                          <td>
                            {item.trackingUrl ? (
                              <a
                                rel="noreferrer"
                                href={item.trackingUrl}
                                target="_blank"
                              >
                                {item.trackingNumber}
                              </a>
                            ) : (
                              <>{item.trackingNumber}</>
                            )}
                          </td>
                          <td>{item.carrierName}</td>
                          <td>{item.packageType}</td>
                          {/* <td>{item.carrier}</td> */}
                          <td>{item.source}</td>
                          <td>
                            {checkPermission(returnsManagement, packages, permssionView, featureSubmodulePermission, props.employeePermissions) && (
                              <button className="actionItem">
                                <img
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleViewRow(item)}
                                  src={eye_icon}
                                  className="editIcon"
                                  alt=""
                                />
                              </button>)}
                            {checkPermission(returnsManagement, packages, permssionEdit, featureSubmodulePermission, props.employeePermissions) && (
                              UserValue?.Value?.roleName !== "Seller" && <button className="actionItem">
                                <img
                                  style={{ cursor: "pointer" }}
                                  onClick={() => handleEditRow(item)}
                                  src={edit_icon}
                                  alt=""
                                  className="editIcon"
                                />
                              </button>

                            )}

                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                  {packagesListStatus === "success" && packagesList.length === 0 && (
                    <>{renderTableRow(8, 'No Record Found')}</>
                  )}
                  {packagesListStatus !== "success" && (renderTableRow(8, 'Loading...'))}
                </tbody>
              </Table>
            </div>
          </div>
          <BatchPagination
            gridData={packagesList || []}
            totalVisualRecords={packagesList?.length}
            numberOfPage={filterValue.pageSize}
            numOfPage={numOfPage}
            loader={loader}
            currentPage={filterValue.pageIndex}
            totalPages={totalPages || 0}
            totalRecords={packagesListPageInfo?.TotalRecords}
            setCurrentPage={(value) => setFilterValue({ ...filterValue, pageIndex: value })}
            setNumberOfPage={(value) => setFilterValue({ ...filterValue, pageSize: value })}
          />
          <ViewImage
            selectedRecord={selectedRecord}
            setShowHideImageModal={setShowHideImageModal}
            showHideImageModal={showHideImageModal}
          />
        </div>
      </div>
    </main>
  );
};

export default PackageList;

import { incomingConstant } from "../constants/incoming";
const initialState = {
  packageYears: [],
  packageYearsStatus: "",
  packageDetails: [],
  packageDetailsStatus: "",
  packagesList: [],
  packagesListStatus: "",
  packagesListPageInfo: 0,
  sourcesList: [],
  PackageTypeList: [],
  packageDetailsById: {},
  packageDetailStatus: "",
  allCarriersList: [],
  statusList: [],
  selectedIncomingYear: 0,
  selectedProductYear: 0,
  productBackDetailsObj: {},
  packageBackDetailsObj: {},
  packageYearBackIndexDetails: 0,
  productYearBackIndexDetails: 0,
};
export default function IncomingReducer(state = initialState, action = "") {
  const { type, payload } = action;
  switch (type) {
    case incomingConstant.GET_YEAR_LIST_PENDING:
      return {
        ...state,
        packageYears: [],
        packageYearsStatus: "pending",
      };

    case incomingConstant.GET_YEAR_LIST_SUCCESS:
      return {
        ...state,
        packageYears: payload,
        packageYearsStatus: "success",
      };
    case incomingConstant.GET_YEAR_LIST_FAILED:
      return {
        ...state,
        packageYears: [],
        packageYearsStatus: "failed",
      };
    case incomingConstant.GET_DAILY_PACKAGE_COUNT_PENDING:
      return {
        ...state,
        packageDetails: [],
        packageDetailsStatus: "pending",
      };
    case incomingConstant.GET_DAILY_PACKAGE_COUNT_SUCCESS:
      return {
        ...state,
        packageDetailsStatus: "success",
        packageDetails: payload,
      };
    case incomingConstant.GET_DAILY_PACKAGE_COUNT_FAILED:
      return {
        ...state,
        packageDetailsStatus: "failed",
        packageDetails: [],
      };

    case incomingConstant.GET_FILTER_DAILY_PACKAGE_COUNT_PENDING:
      return {
        ...state,
        packagesListStatus: "pending",
        packagesList: [],
        packagesListPageInfo: 0,
      };
    case incomingConstant.GET_FILTER_DAILY_PACKAGE_COUNT_SUCCESS:
      return {
        ...state,
        packagesListStatus: "success",
        packagesList: payload.EntityList || [],
        packagesListPageInfo: payload,
      };
    case incomingConstant.GET_FILTER_DAILY_PACKAGE_COUNT_FAILED:
      return {
        ...state,
        packagesListStatus: "failed",
        packagesList: [],
        packagesListPageInfo: 0,
      };
    case incomingConstant.GET_PACKAGE_TYPE_LIST_SUCCESS:
      return {
        ...state,
        PackageTypeList: payload || [],
      };
    case incomingConstant.GET_SOURCELIST_SUCCESS:
      return {
        ...state,
        sourcesList: payload || [],
      };
    case incomingConstant.GET_PACKAGE_DETAILS_PENDING:
      return {
        ...state,
        packageDetailsById: {},
        packageDetailStatus: "pending",
      };

    case incomingConstant.GET_PACKAGE_DETAILS_SUCCESS:
      return {
        ...state,
        packageDetailsById: payload,
        packageDetailStatus: "success",
      };

    case incomingConstant.GET_PACKAGE_DETAILS_FAILED:
      return {
        ...state,
        packageDetailsById: [],
        packageDetailStatus: "failed",
      };
    case incomingConstant.GET_ALL_CARRIER_LIST_PENDING:
      return {
        ...state,
        allCarriersList: [],
      };
    case incomingConstant.GET_ALL_CARRIER_LIST_SUCCESS:
      return {
        ...state,
        allCarriersList: payload,
      };
    case incomingConstant.GET_ALL_CARRIER_LIST_FAILED:
      return {
        ...state,
        allCarriersList: "",
      };
    case incomingConstant.GET_ALL_STATUS_LIST_SUCCESS:
      return {
        ...state,
        statusList: payload,
      };
    case incomingConstant.INCOMING_SELECTED_YEAR:
      return {
        ...state,
        selectedIncomingYear: payload,
      };
    case incomingConstant.PRODUCT_SELECTED_YEAR:
      return {
        ...state,
        selectedProductYear: payload,
      };
    case incomingConstant.PRODUCT_BACK_DETAILS:
      return {
        ...state,
        productBackDetailsObj: payload,
      };
    case incomingConstant.PACKAGE_BACK_DETAILS:
      return {
        ...state,
        packageBackDetailsObj: payload,
      };
    case incomingConstant.PACKAGE_YEAR_BACK_DETAILS:
      return {
        ...state,
        packageYearBackIndexDetails: payload,
      };
    case incomingConstant.PRODUCT_YEAR_BACK_DETAILS:
      return {
        ...state,
        productYearBackIndexDetails: payload,
      };
    default:
      return state;
  }
}



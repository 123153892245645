import React, { useEffect, useState } from "react";
import { Button, Table } from "bootstrap-4-react/lib/components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getAppConfiguration, updateConfig } from "../../store/actions/auth";
import { BsPencil, BsSave2 } from "react-icons/bs";
import { toast } from "react-toastify";
import { featureSubmodulePermission, permssionEdit, settings, SUBF_CONFIGURATION } from "../../constant";
import { checkPermission } from "../../config/utils";

export default function Configuration() {
  const navigate = useNavigate();
  const configData = useSelector((state) => state?.auth?.configurations);
  const [configList, setConfigList] = useState([]);
  const [configValue, setConfigValue] = useState();
  const userDetails = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const [column, setColumn] = useState();
  const employeePermissions = useSelector((state) => state?.sidebar?.employeePermissions);

  useEffect(() => {
    setConfigList(configData);
  }, [configData]);

  useEffect(() => {
    if (userDetails && !userDetails?.Value?.isSuperAdmin) {
      navigate("/setting");
    }
  }, [userDetails]);

  const editColumn = (i) => {
    setEdit(true);
    setColumn(i);
  };

  const onConfigSave = async (data) => {
    let payload = {
      id: data.id,
      configKey: data.configKey,
      configValue: configValue,
      configName: data.configName,
    };
    let res = await Promise.resolve(dispatch(updateConfig(payload)));
    if (res?.data.Status === 200) {
      dispatch(getAppConfiguration());
      toast.success("Request successfully");
    }
    setEdit(false);
    setConfigValue(null);
  };

  return (
    <main>
      <div className="main__container">
        <div className="main__tile_heading cus-btn-include-sec">
          Configuration
          <div className="top_right_button_container">
            <Button
              className="cus-primary-transparent-btn"
              onClick={() => {
                navigate("/setting");
              }}
            >
              Back
            </Button>
          </div>
        </div>
        <div className="main_content_table incomingTbl">
          <div className="tableFixHead">

            <Table className="table table-borderless">
              <thead border="Green">
                <tr>
                  <th width="5%" style={{ textAlign: 'center' }}>
                    #
                  </th>
                  <th className="trows-s" width="35%">
                    Config Name
                  </th>
                  <th className="trows-s" width="35%">
                    Config Key
                  </th>
                  <th className="trows-s" width="20%">
                    Config Value
                  </th>
                </tr>
              </thead>
              <tbody>
                {configList?.map((d, i) => (
                  <>
                    <tr key={d?.id}>
                      <td align="center">{i + 1}</td>
                      <td>{d?.configName}</td>
                      <td>{d?.configKey}</td>
                      <td className="alignLeft">
                        {edit && column === i ? (
                          <>
                            <div className="d-inline-flex">
                              <div className="thresh-input-1">
                                <input
                                  className="cus-input-cls thresh-data"
                                  onChange={(e) => setConfigValue(e.target.value)}
                                  defaultValue={d?.configValue}
                                  type="number"
                                  onKeyDown={(evt) =>
                                    ["e", "E", "+", "-"].includes(evt.key) &&
                                    evt.preventDefault()
                                  }
                                />
                              </div>
                              <div>
                                <BsSave2
                                  className="penconEditIcon"
                                  onClick={() => onConfigSave(d)}
                                  title="Save"
                                ></BsSave2>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="d-inline-flex">
                              <div>{d?.configValue}</div>
                              <div style={{ marginLeft: "40px" }}>
                                {checkPermission(settings, SUBF_CONFIGURATION, permssionEdit, featureSubmodulePermission, employeePermissions) && (
                                  <BsPencil
                                    className="cus-pencil-icon-sec"
                                    onClick={() => editColumn(i)}
                                    title="Edit"
                                  ></BsPencil>
                                )}
                              </div>
                            </div>
                          </>
                        )}
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </main>
  );
}

import { addressConstant } from "../constants/address.constant";

const initialState = {
    addressListObject: {},
    addressListStatus: '',
    loadAddressList: [],
    loadAddressListStatus: '',
};

const AddressReducer = (state = initialState, { type, payload } = []) => {
    switch (type) {
        case addressConstant.GET_ADDRESSLIST_PENDING:
            return {
                ...state,
                addressListObject: {},
                addressListStatus: 'pending'
            };
        case addressConstant.GET_ADDRESSLIST_SUCCESS:
            return {
                ...state,
                addressListObject: payload,
                addressListStatus: 'success'
            };
        case addressConstant.GET_ADDRESSLIST_FAILED:
            return {
                ...state,
                addressListObject: {},
                addressListStatus: 'failed'
            };
        case addressConstant.GET_LOAD_ADDRESSLIST_PENDING:
            return {
                ...state,
                loadAddressList: [],
                loadAddressListStatus: 'pending'
            };
        case addressConstant.GET_LOAD_ADDRESSLIST_SUCCESS:
            return {
                ...state,
                loadAddressList: payload,
                loadAddressListStatus: 'success'
            };
        case addressConstant.GET_LOAD_ADDRESSLIST_FAILED:
            return {
                ...state,
                loadAddressList: [],
                loadAddressListStatus: 'failed'
            };
        default: return state;
    }
}

export default AddressReducer;
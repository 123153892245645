import { sidebarConstant } from '../constants'
const initialState = {
  employeePermissions: {}
};
export default function (state = initialState, action = '') {
  const { type, payload } = action;
  switch (type) {
    case sidebarConstant.GET_EMPLOYEE_PERMISSION_SUCCESS:
      return {
        ...state,
        employeePermissions: payload,
      };
    default:
      return state;
  }
}


import React, { useEffect, useState } from "react";
import { Button, Table } from "bootstrap-4-react/lib/components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import LoaderSpinner from "../common/Loader";
import eye_icon from "../../assets/eye_icon.svg";
import edit_icon from "../../assets/edit_icon.svg";
import { Tooltip as WmsTooltip } from "react-tooltip";
import BatchPagination from "../batch/BatchPagination";
import { featureSubmodulePermission, numOfPage, permssionEdit, permssionView, settings, SUBF_MARGINCONFIGURATION } from "../../constant";
import { fetchAllMargin } from "../../store/actions/MarginConfig.action";
import MarginFilter from "./MarginFilter";
import { marginConfigBackDetails } from "../../store/actions/employee";
import { checkPermission } from "../../config/utils";

const MarginConfiguration = () => {
    const navigate = useNavigate();
    const [showLoader, setShowLoader] = useState(false);
    const userDetails = useSelector((state) => state.auth.user);
    const marginData = useSelector((state) => state.MarginReducer.marginList);
    const marginListStatus = useSelector((state) => state.MarginReducer.marginListStatus);
    const { marginConfgPrevData } = useSelector((state) => state.employee);

    const [selectedCompanies, setSelectedCompanies] = useState(marginConfgPrevData?.selectedCompanies || []);
    const [selectedSellers, setSelectedSellers] = useState(marginConfgPrevData?.selectedSellers || []);

    const [totalPages, setTotalPages] = useState(1);
    const [sortOrder, setSortOrder] = useState(marginConfgPrevData?.sortOrder || "DESCENDING");
    const [sortingColumn, setSortingColumn] = useState(marginConfgPrevData?.sortingColumn || "");
    const [numberOfPage, setNumberOfPage] = useState(marginConfgPrevData?.numberOfPage || 30);
    const [currentPage, setCurrentPage] = useState(marginConfgPrevData?.currentPage || 1);
    const [marginList, setMarginList] = useState([]);
    const [companyIds, setCompanyIds] = useState(marginConfgPrevData?.companyIds || []);
    const [selllerIds, setSelllerIds] = useState(marginConfgPrevData?.selllerIds || []);
    const dispatch = useDispatch();
    const [filterData, setfilterData] = useState({});
    const [sellerList, setSellerList] = useState([]);
    const [allSellers, setAllSellers] = useState([]);
    const employeePermissions = useSelector((state) => state?.sidebar?.employeePermissions);
    const UserValue = useSelector((state) => state.auth.user);
    useEffect(() => {
        if (marginListStatus === 'pending') {
            setShowLoader(true);
        } else {
            setShowLoader(false);
        }
    }, [marginListStatus]);

    useEffect(() => {
        setMarginList(marginData?.Entity?.listMarginOrdersEntity || []);
        setTotalPages(
            Math.ceil(marginData?.Entity?.TotalRecords / numberOfPage)
        );
    }, [marginData]);

    /**redirect user if not super admin */
    useEffect(() => {
        if (userDetails && !userDetails?.Value?.isSuperAdmin) {
            navigate("/setting");
        }
    }, [userDetails]);

    const getAllMargins = async () => {
        let data = {
            pageIndex: currentPage,
            pageSize: numberOfPage,
            sortColumn: sortingColumn,
            sortOrder: sortOrder,
            filterCompanyIds: companyIds,
            filterSellerIds: selllerIds,
        }
        dispatch(fetchAllMargin(data));
    }

    const handleSortingColumn = (event, sortColumn) => {
        event.preventDefault();
        let newSortOrder = "DESCENDING";
        setSortingColumn(sortColumn);
        if (sortColumn === sortingColumn) {
            setSortOrder(sortOrder === "ASCENDING" ? "DESCENDING" : "ASCENDING");
            newSortOrder = sortOrder === "ASCENDING" ? "DESCENDING" : "ASCENDING";
        } else {
            setSortOrder("DESCENDING");
        }
        setfilterData({
            ...filterData,
            sortColumn: sortColumn,
            sortOrder: newSortOrder,
        });
    }

    const handleNavigateToMargin = (item, type) => {
        saveFilterDetails();
        if (type === 'edit') {
            navigate(`/margin-configuration/mc-${item.companySellerId}-3pl`, {
                state: {
                    data: item,
                    edit: true
                },
            });
        } else {
            navigate(`/margin-configuration/mc-${item.companySellerId}-3pl`, {
                state: {
                    data: item,
                    edit: false
                },
            });
        }
    };

    const handleFilterValue = (value, type) => {
        if (type === 'company') {
            setCompanyIds(value ? value : []);
        } else if (type === 'seller') {
            setSelllerIds(value ? value : []);
        }
    }

    const handleFilter = () => {
        let data = {
            pageIndex: 1,
            pageSize: 30,
            sortColumn: sortingColumn,
            sortOrder: sortOrder,
            filterCompanyIds: companyIds,
            filterSellerIds: selllerIds,
        }
        dispatch(fetchAllMargin(data));
        setCurrentPage(1)
        setNumberOfPage(30)
    }

    const handlePageView = () => {
        return (
            marginListStatus === 'success' && !marginList.length ? (
                <tr>
                    <td colSpan={4} align="center">No Record Found</td>
                </tr>
            ) : (
                <tr>
                    <td colSpan={4} align="center">Loading...</td>
                </tr>
            )
        )
    }

    useEffect(() => {
        getAllMargins();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numberOfPage, sortingColumn, sortOrder, currentPage]);

    const saveFilterDetails = () => {
        let data = {
            sortOrder: sortOrder,
            sortingColumn: sortingColumn,
            numberOfPage: numberOfPage,
            currentPage: currentPage,
            companyIds: companyIds,
            selllerIds: selllerIds,
            selectedCompanies: selectedCompanies,
            selectedSellers: selectedSellers,
        }
        dispatch(marginConfigBackDetails(data));
    }

    const handleSortingClass = (columnName, isArrow = false) => {
        if (isArrow) {
            return sortingColumn === columnName && sortOrder === "ASCENDING"
                ? "fa fa-arrow-up"
                : "fa fa-arrow-down";
        }
        return sortingColumn === columnName ? "active" : "sortColumn";
    };

    return (
        <main>
            <div className="main__container">
                <div className="main__tile_heading cus-btn-include-sec">
                    Margin Configuration
                    <div className="top_right_button_container">
                        <Button
                            className="cus-primary-transparent-btn"
                            onClick={() => {
                                navigate("/setting");
                                dispatch(marginConfigBackDetails({}));
                            }}
                        >
                            Back
                        </Button>
                    </div>
                </div>
                {UserValue?.Value?.roleName !== 'Seller' && (
                    <MarginFilter
                        userDetails={userDetails}
                        handleFilterValue={handleFilterValue}
                        companyIds={companyIds}
                        selllerIds={selllerIds}
                        handleFilter={handleFilter}
                        setCurrentPage={setCurrentPage}
                        setSortingColumn={setSortingColumn}
                        setSortOrder={setSortOrder}
                        numberOfPage={numberOfPage}
                        setCompanyIds={setCompanyIds}
                        setSelllerIds={setSelllerIds}
                        selectedCompanies={selectedCompanies}
                        setSelectedCompanies={setSelectedCompanies}
                        selectedSellers={selectedSellers}
                        setSelectedSellers={setSelectedSellers}
                        saveFilterDetails={saveFilterDetails}
                        sellerList={sellerList}
                        setSellerList={setSellerList}
                        allSellers={allSellers}
                        setAllSellers={setAllSellers}
                    />
                )}
                {showLoader && <LoaderSpinner />}
                <div className="cus-page-content-sec">
                    <div className="top_right_button_container cus-content-only-sec">
                        <div className="incoming_wrapper main_content_table incomingTbl">
                            <div className="tableFixHead">
                                <Table className="table table-borderless keyGenerate">
                                    <thead>
                                        <tr className="trow">
                                            <th className="trows-s" width="10%">#</th>
                                            <th className="trows-s" width="35%">
                                                <div
                                                    className={handleSortingClass('companyName')}
                                                    onClick={(e) => handleSortingColumn(e, "companyName")}
                                                    id="sortColumn"
                                                >
                                                    Company Name
                                                    <i
                                                        className={handleSortingClass('companyName', true)}
                                                        aria-hidden="true"
                                                    ></i>
                                                </div>
                                            </th>

                                            <th className="trows-s" width="35%">
                                                <div
                                                    className={handleSortingClass('sellerName')}
                                                    onClick={(e) => handleSortingColumn(e, "sellerName")}
                                                    id="sortColumn1"
                                                >
                                                    Seller Name
                                                    <i
                                                        className={handleSortingClass('sellerName', true)}
                                                        aria-hidden="true"
                                                    ></i>
                                                </div>
                                            </th>
                                            <th className="trows-s" width="20%">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {marginList.length > 0 ? (
                                            <>
                                                {marginList.map((item, index) => (
                                                    <tr key={item.companySellerId}>
                                                        <td className="trows-s">{index + 1}</td>
                                                        <td className="trows-s">{item.companyName}</td>
                                                        <td className="trows-s">{item.sellerName}</td>
                                                        <td className="flex-mob-res">
                                                            {checkPermission(settings, SUBF_MARGINCONFIGURATION, permssionView, featureSubmodulePermission, employeePermissions) && (
                                                                <img
                                                                    className="editIcon mr-15"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => handleNavigateToMargin(item, 'view')}
                                                                    src={eye_icon}
                                                                    alt=""
                                                                    id={`${item.companySellerId}-view`}
                                                                />
                                                            )}
                                                            {checkPermission(settings, SUBF_MARGINCONFIGURATION, permssionEdit, featureSubmodulePermission, employeePermissions) && (
                                                                <img
                                                                    className="editIcon mr-15"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => handleNavigateToMargin(item, 'edit')}
                                                                    src={edit_icon}
                                                                    alt=""
                                                                    id={`${item.companySellerId}-edit`}
                                                                />
                                                            )}
                                                            <WmsTooltip
                                                                anchorId={`${item.companySellerId}-view`}
                                                                content="View"
                                                                place="left"
                                                                className="tooltipWidth"
                                                            />
                                                            <WmsTooltip
                                                                anchorId={`${item.companySellerId}-edit`}
                                                                content="Edit"
                                                                place="left"
                                                                className="tooltipWidth"
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </>
                                        ) : handlePageView()}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
                {marginList.length > 0 && (
                    <BatchPagination
                        gridData={marginList}
                        totalVisualRecords={marginList.length}
                        numberOfPage={numberOfPage}
                        numOfPage={numOfPage}
                        loader={showLoader}
                        currentPage={currentPage}
                        totalPages={totalPages}
                        totalRecords={marginData?.Entity?.TotalRecords}
                        setCurrentPage={setCurrentPage}
                        setNumberOfPage={setNumberOfPage}
                    />
                )}
            </div>
        </main>
    );
}

export default MarginConfiguration;
import { barcodeConstant } from "../constants/barcode.constant";

const initialState = {
    barcodeColumnsStatus: '',
    barcodeColumnsList: [],
    barCodeIntegrationList: [],
    barCodeIntegrationListStatus: '',
    barCodeListingStatus: '',
    barCodeListing: [],
    barcodeSizeStatus: '',
    barcodeSizeList: [],
    barcodeTypeList: [],
    barcodeTypeStatus: '',
    barcodeList: {},
    barcodeStatus: '',
    csvUploadBackDetails: {},
    mappingBackDetails: {},
    barCodeListingBackDetails: {}
};

const BarcodeReducer = (state = initialState, { type, payload } = []) => {
    switch (type) {
        case barcodeConstant.GET_BARCODE_COLUMNS_PENDING:
            return {
                ...state,
                barcodeColumnsList: [],
                barcodeColumnsStatus: 'pending',
            };
        case barcodeConstant.GET_BARCODE_COLUMNS_SUCCESS:
            return {
                ...state,
                barcodeColumnsList: payload,
                barcodeColumnsStatus: 'success',
            };
        case barcodeConstant.GET_BARCODE_COLUMNS_FAILED:
            return {
                ...state,
                barcodeColumnsList: [],
                barcodeColumnsStatus: 'failed',
            };
        case barcodeConstant.GET_INTEGRATION_LIST_PENDING:
            return {
                ...state,
                barCodeIntegrationList: [],
                barCodeIntegrationListStatus: 'pending',
            };
        case barcodeConstant.GET_INTEGRATION_LIST_SUCCESS:
            return {
                ...state,
                barCodeIntegrationList: payload,
                barCodeIntegrationListStatus: 'success',
            };
        case barcodeConstant.GET_INTEGRATION_LIST_FAILED:
            return {
                ...state,
                barCodeIntegrationList: [],
                barCodeIntegrationListStatus: 'failed',
            };
        case barcodeConstant.GET_COLUMN_LISTING_PENDING:
            return {
                ...state,
                barCodeListing: [],
                barCodeListingStatus: 'pending',
            };
        case barcodeConstant.GET_COLUMN_LISTING_SUCCESS:
            return {
                ...state,
                barCodeListing: payload,
                barCodeListingStatus: 'success',
            };
        case barcodeConstant.GET_COLUMN_LISTING_FAILED:
            return {
                ...state,
                barCodeListing: [],
                barCodeListingStatus: 'failed',
            }
        case barcodeConstant.GET_BARCODE_SIZE_PENDING:
            return {
                ...state,
                barcodeSizeList: [],
                barcodeSizeStatus: 'pending',
            };
        case barcodeConstant.GET_BARCODE_SIZE_SUCCESS:
            return {
                ...state,
                barcodeSizeList: payload,
                barcodeSizeStatus: 'success',
            };
        case barcodeConstant.GET_BARCODE_SIZE_FAILED:
            return {
                ...state,
                barcodeSizeList: [],
                barcodeSizeStatus: 'failed',
            };
        case barcodeConstant.GET_ALL_BARCODE_TYPE_PENDING:
            return {
                ...state,
                barcodeTypeList: [],
                barcodeTypeStatus: 'pending',
            };
        case barcodeConstant.GET_ALL_BARCODE_TYPE_SUCCESS:
            return {
                ...state,
                barcodeTypeList: payload,
                barcodeTypeStatus: 'success',
            };
        case barcodeConstant.GET_ALL_BARCODE_TYPE_FAILED:
            return {
                ...state,
                barcodeTypeList: [],
                barcodeTypeStatus: 'failed',
            };
        case barcodeConstant.BARCODE_LISTING_PENDING:
            return {
                ...state,
                barcodeList: {},
                barcodeStatus: 'pending',
            };
        case barcodeConstant.BARCODE_LISTING_SUCCESS:
            return {
                ...state,
                barcodeList: payload,
                barcodeStatus: 'success',
            };
        case barcodeConstant.BARCODE_LISTING_FAILED:
            return {
                ...state,
                barcodeList: {},
                barcodeStatus: 'failed',
            };
        case barcodeConstant.BARCODE_BACKDETAILS:
            return {
                ...state,
                csvUploadBackDetails: payload
            };
        case barcodeConstant.BARCODE_MAPPING_BACKDETAILS:
            return {
                ...state,
                mappingBackDetails: payload
            };
        case barcodeConstant.BARCODE_LISTING_BACKDETAILS:
            return {
                ...state,
                barCodeListingBackDetails: payload
            };
        default: return state;
    }
}

export default BarcodeReducer;
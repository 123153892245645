import React from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from "bootstrap-4-react/lib/components";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useState } from 'react';
import { createBatch } from '../../store/actions/batch.action';
import { batchModule, featureSubmodulePermission, orderManagement, permssionCreate } from '../../constant';
import { checkPermission } from "../../config/utils";

const CreateBatchDialog = (props) => {
    const { modalClass, setModalShow, modalShow, setShowCreateBatchDialog, createdBy, selectedData, fetchAllBatches } = props;
    const [batchName, setBatchName] = useState('');
    const employeePermissions = useSelector((state) => state?.sidebar?.employeePermissions);
    const dispatch = useDispatch();
    const saveBatch = async () => {
        let createSaveData = selectedData.map((item) => {
            return {
                "createdBy": createdBy?.Value?.employeeId,
                "sellerId": createdBy?.Value?.sellerId,
                "companyId": createdBy?.Value?.companyId,
                orderBatchDetailsId: 0,
                orderId: item.orderId,
                orderBatchId: 0
            }
        })
        let data = {
            "createdBy": createdBy?.Value?.employeeId,
            "sellerId": createdBy?.Value?.sellerId,
            "companyId": createdBy?.Value?.companyId,
            "createdDate": new Date(),
            // "modifiedBy": createdBy,
            "modifiedDate": new Date(),
            "isActive": true,
            "orderBatchId": 0,
            "orderBatchName": batchName,
            "orderBatchDetails": createSaveData
        };
        let res = await Promise.resolve(dispatch(createBatch(data)));
        if (res.data && res.data.Status === 200) {
            setShowCreateBatchDialog(false);
            toast.success(res?.data?.Message?.AppStatusDescription);
            fetchAllBatches();
        }
    };

    return (
        <Modal
            className={modalClass || ''}
            size="md"
            show={modalShow}
            onHide={() => setShowCreateBatchDialog(false)}
        >
            <Modal.Header closeButton>
                <div className="modal-main-div">
                    <text className="modal-m-text">Create a Batch</text>
                </div>
                <svg
                    className="btn-cclose close"
                    onClick={() => setModalShow(false)}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    viewBox="0 0 16 16"
                    style={{ marginRight: "-520px" }}
                    cursor="pointer"
                >
                    <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
            </Modal.Header>
            <Modal.Body>
                <div className='createBatchForm'>
                    <label>Name</label>
                    <div className=''>
                        <input className='form-control' type={'text'} onChange={(e) => setBatchName(e.target.value)} value={batchName} />
                    </div>
                    <div className='formActions'>
                        <Button className='btn  cus-seconday-bg-btn' onClick={() => setShowCreateBatchDialog(false)}>Cancel</Button>
                        {checkPermission(orderManagement, batchModule, permssionCreate, featureSubmodulePermission, employeePermissions) && (
                            <Button className='btn  cus-seconday-bg-btn marginLeft5' disabled={!batchName} onClick={saveBatch}>Create</Button>
                        )}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default CreateBatchDialog;
export const shipmentConstant = {
    GET_EMPLOYEE_ADDRESS_BY_ID_SUCCESS: 'app/shipment/GET_EMPLOYEE_ADDRESS_BY_ID_SUCCESS',
    GET_ALL_SERVICES_SUCCESS: 'app/shipment/GET_ALL_SERVICES_SUCCESS',
    GET_ALL_CARRIER_SUCCESS: 'app/shipment/GET_ALL_CARRIER_SUCCESS',
    GET_ALL_CARRIER_SERVICES_SUCCESS: 'app/shipment/GET_ALL_CARRIER_SERVICES_SUCCESS',
    GET_ALL_PACKAGES_BY_SERVICE_ID_SUCCESS: 'app/shipment/GET_ALL_PACKAGES_BY_SERVICE_ID_SUCCESS',
    GET_ALL_COUNTRIES_SUCCESS: 'app/shipment/GET_ALL_COUNTRIES_SUCCESS',
    CREATE_SHIPMENT_SUCCESS: 'app/shipment/CREATE_SHIPMENT_SUCCESS',
    EDIT_SHIPMENT_SUCCESS: 'app/shipment/EDIT_SHIPMENT_SUCCESS',
    GET_STATES_SUCCESS: 'app/shipment/GET_STATES_SUCCESS',
    GET_ACTIVE_CSV_LIST: 'app/shipment/GET_ACTIVE_CSV_LIST',
    GET_SHIPMENT_LIST: 'GET_SHIPMENT_LIST',
    GET_GET_ALL_API_KEYS_PENDING: 'GET_GET_ALL_API_KEYS_PENDING',
    GET_GET_ALL_API_KEYS_SUCCESS: 'GET_GET_ALL_API_KEYS_SUCCESS',
    GET_GET_ALL_API_KEYS_FAILED: 'GET_GET_ALL_API_KEYS_FAILED',
    GET_ALL_SELLER_CARRIER_SUCCESS: 'GET_ALL_SELLER_CARRIER_SUCCESS',
    GET_ALL_COMPANY_SELLER_SUCCESS: 'GET_ALL_COMPANY_SELLER_SUCCESS',
    RESET_COMPANY_SELLER_LIST: 'RESET_COMPANY_SELLER_LIST',
    RESET_CARRIERS: 'RESET_CARRIERS',
    GET_SIGNATURE_LIST: 'GET_SIGNATURE_LIST',
    GET_ALL_SELLER_LIST_PENDING: 'GET_ALL_SELLER_LIST_PENDING',
    GET_ALL_SELLER_LIST_SUCCESS: 'GET_ALL_SELLER_LIST_SUCCESS',
    GET_ALL_SELLER_LIST_FAILED: 'GET_ALL_SELLER_LIST_FAILED',
    GET_CONTENT_TYPE_LIST: 'GET_CONTENT_TYPE_LIST',
    GET_DIMENSION_UNIT_LIST: 'GET_DIMENSION_UNIT_LIST',
    GET_WEIGHT_UNIT_LIST: 'GET_WEIGHT_UNIT_LIST',
    CSV_UPLOAD_LIST_BACK_DETAILS : 'CSV_UPLOAD_LIST_BACK_DETAILS',
}

export const walletConstant = {
    GET_PREPAY_BALANCE_PENDING: 'GET_PREPAY_BALANCE_PENDING',
    GET_PREPAY_BALANCE_SUCCESS: 'GET_PREPAY_BALANCE_SUCCESS',
    GET_PREPAY_BALANCE_FAILED: 'GET_PREPAY_BALANCE_FAILED',
}

export const csvIntegrationConstant = {
    GET_COLUMN_LIST_SUCCESS: 'GET_COLUMN_LIST_SUCCESS',
    GET_COLUMN_LIST_PENDING: 'GET_COLUMN_LIST_PENDING',
    GET_COLUMN_LIST_FAILURE: 'GET_COLUMN_LIST_FAILURE',
    MAPPING_LIST_BACK_DETAILS: 'MAPPING_LIST_BACK_DETAILS',
}
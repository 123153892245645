import React from 'react';
import { Accordion } from "react-bootstrap";
import { Table } from "bootstrap-4-react/lib/components";
import { formatNumberWithCommas } from '../../config/utils';
import Switch from "react-switch";
import { v4 as uuidv4 } from 'uuid';

const AccordionList = (props) => {
    const { handleMarginValue, handleStatusChange, data, edit, isSeller } = props;
    return (
        <Accordion key={uuidv4} className="incomingAccordion pt-2" defaultActiveKey={[0]} alwaysOpen>
            {data && data.length > 0 && data.map((item, index) => (
                <Accordion.Item eventKey={index} key={item.id}>
                    <Accordion.Header>
                        {item.carrierCompanyName}
                        <i className="fa fa-chevron-down" aria-hidden="true"></i>
                    </Accordion.Header>
                    <Accordion.Body className='tableFixAcodianHead'>
                        <Table className="table table-borderless carrierListingTable">
                            <thead>
                                <tr className="trow">
                                    <th className="trows-s" width={"5%"}>#</th>
                                    <th className="trows-s" width={"25%"}>
                                        <div>Service</div>
                                    </th>
                                    {!isSeller && (
                                        <>
                                            <th className="trows-s alignLeft" width={"25%"}>Margin($)</th>
                                            <th className="trows-s alignLeft" width={"25%"}>Margin(%)</th>
                                        </>
                                    )}
                                    <th className="trows-s" width={"10%"}>Status</th>
                                </tr>
                            </thead>

                            <tbody>
                                {item?.services.map((serviceItem, serviceIndex) => (
                                    <tr key={serviceItem.id}>
                                        <td className="trows-s">{serviceIndex + 1}</td>
                                        <td className="trows-s">{serviceItem.serviceName}</td>
                                        {!isSeller && (
                                            <>
                                                <td className="trows-s">
                                                    {edit ? (
                                                        <div className="d-inline-flex">
                                                            <input
                                                                className="form-control inputFilter"
                                                                min={0}
                                                                onChange={(e) => handleMarginValue(e, index, serviceIndex)}
                                                                value={serviceItem?.marginFlat}
                                                                name="marginFlat"
                                                                type='number'
                                                                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                                                onBlur={(e) => handleMarginValue(e, index, serviceIndex, true)}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div className="d-inline-flex">
                                                            {formatNumberWithCommas(serviceItem?.marginFlat || 0)}
                                                        </div>
                                                    )}
                                                </td>
                                                <td className="alignLeft">
                                                    {edit ? (
                                                        <div className="d-inline-flex">
                                                            <input
                                                                className="form-control"
                                                                min={0}
                                                                onChange={(e) => handleMarginValue(e, index, serviceIndex)}
                                                                name="marginPercentage"
                                                                value={serviceItem?.marginPercentage}
                                                                type='number'
                                                                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                                                onBlur={(e) => handleMarginValue(e, index, serviceIndex, true)}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div className="d-inline-flex">
                                                            {formatNumberWithCommas(serviceItem?.marginPercentage || 0)}
                                                        </div>
                                                    )}
                                                </td>
                                            </>
                                        )}
                                        <td>
                                            <Switch
                                                key={serviceItem?.id}
                                                checked={serviceItem?.isActive}
                                                disabled={!edit}
                                                id='switchBtn'
                                                className="switchBtn"
                                                onChange={(checked) => handleStatusChange(checked, index, serviceIndex)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    );
}

export default AccordionList;
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFilterPackagesList } from "../../store/actions/incoming";
import _ from "lodash";
import { fetchCompanyFilters, fetchSellerFilters } from "../../store/actions/rateCard";
import { getSelectList } from "../../config/utils";
import SelectMenu from "../common/SelectMenu";
import { getAllSellersList } from "../../store/actions";

const FilterIncoming = (props) => {
  const { filterValue, setFilterValue, handleReset, sellerList, setSellerList, allSellers, setAllSellers } = props;
  const dispatch = useDispatch();
  const [packageTypeList, setPackageTypeList] = useState([]);
  const [sourcesList, setSourcesList] = useState([]);
  const [companyList, setCompanyList] = useState([]);
  const { filterCompanies, filterSellers } = useSelector((state) => state.RateCardReducer);
  const UserValue = useSelector((state) => state.auth.user);

  const sourcesListData = useSelector(
    (state) => state.IncomingReducer.sourcesList
  );
  const packageTypeListData = useSelector(
    (state) => state.IncomingReducer.PackageTypeList
  );

  useEffect(() => {
    let companyList = filterCompanies?.Entity?.listCompanyMasterOrdersEntity || [];
    let sellerList = filterSellers?.Entity?.listSellerMasterOrdersEntity || [];
    if (companyList.length) {
      setCompanyList(getSelectList(filterCompanies?.Entity?.listCompanyMasterOrdersEntity, 'Id', 'name'));
    }
    if (sellerList.length) {
      setAllSellers(getSelectList(sellerList, 'id', 'newSellerName'));
      setSellerList(getSelectList(sellerList, 'id', 'newSellerName'));
    }
  }, [filterCompanies, filterSellers]);

  useEffect(() => {
    let data = {
      pageIndex: 1,
      pageSize: 10,
      sortColumn: 'name',
      sortOrder: 'ascending'
    }; let sellerData = {
      screenType: 2,
      sortColumn: 'sellerName',
      sortOrder: 'ASCENDING'
    };
    dispatch(fetchCompanyFilters(data));
    dispatch(fetchSellerFilters(sellerData));
  }, []);

  useEffect(() => {
    let sourcesListCopyObj = JSON.parse(JSON.stringify(sourcesListData));
    let packageTypeListObj = JSON.parse(JSON.stringify(packageTypeListData));
    setSourcesList(getSelectList(sourcesListCopyObj, 'id', 'source'));
    setPackageTypeList(getSelectList(packageTypeListObj, 'id', 'packageType'));
  }, [sourcesListData, packageTypeListData]);

  const handleApplyFilter = () => {
    let data = { ...filterValue };
    data.pageIndex = 1;
    data.sortColumn = '';
    data.sources = _.map(filterValue.sources, "id");
    data.packageTypes = _.map(filterValue.packageTypes, "id");
    data.sellerIdList = _.map(filterValue.companySellerId, "id");
    data.companyIdList = _.map(filterValue.companyIdList, "Id");
    data.companySellerId = 0;
    dispatch(getFilterPackagesList(data));
    setFilterValue({ ...filterValue, pageIndex: 1, sortColumn: '', sellerIdList: filterValue.companySellerId });
  };

  const handleChangeFilter = (value, name) => {
    setFilterValue({ ...filterValue, [name]: value });
    if (name === "companyIdList") {
      let companyData = _.map(value, "Id");
      fetchSellers(companyData);
      if (filterValue?.companySellerId?.length) {
        const filteredSellers = filterValue.companySellerId.filter(seller =>
          companyData.includes(parseInt(seller.companyIds))
        );
        setFilterValue({ ...filterValue, companySellerId: filteredSellers, companyIdList: value })
      }
    }
  }

  const fetchSellers = async (companyList) => {
    if (companyList.length === 0) {
      setSellerList(allSellers);
      return;
    }
    let data = {
      companyIds: companyList,
    };
    let res = await getAllSellersList(data);
    if (res?.data?.Status === 200) {
      let dataList = res?.data?.Entity?.listSellerMasterOrdersEntity.map(
        (item) => {
          return {
            ...item,
            label: item.sellerAndCompany,
            value: item.id,
          };
        }
      );
      setSellerList(dataList);
    }
  };

  return (
    <>
      <div className={filterValue.showFilter ? 'filterContainer show historyFilter' : 'filterContainer historyFilter'}>
        <div className='row mb10 rowBottomSpace fontFix'>
          <div className='col-sm-12 col-lg-3'>
            <label className='labelHead'>Package Type</label>
            <SelectMenu
              optionList={packageTypeList}
              handleChange={(value) => handleChangeFilter(value, 'packageTypes')}
              value={filterValue.packageTypes}
              placeholderText={'Select package..'}
              name='test'
              isMulti={true}
              closeMenuOnSelect={false}
            />
          </div>

          <div className="col-sm-12 col-lg-3">
            <label className='labelHead'>Source</label>
            <SelectMenu
              optionList={sourcesList}
              handleChange={(value) => handleChangeFilter(value, 'sources')}
              value={filterValue.sources}
              placeholderText={'Select sources..'}
              name='test'
              isMulti={true}
              closeMenuOnSelect={false}
            />
          </div>
          {UserValue?.Value?.roleName !== 'Seller' && (
            <>
              <div className='col-sm-12 col-lg-3'>
                <label className='labelHead'>Company</label>
                <SelectMenu
                  optionList={companyList}
                  handleChange={(value) => handleChangeFilter(value, 'companyIdList')}
                  value={filterValue.companyIdList}
                  placeholderText={'Select company..'}
                  name='test'
                  isMulti={true}
                  closeMenuOnSelect={false}
                />
              </div>

              <div className='col-sm-12 col-lg-3'>
                <label className='labelHead'>Seller</label>
                <SelectMenu
                  optionList={sellerList}
                  handleChange={(value) => handleChangeFilter(value, 'companySellerId')}
                  value={filterValue.companySellerId}
                  placeholderText={'Select sellers..'}
                  name='test'
                  isMulti={true}
                  closeMenuOnSelect={false}
                />
              </div>
            </>
          )}

          <div className="col-12">
            <div className="filterActionReturn mt-3">
              <button
                className="btn cus-primary-transparent-btn top-btn"
                onClick={handleReset}
              >
                Clear
              </button>
              <button
                className="btn cus-seconday-bg-btn marginLeft5"
                onClick={handleApplyFilter}
              >
                Apply Filter
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterIncoming;

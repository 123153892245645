import React, { useEffect, useState } from "react";
import { Button } from "bootstrap-4-react/lib/components";
import LoaderSpinner from "../../common/Loader";
import Table from "react-bootstrap/Table";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import BatchPagination from "../../batch/BatchPagination";
import { numOfPage } from "../../../constant";
import { renderTableRow } from "../../../config/utils";
import BarcodeListingFilter from "./barcodeListingFilter";
import { barCodeLisitingBackDetails, getBarcodeList, updateBarCodes } from "../../../store/actions/barcode.action";
import moment from 'moment';
import _ from "lodash";
import { toast } from "react-toastify";

const BarcodeUploadedList = () => {
  const location = useLocation();
  const id = location?.state?.Id;
  const [isEditable, setIsEditable] = useState(location?.state?.isEdit);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const { barcodeList, barcodeStatus, barCodeListingBackDetails } = useSelector(
    (state) => state.barcodeReducer
  );
  const dispatch = useDispatch();
  const [totalPages, setTotalPages] = useState(1);
  const [productList, setProductList] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isFormEdited, setIsFormEdited] = useState(false);

  let dataLoad = {
    pageIndex: 1,
    pageSize: 30,
    sortColumn: '',
    sortOrder: 'DESCENDING',
    barCodeProductIntegrationId: id,
    sellerIdList: [],
    companyIdList: [],
  };
  const [filterData, setfilterData] = useState({});

  useEffect(() => {
    if (!_.isEmpty(barCodeListingBackDetails)) {
      setfilterData({ ...filterData, ...barCodeListingBackDetails });
    } else {
      setfilterData({ ...dataLoad });
    }
  }, []);

  useEffect(() => {
    if (barcodeStatus === "pending") {
      setLoader(true);
    } else {
      setLoader(false);
    }
  }, [barcodeStatus]);

  useEffect(() => {
    if (barcodeList?.TotalRecords) {
      setTotalRecords(barcodeList?.TotalRecords);
      setTotalPages(
        Math.ceil(barcodeList?.TotalRecords / (filterData.pageSize || 1))
      );
    }
    if (barcodeList?.listBarCodeProductEntities) {
      setProductList(barcodeList?.listBarCodeProductEntities);
    }
  }, [barcodeList]);

  useEffect(() => {
    if (!_.isEmpty(filterData)) {
      fetchBarcodeDataList();
    }
  }, [filterData.pageSize, filterData.pageIndex, filterData.sortOrder]);

  useEffect(() => {
    if (barcodeList?.listBarCodeProductEntities) {
      let data1 = [...barcodeList?.listBarCodeProductEntities];
      let findEdited = _.differenceWith(productList, data1, _.isEqual);
      if (findEdited.length) {
        setIsFormEdited(true);
      } else {
        setIsFormEdited(false);
      }
    }
  }, [productList])

  const fetchBarcodeDataList = () => {
    fetchList('')
  };

  const handleReset = () => {
    fetchList('reset');
  }

  const fetchList = (type) => {
    let data = {
      pageIndex: filterData.pageIndex,
      pageSize: filterData.pageSize,
      sortColumn: filterData.sortColumn,
      sortOrder: filterData.sortOrder,
      companySellerId: 0,
      roleId: 0,
      barCodeProductIntegrationId: id,
      sellerIdList: _.map(filterData.sellerIdList, "id"),
      companyIdList: _.map(filterData.companyIdList, "Id"),
    };
    if (type === 'reset') {
      data.pageIndex = 1;
      data.sellerIdList = [];
      data.companyIdList = [];
      data.pageIndex === filterData.pageIndex && dispatch(getBarcodeList(data));
      setfilterData(data)
    } else {
      dispatch(getBarcodeList(data));
    }
    dispatch(barCodeLisitingBackDetails(filterData));
  }

  const handleSortingColumn = (event, sortColumn) => {
    event.preventDefault();
    let newSortOrder = filterData.sortOrder === "ASCENDING" ? "DESCENDING" : "ASCENDING";
    setfilterData({
      ...filterData,
      sortColumn: sortColumn,
      sortOrder: newSortOrder,
    });
  };

  const handleSortingClass = (columnName, isArrow = false) => {
    if (isArrow) {
      return filterData.sortColumn === columnName && filterData.sortOrder === "ASCENDING"
        ? "fa fa-arrow-up"
        : "fa fa-arrow-down";
    }
    return filterData.sortColumn === columnName ? "active" : "sortColumn";
  };

  const handleEditable = () => {
    setIsEditable(true);
  };

  const handleValueChange = (event, index, inputType) => {
    let { name, value } = event.target;
    if (inputType === 'number' && value.length === 5) {
      return false
    }
    let data = _.cloneDeep(productList);
    data[index][name] = value;
    setProductList(data);
  };

  const handleNumberValue = (event, index) => {
    let { name, value } = event.target;
    let data = _.cloneDeep(productList);
    data[index][name] = Number(value);
    setProductList(data);
  };

  const handleKeyDown = (evt) => {
    return ["e", "E", "+", "-", "."].includes(evt.key) && evt.preventDefault()
  }

  const handleEditField = (item, index, field, inputType) => {
    const isNumberInput = inputType === 'number';

    const inputProps = isNumberInput ? {
      onBlur: (e) => handleNumberValue(e, index),
      onKeyDown: handleKeyDown,
      min: 0,
    } : {};

    return (
      <>
        {isEditable && Number(item.NoOfPrints) === 0 ? (
          <input
            type={inputType}
            className="form-control inputBarCodeInput"
            value={inputType === 'number' ? Number(item[field]).toString() : item[field]}
            name={field}
            onChange={(e) => handleValueChange(e, index, inputType)}
            maxLength={50}
            {...inputProps}
          />
        ) : (
          <>{item[field]}</>
        )}
      </>
    )
  }

  const handleSaveData = async (e) => {
    e.preventDefault();
    let data = [...productList];
    let data1 = [...barcodeList?.listBarCodeProductEntities];
    let findEdited = _.differenceWith(data, data1, _.isEqual);
    setLoader(true);
    let dataRes = await Promise.resolve(dispatch(updateBarCodes(findEdited)));
    setLoader(false);
    if (dataRes.data.Status === 200) {
      toast.success(dataRes.data.Entity);
      setIsEditable(false);
      fetchBarcodeDataList();
    } else {
      toast.error('Error: Check error file.');
      window.open(dataRes.data.Entity);
    }
  }

  const handleRowClass = (item) => {
    let className = '';

    if (Number(item.NoOfPrints)) {
      className = 'disabledRow';
    } else {
      if (item.IsUpdated) {
        className = 'editedRowBarCode';
      }
    }
    return className;
  }

  return (
    <main>
      <div className="main__container">
        <div className="main__tile_heading cus-btn-include-sec">
          <span>Barcode Listing</span>
          <div className="top_right_button_container">
            <button
              className="btn cus-seconday-bg-btn top-btn saveBtn"
              onClick={handleSaveData}
              disabled={loader || !isFormEdited || !isEditable}
            >
              Save
            </button>
            <button
              className="btn cus-seconday-bg-btn top-btn"
              onClick={handleEditable}
              disabled={loader || isEditable || !productList.length}
            >
              Edit
            </button>
            <Button
              className="cus-primary-transparent-btn"
              onClick={() => navigate("/barcode/barcode-upload")}
            >
              Back
            </Button>
          </div>
        </div>

        {loader && <LoaderSpinner />}

        <div className="main_content cus-page-content-sec">
          <div className="main_content_table_history custom-scroll-remove">
            <div className="pt-2">
              {
                <BarcodeListingFilter
                  dispatch={dispatch}
                  useSelector={useSelector}
                  filterData={filterData}
                  setfilterData={setfilterData}
                  fetchBarcodeDataList={fetchBarcodeDataList}
                  handleReset={handleReset}
                  id={id}
                  productList={productList}
                />
              }
            </div>
            <div className="tableFixHead incomingTbl">
              <Table
                className={`table table-height horizontalAlignedTbl cus-left-right-col-layout table ${loader ? "disableTble" : ""
                  }`}
              >
                <thead className="tbl-btch">
                  <tr className="table-rw-batch">
                    <th width="10%">
                      <div
                        className={handleSortingClass("CompanySellerName")}
                        onClick={(e) =>
                          handleSortingColumn(e, "CompanySellerName")
                        }
                        id="CompanySellerName"
                      >
                        Company / Seller Name
                        <i
                          className={handleSortingClass(
                            "CompanySellerName",
                            true
                          )}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </th>
                    {!id && (
                      <>
                        <th width="10%">
                          <div
                            className={handleSortingClass("FileName")}
                            onClick={(e) =>
                              handleSortingColumn(e, "FileName")
                            }
                            id="FileName"
                          >
                            File Name
                            <i
                              className={handleSortingClass(
                                "FileName",
                                true
                              )}
                              aria-hidden="true"
                            ></i>
                          </div>
                        </th>
                        <th width="10%">
                          <div
                            className={handleSortingClass("TemplateName")}
                            onClick={(e) =>
                              handleSortingColumn(e, "TemplateName")
                            }
                            id="TemplateName"
                          >
                            Template Name
                            <i
                              className={handleSortingClass(
                                "TemplateName",
                                true
                              )}
                              aria-hidden="true"
                            ></i>
                          </div>
                        </th></>)}
                    <th width="10%">
                      <div
                        className={handleSortingClass("OldBarCodeValue")}
                        onClick={(e) =>
                          handleSortingColumn(e, "OldBarCodeValue")
                        }
                        id="OldBarCodeValue"
                      >
                        Old Barcode Value
                        <i
                          className={handleSortingClass(
                            "OldBarCodeValue",
                            true
                          )}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </th>
                    <th width="10%">
                      <div
                        className={handleSortingClass("ProductTitle")}
                        onClick={(e) => handleSortingColumn(e, "ProductTitle")}
                        id="ProductTitle"
                      >
                        Product Title
                        <i
                          className={handleSortingClass("ProductTitle", true)}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </th>
                    <th width="10%">
                      <div
                        className={handleSortingClass("ProductSubTitle")}
                        onClick={(e) =>
                          handleSortingColumn(e, "ProductSubTitle")
                        }
                        id="ProductSubTitle"
                      >
                        Product Sub Title
                        <i
                          className={handleSortingClass(
                            "ProductSubTitle",
                            true
                          )}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </th>
                    <th width="10%">
                      <div
                        className={handleSortingClass("NewBarCodeValue")}
                        onClick={(e) =>
                          handleSortingColumn(e, "NewBarCodeValue")
                        }
                        id="NewBarCodeValue"
                      >
                        New Barcode Value
                        <i
                          className={handleSortingClass(
                            "NewBarCodeValue",
                            true
                          )}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </th>
                    <th width="10%">
                      <div
                        className={handleSortingClass("Threshold")}
                        onClick={(e) => handleSortingColumn(e, "Threshold")}
                        id="Threshold"
                      >
                        Threshold
                        <i
                          className={handleSortingClass("Threshold", true)}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </th>
                    <th width="10%">
                      <div
                        className={handleSortingClass("Source")}
                        onClick={(e) => handleSortingColumn(e, "Source")}
                        id="Source"
                      >
                        Source
                        <i
                          className={handleSortingClass("Source", true)}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </th>
                    <th width="10%">
                      <div
                        className={handleSortingClass("NoOfPrints")}
                        onClick={(e) => handleSortingColumn(e, "NoOfPrints")}
                        id="NoOfPrints"
                      >
                        No of Prints
                        <i
                          className={handleSortingClass("NoOfPrints", true)}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </th>
                    <th width="10%">
                      <div
                        className={handleSortingClass("CreatedByName")}
                        onClick={(e) => handleSortingColumn(e, "CreatedByName")}
                        id="CreatedByName"
                      >
                        Created By
                        <i
                          className={handleSortingClass("CreatedByName", true)}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </th>
                    <th width="10%">
                      <div
                        className={handleSortingClass("createdDate")}
                        onClick={(e) => handleSortingColumn(e, "createdDate")}
                        id="createdDate"
                      >
                        Created Date
                        <i
                          className={handleSortingClass("createdDate", true)}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </th>
                    <th>
                      <div
                        className={handleSortingClass("ModifiedByName")}
                        onClick={(e) =>
                          handleSortingColumn(e, "ModifiedByName")
                        }
                        id="ModifiedByName"
                      >
                        Modified By
                        <i
                          className={handleSortingClass("ModifiedByName", true)}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </th>
                    <th width="10%">
                      <div
                        className={handleSortingClass("modifiedDate")}
                        onClick={(e) => handleSortingColumn(e, "modifiedDate")}
                        id="modifiedDate"
                      >
                        Modified Date
                        <i
                          className={handleSortingClass("modifiedDate", true)}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="tbl-bt-body">
                  {productList.map((item, index) => (
                    <tr key={item.Id} className={handleRowClass(item)}>
                      <td>
                        {item.CompanySellerName}
                        {item.IsUpdated && <div className="ribbon-wrapper"><div class="ribbon-1 left">Edited</div></div>}
                      </td>
                      {!id && (<>
                        <td>{item.FileName}</td>
                        <td>{item.TemplateName}</td></>
                      )}
                      <td>
                        {handleEditField(item, index, 'OldBarCodeValue', 'text')}
                      </td>
                      <td>
                        {handleEditField(item, index, 'ProductTitle', 'text')}
                      </td>
                      <td>
                        {handleEditField(item, index, 'ProductSubTitle', 'text')}
                      </td>
                      <td>
                        {handleEditField(item, index, 'NewBarCodeValue', 'text')}
                      </td>
                      <td>
                        {handleEditField(item, index, 'Threshold', 'number')}
                      </td>
                      <td>
                        {handleEditField(item, index, 'Source', 'text')}
                      </td>
                      <td>{item.NoOfPrints}</td>
                      <td><p className="capitalize">{item.CreatedByName}</p></td>
                      <td>{moment(item.createdDate).format(
                        "MM-DD-YYYY | h:mm:ss A"
                      )}</td>
                      <td><p className="capitalize">{item.ModifiedByName}</p></td>
                      <td>{moment(item.modifiedDate).format(
                        "MM-DD-YYYY | h:mm:ss A"
                      )}</td>
                    </tr>
                  ))}
                  {barcodeStatus === "success" &&
                    productList.length === 0 &&
                    renderTableRow(14, "No Result Found...", 'left')}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
        <BatchPagination
          gridData={productList}
          totalRecords={totalRecords}
          numberOfPage={filterData.pageSize}
          numOfPage={numOfPage}
          loader={loader}
          currentPage={filterData.pageIndex}
          totalPages={totalPages}
          setCurrentPage={(value) => setfilterData({ ...filterData, pageIndex: value })}
          setNumberOfPage={(value) => setfilterData({ ...filterData, pageSize: value })}
        />
      </div>
    </main>
  );
};

export default BarcodeUploadedList;
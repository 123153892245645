import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button } from "bootstrap-4-react/lib/components";
import { useNavigate } from "react-router-dom";
import "./csvList.css";
import Table from "react-bootstrap/Table";
import { UploadDialog } from "./csvListUploadForm";
import { apiUrl, featureSubmodulePermission, numOfPage, permssionCreate, permssionDelete, permssionView, settings, SUBF_CSVUPLOAD } from "../../constant";
import { toast } from "react-toastify";
import moment from 'moment/moment';
import AlertDialogModal from "../common/AlertDialog";
import { useDispatch } from "react-redux";
import { deleteFileMaster, csvUploadListBackDetails } from "../../store/actions";
import axios from "axios";
import BatchPagination from "../batch/BatchPagination";
import LoaderSpinner from "../common/Loader";
import { checkPermission, renderTableRow } from "../../config/utils";

export function CSVList(props) {
  const navigate = useNavigate();
  const [gridData, setGridData] = useState([]);
  const [location, setlocation] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  let dataLoad = {
    pageIndex: 1,
    pageSize: 30,
    totalPages: 1,
    numberOfPage: 30,
    sortColumn: '',
    sortOrder: 'DESCENDING',
    totalRecords: 0,
  }

  const [filterData, setfilterData] = useState({});
  const [paginationGridData, setPaginationGridData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const createdBy = useSelector((state) => state?.auth?.user);
  const csvUploadListPrevState = useSelector((state) => state?.shipment?.csvUploadListPrevState);
  let dispatch = useDispatch();

  useEffect(() => {
    if (!_.isEmpty(csvUploadListPrevState)) {
      setfilterData({ ...dataLoad, ...csvUploadListPrevState });
    } else {
      setfilterData({ ...dataLoad });
    }
  }, []);

  useEffect(() => {
    if (!_.isEmpty(filterData)) {
      getAllRecords();
    }
  }, [createdBy, filterData.numberOfPage, filterData.pageIndex, filterData.sortColumn, filterData.sortOrder]);

  useEffect(() => {
    if (window.location.href.includes("csvUpload")) {
      setlocation(true);
    }
  }, [window.location.href]);

  /* istanbul ignore next */
  const handleSortingColumn = (event, sortColumn) => {
    event.preventDefault();
    let newSortOrder = filterData.sortOrder === "ASCENDING" ? "DESCENDING" : "ASCENDING";
    setfilterData({
      ...filterData,
      sortColumn: sortColumn,
      sortOrder: newSortOrder,
    });
  };

  const handleSortingClass = (columnName, isArrow = false) => {
    if (isArrow) {
      return filterData.sortColumn === columnName && filterData.sortOrder === "ASCENDING"
        ? "fa fa-arrow-up"
        : "fa fa-arrow-down";
    }
    return filterData.sortColumn === columnName ? "active" : "sortColumn";
  };

  const setCurrentPagePagination = (value) => {
    setfilterData({ ...filterData, pageIndex: value });
  }
  const setTotalNumberOfPage = (value) => {
    setfilterData({ ...filterData, numberOfPage: value, pageIndex: 1 });
  }

  const getAllRecords = () => {
    setLoader(true);
    axios.get(
      `${apiUrl}/api/GetCsvFileMasterDetails?IsAdmin=${true}&PageIndex=${filterData.pageIndex}&SortColumn=${filterData.sortColumn}&SortOrder=${filterData.sortOrder}&PageSize=${filterData.numberOfPage}&CompanyId=${createdBy?.Value?.companyId}&CompanySellerId=${createdBy?.Value?.companySellerId}&SellerId=${createdBy?.Value?.sellerId}&role=${createdBy?.Value?.role}&CreatedBy=${createdBy?.Value?.employeeId}&ModifiedDate=05-15-2023&ModifiedBy=${createdBy?.Value?.employeeId}&CreatedDate=05-15-2023&IsActive=${true}`
    ).then((response) => {
      const resp = response.data.Entity.listCsvFileMaster;
      if (response.data.Status === 200) {
        setGridData(Object.values(resp));
        setPaginationGridData(response?.data?.Entity?.listCsvFileMaster);
        setTotalPages(Math.ceil(response?.data?.Entity?.TotalRecords / filterData.numberOfPage))
        setfilterData({ ...filterData, totalRecords: response?.data?.Entity?.TotalRecords })
        setLoader(false);
      }
    });
  };

  const handleYesDeleteAlert = async () => {
    let currentOrderSelected = { ...currentSelectedRecord };
    let postData = {
      companyId: createdBy?.Value?.companyId,
      sellerId: createdBy?.Value?.sellerId,
      createdBy: createdBy?.Value?.employeeId,
      createdDate: "2023-03-20T06:46:53.825Z",
      modifiedBy: 0,
      modifiedDate: "2023-03-20T06:46:53.825Z",
      isActive: true,
    };
    let deleteOrderResponse = await dispatch(
      deleteFileMaster(currentOrderSelected.id, postData)
    );
    if (deleteOrderResponse.data.Status === 200) {
      getAllRecords();
      toast.success("Order deleted Successfully");
    } else {
      toast.error("Something went wrong", 1000);
    }
    handleCancelDeleteAlert();
  };
  const [currentSelectedRecord, setCurrentSelectedRecord] = React.useState({});
  const [showDeleteShipDialog, setShowDeleteShipDialog] = React.useState(false);
  const handleCancelDeleteAlert = () => {
    setCurrentSelectedRecord({});
    setShowDeleteShipDialog(false);
  };

  const childCallRefresh = () => {
    getAllRecords();
  };

  const handleNavigation = (item) => {
    navigate(`/createIntegration/csv-3pl-${item.IntegrationId}`, {
      state: {
        url: `/csvUpload`,
      },
    })
  }

  return (
    <main>
      <div className="main__container">
        <div className="main__tile_heading cus-btn-include-sec">
          <>
            {location ? 'CSV Upload' : 'Marketplace Mapping'}
          </>
          <div className="top_right_button_container">
            {checkPermission(settings, SUBF_CSVUPLOAD, permssionCreate, featureSubmodulePermission, props.employeePermissions) && (
              <Button
                className="cus-seconday-bg-btn top-btn"
                onClick={() => setModalShow(true)}
              >
                Upload CSV
              </Button>
            )}
            {!location && (
              <Button
                className="cus-seconday-bg-btn top-btn"
                onClick={() => {
                  navigate("/createcsv");
                }}
              >
                View CSV Integration
              </Button>
            )}
            <Button
              className="cus-primary-transparent-btn"
              onClick={() => {
                navigate("/setting");
                dispatch(csvUploadListBackDetails({}));
              }}
            >
              Back
            </Button>
            <div>
              {modalShow && (
                <UploadDialog
                  onHide={() => setModalShow(false)}
                  show={modalShow}
                  parentCallRefresh={childCallRefresh}
                />
              )}
            </div>
          </div>
        </div>
        {loader && <LoaderSpinner />}
        <div className="main_content cus-page-content-sec">
          <div className="main_content_table incomingTbl tableFixHead">
            <Table className="table-borderless tableMobile">
              <thead border="Green">
                <tr className="trow">
                  <th width="15%">
                    <div
                      className={handleSortingClass("FileName")}
                      onClick={(e) => handleSortingColumn(e, "FileName")}
                    >
                      File Name
                      <i
                        className={handleSortingClass("FileName", true)}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </th>
                  <th width="10%">
                    <div
                      className={handleSortingClass("Status")}
                      onClick={(e) => handleSortingColumn(e, "Status")}
                    >
                      Status
                      <i
                        className={handleSortingClass("Status", true)}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </th>
                  <th width="15%">
                    <div
                      className={handleSortingClass("IntegrationName")}
                      onClick={(e) => handleSortingColumn(e, "IntegrationName")}
                    >
                      Integration Name
                      <i
                        className={handleSortingClass("IntegrationName", true)}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </th>
                  <th>
                    <div
                      className={handleSortingClass("DateTime")}
                      onClick={(e) => handleSortingColumn(e, "DateTime")}>
                      Upload Date/Time
                      <i
                        className={handleSortingClass("DateTime", true)}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </th>
                  <th>
                    <div
                      className={handleSortingClass("RowsSubmitted")}
                      onClick={(e) => handleSortingColumn(e, "RowsSubmitted")}
                    >
                      Rows Submitted
                      <i
                        className={handleSortingClass("RowsSubmitted", true)}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </th>
                  <th>
                    <div
                      className={handleSortingClass("OrderSubmitted")}
                      onClick={(e) => handleSortingColumn(e, "OrderSubmitted")}
                    >
                      Orders Submitted
                      <i
                        className={handleSortingClass("OrderSubmitted", true)}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </th>

                  <th>
                    <div
                      className={handleSortingClass("OrdersAccepted")}
                      onClick={(e) => handleSortingColumn(e, "OrdersAccepted")}
                    >
                      Orders Accepted
                      <i
                        className={handleSortingClass("OrdersAccepted", true)}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {gridData.map((i) => {
                  return (
                    <tr key={i.id}>
                      <td className="td-data-csv">{i.FileName}</td>
                      <td className="td-data-csv-csv">
                        <lable
                          className={
                            i.Status === "Error"
                              ? "errorStatus"
                              : "activeStatus"
                          }
                        >
                          {i.Status}
                        </lable>
                        {i && i.Status === "Downloaded" ? (
                          <a
                            rel="noreferrer"
                            href={i.DownloadCsvURL}
                          >
                            <i
                              class="fa fa-cloud-download fa-lg"
                              aria-hidden="true"
                            ></i>
                          </a>
                        ) : ""}
                      </td>
                      {checkPermission(settings, SUBF_CSVUPLOAD, permssionView, featureSubmodulePermission, props.employeePermissions) && (
                        <td className="td-data-csv">
                          <span className="link v" onClick={() => {
                            handleNavigation(i);
                            dispatch(csvUploadListBackDetails(filterData));
                          }

                          }>
                            {i.IntegrationName}
                          </span>
                        </td>
                      )}
                      <td className="td-data-csv">
                        {moment(i.UploadedDate).format(
                          "MM-DD-YYYY | h:mm:ss A"
                        )}
                      </td>
                      <td className="td-data-csvs">{i.RowsSubmitted}</td>
                      <td className="td-data-csvs">{i.OrderSubmitted}</td>
                      <td className="td-data-csvs">{i.OrdersAccepted}</td>
                      <td className="td-data-csv">
                        {checkPermission(settings, SUBF_CSVUPLOAD, permssionDelete, featureSubmodulePermission, props.employeePermissions) && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-trash"
                            viewBox="0 0 16 16"
                            color="red"
                            cursor="pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowDeleteShipDialog(true);
                              setCurrentSelectedRecord(i);
                              setCurrentSelectedRecord(i);
                            }}
                          >
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                            <path
                              fill-rule="evenodd"
                              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                            />
                          </svg>
                        )}
                      </td>
                    </tr>
                  );
                })}
                {!loader && !gridData.length && renderTableRow(8, 'No Record Found')}
              </tbody>
            </Table>
          </div>
          <AlertDialogModal
            modalShow={showDeleteShipDialog}
            modalClass={"modalDeleteWrapper"}
            handleCancelDeleteAlert={handleCancelDeleteAlert}
            handleYesDeleteAlert={handleYesDeleteAlert}
          />
        </div>
        <BatchPagination
          gridData={paginationGridData}
          totalRecords={filterData.totalRecords}
          numberOfPage={filterData.numberOfPage}
          numOfPage={numOfPage}
          loader={loader}
          currentPage={filterData.pageIndex}
          totalPages={totalPages}
          setCurrentPage={setCurrentPagePagination}
          setNumberOfPage={setTotalNumberOfPage}
        />
      </div>
    </main>
  );
}
import React, { useEffect, useState } from "react";
import Select from "react-select";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { fetchCompanyFilters, getFilterSellers } from "../../store/actions/rateCard";
import { fetchAllMargin, getAllSellersList } from "../../store/actions";
import { getSelectList } from "../../config/utils";

const MarginFilter = (props) => {
  const {
    UserValue,
    handleFilterValue,
    handleFilter,
    numberOfPage,
    setSelllerIds,
    setCompanyIds,
    setSortOrder,
    setSortingColumn,
    setCurrentPage,
    selectedCompanies,
    setSelectedCompanies,
    selectedSellers,
    setSelectedSellers, sellerList, setSellerList, allSellers, setAllSellers,
  } = props;
  const { filterCompanies, filterAllSellers } = useSelector((state) => state.RateCardReducer);
  const [companyList, setCompanyList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    let sellerData = {
      "pageIndex": 1,
      "pageSize": 10,
      "sortColumn": "name",
      "sortOrder": "ASCENDING",
      "companyIdList": [0],
      screenType: 2,
    };
    let data = {
      pageIndex: 1,
      pageSize: 10,
      sortColumn: 'name',
      sortOrder: 'ascending'
    };
    dispatch(fetchCompanyFilters(data));
    dispatch(getFilterSellers(sellerData));
  }, [UserValue]);

  const fetchSellers = async (companyList) => {
    if (companyList?.length === 0) {
      setSellerList(allSellers);
      return;
    }
    let data = {
      companyIds: companyList,
    };
    let res = await getAllSellersList(data);
    if (res?.data?.Status === 200) {
      let dataList = res?.data?.Entity?.listSellerMasterOrdersEntity.map(
        (item) => {
          return {
            ...item,
            label: item.sellerAndCompany,
            value: item.id,
          };
        }
      );
      setSellerList(dataList);
    }
  };

  useEffect(() => {
    let sellerList = filterAllSellers?.EntityList || [];
    if (
      filterCompanies?.Entity &&
      filterCompanies?.Entity?.listCompanyMasterOrdersEntity.length
    ) {
      setCompanyList(
        getSelectList(
          filterCompanies?.Entity?.listCompanyMasterOrdersEntity,
          "Id",
          "name"
        )
      );
    }
    if (sellerList.length) {
      setAllSellers(getSelectList(sellerList, 'id', 'sellerName'));
      setSellerList(getSelectList(sellerList, 'id', 'sellerName'));
    }
  }, [filterCompanies, filterAllSellers]);

  ;

  const handleServiceChange = (e, type) => {
    if (type === "company") {
      setSelectedCompanies(e);
      if (e && e.length > 0) {
        let companyData = _.map(e, "Id");
        fetchSellers(companyData);
        if (selectedSellers?.length) {
          const filteredSellers = selectedSellers.filter(seller =>
            companyData.includes(parseInt(seller.companyIds))
          );
          setSelectedCompanies(e)
          setSelectedSellers(filteredSellers)
        }
      } else {
        setSelectedSellers([]);
        handleFilterValue([], "company");
        setSellerList(allSellers);
      }
    } else if (type === "seller") {
      setSelectedSellers(e);
      handleFilterValue(_.map(e, "id"), "seller");
    }
  };

  const handleResetFilter = async () => {
    setSelectedCompanies([]);
    setSelectedSellers([]);
    setCurrentPage(1);
    setSortingColumn("");
    setCompanyIds([]);
    setSelllerIds([]);
    setSellerList([]);
    setSortOrder("DESCENDING");
    let data = {
      pageIndex: 1,
      pageSize: numberOfPage,
      sortColumn: "",
      sortOrder: "DESCENDING",
      filterCompanyIds: [],
      filterSellerIds: [],
    };
    dispatch(fetchAllMargin(data));
    setSellerList(allSellers)
  };

  return (
    <div className={'filterContainer show historyFilter'}>
      <div className="row mb10">
        <div className="col-lg-4 col-sm-12 mobMb-10">
          <div className="row">
            <div className="col-12">
              <label className="labelHead">Company Name</label>
            </div>
            <div className="col-12 fieldAndLabel">
              <div className={"select_react carrierListSelect"}>
                <Select
                  closeMenuOnSelect={false}
                  isMulti
                  options={companyList}
                  value={selectedCompanies}
                  onChange={(e) => handleServiceChange(e, "company")}
                  className="activeSellerListInput"
                  classNamePrefix="list"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-12 ">
          <div className="row">
            <div className="col-12">
              <label className="labelHead">Seller Name</label>
            </div>
            <div className="col-12 fieldAndLabel">
              <div className={"select_react carrierListSelect"}>
                <Select
                  isMulti
                  options={sellerList}
                  value={selectedSellers}
                  onChange={(e) => handleServiceChange(e, "seller")}
                  className="activeSellerListInput"
                  classNamePrefix="listSeller"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-sm-12">
          <div className="col-12">
            <label
              className="labelHead"
              style={{ marginBottom: "26px" }}
            ></label>
          </div>
          <button
            className="btn cus-primary-transparent-btn top-btn"
            onClick={handleResetFilter}
            disabled={selectedCompanies?.length === 0 && selectedSellers?.length === 0 ? true : false}
          >
            Clear Filter
          </button>
          <button
            className="btn cus-seconday-bg-btn top-btn filterButton"
            onClick={handleFilter}
          >
            Apply Filter
          </button>
        </div>
      </div>
    </div>
  );
};

export default MarginFilter;

import React from 'react';
import { Dropdown } from "react-bootstrap";

export const MenuList = (props) => {
    const { children } = props;
    return (
        <Dropdown className="historyPopper">
            <Dropdown.Toggle className="opneDropDownMenu">
                <i
                    className="fa fa-ellipsis-v"
                    aria-hidden="true"
                ></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                <>
                    {children}
                </>
            </Dropdown.Menu>
        </Dropdown>
    );
};
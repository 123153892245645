import { batchConstant } from "../constants/batch.constant";
const initialState = {
    listData: {
        EntityList: [],
        TotalRecords: 0,
        unique_integration_names: [],
        unique_types: []
    },
    listDataStatus: '',
    listingBackDetails: {}
};
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action = '') {
    const { type, payload } = action;
    switch (type) {
        case batchConstant.GET_INTEGRATION_LIST_PENDING:
            return {
                ...state,
                listData: {
                    EntityList: [],
                    TotalRecords: 0,
                    unique_integration_names: [],
                    unique_types: []
                },
                listDataStatus: 'PENDING'
            };
        case batchConstant.GET_FILTER_STATUS:
            return {
                ...state,
                listData: payload,
                listDataStatus: 'SUCCESS'
            };
        case batchConstant.MARKET_PLACE_BACK_DETAILS:
            return {
                ...state,
                listingBackDetails: payload
            };
        default:
            return state;
    }
}

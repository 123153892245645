import { shipmentConstant } from '../constants'
const initialState = {
  employeeAddress: {},
  services: [],
  packages: [],
  countries: [],
  shipment: {},
  editShipmentDetails: {
    "channelID": 2335,
    "cargo_type": 2,
    "employeeId": 40,
    "fTel": "6267886281",
    "sTel": "9168045740",
    "ckid": "1",
    "Parcel_num": 1,
    "weight": "0.1",
    "note": "806905050401",
    "parcels": [
      {
        "box": "1",
        "sku": "YJ0022",
        "cnName": "waterproof bag",
        "enName": "waterproof bag",
        "price": "1.00",
        "num": 1,
        "cWeight": "0.01000",
      }
    ]
  },
  states: [],
  activeCSVList: [],
  shipmentList: [],
  shipmentListStatus: '',
  carriers: [],
  sellerCarrierList: [],
  companySellerList: [],
  signatureList: [],
  carrierServices: [],
  contentTypeList: [],
  dimensionUnitList: [],
  weightUnitList: [],
  csvUploadListPrevState: {},
};
export default function (state = initialState, action = '') {
  const { type, payload } = action;
  switch (type) {
    case shipmentConstant.GET_EMPLOYEE_ADDRESS_BY_ID_SUCCESS:
      return {
        ...state,
        employeeAddress: payload,
      };
    case shipmentConstant.RESET_CARRIERS:
      return {
        ...state,
        services: [],
      };
    case shipmentConstant.GET_ALL_SERVICES_SUCCESS:
      return {
        ...state,
        services: payload,
      };
    case shipmentConstant.GET_ALL_CARRIER_SUCCESS:
      return {
        ...state,
        carriers: payload,
      };
    case shipmentConstant.GET_ALL_PACKAGES_BY_SERVICE_ID_SUCCESS:
      return {
        ...state,
        packages: payload,
      };
    case shipmentConstant.GET_ALL_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: payload,
      };
    case shipmentConstant.CREATE_SHIPMENT_SUCCESS:
      return {
        ...state,
        shipment: payload,
      };
    case shipmentConstant.EDIT_SHIPMENT_SUCCESS:
      return {
        ...state,
        editShipmentDetails: payload,
      };
    case shipmentConstant.GET_STATES_SUCCESS:
      return {
        ...state,
        states: payload,
      };
    case shipmentConstant.GET_ACTIVE_CSV_LIST:
      return {
        ...state,
        activeCSVList: payload,
      };
    case shipmentConstant.GET_SHIPMENT_LIST:
      return {
        ...state,
        shipmentList: payload,
        shipmentListStatus: "success",
      };
    case shipmentConstant.GET_ALL_SELLER_CARRIER_SUCCESS:
      return {
        ...state,
        sellerCarrierList: payload,
      };
    case shipmentConstant.GET_ALL_COMPANY_SELLER_SUCCESS:
      return {
        ...state,
        companySellerList: payload,
      };
    case shipmentConstant.RESET_COMPANY_SELLER_LIST:
      return {
        ...state,
        companySellerList: [],
      };
    case shipmentConstant.GET_ALL_CARRIER_SERVICES_SUCCESS:
      return {
        ...state,
        carrierServices: payload,
      };
    case shipmentConstant.GET_SIGNATURE_LIST:
      return {
        ...state,
        signatureList: payload,
      };
    case shipmentConstant.GET_CONTENT_TYPE_LIST:
      return {
        ...state,
        contentTypeList: payload,
      };
    case shipmentConstant.GET_DIMENSION_UNIT_LIST:
      return {
        ...state,
        dimensionUnitList: payload,
      };
    case shipmentConstant.GET_WEIGHT_UNIT_LIST:
      return {
        ...state,
        weightUnitList: payload,
      };
      case shipmentConstant.CSV_UPLOAD_LIST_BACK_DETAILS:
      return {
        ...state,
        csvUploadListPrevState: payload,
      };
    default:
      return state;
  }
}


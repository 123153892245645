import { sidebarservice } from '../../services'
import { sidebarConstant } from '../constants'

export const getPermissionsByEmailId = () => {
    return (dispatch) => {
        return new Promise((resolve, reject) => {
            return sidebarservice.getPermissionByEmailId().then(result => {
                dispatch(getPermissionByEmailIdSuccess(result.data.EntityList))
                return resolve(result.data.EntityList)
            },
                error => {
                    return reject(error)
                })
        })
    }
}
export const getPermissionByEmailIdSuccess = response => ({
    type: sidebarConstant.GET_EMPLOYEE_PERMISSION_SUCCESS,
    payload: response
})
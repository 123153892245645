import React from 'react';
import { useNavigate } from 'react-router-dom';
import _ from "lodash";

const MarketPlaceListing = (props) => {
    const { listData } = props;
    const navigate = useNavigate();

    const navigateToSetting = (item) => {
        let dataIntegrationObj = { ...item.active_integration }
        navigate(`${item.channel_name}`, {
            state: {
                data: { ...dataIntegrationObj, ...item },
                backUrl: '/market-place',
                isIntegration: false,
            },
        });
    }

    return (
        <div className='integrationListWrapper row'>
            {listData && listData.length > 0 && listData.map((item) => (
                <div
                    className='col-lg-3 col-sm-12'
                    key={item.id}
                    onClick={() => navigateToSetting(item)}
                >
                    <div className='integrationCard'>
                        <div className="image">
                            <img
                                src={item.channel_logo}
                                alt='test'
                                style={{ maxWidth: '100%', cursor: 'pointer', maxHeight: '100px' }}
                            />
                        </div>
                        <button className='btn cus-primary-bg-btn custom-btn-shipment ingration'>{item.channel_name}</button>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default MarketPlaceListing;
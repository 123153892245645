import axios from "axios";
import authHeader from "./auth-header";
import { apiUrl } from "../constant";
// Test api url
const API_URL = "http://localhost:5000/api/";

const getCompany = () => {
  return axios.get(API_URL + "company", { headers: authHeader() });
};

const getcompanybyId = (Id) => {
  return axios.get(API_URL + "company/" + Id, {
    headers:
      authHeader()
  });
};

const deletecompanybyId = (Id) => {
  return axios.delete(API_URL + "company/?Id=" + Id, {
    headers:
      authHeader()
  });
};

const getUserRoleService = () => {
  return axios.get(`${apiUrl}/GetRoleList`);
};

export const getAllFilterCompanyService = (data) => {
  return axios.post(`${apiUrl}/api/company-filter`, data);
};
export const getAllSellerService = (data) => {
  return axios.post(`${apiUrl}/api/GetAllSeller`, data);
};

export const getAllSellerFilterService = (data) => {
  return axios.post(`${apiUrl}/api/seller-filter`, data);
};

export const updatePermissionService = (data) => {
  return axios.put(`${apiUrl}/api/updatepermission`, data);
};

export const getAllEmployeePermissionService = () => {
  return axios.get(`${apiUrl}/api/allemployeepermission`);
};

export default {
  getCompany,
  getcompanybyId,
  deletecompanybyId,
  getUserRoleService,
  getAllSellerService,
  getAllSellerFilterService,
  getAllFilterCompanyService,
};
import { MarginConstant, SellerCarrierConstant } from "../constants/Margin.constant";

const initialState = {
    marginList: {},
    marginListStatus: '',
    marginByIdResult: {},
    marginByIdResultStatus: '',
    allCarrerServicesStatus: '',
    allCarrerServicesList: {},
    carrierServices: {},
    carrierServicesStatus: '',
    sellerCarrierServiceprevState: {},
    sellerCarrierprevState: {},
};
export default function MarginReducer(state = initialState, action = '') {
    const { type, payload } = action;
    switch (type) {
        case MarginConstant.GET_ALL_MARGIN_LIST_PENDING:
            return {
                ...state,
                marginList: {},
                marginListStatus: 'pending'
            };
        case MarginConstant.GET_ALL_MARGIN_LIST_SUCCESS:
            return {
                ...state,
                marginList: payload,
                marginListStatus: 'success'
            };
        case MarginConstant.GET_ALL_MARGIN_LIST_FAILURE:
            return {
                ...state,
                marginList: {},
                marginListStatus: 'failure'
            };
        case MarginConstant.GET_MARGIN_BY_ID_PENDING:
            return {
                ...state,
                marginByIdResult: {},
                marginByIdResultStatus: 'pending'
            };
        case MarginConstant.GET_MARGIN_BY_ID_SUCCESS:
            return {
                ...state,
                marginByIdResult: payload,
                marginByIdResultStatus: 'success'
            };
        case MarginConstant.GET_MARGIN_BY_ID_FAILURE:
            return {
                ...state,
                marginByIdResult: {},
                marginByIdResultStatus: 'failed'
            };
        case SellerCarrierConstant.CARRIER_SERVICES_PENDING:
            return {
                ...state,
                allCarrerServicesList: {},
                allCarrerServicesStatus: 'pending'
            };
        case SellerCarrierConstant.CARRIER_SERVICES_SUCCESS:
            return {
                ...state,
                allCarrerServicesList: payload,
                allCarrerServicesStatus: 'success'
            };
        case SellerCarrierConstant.CARRIER_SERVICES_FAILURE:
            return {
                ...state,
                allCarrerServicesList: {},
                allCarrerServicesStatus: 'failed'
            };
        case SellerCarrierConstant.SERVICES_BY_COMPANY_SELLER_ID_PENDING:
            return {
                ...state,
                carrierServices: {},
                carrierServicesStatus: 'pending'
            };
        case SellerCarrierConstant.SERVICES_BY_COMPANY_SELLER_ID_SUCCESS:
            return {
                ...state,
                carrierServices: payload,
                carrierServicesStatus: 'success'
            };
        case SellerCarrierConstant.SERVICES_BY_COMPANY_SELLER_ID_FAILED:
            return {
                ...state,
                carrierServices: {},
                carrierServicesStatus: 'failed'
            };
        case SellerCarrierConstant.SELLER_CARRIER_SERVICE_SERVICE_CONFIG_BACK_DETAILS:
            return {
                ...state,
                sellerCarrierServiceprevState: payload,
            };
        case SellerCarrierConstant.SELLER_CARRIER_SERVICE_CONFIG_BACK_DETAILS:
            return {
                ...state,
                sellerCarrierprevState: payload,
            };
        default:
            return state;
    }
}


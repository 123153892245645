import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getLocalStorageItem, removeLocalStorageItem } from '../../services/StorageService';
import { validateAuthCode } from '../../store/actions/marketplace.action';
/* istanbul ignore next */
const HandleRedirection = () => {
    const navigate = useNavigate();
    const [tokenResponse, setTokenResponse] = useState({
        type: '',
        code: ''
    });

    useEffect(() => {
        let data = JSON.parse(getLocalStorageItem('redirect')) || {};
        if (data.channel_id === '10000' || data.channel_id === '10002') {
            const urlParams = new URLSearchParams(window.location.search);
            let code = urlParams.get('code');

            if (code) {
                setTokenResponse({
                    type: 'success',
                    code: code
                });
                handleValidateAuthCode(code);
            } else {
                setTokenResponse({
                    type: 'error',
                    code: ''
                });
            }
        } else {
            const urlParams = new URLSearchParams(window.location.search);
            let code = urlParams.get('amazon_callback_uri');
            let authCode = urlParams.get('spapi_oauth_code');
            if (code) {
                let amazon_state = urlParams.get('amazon_state');
                window.open(`${code}?amazon_state=${amazon_state}`);
            }
            if (authCode) {
                setTokenResponse({
                    type: 'success',
                    code: code
                });
                handleValidateAuthCode(authCode);
            } else {
                setTokenResponse({
                    type: 'error',
                    code: ''
                });
            }
        }
    }, []);

    const handleValidateAuthCode = async (code) => {
        let data = JSON.parse(getLocalStorageItem('redirect')) || {};
        if (code && Object.keys(data).length) {
            data.auth_code = code;
            let resData = await validateAuthCode(data);
            if (resData && resData.data.Status === 200) {
                console.log(resData.data);
            }
        }
    }

    const handleRedirectionClick = () => {
        removeLocalStorageItem('redirect');
        navigate('/marketplace-integration');
    }

    return (
        <main>
            <div className="main__container">
                <div className="cus-page-content-sec">
                    <div className="top_right_button_container cus-content-only-sec">
                        <div className='incoming_wrapper main_content_table addressDetailWrapper'>
                            <div className={tokenResponse.type === 'success' ? 'statusRedirect' : 'statusRedirect error'}>
                                {tokenResponse.type && (
                                    <>
                                        {tokenResponse.type === 'success' ? (
                                            <>
                                                <div className='icon'>
                                                    <i className="fa fa-check" aria-hidden="true"></i>
                                                </div>
                                                <h2 className='mb-10'>Access Granted</h2>
                                                <button
                                                    className="btn cus-primary-transparent-btn"
                                                    onClick={handleRedirectionClick}> Back to Integration</button>
                                            </>
                                        ) : (
                                            <>
                                                <div className='icon error'>
                                                    <i className="fa fa-times" aria-hidden="true"></i>
                                                </div>
                                                <h2 className='mb-10'>Access Denied</h2>

                                                <button
                                                    className="btn cus-primary-transparent-btn"
                                                    onClick={handleRedirectionClick}> Back to Integration</button>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default HandleRedirection;